import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Box, Grid, MenuItem } from '@mui/material';

import ButtonBlue from '../../../shared/general/ButtonBlue';
import DrawerCustom from '../../../shared/general/Drawer';
import TextFieldTheme from '../../../shared/general/TextFieldTheme';
import { updateManpower, getManpowerById } from '../../../../../actions/manpower';
import { useDispatch } from 'react-redux';
import NumberFormatTheme from '../../../shared/general/NumberFormatTheme';

const degrees = [
	{
		idDegrees: 1,
		degreeName: "ประถมศึกษา",
	},
	{
		idDegrees: 2,
		degreeName: "มัธยมศึกษา",
	},
	{
		idDegrees: 4,
		degreeName: "ปวช.",
	},
	{
		idDegrees: 5,
		degreeName: "ปวส.",
	},
	{
		idDegrees: 6,
		degreeName: "ปริญญาตรี",
	},
	{
		idDegrees: 7,
		degreeName: "ปริญญาโท",
	},
	{
		idDegrees: 8,
		degreeName: "ปริญญาเอก",
	},
];

const DrawerEducationsExperiencesLeft = (props) => {
	const { open, onClose, data, idManpower } = props;
	const { handleSubmit, control, formState: { errors, dirtyFields, isDirty } } = useForm({
    defaultValues: {
      "OESY(Y)": data["OESY(Y)"] || "",
      "OESY(M)": data["OESY(M)"] || "",
      educationDegree: data.educationDegree || "",
      currentSalary: data.currentSalary || "",
      expectedSalary: data.expectedSalary || "",
    }
  });

	const dispatch = useDispatch();

	const HandlerSubmit = (submitData) => {
    const formData = {};

    for (const key in dirtyFields) {
      formData[key] = submitData[key];
    }
    dispatch(updateManpower(idManpower, formData)).then(res => {
      dispatch(getManpowerById(idManpower));
    })
    onClose();
  }

	return (
		<DrawerCustom
			open={open}
			title={"แก้ไขประสบกรณ์ทำงานและระดับการศึกษา"}
			anchor="right"
		>
			<Box style={{ width: 400, padding: 16 }}>
				<form onSubmit={handleSubmit(HandlerSubmit)}>
					<Grid container spacing={2}>
						<Grid item xs={6} sm={6}>
							<Controller
								control={control}
								name="OESY(Y)"
								// rules={{
								//   required: {value: true, message: "กรุณาเลือกประสบการณ์ทำงาน"},
								// }}
								render={({field})=>(
									<TextFieldTheme
										{...field}
										label="ประสบการณ์ทำงาน(ปี)"
										helperText={errors && errors['OESY(Y)'] && errors['OESY(Y)'].message}
										error={errors && errors['OESY(Y)'] ? true: false}
										select
										SelectProps={{
											displayEmpty: true
										}}
										InputLabelProps={{
											shrink: true,
										}}
									>
										<MenuItem value="">ไม่มี</MenuItem>
										<MenuItem value="1">1 ปี</MenuItem>
										<MenuItem value="2">2 ปี</MenuItem>
										<MenuItem value="3">3 ปี</MenuItem>
										<MenuItem value="4">4 ปี</MenuItem>
										<MenuItem value="5">5 ปี</MenuItem>
										<MenuItem value="6">6 ปี</MenuItem>
										<MenuItem value="7">7 ปี</MenuItem>
										<MenuItem value="8">8 ปี</MenuItem>
										<MenuItem value="9">9 ปี</MenuItem>
										<MenuItem value="10">10 ปี</MenuItem>
										<MenuItem value="11">11 ปี</MenuItem>
										<MenuItem value="12">12 ปี</MenuItem>
										<MenuItem value="13">13 ปี</MenuItem>
										<MenuItem value="14">14 ปี</MenuItem>
										<MenuItem value="15">15 ปี</MenuItem>
										<MenuItem value="16">16 ปี</MenuItem>
										<MenuItem value="17">17 ปี</MenuItem>
										<MenuItem value="18">18 ปี</MenuItem>
										<MenuItem value="19">19 ปี</MenuItem>
										<MenuItem value="20">20 ปี</MenuItem>
									</TextFieldTheme>
								)}
							/>
						</Grid>
						<Grid item xs={6} sm={6}>
							<Controller
								control={control}
								name="OESY(M)"
								// rules={{
								//   required: {value: true, message: "กรุณาเลือกประสบการณ์ทำงาน"},
								// }}
								render={({field})=>(
									<TextFieldTheme
										{...field}
										label="ประสบการณ์ทำงาน(เดือน)"
										helperText={errors && errors["OESY(M)"] && errors["OESY(M)"].message}
										error={errors && errors["OESY(M)"] ? true: false}
										select
										SelectProps={{
											displayEmpty: true
										}}
										InputLabelProps={{
											shrink: true,
										}}
									>
										<MenuItem value="">ไม่มี</MenuItem>
										<MenuItem value="1">1 เดือน</MenuItem>
										<MenuItem value="2">2 เดือน</MenuItem>
										<MenuItem value="3">3 เดือน</MenuItem>
										<MenuItem value="4">4 เดือน</MenuItem>
										<MenuItem value="5">5 เดือน</MenuItem>
										<MenuItem value="6">6 เดือน</MenuItem>
										<MenuItem value="7">7 เดือน</MenuItem>
										<MenuItem value="8">8 เดือน</MenuItem>
										<MenuItem value="9">9 เดือน</MenuItem>
										<MenuItem value="10">10 เดือน</MenuItem>
										<MenuItem value="11">11 เดือน</MenuItem>
									</TextFieldTheme>
								)}
							/>
						</Grid>

						{/* <Grid item xs={12}>
							<Controller
								control={control}
								name="OESY"
								defaultValue={""}
								// rules={{
								// 	required: { value: true, message: "กรุณาเลือกประสบการณ์ทำงาน" },
								// }}
								render={({ field }) => (
									<TextFieldTheme
										{...field}
										label="ประสบการณ์ทำงาน"
										helperText={errors && errors.OESY && errors.OESY.message}
										error={errors && errors.OESY ? true : false}
										select
									>
										<MenuItem value="0">น้อยกว่า 1 ปี</MenuItem>
										<MenuItem value="1">1 ปี</MenuItem>
										<MenuItem value="2">2 ปี</MenuItem>
										<MenuItem value="3">3 ปี</MenuItem>
										<MenuItem value="4">4 ปี</MenuItem>
										<MenuItem value="5">5 ปี</MenuItem>
										<MenuItem value="6">6 ปี</MenuItem>
										<MenuItem value="7">7 ปี</MenuItem>
										<MenuItem value="8">8 ปี</MenuItem>
										<MenuItem value="9">9 ปี</MenuItem>
										<MenuItem value="10">10 ปี</MenuItem>
										<MenuItem value="11">11 ปี</MenuItem>
										<MenuItem value="12">12 ปี</MenuItem>
										<MenuItem value="13">13 ปี</MenuItem>
										<MenuItem value="14">14 ปี</MenuItem>
										<MenuItem value="15">15 ปี</MenuItem>
										<MenuItem value="16">16 ปี</MenuItem>
										<MenuItem value="17">17 ปี</MenuItem>
										<MenuItem value="18">18 ปี</MenuItem>
										<MenuItem value="19">19 ปี</MenuItem>
										<MenuItem value="20">20 ปีขึ้นไป</MenuItem>
									</TextFieldTheme>
								)}
							/>
						</Grid> */}
						<Grid item xs={12}>
							<Controller
								control={control}
								name="educationDegree"
								defaultValue={""}
								// rules={{
								// 	required: { value: true, message: "กรุณาเลือกระดับการศึกษา" },
								// }}
								render={({ field }) => (
									<TextFieldTheme
										{...field}
										label="ระดับการศึกษา"
										helperText={errors && errors.educationDegree && errors.educationDegree.message}
										error={errors && errors.educationDegree ? true : false}
										select
									>
										{degrees.map(d => (
											<MenuItem key={`deegree_${d.idDegrees}`} value={`${d.degreeName}`}>{d.degreeName}</MenuItem>
										))}
									</TextFieldTheme>
								)}
							/>
						</Grid>

						<Grid item xs={12}>
							<Controller
								control={control}
								name="currentSalary"
								rules={{
									// required: {value: true, message: "กรุณากรอกเงินเดือนปัจจุบัน"},
								}}
								render={({field})=>(
									<TextFieldTheme
										{...field}
										label="เงินเดือนปัจจุบัน (ไม่จำเป็น)"
										InputProps={{
											inputComponent: NumberFormatTheme,
										}}
										inputProps={{
											allowNegative: false,
											thousandSeparator:",",
											suffix:" บาท",
											onValueChange:(values) => {
												const {value} = values;
												field.onChange(value)
											}
										}}
										onChange={()=>{}}
										helperText={errors && errors.currentSalary && errors.currentSalary.message}
										error={errors && errors.currentSalary ? true: false}
									/>  
								)}
							/>
						</Grid>

						<Grid item xs={12}>
							<Controller
								control={control}
								name="expectedSalary"
								render={({field})=>(
									<TextFieldTheme
										{...field}
										label="เงินเดือนที่คาดหวัง (ไม่จำเป็น)"
										InputProps={{
											inputComponent: NumberFormatTheme,
										}}
										inputProps={{
											allowNegative: false,
											thousandSeparator:",",
											suffix:" บาท",
											onValueChange:(values) => {
												const {value} = values;
												field.onChange(value)
											}
										}}
										onChange={()=>{}}
										helperText={errors && errors.expectedSalary && errors.expectedSalary.message}
										error={errors && errors.expectedSalary ? true: false}
									/>  
								)}
							/>
						</Grid>

						<Grid item xs={12}>
							<Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
								<ButtonBlue
									variant="outlined"
									onClick={onClose}
								>ยกเลิก</ButtonBlue>
								<ButtonBlue
									variant="contained"
									type="submit"
									disabled={!isDirty}
								>ยืนยัน</ButtonBlue>
							</Box>
						</Grid>
					</Grid>
				</form>
			</Box>
		</DrawerCustom>
	);
};

export default DrawerEducationsExperiencesLeft;