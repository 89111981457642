import React, { useEffect, useState } from "react";
import { Autocomplete, Container, Grid, InputAdornment, Pagination, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getVendorSearch } from "../../../../actions/vendor";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import CardVendor from "../../shared/general/CardVendor";
import { useLocation } from "react-router-dom";

import { getAllWorkingLocations } from "../../../../actions/address";
import { getJobGroups } from "../../../../actions/job";

const StyledRoot = styled("div")({
  "& .part-filter": {
    marginBottom: 48,
    padding: 24,
    backgroundColor: "#f9fafb",
    borderRadius: 20,
    "& .MuiAutocomplete-root": {
      "& input": {
        padding: 0,
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiInputAdornment-root": {
        "& i": {
          fontSize: 24,
          color: "#919EAB",
        }
      },
      "& .MuiButtonBase-root": {
        "& i": {
          fontSize: 14,
          color: "#919EAB",
        }
      }
    }
  },
  "& .part-pagination": {
    paddingTop: 24,
    display: "flex",
    justifyContent: "flex-end",
  }
})

const VendorSearchPage = () => {

  const dispatch = useDispatch();

  const search = useLocation();

  const { control, setValue, getValues } = useForm({
    defaultValues: {
      province: null,
      jobGroup: null,
    }
  })

  const { result: vendorStore } = useSelector((state) => state.vendorSearch);
  const { result: AllWorkingLocations } = useSelector(state => state.workingLocation);
  const { result: AllJobGroups } = useSelector(state => state.jobGroups);

  const [vendors, setVendors] = useState([]);
  const [page, setPage] = useState(1);

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  }

  const onSubmitHandler = () => {
    let formData = getValues();
    let query = {
      province: (formData.province && formData.province.workingLocationNameTH) || null,
      jobGroup: (formData.jobGroup && formData.jobGroup.idJobGroup) || null
    };
    dispatch(getVendorSearch(query));
  }

  useEffect(() => {
    dispatch(getVendorSearch());
    dispatch(getAllWorkingLocations());
    dispatch(getJobGroups());
  }, [])

  useEffect(() => {
    if(vendorStore !== null){
      setVendors([...vendorStore].reverse());
    }
  }, [vendorStore])

  useEffect(() => {
    let province = new URLSearchParams(search.search).get("province");
    if(province !== null && AllWorkingLocations !== null){
      let val = AllWorkingLocations.find(location => location.workingLocationNameTH.includes(province));
      setValue("province",val);
      onSubmitHandler();
    }
  },[AllWorkingLocations]);

  useEffect(() => {
    let jobGroup = new URLSearchParams(search.search).get("jobGroup");
    if(jobGroup !== null && AllJobGroups !== null){
        let val = AllJobGroups.find(group => parseInt(group.idJobGroup) === parseInt(jobGroup));
        setValue("jobGroup",val);
        onSubmitHandler();
    }
  },[AllJobGroups]);

  const onClickFavoriteButton = (index, isAdd) => {
    let temp = [...vendors];
    temp[index].isFavorite = isAdd ? 1 : 0;
    setVendors(temp);
  };

  return(
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="part-filter">
          <form>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="province"
                  render={({ field: { onChange, value }}) => (
                    <Autocomplete
                      options={AllWorkingLocations || []}
                      popupIcon={<i className="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) => option.workingLocationNameTH}
                      isOptionEqualToValue={(option, value) => option.workingLocationNameTH === value.workingLocationNameTH}
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.workingLocationNameTH}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder="จังหวัด"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="fa-light fa-location-dot"></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, data) => {
                        onChange(data);
                        onSubmitHandler();
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                    control={control}
                    name="jobGroup"
                    render={({ field: { onChange, value }}) => (
                      <Autocomplete
                        fullWidth
                        options={AllJobGroups || []}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => option.jobGroupName}
                        isOptionEqualToValue={(option, value) => option.jobGroupName === value.jobGroupName}
                        renderOption={(props, option) => (
                          <li {...props}>
                            {option.jobGroupName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            placeholder="ประเภทกลุ่มงาน"
                            InputProps={{
                              ...params.InputProps
                            }}
                          />
                        )}
                        onChange={(_, data) => {
                          onChange(data);
                          onSubmitHandler()
                        }}
                        value={value}
                      />
                    )}
                  />
              </Grid>
            </Grid>
          </form>
        </div>

        <div>
          <Grid container columnSpacing={4} rowSpacing={8}>
            {
              vendors.length > 0 ? vendors
              .slice((page-1) * 12, ((page-1) * 12)+ 12)
              .map((vendor, index) => (
                <Grid key={`vendor_${index}`} item xs={4}>
                  <CardVendor 
                    vendor={vendor} 
                    favorite={!!vendor.isFavorite}
                    index={index}
                    onClickHandler={onClickFavoriteButton}
                  />
                </Grid>
              ))
              :
              <Grid item xs={12} style={{ paddingTop:"100px", paddingBottom:"100px" }}>
                  <Typography variant="h5" align="center">ไม่พบรายชื่อคู่ธุรกิจ</Typography>
              </Grid>
            }
          </Grid>
        </div>

        {vendors.length > 0 && 
        <div className="part-pagination">
          <Pagination
            count={Math.ceil(vendors.length / 12) || 1}
            shape="rounded"
            onChange={onChangePage}
            page={page}
          />
        </div>}

      </Container>
    </StyledRoot>
  )
}

export default VendorSearchPage;