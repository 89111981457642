import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import CardStyle from "./Card";
import { Skeleton } from "@mui/material";

const StyledCardContent = styled(CardContent)({
  borderRadius: 20,
  backgroundColor: "#FFFFFF",
  "& .part-one": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    justifyContent: "space-between",
  },
  "& .part-two": {
    marginBottom: 16,
  },
  "& .part-three": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .part-four": {},
  "& .part-five": {
    "& .wrap-item": {
      display: "flex",
      color: "#637381",
      alignItems: "center",
    },
  },
});

const CardRequestSkeleton = () => {
  return (
    <CardStyle>
      <StyledCardContent>
        <div className={`part-one`}>
          <Skeleton variant="rectangular" style={{width: 64, height: 64}}/>
          <div style={{ textAlign: "end" }}>
            <Skeleton variant="text" style={{width: 60}}/>
            <Skeleton variant="text" style={{width: 30}}/>
          </div>
        </div>
        <div className={`part-two`}>
          <div>
            <Skeleton variant="text" style={{width: 180}}/>
            <Skeleton variant="text" style={{width: 250}}/>
          </div>
        </div>
        <div className={`part-three`}>
          <Skeleton variant="text" style={{width: 150}}/>
        </div>
        <div className={`part-four`}>
          <Skeleton variant="text" style={{width: 120}}/>
        </div>
        <Divider
          style={{
            margin: "16px 0px",
            borderWidth: "0px 0px thin",
            borderColor: "rgba(145, 158, 171, 0.24)",
            borderStyle: "dashed",
          }}
        />
        <div className={`part-five`}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <div className={`wrap-item`}>
                <Skeleton variant="text" style={{width: 100}}/>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={`wrap-item`}>
                <Skeleton variant="text" style={{width: 100}}/>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={`wrap-item`}>
                <Skeleton variant="text" style={{width: 100}}/>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={`wrap-item`}>
                <Skeleton variant="text" style={{width: 100}}/>
              </div>
            </Grid>
          </Grid>
        </div>
      </StyledCardContent>
    </CardStyle>
  );
};

export default CardRequestSkeleton;
