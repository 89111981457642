import React, { useState } from "react";
import { styled, Tab, Tabs, Typography } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import ProfilePanel from "./profilePanel";
import AddressPanel from "./addressPanel";
import BankPanel from "./bankPanel";
import InformationPanel from "./informationPanel";
import GeneretorChildsCompany from "./generatorChildsCompany";
import AdminSetting from "./adminSetting";

const StyledRoot = styled("div")({
  padding: 24,
  paddingTop: 16,
  "& .MuiTabs-root": {
    marginBottom: 16,
  },
  "& i": {
    marginRight: 8,
  }
});

const RightPanel = (props) => {

  const { company, bankDetail, banks, companyAddress, branches } = props;

  const [tabValue, setTabValue] = useState(0);

  const [adminList, setAdminList] = useState(company.admin);

  const handleTabChangeHandler = (event, newValue) => {
    setTabValue(newValue);
  }

  return(
    <CardStyle>
      <StyledRoot>
        <Tabs value={tabValue} onChange={handleTabChangeHandler} variant="scrollable">
          <Tab label={<Typography><i className="fa-solid fa-building"></i>บริษัท</Typography>}></Tab>
          <Tab label={<Typography><i className="fa-solid fa-address-card"></i>ที่อยู่</Typography>}></Tab>
          <Tab label={<Typography><i className="fa-solid fa-building-columns"></i>ธนาคาร</Typography>}></Tab>
          <Tab label={<Typography><i className="fa-solid fa-circle-info"></i>รายละเอียด</Typography>}></Tab>
          <Tab label={<Typography><i className="fa-solid fa-share-from-square"></i>ลิงก์บริษัทลูก</Typography>}></Tab>
          <Tab label={<Typography><i className="fa-solid fa-users-gear"></i>ผู้ดูแล</Typography>}></Tab>
        </Tabs>
        {tabValue === 0 && <ProfilePanel company={company}/>}
        {tabValue === 1 && <AddressPanel company={company} companyAddress={companyAddress} branches={branches}/>}
        {tabValue === 2 && <BankPanel banks={banks} bankDetail={bankDetail} company={company}/>}
        {tabValue === 3 && <InformationPanel company={company}/>}
        {tabValue === 4 && <GeneretorChildsCompany />}
        {tabValue === 5 && <AdminSetting adminList={adminList} setAdminList={setAdminList}/>}
      </StyledRoot>
    </CardStyle>
  )
}

export default RightPanel;