import React, { useState } from "react";
import { Box, Button, Dialog, styled, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDispatch } from "react-redux";
import { deleteContractCompany, deleteContractVendor } from "../../../../actions/contract";

const StyledRoot = styled(Box)({
  padding: "24px 16px",
  display:"flex",
  flexDirection:"column",
  // alignItems:"center",
  boxSizing: "border-box",
  "& .text":{
    marginTop: 24,
    fontSize: 20,
    textAlign: "center",
  },
})

const DialogDeleteContract = (props) => {

  const { open, id, filename, onClose, refreshContract } = props;

  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch()

  const onConfirmDelete = () => {
    setIsFetching(true);
    dispatch(deleteContractVendor(id)).then(res => {
      if(res.status === 200){
        setIsFetching(false);
        onClose();
        refreshContract()
      }
    });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <StyledRoot>
        <Box className="text" display="flex" justifyContent="center" alignItems="center">
          <i className="fa-regular fa-triangle-exclamation" style={{marginRight: 8, color: "#ffcc00"}}></i>
          <Typography fontSize={20}>ยืนยันการลบสัญญา ?</Typography>
        </Box>
        <Typography marginTop="24px" textAlign="center">{filename}</Typography>
        <Box display="flex" justifyContent="space-between" paddingTop="32px">
          <ButtonBlue variant="outlined" onClick={onClose}>ยกเลิก</ButtonBlue>
          <Button variant="outlined" color="error" sx={{borderRadius: "8px"}} onClick={onConfirmDelete} disabled={isFetching}>ลบ</Button>
        </Box>
      </StyledRoot>
    </Dialog>
  )
}

export default DialogDeleteContract