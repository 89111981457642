export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const USERS_FETCHING = "USERS_FETCHING";
export const USERS_FAILED = "USERS_FAILED";
export const USERS_SUCCESS = "USERS_SUCCESS";

export const USERS_VENDOR_FREE_FETCHING = "USERS_VENDOR_FREE_FETCHING";
export const USERS_VENDOR_FREE_FAILED = "USERS_VENDOR_FREE_FAILED";
export const USERS_VENDOR_FREE_SUCCESS = "USERS_VENDOR_FREE_SUCCESS";

export const USERPROFILE_FETCHING = "USERPROFILE_FETCHING";
export const USERPROFILE_FAILED = "USERPROFILE_FAILED";
export const USERPROFILE_SUCCESS = "USERPROFILE_SUCCESS";

export const USERPROFILE_VENDOR_MANPOWER_FETCHING = "USERPROFILE_VENDOR_MANPOWER_FETCHING";
export const USERPROFILE_VENDOR_MANPOWER_FAILED = "USERPROFILE_VENDOR_MANPOWER_FAILED";
export const USERPROFILE_VENDOR_MANPOWER_SUCCESS = "USERPROFILE_VENDOR_MANPOWER_SUCCESS";

export const COURSE_FETCHING = "COURSE_FETCHING";
export const COURSE_FAILED = "COURSE_FAILED";
export const COURSE_SUCCESS = "COURSE_SUCCESS";

export const DEPARTMENT_FETCHING = "DEPARTMENT_FETCHING";
export const DEPARTMENT_FAILED = "DEPARTMENT_FAILED";
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";

export const COURSEITEM_FETCHING = "COURSEITEM_FETCHING";
export const COURSEITEM_FAILED = "COURSEITEM_FAILED";
export const COURSEITEM_SUCCESS = "COURSEITEM_SUCCESS";

export const PAYTYPES_FETCHING = "PAYTYPES_FETCHING";
export const PAYTYPES_FAILED = "PAYTYPES_FAILED";
export const PAYTYPES_SUCCESS = "PAYTYPES_SUCCESS";

export const PAYRUNS_FETCHING = "PAYRUNS_FETCHING";
export const PAYRUNS_FAILED = "PAYRUNS_FAILED";
export const PAYRUNS_SUCCESS = "PAYRUNS_SUCCESS";

export const PAYRUNS_DETAIL_FETCHING = "PAYRUNS_DETAIL_FETCHING";
export const PAYRUNS_DETAIL_FAILED = "PAYRUNS_DETAIL_FAILED";
export const PAYRUNS_DETAIL_SUCCESS = "PAYRUNS_DETAIL_SUCCESS";

export const NECESSARY_FETCHING = "NECESSARY_FETCHING";
export const NECESSARY_FAILED = "NECESSARY_FAILED";
export const NECESSARY_SUCCESS = "NECESSARY_SUCCESS";

export const CATEGORY_FETCHING = "CATEGORY_FETCHING";
export const CATEGORY_FAILED = "CATEGORY_FAILED";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";

export const BOOK_FETCHING = "BOOK_FETCHING";
export const BOOK_FAILED = "BOOK_FAILED";
export const BOOK_SUCCESS = "BOOK_SUCCESS";

export const REVIEW_FETCHING = "REVIEW_FETCHING";
export const REVIEW_FAILED = "REVIEW_FAILED";
export const REVIEW_SUCCESS = "REVIEW_SUCCESS";

export const INSURANCE_FETCHING = "INSURANCE_FETCHING";
export const INSURANCE_FAILED = "INSURANCE_FAILED";
export const INSURANCE_SUCCESS = "INSURANCE_SUCCESS";

export const INSURANCECATEGORY_FETCHING = "INSURANCECATEGORY_FETCHING";
export const INSURANCECATEGORY_FAILED = "INSURANCECATEGORY_FAILED";
export const INSURANCECATEGORY_SUCCESS = "INSURANCECATEGORY_SUCCESS";

export const ITEM_FETCHING = "ITEM_FETCHING";
export const ITEM_FAILED = "ITEM_FAILED";
export const ITEM_SUCCESS = "ITEM_SUCCESS";

export const SHOPCATEGORY_FETCHING = "SHOPCATEGORY_FETCHING";
export const SHOPCATEGORY_FAILED = "SHOPCATEGORY_FAILED";
export const SHOPCATEGORY_SUCCESS = "SHOPCATEGORY_SUCCESS";

export const TRAVELCATEGORY_FETCHING = "TRAVELCATEGORY_FETCHING";
export const TRAVELCATEGORY_FAILED = "TRAVELCATEGORY_FAILED";
export const TRAVELCATEGORY_SUCCESS = "TRAVELCATEGORY_SUCCESS";

export const TRAVEL_FETCHING = "TRAVEL_FETCHING";
export const TRAVEL_FAILED = "TRAVEL_FAILED";
export const TRAVEL_SUCCESS = "TRAVEL_SUCCESS";

export const DETAIL_FETCHING = "DETAIL_FETCHING";
export const DETAIL_FAILED = "DETAIL_FAILED";
export const DETAIL_SUCCESS = "DETAIL_SUCCESS";

export const MAINCATEGORY_FETCHING = "MAINCATEGORY_FETCHING";
export const MAINCATEGORY_FAILED = "MAINCATEGORY_FAILED";
export const MAINCATEGORY_SUCCESS = "MAINCATEGORY_SUCCESS";

export const PACKAGE_FETCHING = "PACKAGE_FETCHING";
export const PACKAGE_FAILED = "PACKAGE_FAILED";
export const PACKAGE_SUCCESS = "PACKAGE_SUCCESS";

export const DELIVERY_FETCHING = "DELIVERY_FETCHING";
export const DELIVERY_FAILED = "DELIVERY_FAILED";
export const DELIVERY_SUCCESS = "DELIVERY_SUCCESS";

export const FACILITIES_FETCHING = "FACILITIES_FETCHING";
export const FACILITIES_FAILED = "FACILITIES_FAILED";
export const FACILITIES_SUCCESS = "FACILITIES_SUCCESS";

export const HISTORY_FETCHING = "HISTORY_FETCHING";
export const HISTORY_FAILED = "HISTORY_FAILED";
export const HISTORY_SUCCESS = "HISTORY_SUCCESS";

export const NOTIFICATION_FETCHING = "NOTIFICATION_FETCHING";
export const NOTIFICATION_FAILED = "NOTIFICATION_FAILED";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";

export const CART_SUCCESS = "CART_SUCCESS";
export const CART_FAIL = "CART_FAIL";

export const JOBS_FETCHING = "JOBS_FETCHING";
export const JOBS_FAILED = "JOBS_FAILED";
export const JOBS_SUCCESS = "JOBS_SUCCESS";

export const JOB_DETAIL_FETCHING = "JOB_DETAIL_FETCHING";
export const JOB_DETAIL_FAILED = "JOB_DETAIL_FAILED";
export const JOB_DETAIL_SUCCESS = "JOB_DETAIL_SUCCESS";

export const VENDOR_HOMEPAGE_FETCHING = "VENDOR_HOMEPAGE_FETCHING";
export const VENDOR_HOMEPAGE_FAILED = "VENDOR_HOMEPAGE_FAILED";
export const VENDOR_HOMEPAGE_SUCCESS = "VENDOR_HOMEPAGE_SUCCESS";

export const VENDOR_INFORMATION_FETCHING = "VENDOR_INFORMATION_FETCHING";
export const VENDOR_INFORMATION_FAILED = "VENDOR_INFORMATION_FAILED";
export const VENDOR_INFORMATION_SUCCESS = "VENDOR_INFORMATION_SUCCESS";

export const COMPANY_INFORMATION_FETCHING = "COMPANY_INFORMATION_FETCHING";
export const COMPANY_INFORMATION_FAILED = "COMPANY_INFORMATION_FAILED";
export const COMPANY_INFORMATION_SUCCESS = "COMPANY_INFORMATION_SUCCESS";

export const VENDOR_SEARCH_FETCHING = "VENDOR_SEARCH_FETCHING";
export const VENDOR_SEARCH_FAILED = "VENDOR_SEARCH_FAILED";
export const VENDOR_SEARCH_SUCCESS = "VENDOR_SEARCH_SUCCESS";

export const COMPANY_MANAGE_FETCHING = "COMPANY_MANAGE_FETCHING";
export const COMPANY_MANAGE_FAILED = "COMPANY_MANAGE_FAILED";
export const COMPANY_MANAGE_SUCCESS = "COMPANY_MANAGE_SUCCESS";

export const BANK_FETCHING = "BANK_FETCHING";
export const BANK_FAILED = "BANK_FAILED";
export const BANK_SUCCESS = "BANK_SUCCESS";

export const NEW_CANDIDATES_FETCHING = "NEW_CANDIDATES_FETCHING";
export const NEW_CANDIDATES_FAILED = "NEW_CANDIDATES_FAILED";
export const NEW_CANDIDATES_SUCCESS = "NEW_CANDIDATES_SUCCESS";

export const INTERVIEWED_CANDIDATE_FETCHING = "INTERVIEWED_CANDIDATE_FETCHING";
export const INTERVIEWED_CANDIDATE_FAILED = "INTERVIEWED_CANDIDATE_FAILED";
export const INTERVIEWED_CANDIDATE_SUCCESS = "INTERVIEWED_CANDIDATE_SUCCESS";

export const SCHEDULEANDCANDIDATES_FETCHING = "SCHEDULEANDCANDIDATES_FETCHING";
export const SCHEDULEANDCANDIDATES_FAILED = "SCHEDULEANDCANDIDATES_FAILED";
export const SCHEDULEANDCANDIDATES_SUCCESS = "SCHEDULEANDCANDIDATES_SUCCESS";

export const VENDOR_MANAGE_FETCHING = "VENDOR_MANAGE_FETCHING";
export const VENDOR_MANAGE_FAILED = "VENDOR_MANAGE_FAILED";
export const VENDOR_MANAGE_SUCCESS = "VENDOR_MANAGE_SUCCESS";

export const WORKINGLOCATIONS_FETCHING = "WORKINGLOCATIONS_FETCHING";
export const WORKINGLOCATIONS_FAILED = "WORKINGLOCATIONS_FAILED";
export const WORKINGLOCATIONS_SUCCESS = "WORKINGLOCATIONS_SUCCESS";

export const COMPANYS_FETCHING = "COMPANYS_FETCHING";
export const COMPANYS_FAILED = "COMPANYS_FAILED";
export const COMPANYS_SUCCESS = "COMPANYS_SUCCESS";

export const INDUSTRIES_FETCHING = "INDUSTRIES_FETCHING";
export const INDUSTRIES_FAILED = "INDUSTRIES_FAILED";
export const INDUSTRIES_SUCCESS = "INDUSTRIES_SUCCESS";

export const EXPERTISES_FETCHING = "EXPERTISES_FETCHING";
export const EXPERTISES_FAILED = "EXPERTISES_FAILED";
export const EXPERTISES_SUCCESS = "EXPERTISES_SUCCESS";

export const HOMEPAGE_GUEST_FETCHING = "HOMEPAGE_GUEST_FETCHING";
export const HOMEPAGE_GUEST_FAILED = "HOMEPAGE_GUEST_FAILED";
export const HOMEPAGE_GUEST_SUCCESS = "HOMEPAGE_GUEST_SUCCESS";

export const HOMEPAGE_COMPANY_FETCHING = "HOMEPAGE_COMPANY_FETCHING";
export const HOMEPAGE_COMPANY_FAILED = "HOMEPAGE_COMPANY_FAILED";
export const HOMEPAGE_COMPANY_SUCCESS = "HOMEPAGE_COMPANY_SUCCESS";

export const HOMEPAGE_VENDOR_FETCHING = "HOMEPAGE_VENDOR_FETCHING";
export const HOMEPAGE_VENDOR_FAILED = "HOMEPAGE_VENDOR_FAILED";
export const HOMEPAGE_VENDOR_SUCCESS = "HOMEPAGE_VENDOR_SUCCESS";

export const JOB_FORM_FETCHING = "JOB_FORM_FETCHING";
export const JOB_FORM_FAILED = "JOB_FORM_FAILED";
export const JOB_FORM_SUCCESS = "JOB_FORM_SUCCESS";

export const SIGNUP_COMPANY_FORM_FETCHING = "SIGNUP_COMPANY_FORM_FETCHING";
export const SIGNUP_COMPANY_FORM_FAILED = "SIGNUP_COMPANY_FORM_FAILED";
export const SIGNUP_COMPANY_FORM_SUCCESS = "SIGNUP_COMPANY_FORM_SUCCESS";

export const BUSINESS_GROUPS_FETCHING = "BUSINESS_GROUPS_FETCHING";
export const BUSINESS_GROUPS_FAILED = "BUSINESS_GROUPS_FAILED";
export const BUSINESS_GROUPS_SUCCESS = "BUSINESS_GROUPS_SUCCESS";

export const JOB_GROUPS_FETCHING = "JOB_GROUPS_FETCHING";
export const JOB_GROUPS_FAILED = "JOB_GROUPS_FAILED";
export const JOB_GROUPS_SUCCESS = "JOB_GROUPS_SUCCESS";

export const FAVORITE_VENDORS_FETCHING = "FAVORITE_VENDORS_FETCHING";
export const FAVORITE_VENDORS_FAILED = "FAVORITE_VENDORS_FAILED";
export const FAVORITE_VENDORS_SUCCESS = "FAVORITE_VENDORS_SUCCESS";

export const VENDOR_NOTIFICATION_FETCHING = "VENDOR_NOTIFICATION_FETCHING";
export const VENDOR_NOTIFICATION_FAILED = "VENDOR_NOTIFICATION_FAILED";
export const VENDOR_NOTIFICATION_SUCCESS = "VENDOR_NOTIFICATION_SUCCESS";

export const JOB_EDIT_FETCHING = "JOB_EDIT_FETCHING";
export const JOB_EDIT_FAILED = "JOB_EDIT_FAILED";
export const JOB_EDIT_SUCCESS = "JOB_EDIT_SUCCESS";

export const CANDIDATES_ALREADY_SENT_FETCHING = "CANDIDATES_ALREADY_SENT_FETCHING";
export const CANDIDATES_ALREADY_SENT_FAILED = "CANDIDATES_ALREADY_SENT_FAILED";
export const CANDIDATES_ALREADY_SENT_SUCCESS = "CANDIDATES_ALREADY_SENT_SUCCESS";

export const DASHBOARD_VENDOR_FETCHING = "DASHBOARD_VENDOR_FETCHING";
export const DASHBOARD_VENDOR_FAILED = "DASHBOARD_VENDOR_FAILED";
export const DASHBOARD_VENDOR_SUCCESS = "DASHBOARD_VENDOR_SUCCESS";

export const BIDDINGS_MYJOBS_FETCHING = "BIDDINGS_MYJOBS_FETCHING";
export const BIDDINGS_MYJOBS_FAILED = "BIDDINGS_MYJOBS_FAILED";
export const BIDDINGS_MYJOBS_SUCCESS = "BIDDINGS_MYJOBS_SUCCESS";

export const BIDDINGS_VENDORS_FETCHING = "BIDDINGS_VENDORS_FETCHING";
export const BIDDINGS_VENDORS_FAILED = "BIDDINGS_VENDORS_FAILED";
export const BIDDINGS_VENDORS_SUCCESS = "BIDDINGS_VENDORS_SUCCESS";

export const MYBIDDINGS_VENDOR_FETCHING = "MYBIDDINGS_VENDOR_FETCHING";
export const MYBIDDINGS_VENDOR_FAILED = "MYBIDDINGS_VENDOR_FAILED";
export const MYBIDDINGS_VENDOR_SUCCESS = "MYBIDDINGS_VENDOR_SUCCESS";

export const HISTORY_BIDDINGS_VENDOR_FETCHING = "HISTORY_BIDDINGS_VENDOR_FETCHING";
export const HISTORY_BIDDINGS_VENDOR_FAILED = "HISTORY_BIDDINGS_VENDOR_FAILED";
export const HISTORY_BIDDINGS_VENDOR_SUCCESS = "HISTORY_BIDDINGS_VENDOR_SUCCESS";

export const HISTORY_JOB_COMPANY_FETCHING = "HISTORY_JOB_COMPANY_FETCHING";
export const HISTORY_JOB_COMPANY_FAILED = "HISTORY_JOB_COMPANY_FAILED";
export const HISTORY_JOB_COMPANY_SUCCESS = "HISTORY_JOB_COMPANY_SUCCESS";

export const PASSED_CANDIDATES_COMPANY_FETCHING = "PASSED_CANDIDATES_COMPANY_FETCHING";
export const PASSED_CANDIDATES_COMPANY_FAILED = "PASSED_CANDIDATES_COMPANY_FAILED";
export const PASSED_CANDIDATES_COMPANY_SUCCESS = "PASSED_CANDIDATES_COMPANY_SUCCESS";

export const HISTORY_BIDDINGS_COMPANY_FETCHING = "HISTORY_BIDDINGS_COMPANY_FETCHING";
export const HISTORY_BIDDINGS_COMPANY_FAILED = "HISTORY_BIDDINGS_COMPANY_FAILED";
export const HISTORY_BIDDINGS_COMPANY_SUCCESS = "HISTORY_BIDDINGS_COMPANY_SUCCESS";

export const BIDDING_INFORMATION_FETCHING = "BIDDING_INFORMATION_FETCHING";
export const BIDDING_INFORMATION_FAILED = "BIDDING_INFORMATION_FAILED";
export const BIDDING_INFORMATION_SUCCESS = "BIDDING_INFORMATION_SUCCESS";

export const BIDDING_MIN_GUARANTEE_CONTRACT_FETCHING = "BIDDING_MIN_GUARANTEE_CONTRACT_FETCHING";
export const BIDDING_MIN_GUARANTEE_CONTRACT_FAILED = "BIDDING_MIN_GUARANTEE_CONTRACT_FAILED";
export const BIDDING_MIN_GUARANTEE_CONTRACT_SUCCESS = "BIDDING_MIN_GUARANTEE_CONTRACT_SUCCESS";

export const MYCONTRACT_BIDDINGS_LIMITTIME_FETCHING = "MYCONTRACT_BIDDINGS_LIMITTIME_FETCHING";
export const MYCONTRACT_BIDDINGS_LIMITTIME_FAILED = "MYCONTRACT_BIDDINGS_LIMITTIME_FAILED";
export const MYCONTRACT_BIDDINGS_LIMITTIME_SUCCESS = "MYCONTRACT_BIDDINGS_LIMITTIME_SUCCESS";

export const MYCONTRACT_BIDDINGS_FETCHING = "MYCONTRACT_BIDDINGS_FETCHING";
export const MYCONTRACT_BIDDINGS_FAILED = "MYCONTRACT_BIDDINGS_FAILED";
export const MYCONTRACT_BIDDINGS_SUCCESS = "MYCONTRACT_BIDDINGS_SUCCESS";

export const ANTHR_EMPLOYEES_FETCHING = "ANTHR_EMPLOYEES_FETCHING";
export const ANTHR_EMPLOYEES_FAILED = "ANTHR_EMPLOYEES_FAILED";
export const ANTHR_EMPLOYEES_SUCCESS = "ANTHR_EMPLOYEES_SUCCESS";

export const JOBS_SEARCH_FETCHING = "JOBS_SEARCH_FETCHING";
export const JOBS_SEARCH_FAILED = "JOBS_SEARCH_FAILED";
export const JOBS_SEARCH_SUCCESS = "JOBS_SEARCH_SUCCESS";

export const BIDDINGS_FETCHING = "BIDDINGS_FETCHING";
export const BIDDINGS_FAILED = "BIDDINGS_FAILED";
export const BIDDINGS_SUCCESS = "BIDDINGS_SUCCESS";

export const COMPANY_MYVENDORS_FETCHING = "COMPANY_MYVENDORS_FETCHING";
export const COMPANY_MYVENDORS_FAILED = "COMPANY_MYVENDORS_FAILED";
export const COMPANY_MYVENDORS_SUCCESS = "COMPANY_MYVENDORS_SUCCESS";

export const MANPOWER_PROFILE_FETCHING = "MANPOWER_PROFILE_FETCHING";
export const MANPOWER_PROFILE_FAILED = "MANPOWER_PROFILE_FAILED";
export const MANPOWER_PROFILE_SUCCESS = "MANPOWER_PROFILE_SUCCESS";

export const HISTORY_CANDIDATES_JOBS_MATCHING_FETCHING = "HISTORY_CANDIDATES_JOBS_MATCHING_FETCHING";
export const HISTORY_CANDIDATES_JOBS_MATCHING_FAILED = "HISTORY_CANDIDATES_JOBS_MATCHING_FAILED";
export const HISTORY_CANDIDATES_JOBS_MATCHING_SUCCESS = "HISTORY_CANDIDATES_JOBS_MATCHING_SUCCESS";

export const VENDOR_MYCOMPANYS_FETCHING = "VENDOR_MYCOMPANYS_FETCHING";
export const VENDOR_MYCOMPANYS_FAILED = "VENDOR_MYCOMPANYS_FAILED";
export const VENDOR_MYCOMPANYS_SUCCESS = "VENDOR_MYCOMPANYS_SUCCESS";

export const HISTORY_MANPOWERS_SENDING_FETCHING = "HISTORY_MANPOWERS_SENDING_FETCHING";
export const HISTORY_MANPOWERS_SENDING_FAILED = "HISTORY_MANPOWERS_SENDING_FAILED";
export const HISTORY_MANPOWERS_SENDING_SUCCESS = "HISTORY_MANPOWERS_SENDING_SUCCESS";

export const CONTRACT_COMPANY_FETCHING = "CONTRACT_COMPANY_FETCHING";
export const CONTRACT_COMPANY_FAILED = "CONTRACT_COMPANY_FAILED";
export const CONTRACT_COMPANY_SUCCESS = "CONTRACT_COMPANY_SUCCESS";

export const MANPOWER_CERTIFICATE_FETCHING = "MANPOWER_CERTIFICATE_FETCHING";
export const MANPOWER_CERTIFICATE_FAILED = "MANPOWER_CERTIFICATE_FAILED";
export const MANPOWER_CERTIFICATE_SUCCESS = "MANPOWER_CERTIFICATE_SUCCESS";

export const DEV_INFO_CONTRACT_FETCHING = "DEV_INFO_CONTRACT_FETCHING";
export const DEV_INFO_CONTRACT_FAILED = "DEV_INFO_CONTRACT_FAILED";
export const DEV_INFO_CONTRACT_SUCCESS = "DEV_INFO_CONTRACT_SUCCESS";

export const CONTRACT_VENDOR_FETCHING = "CONTRACT_VENDOR_FETCHING";
export const CONTRACT_VENDOR_FAILED = "CONTRACT_VENDOR_FAILED";
export const CONTRACT_VENDOR_SUCCESS = "CONTRACT_VENDOR_SUCCESS";