import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Box, Grid, MenuItem } from '@mui/material';

import DrawerCustom from '../../../shared/general/Drawer';
import TextFieldTheme from '../../../shared/general/TextFieldTheme';
import ButtonBlue from '../../../shared/general/ButtonBlue';
import { useDispatch } from 'react-redux';
import { updateManpower, getManpowerById } from '../../../../../actions/manpower';

const DrawerName = (props) => {
  const { open, onClose, data, idManpower } = props;
  const { handleSubmit, control, formState: { errors, dirtyFields }, } = useForm({
    defaultValues: {
      title: data.title || "",
      name: data.name || "",
      lastname: data.lastname || "",
    }
  });

  const dispatch = useDispatch();

  const HandlerSubmit = async (submitData) => {

    const formData = {};

    for (const key in dirtyFields) {
      formData[key] = submitData[key];
    }

    dispatch(updateManpower(idManpower, formData)).then(res => {
      dispatch(getManpowerById(idManpower));
    })
    // console.log(idManpower, formData)
    onClose();
  }

  return (
    <DrawerCustom
      open={open}
      title={"แก้ไขชื่อ-นามสกุล"}
      anchor="right"
    >
      <Box style={{ width: 400, padding: 16 }}>
        <form onSubmit={handleSubmit(HandlerSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: { value: true, message: "กรุณากรอกชื่อ" }
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label="คำนำหน้า"
                    // fullWidth
                    helperText={errors && errors.title && errors.title.message}
                    error={errors && errors.title ? true: false}
                    select
                  >
                    <MenuItem value="นาย">นาย</MenuItem>
                    <MenuItem value="นาง">นาง</MenuItem>
                    <MenuItem value="นางสาว">นางสาว</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { value: true, message: "กรุณากรอกชื่อ" }
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label="ชื่อ"
                    fullWidth
                    autoComplete="manpower-firstname"
                    helperText={errors && errors.name && errors.name.message}
                    error={errors && errors.name ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="lastname"
                control={control}
                rules={{
                  required: { value: true, message: "กรุณากรอกนามสกุล" }
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label="นามสกุล"
                    fullWidth
                    autoComplete="manpower-lastname"
                    helperText={errors && errors.lastname && errors.lastname.message}
                    error={errors && errors.lastname ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <ButtonBlue
                  variant="outlined"
                  onClick={onClose}
                >ยกเลิก</ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={Object.keys(dirtyFields).length <= 0}
                >ยืนยัน</ButtonBlue>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </DrawerCustom>
  );
};

export default DrawerName;