import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Avatar, Box, Divider, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getOptionTimes } from "../../../../utils";
import { getNewCandidatesByIdJobRequest, getNewCandidatesByIdBiddings, updateInterviewDetail } from "../../../../actions/candidate";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";

const StyledRoot = styled("div")({
  padding: 16,
  width: 450,
  "& .part-footer": {
    paddingTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .cancel-button": {
      color: "#9e9e9e",
      borderColor: "#9e9e9e",
    },
    "& i": {
      marginRight: 8,
    }
  },
  "& .part-auto-appointment": {
    marginBottom: 24,
    "& .MuiButton-root": {
      paddingTop: 8,
      paddingBottom: 8,
    }
  },
  "& .part-form": {
    "& .MuiInputBase-root": {
      height: 58,
    },
  }
})

const StyledName = styled("div")({
  padding: 8,
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderRadius: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#ffffff",
  "& .wrap-name": {
    display: "flex",
    alignItems: "center",
    width: 0,
    flexGrow: 1,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    "& .MuiTypography-root": {
      flexGrow: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  "& i": {
    paddingLeft: 8,
    color: "#9e9e9e",
  }
})

const DrawerInterviewAppointment = (props) => {
  const { open, onClose, listCandidate, id, setIsSuccess, handleCountDownTimeSet, type } = props;
  const dispatch = useDispatch();

  const { control, handleSubmit, formState: { errors }, getValues, setError, setValue, clearErrors, watch } = useForm({
    defaultValues: {
      interviewAppointmentDate: "",
      listInterviewPositions: [
        ...Object.keys(listCandidate).map(key => {
          return ({
            id: parseInt(key),
            positionName: listCandidate[key][0].positionName,
            interviewPlace: "",
            contactName: "",
            contactPhone: "",
            isOnline: '0',
            interviewStartTime: [
              ...listCandidate[key].map(() => "")
            ],
          })
        })
      ]
    }
  })

  const AutoForm = useForm({
    defaultValues: {
      interviewStartTimeAuto: "",
      interviewDurationTimeAuto: "",
    }
  })

  const [formPage, setFormPage] = useState(1);

  const [interviews, setInterviews] = useState([]);

  useEffect(() => {
    let ListCandidateByKey = {};
    Object.keys(listCandidate).map(key => {
      ListCandidateByKey[key] = [
        ...listCandidate[key].map(candidate => {
          return {
            idCandidate: candidate.idCandidate,
            idManpower: candidate.idManpower,
            name: candidate.name,
            lastName: candidate.lastName
          }
        })
      ];
    });
    setInterviews(ListCandidateByKey);
    return () => {
      setInterviews([]);
    };
  },[listCandidate]);

  const onSubmit = () => {
    setFormPage(2);
  }

  const onSubmitAutoAppointment = () => {
    let minutes = ["00","15","30","45","60","75","90","105","120"];
    let diffMinute = minutes.indexOf(AutoForm.getValues("interviewDurationTimeAuto"));

    let times = getOptionTimes();

    let startIndex = times.indexOf(AutoForm.getValues("interviewStartTimeAuto"));

    Object.keys(listCandidate).map((key, index) => {
      let startTimeAuto = [];
      let listCandidateLength = listCandidate[key].length;
      for(let i=startIndex;i<times.length;i+=diffMinute){
        if(startTimeAuto.length < listCandidateLength){
          startTimeAuto.push(times[i]);
        }
      }
      setValue(`listInterviewPositions[${index}].interviewStartTime`,startTimeAuto);
      clearErrors([`listInterviewPositions[${index}].interviewStartTime`]);
    });
  }

  const onSubmitAppointment = async (formData) => {
    const result = await dispatch(updateInterviewDetail({
      listCandidate: interviews,
      data: {...formData}
    }));
    if(result){
      if (type === 'job') {
        dispatch(getNewCandidatesByIdJobRequest(id));
      } else {
        dispatch(getNewCandidatesByIdBiddings(id))
      }
      onClose();
      setIsSuccess(true);
      handleCountDownTimeSet();
    }
  }

  const handleOnDragEnd = (result, key) => {
    if(!result.destination) return;
    const item = Array.from(interviews[key]);
    const [reorderedItem] = item.splice(result.source.index, 1)
    item.splice(result.destination.index, 0, reorderedItem)

    setInterviews({...interviews, [key] : item});
  }

  return(
    <DrawerCustom
      title="นัดหมายสัมภาษณ์"
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        {formPage === 1 && <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="interviewAppointmentDate"
                rules={{
                  required: {value: true, message: "กรุณากรอกวันที่นัดหมายสัมภาษณ์"},
                  validate: {
                    dateValidation : (value) => !isNaN(value) || "กรุณากรอกวันที่ให้ถูกต้อง",
                    disblePast: (value) => ((new Date(value)) >= new Date(new Date().setHours(0,0,0,0))) || "ไม่สามารถเลือกวันที่ผ่านมาแล้วได้"
                  }
                }}
                render={({field})=>(
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      {...field}
                      label="วันที่นัดหมายสัมภาษณ์"
                      inputFormat="DD/MM/YYYY"
                      disablePast
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          helperText={errors && errors.interviewAppointmentDate && errors.interviewAppointmentDate.message}
                          error={errors && errors.interviewAppointmentDate ? true: false}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            {getValues("listInterviewPositions") && getValues("listInterviewPositions").map((interviewDetail, index) => (
              <Fragment key={interviewDetail.id}>
                {type !== 'job' && 
                  <Grid item xs={12}>
                    <Divider style={{ marginBottom:"16px" }} />
                    <Typography>{interviewDetail.positionName}</Typography>
                  </Grid>
                }
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Controller 
                      control={control}
                      name={`listInterviewPositions[${index}].isOnline`}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          onChange={(e) => {
                            if(errors && errors.listInterviewPositions && errors.listInterviewPositions[index] &&
                              errors.listInterviewPositions[index].interviewPlace){ 
                                setError(`listInterviewPositions[${index}].interviewPlace`, { type: 'required', message: e.target.value === '1' ? "กรุณากรอกลิงก์สัมภาษณ์" : "กรุณากรอกสถานที่สัมภาษณ์" });
                            }
                            field.onChange(e);
                          }}
                        >
                          <Box style={{ display:"flex" }}>
                            <FormControlLabel value={'0'} control={<Radio />} label="Offline" />
                            <FormControlLabel value={'1'} control={<Radio />} label="Online" />
                          </Box>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={`listInterviewPositions[${index}].interviewPlace`}
                    rules={{
                      required: { value: true, message: watch(`listInterviewPositions[${index}].isOnline`) === '1' ? "กรุณากรอกลิงก์สัมภาษณ์" : "กรุณากรอกสถานที่สัมภาษณ์" }
                    }}
                    render={({ field }) => (
                      <TextFieldTheme
                        {...field}
                        label={watch(`listInterviewPositions[${index}].isOnline`) === '1' ? "ลิงก์สัมภาษณ์" : "สถานที่สัมภาษณ์"}
                        helperText={
                          errors && errors.listInterviewPositions && errors.listInterviewPositions[index] &&
                          errors.listInterviewPositions[index].interviewPlace && errors.listInterviewPositions[index].interviewPlace.message
                        }
                        error={
                          (errors && errors.listInterviewPositions && errors.listInterviewPositions[index] &&
                          errors.listInterviewPositions[index].interviewPlace) ? true : false
                        }
                        placeholder="เช่น ตึก อาคาร"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={`listInterviewPositions[${index}].contactName`}
                    rules={{
                      required: { value: true, message: "กรุณากรอกชื่อผู้ติดต่อ" }
                    }}
                    render={({ field }) => (
                      <TextFieldTheme
                        {...field}
                        label="ชื่อผู้ติดต่อ"
                        helperText={
                          errors && errors.listInterviewPositions && errors.listInterviewPositions[index] &&
                          errors.listInterviewPositions[index].contactName && errors.listInterviewPositions[index].contactName.message
                        }
                        error={
                          (errors && errors.listInterviewPositions && errors.listInterviewPositions[index] &&
                          errors.listInterviewPositions[index].contactName) ? true : false
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={`listInterviewPositions[${index}].contactPhone`}
                    rules={{
                      required: {
                        value: true,
                        message: "กรุณากรอกเบอร์โทรศัพท์"
                      },
                      validate: {
                        length: (value) => value.length === 10 || "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก"
                      }
                    }}
                    render={({ field }) => (
                      <TextFieldTheme
                        {...field}
                        label="เบอร์โทรผู้ติดต่อ"
                        InputProps={{
                          inputComponent: NumberFormatTheme,
                        }}
                        inputProps={{
                          allowNegative: false
                        }}
                        helperText={
                          errors && errors.listInterviewPositions && errors.listInterviewPositions[index] &&
                          errors.listInterviewPositions[index].contactPhone && errors.listInterviewPositions[index].contactPhone.message
                        }
                        error={
                          (errors && errors.listInterviewPositions && errors.listInterviewPositions[index] &&
                          errors.listInterviewPositions[index].contactPhone) ? true : false
                        }
                      />
                    )}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>
          <div className="part-footer">
            <ButtonBlue className="cancel-button" variant="outlined" onClick={onClose}><i className="fa-regular fa-x"></i>ยกเลิก</ButtonBlue>
            <ButtonBlue type="submit" variant="contained"><i className="fa-regular fa-check"></i>ถัดไป</ButtonBlue>
          </div>
        </form>}

        {formPage === 2 && (
          <Fragment>
            <div className="part-auto-appointment">
              <form onSubmit={AutoForm.handleSubmit(onSubmitAutoAppointment)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={AutoForm.control}
                      name="interviewStartTimeAuto"
                      rules={{
                        required: {value: true, message: "กรุณากรอกเวลาเริ่มสัมภาษณ์"}
                      }}
                      render={({field})=>(
                        <TextFieldTheme
                          {...field}
                          label="เวลาเริ่มสัมภาษณ์"
                          helperText={AutoForm.formState.errors && AutoForm.formState.errors.interviewStartTimeAuto && AutoForm.formState.errors.interviewStartTimeAuto.message}
                          error={AutoForm.formState.errors && AutoForm.formState.errors.interviewStartTimeAuto ? true: false}
                          select
                        >
                          <MenuItem value="8.30">8.30</MenuItem>
                          <MenuItem value="9.00">9.00</MenuItem>
                          <MenuItem value="9.30">9.30</MenuItem>
                          <MenuItem value="10.00">10.00</MenuItem>
                        </TextFieldTheme>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={AutoForm.control}
                      name="interviewDurationTimeAuto"
                      rules={{
                        required: {value: true, message: "กรุณากรอกเวลาสัมภาษณ์รายคน"}
                      }}
                      render={({field})=>(
                        <TextFieldTheme
                          {...field}
                          label="เวลาสัมภาษณ์รายคน"
                          helperText={AutoForm.formState.errors && AutoForm.formState.errors.interviewDurationTimeAuto && AutoForm.formState.errors.interviewDurationTimeAuto.message}
                          error={AutoForm.formState.errors && AutoForm.formState.errors.interviewDurationTimeAuto ? true: false}
                          select
                        >
                          <MenuItem value="15">15 นาที</MenuItem>
                          <MenuItem value="30">30 นาที</MenuItem>
                          <MenuItem value="45">45 นาที</MenuItem>
                          <MenuItem value="60">60 นาที</MenuItem>
                          <MenuItem value="90">90 นาที</MenuItem>
                          <MenuItem value="120">120 นาที</MenuItem>
                        </TextFieldTheme>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ButtonBlue type="submit" variant="outlined" fullWidth startIcon={<i className="fa-regular fa-gears"></i>}>นัดสัมภาษณ์อัตโนมัติ</ButtonBlue>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </form>
            </div>

            <div className="part-form">
              <form onSubmit={handleSubmit(onSubmitAppointment)}>
                <Grid container spacing={2}>   
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      {getValues("listInterviewPositions") && getValues("listInterviewPositions").map((interview, indexListInterview) => (
                        <Fragment key={interview.id}>
                          <Grid item container xs={12} spacing={2} alignItems="center">
                            {type !== 'job' &&
                              <Grid item xs={12}>
                                <Divider style={{ marginBottom:"16px" }} />
                                <Typography>{interview.positionName}</Typography>
                              </Grid>
                            }
                            <DragDropContext onDragEnd = { (e) => handleOnDragEnd(e, interview.id) } >
                              <Droppable droppableId="drop-1">
                                {(provided) => (
                                  <Grid ref={provided.innerRef} item xs={8} container spacing={2} {...provided.droppableProps}>
                                    {interviews[interview.id].map((interview, index) => (
                                      <Draggable key={`drag-${interview.idCandidate}`} draggableId={`${interview.idCandidate}`} index={index}>
                                        {(provided) => (
                                          <Grid item xs={12} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <StyledName>
                                              <div className="wrap-name">
                                                <Avatar src={`${process.env.REACT_APP_API_URL}image/profile/${parseInt(Math.random() * 20) + 1}.jpg`} />
                                                <Typography>{`${interview.name} ${interview.lastName}`}</Typography>
                                              </div>
                                              <i className="fa-regular fa-ellipsis-vertical"></i>
                                            </StyledName>
                                          </Grid>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </Grid>
                                )}
                              </Droppable>
                            </DragDropContext>
                            <Grid item xs={4}>
                              <Grid container spacing={2}>
                                {getValues(`listInterviewPositions[${indexListInterview}].interviewStartTime`) && 
                                 getValues(`listInterviewPositions[${indexListInterview}].interviewStartTime`).map((_, index) => (
                                  <Grid item xs={12} key={index}>
                                    <Controller
                                      control={control}
                                      name={`listInterviewPositions[${indexListInterview}].interviewStartTime.${index}`}
                                      rules={{
                                        required: { value: true, message: "กรุณากรอกเวลาเริ่มสัมภาษณ์" }
                                      }}
                                      render={({ field }) => (
                                        <TextFieldTheme
                                          {...field}
                                          label="เวลาเริ่ม"
                                          error={
                                            errors && errors.listInterviewPositions && errors.listInterviewPositions[indexListInterview] &&
                                            errors.listInterviewPositions[indexListInterview].interviewStartTime && errors.listInterviewPositions[indexListInterview].interviewStartTime[index] ? true : false
                                          }
                                          select
                                        >
                                          {getOptionTimes().map(time => (
                                            <MenuItem key={time} value={time}>
                                              {time}
                                            </MenuItem>
                                          ))}
                                        </TextFieldTheme>
                                      )}
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>

                  </Grid>
                </Grid>
                <div className="part-footer">
                  <ButtonBlue className="cancel-button" variant="outlined" onClick={onClose}><i className="fa-regular fa-x"></i>ยกเลิก</ButtonBlue>
                  <ButtonBlue type="submit" variant="contained"><i className="fa-regular fa-check"></i>ยืนยัน</ButtonBlue>
                </div>
              </form>
            </div>
          </Fragment>
        )}
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerInterviewAppointment;