import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
  } from "./types";
  
import AuthService from "../services/auth.service";


export const login = (email, password, rememberMe) => async (dispatch) => {
  // return AuthService.login(email, password).then(
  //   (data) => {
  //     dispatch({
  //       type: LOGIN_SUCCESS,
  //       payload: { user: data },
  //     });

  //     return Promise.resolve();
  //   },
  //   (error) => {
  //     const message =
  //       (error.response &&
  //         error.response.data &&
  //         error.response.data.message) ||
  //       error.message ||
  //       error.toString();

  //     dispatch({
  //       type: LOGIN_FAIL,
  //     });

  //     return Promise.reject();
  //   }
  // );
  try {
    const res = await AuthService.login(email, password, rememberMe);
    if (res) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: res.data }
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL
    });
    return error.response
  }
};
  
export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const changePassword = (formData) => async () => {
  try {
    const res = await AuthService.changePassword(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error.response;
  }
};

export const requestResetPassword = (formData) => async () => {
  try {
    const res = await AuthService.requestResetPassword(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error.response;
  }
};

export const verifyResetPassword = (query) => async () => {
  try {
    const res = await AuthService.verifyResetPassword(query);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error.response;
  }
};

export const resetPassword = (formData) => async () => {
  try {
    const res = await AuthService.resetPassword(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error.response;
  }
};

export const verifyEmail = (formData) => async () => {
  try {
    const res = await AuthService.verifyEmail(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error.response;
  }
};
  
export const changeEmail = (formData) => async () => {
  try {
    const res = await AuthService.changeEmail(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error.response;
  }
};