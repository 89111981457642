import React, { useEffect, useState } from "react";
import { Box, Container, styled, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createPasswordAdminCompany, verifyTokenAddAdminCompany } from "../../../../../../actions/adminCompany";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

const StyledRoot = styled(Box)({

})

const SetPasswordAdminCompany = () => {

  const [isVerify, setIsVerify] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitFetcing, setIsSubmitFetcing] = useState(false);
  const [hasUser, setHasUser] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
      name: "",
      phoneNumber: "",
    }
  }) 

  const onSubmit = (submitData) => {

    const formData = {
      ...submitData,
      token: data.token,
    }

    setIsSubmitFetcing(true);

    dispatch(createPasswordAdminCompany(formData)).then(res => {
      if(res.status === 200){
        setIsSuccess(true)
      }
      setIsSubmitFetcing(false);
    })
  }

  useEffect(() => {
    setIsFetching(true);
    const token = new URLSearchParams(history.location.search).get("token");
    dispatch(verifyTokenAddAdminCompany({token: token}))
      .then(res => {
        setIsFetching(false);
        if(res.status === 200){
          if(res.data.hasUser){
            setHasUser(true)
            setData({
              company: res.data.company,
              email: res.data.email,
            })
          } else {
            setData({
              email: res.data.email,
              company: res.data.company,
              token: token,
            })
          }
          setIsVerify(true)
        } else if(res.status === 400){
          if(res.data.errorCode === "TOKEN_IS_NOTFOUND_OR_EXPIRED"){
            setError("การยืนยันตัวตนไม่ถูกต้อง หรือ ลิงก์ของคุณหมดอายุ")
          } else if(res.data.errorCode === "USERNAME_IS_EXISTS"){
            setError("อีเมลของคุณมีอยู่ในระบบเรียบร้อยแล้ว")
          }
        }
      })
  }, [])

  useEffect(() => {
    if(isSuccess){
      setTimeout(() => {history.push("/login")}, 3000);
    }
  }, [isSuccess])

  useEffect(() => {
    if(hasUser){
      setTimeout(() => {history.push("/login")}, 5000);
    }
  }, [hasUser])

  useEffect(() => {
    if(error){
      setTimeout(() => {history.push("/")}, 3000);
    }
  }, [error])

  return (
    <Box className="page">
      <StyledRoot>

        <Container maxWidth="xs">

          {!isSuccess && !isFetching && isVerify && data && !hasUser && (

            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display="flex" justifyContent="center" flexDirection="column" gap="16px">
                <Box>
                  <Typography variant="h5" textAlign="center">สร้างรหัสผ่านสำหรับแอดมิน</Typography>
                  <Typography variant="h5" textAlign="center">{data.company}</Typography>
                </Box>

                <Box>
                  <Typography variant="h6" textAlign="center">อีเมล</Typography>
                  <Typography variant="h6" textAlign="center">{data.email}</Typography>
                </Box>

                <Box>
                  <Controller
                    control={control}
                    name="password"
                    rules={{
                      required: {value: true, message: "กรุณากรอกรหัสผ่าน"},
                      validate: (value) => (value.length >= 8) || "กรุณากรอกรหัสผ่าน อย่างน้อย 8 ตัว",
                      pattern: {
                        value: /^[A-Za-z0-9_]+$/,
                        message: "กรุณากรอกรหัสผ่าน เป็นตัวภาษาอังกฤษ ตัวเลข หรือเครื่องหมาย_เท่านั้น"
                      }
                    }}
                    render={({field, formState}) => (
                      <TextFieldTheme
                        {...field}
                        label="รหัสผ่าน"
                        type="password"
                        helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                        error={formState.errors && formState.errors[field.name]? true: false}
                      />
                    )}
                  />
                </Box>

                <Box>
                  <Controller
                    control={control}
                    name="confirmPassword"
                    rules={{
                      required: {value: true, message: "กรุณากรอกยืนยันรหัสผ่าน"},
                      validate: (value) => (value === getValues("password")) || "รหัสผ่านไม่ตรงกัน",
                    }}
                    render={({field, formState}) => (
                      <TextFieldTheme
                        {...field}
                        label="ยืนยันรหัสผ่าน"
                        type="password"
                        helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                        error={formState.errors && formState.errors[field.name]? true: false}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    control={control}
                    name="name"
                    rules={{
                      required: {value: true, message: "กรุณากรอกชื่อ-สกุล"},
                    }}
                    render={({field, formState}) => (
                      <TextFieldTheme
                        {...field}
                        label="ชื่อ-สกุล"
                        helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                        error={formState.errors && formState.errors[field.name]? true: false}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    control={control}
                    name="phoneNumber"
                    rules={{
                      required: {value: true, message: "กรุณากรอกเบอร์ติดต่อ"},
                    }}
                    render={({field, formState}) => (
                      <TextFieldTheme
                        {...field}
                        label="เบอร์ติดต่อ"
                        helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                        error={formState.errors && formState.errors[field.name]? true: false}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <ButtonBlue type="submit" variant="contained" sx={{width: "100%"}} disabled={isSubmitFetcing}>สร้างรหัสผ่าน</ButtonBlue>
                </Box>
              </Box>
            </form>
          )}

          {!isSuccess && !isFetching && error && (
            <Box>
              <Typography variant="h5" textAlign="center">{error}</Typography>
            </Box>
          )}

          {isSuccess && <Typography fontSize={24} textAlign="center">สร้างรหัสผ่านสำหรับแอดมินเรียบร้อยแล้ว</Typography>}

          {hasUser && data && <Box>
            <Typography fontSize={24} textAlign="center" paddingBottom="24px">คุณได้เข้าเป็นแอดมินของ {data.company} เรียบร้อยแล้ว</Typography>
            <Typography fontSize={24} textAlign="center">คุณสามารถเข้าสู่ระบบด้วยอีเมล {data.email} โดยใช้รหัสผ่านเดิมของท่าน</Typography>
          </Box>}

        </Container>

      </StyledRoot>
    </Box>
  )
}

export default SetPasswordAdminCompany