import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
    Container,
    Grid,
    Pagination,
    Typography
} from "@mui/material";

import CardVendor from '../../shared/general/CardVendor';
import { getAllFavoriteVendors } from "../../../../actions/favoriteVendor";

const CompanyFavorite = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [favoriteVendors, setFavoriteVendors] = useState([]);
    const { result: favoriteVendor } = useSelector(state => state.favoriteVendors);

    useEffect(() => {
        dispatch(getAllFavoriteVendors());
    },[]);

    useEffect(() => {
        if(favoriteVendor){
            setFavoriteVendors([...favoriteVendor]);
        }
    },[favoriteVendor]);

    const onChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const onClickFavoriteButton = (index) => {
        let temp = [...favoriteVendors];
        temp.splice(index,1);
        setFavoriteVendors(temp);
    };

    return (
        <Container maxWidth="lg" className="page">
            <Typography 
                variant="h4"
                style={{ marginBottom:"20px" }}
            >คุ่ธุรกิจโปรด</Typography>
            <Grid container columnSpacing={4} rowSpacing={8}>
                {favoriteVendors && favoriteVendors.length > 0 ?
                    favoriteVendors.slice((page-1) * 12, ((page-1) * 12) + 12)
                    .map((vendor, index) => (
                        <Grid key={`vendor_${vendor.idVendor}`} item xs={4}>
                            <CardVendor 
                                vendor={vendor} 
                                favorite={true} 
                                index={index}
                                onClickHandler={onClickFavoriteButton}
                            />
                        </Grid>
                    ))
                    :
                    <Grid item xs={12} style={{ margin:"40px 0 60px" }}>
                        <Typography align='center' variant="h5">
                            ไม่พบรายการโปรด
                        </Typography>
                    </Grid>
                }
            </Grid>
            {favoriteVendors && favoriteVendors.length > 0 && 
                <div style={{ display:"flex", justifyContent:"flex-end", margin:"40px 0 20px" }}>
                    <Pagination
                        count={Math.ceil(favoriteVendors.length / 12) || 1}
                        shape="rounded"
                        onChange={onChangePage}
                        page={page}
                    />
                </div>
            }
        </Container>
    );
};

export default CompanyFavorite;