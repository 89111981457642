import React from "react";
import { Avatar, Box, styled, Typography } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import { Link } from "react-router-dom";

const StyledRoot = styled(Box)({
  padding: 24,
  "& .avatar": {
    width: 80,
    height: 80,
    borderRadius: 8,
    backgroundColor: "white",
    "& img": {
      width: "inherit",
      height: "inherit",
      objectFit: "contain"
    }
  },
  "& .company-name": {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  }
})

const MyCompanyCard = (props) => {

  const { company } = props;

  return (
    <Link to={`/company/${company.companyNameShort}`} style={{textDecoration: "none"}}>
      <CardStyle>
        <StyledRoot>
          <Box display="flex" alignItems="center">
            <Avatar className="avatar" variant="rounded" src={company.companyImageURL}>
              <img src="/assets/logo_default.png" />
            </Avatar>
            <Box flexGrow={1} paddingLeft="16px">
              <Typography className="company-name" variant="h6">{company.companyName}</Typography>
            </Box>
          </Box>
        </StyledRoot>
      </CardStyle>
    </Link>
  )
}

export default MyCompanyCard