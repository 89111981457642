import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Card, CardContent, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import ButtonBlue from "../shared/general/ButtonBlue";

const StyledRoot = styled("div")({
  // paddingBottom: 120,
  "& .category-head": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-card": {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: 12,
    ["@media only screen and (max-width: 600px)"]:{
      gridTemplateColumns: "repeat(1, 1fr)",
    }
  },
});

const StyledCard = styled(Card)({
  boxShadow: "none",
  backgroundSize: "cover",
  backgroundPosition: "50%",
  borderRadius: 16,
  "& .inner": {
    height: "6rem",
    padding: "8px 16px",
    backgroundColor: "#0000007A",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    boxSizing: "border-box",
    "&:hover>div": {
      transform: "translate3d(0,-8px,0)",
    },
    "& div": {
      display: "flex",
      flexDirection: "column",
    },
    "& .MuiTypography-subtitle": {
      color: "#FFFFFF",
      fontSize: 22,
      lineHeight: 1.2,
    },
    "& .MuiTypography-caption": {
      color: "#FFFFFF",
      lineHeight: 1.2,
      fontSize: 14,
    },
  },
});

const Category = (props) => {
  const { jobGroups, isFetching } = props;
  const history = useHistory();

  const handleClickCard = (query) => {
    history.push("/jobs?jobGroup=" + query);
  }

  return (
    <Fragment>
      {jobGroups.length > 0 && !isFetching && <StyledRoot>
        <div className="category-head">
          <Typography variant="h5" gutterBottom>
            ประเภทงาน
          </Typography>
          <div>
            <ButtonBlue
              component={Link}
              to={`/jobs`}
            >
              ทั้งหมด{" "}
              <i
                style={{ lineHeight: 0, paddingLeft: 6 }}
                className="fi fi-br-angle-small-right"
              ></i>
            </ButtonBlue>
          </div>
        </div>
        <div className="wrap-card">
          {jobGroups.map((jobGroup, index) => {
            return (
              <StyledCard
                key={index}
                style={{ backgroundImage: jobGroup.image && `url(${require(`./assets/jobGroup/${jobGroup.image}`)})` || "" }}
                onClick={() => handleClickCard(jobGroup.idJobGroup)}
              >
                <div className="inner">
                  <div>
                    <Typography variant="subtitle" style={{ fontSize: "18px" }}>{jobGroup.jobGroupName}</Typography>
                    <Typography variant="caption">{`${jobGroup.openJobCount || 0} งาน`}</Typography>
                  </div>
                </div>
              </StyledCard>
            );
          })}
        </div>
      </StyledRoot>}
    </Fragment>
  );
};

export default Category;
