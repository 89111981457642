import { httpClient } from "./httpClient";

const addManpower = (formData) => {
  return httpClient.post("manpower", formData);
};

const updateManpower = (idManpower, formData) => {
  return httpClient.patch(`manage/vendors/manpower/${idManpower}`, formData);
};

const getManpowerById = (idManpower) => {
  return httpClient.get(`manpower/${idManpower}`);
};

const addManpowerExperience = (idManpower, formData) => {
  return httpClient.post(`manage/vendors/manpower/${idManpower}/experiences`, formData);
};

const updateManpowerExperience = (idManpower, idManpowerExperience, formData) => {
  return httpClient.patch(`manage/vendors/manpower/${idManpower}/experiences/${idManpowerExperience}`, formData);
};

const deleteManpowerExperience = (idManpower, idManpowerExperience) => {
  return httpClient.delete(`manage/vendors/manpower/${idManpower}/experiences/${idManpowerExperience}`);
};

const addManpowerEducation = (idManpower, formData) => {
  return httpClient.post(`manage/vendors/manpower/${idManpower}/educations`, formData);
};

const updateManpowerEducation = (idManpower, idManpowerEducation, formData) => {
  return httpClient.patch(`manage/vendors/manpower/${idManpower}/educations/${idManpowerEducation}`, formData);
};

const deleteManpowerEducation = (idManpower, idManpowerEducation) => {
  return httpClient.delete(`manage/vendors/manpower/${idManpower}/educations/${idManpowerEducation}`);
};

const updateManpowerStatus = (idManpower, formData) => {
  return httpClient.patch(`manage/vendors/manpower/${idManpower}/status`, formData);
};

const addManpowerFromExcel = (formData) => {
  return httpClient.post(`manpower/excel`, formData);
};

const addManpowerCertificate = (idManpower, formData) => {
  return httpClient.post(`manage/vendors/manpower/${idManpower}/certificates`, formData);
};

const getManpowerCertificate = (idManpower) => {
  return httpClient.get(`manage/vendors/manpower/${idManpower}/certificates`);
};

const deleteManpowerCertificate = (idManpower, idManpowerCertificate) => {
  return httpClient.delete(`manage/vendors/manpower/${idManpower}/certificates/${idManpowerCertificate}`);
};

const addManpowerResume = (idManpower, formData) => {
  return httpClient.post(`manage/vendors/manpower/${idManpower}/resume`, formData);
};

const deleteManpowerResume = (idManpower, idManpowerResume) => {
  return httpClient.delete(`manage/vendors/manpower/${idManpower}/resume/${idManpowerResume}`);
};

const getFormAddManpowerByVendorId = (idVendor) => {
  return httpClient.get(`manpower/getFormAddManpowerByVendorId/${idVendor}`);
};

const addManpowerByVendorId = (idVendor, formData) => {
  return httpClient.post(`manpower/addManpowerByVendorId/${idVendor}`, formData);
};

export default {
  addManpower,
  updateManpower,
  getManpowerById,
  addManpowerExperience,
  updateManpowerExperience,
  deleteManpowerExperience,
  addManpowerEducation,
  updateManpowerEducation,
  deleteManpowerEducation,
  updateManpowerStatus,
  addManpowerFromExcel,
  addManpowerCertificate,
  getManpowerCertificate,
  deleteManpowerCertificate,
  addManpowerResume,
  deleteManpowerResume,
  getFormAddManpowerByVendorId,
  addManpowerByVendorId
};
