import React, { useEffect, useState  } from "react";
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import { Avatar, Box, styled, TablePagination, Typography } from "@mui/material";
import TableTheme from "../../shared/general/TableTheme";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { stringToColor } from "../../../../utils";

var buddhistEra = require('dayjs/plugin/buddhistEra');
dayjs.extend(buddhistEra);

const StyledRoot = styled("div")({
  "& .part-filter": {
    marginBottom: 16,
    "& .search-name": {
      width: 250,
    }
  },
  "& .CandidateSuccess":{
    animation: "CandidateSuccess 0.8s 5",
    animationFillMode: "forwards"
  }
});

const columns = [
  {
    field: "name",
    headerName: "ชื่อ-สกุล",
    width: 250,
    classname: (params) => { return !params.isRead ? "CandidateSuccess" : null; },
    format: (params) => (
      <Box 
        className="column-name"
        component={Link} 
        to={`/manpower/profile/${params.idManpower}`} 
        style={{ textDecoration:"none", color:"black" }}
      >
        {/* <Avatar src={`${process.env.REACT_APP_API_URL}image/profile/${parseInt(Math.random()*20)+1}.jpg`}/> */}
        <Avatar sx={{bgcolor: stringToColor(`${params.name} ${params.lastname}`)}} children={`${params.name[0]}${params.lastname[0]}`}/>
        <Typography>{params && `${params.name} ${params.lastname}` || "-"}</Typography>
      </Box>
    )
  },
  {
    field: "companyName",
    headerName: "บริษัท",
    width: 250,
    classname: (params) => { return !params.isRead ? "CandidateSuccess" : null; },
    format: (params) => (
      <Box>
        <Typography>{params && params.companyName || "ไม่พบชื่อบริษัท"}</Typography>
      </Box>
    )
  },
  {
    field: "postionName",
    headerName: "ตำแหน่งงาน",
    width: 150,
    classname: (params) => { return !params.isRead ? "CandidateSuccess" : null; },
    format: (params) => (
      <Box>
        <Typography>{params && params.positionName || "ไม่พบชื่อตำแหน่งงาน"}</Typography>
      </Box>
    )
  },
  {
    field: "workDuration",
    headerName: "เริ่มงาน",
    width: 200,
    classname: (params) => { return !params.isRead ? "CandidateSuccess" : null; },
    format: (params) => (
      <Box>
        <Typography>{`${dayjs(params.workStartDate).format("D MMM BBBB")}`}</Typography>
      </Box>
    )
  }
]

const SentManpower = (props) => {
  const { candidate } = props;
  const [filterCandidates, setFilterCandidates] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if(candidate){
      setFilterCandidates([...candidate.filter((can) => can.name.includes(search) || can.lastname.includes(search))]);
    }
  },[candidate, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return(
    <StyledRoot>
      <div className="header-container">
        <Typography variant="h4" id="Candidate">รายชื่อผู้ถูกส่งเข้าทำงาน</Typography>
      </div>
      <div className="part-filter">
        <TextFieldTheme 
          className="search-name" 
          placeholder="ค้นหาชื่อ..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <TableTheme
        columns={columns}
        rows={filterCandidates}
        textEmpty={"ไม่พบรายชื่อพนักงาน"}
      />
      <TablePagination
        rowsPerPageOptions={[2, 10, 25, 100]}
        component="div"
        count={filterCandidates.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledRoot>
  )
}

export default SentManpower;