import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import {
  Grid,
  styled,
  Typography
} from "@mui/material";

import CardBiddingManager from '../../shared/CardBidding';

import { getBiddingsMyJobs } from "../../../../../actions/bidding";

const StyledRoot = styled("div")({

});

const  BiddingPanel = (props) => {

  const { data, type } = props;

  const dispatch = useDispatch();
    // const { result : UserProfile } = useSelector((state) => state.userProfile);
    // const { isFetching: isFetching, result : AllBiddings } = useSelector((state) => state.biddingsMyJobs);
    // const [listBiddings, setListBiddings] = useState([]);

    // useEffect(() => {
    //     if(UserProfile && UserProfile.idCompany) {
    //         dispatch(getBiddingsMyJobs());
    //     }
    // },[UserProfile]);

    // useEffect(() => {
    //     if(AllBiddings && AllBiddings.length > 0){
    //         let list = AllBiddings.filter((bidding) => {
    //           return (type === "" ||
    //             (type === "notOpen" &&
    //               dayjs().isBefore(dayjs(bidding.biddingStartAt))) ||
    //             (type === "ending" &&
    //               dayjs().isAfter(dayjs(bidding.biddingEndAt))) ||
    //             (type === "opening" &&
    //               !dayjs().isBefore(dayjs(bidding.biddingStartAt)) &&
    //               !dayjs().isAfter(dayjs(bidding.biddingEndAt))));
    //         });
    //         setListBiddings([...list]);
    //     }
    // },[AllBiddings, type]);

  return (
    <StyledRoot>
      <Grid container spacing={3}>
        {data.map((bidding) => (
          <Grid item xs={12} sm={6} md={4} key={bidding.idBiddings}>
            <CardBiddingManager  bidding={bidding} isManage={true} />
          </Grid>
        ))}
        {/* {!isFetching && listBiddings.length > 0 && 
            listBiddings.map((bidding) => {
                return (
                    <Grid item xs={12} sm={6} lg={4} key={bidding.idBiddings}>
                        <CardBiddingManager  bidding={bidding} isManage={true} />
                    </Grid>
                );
            })
        }
        {isFetching && 
            <Grid item xs={12}>
                <Typography variant="h5" textAlign="center">กำลังโหลดข้อมูล...</Typography>
            </Grid>
        }
        {!isFetching && AllBiddings && AllBiddings.length === 0 && 
            <Grid item xs={12}>
                <Typography variant="h5" textAlign="center">ไม่พบการเปิดรับสมัครงาน Bidding</Typography>
            </Grid>
        } */}
        {data.length === 0 && <Grid item xs={12}><Typography variant="h5" textAlign="center">ไม่พบสัญญา</Typography></Grid>}
      </Grid>
    </StyledRoot>
  );
};

export default BiddingPanel;