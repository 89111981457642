import React, { useEffect, useState } from "react";
import { Grid, styled, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardVendor from "../../shared/general/CardVendor";

import vendorStore from "../../assets/data/vendor";

const StyledRoot = styled("div")({
  "&.vendors": {
    paddingBottom: 64,
    "& .part-header": {
      paddingBottom: 16,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .all-button": {
        "& i": {
          display: "flex",
        }
      }
    },
  }
})

const ContractVendor = () => {

  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    setVendors(vendorStore.slice(3, 6));
  }, [])

  return(
    <StyledRoot className="vendors">
      <div className="part-header">
        <Typography variant="h4">เวนเดอร์คู่สัญญา</Typography>
        <ButtonBlue className="all-button">ทั้งหมด<i className="fi fi-br-angle-small-right"></i></ButtonBlue>
      </div>
      <Grid container spacing={2}>
        {vendors.map((vendor, index) => (
          <Grid key={`vendor_${index}`} item xs={4}>
            <CardVendor vendor={vendor}/>
          </Grid>
        ))}
      </Grid>
    </StyledRoot>
  )
}

export default ContractVendor;