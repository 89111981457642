import {
  JOBS_FETCHING,
  JOBS_FAILED,
  JOBS_SUCCESS,
  JOB_DETAIL_FETCHING,
  JOB_DETAIL_FAILED,
  JOB_DETAIL_SUCCESS,
  JOB_FORM_FETCHING,
  JOB_FORM_FAILED,
  JOB_FORM_SUCCESS,
  JOB_GROUPS_FETCHING,
  JOB_GROUPS_FAILED,
  JOB_GROUPS_SUCCESS,
  JOB_EDIT_FETCHING,
  JOB_EDIT_FAILED,
  JOB_EDIT_SUCCESS,
  HISTORY_JOB_COMPANY_FETCHING,
  HISTORY_JOB_COMPANY_FAILED,
  HISTORY_JOB_COMPANY_SUCCESS
} from "./types";
import jobService from "../services/job.service";

export const getAllJobRequestAllVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: JOBS_FETCHING
    });
    const res = await jobService.getAllJobRequestAllVendor();
    if (res) {
      dispatch({
        type: JOBS_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: JOBS_FAILED
    });
  }
};

export const getJobRequestByIdJobRequest = (idJobRequest) => async (dispatch) => {
  try {
    dispatch({
      type: JOB_DETAIL_FETCHING
    });
    const res = await jobService.getJobRequestByIdJobRequest(idJobRequest);
    if (res) {
      dispatch({
        type: JOB_DETAIL_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: JOB_DETAIL_FAILED
    });
  }
};

export const getCompanyMyJob = () => async (dispatch) => {
  try {
    dispatch({
      type: JOBS_FETCHING
    });
    const res = await jobService.getCompanyMyJob();
    if (res) {
      dispatch({
        type: JOBS_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: JOBS_FAILED
    });
  }
};

export const closeJob = (idJob) => async () => {
  try {
    const res = await jobService.closeJob(idJob);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const addJob = (formData) => async () => {
  try {
    const res = await jobService.addJob(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const updateJob = (idJob, formData) => async () => {
  try {
    const res = await jobService.updateJob(idJob, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getJobForm = () => async (dispatch) => {
  try {
    dispatch({
      type: JOB_FORM_FETCHING
    });
    const res = await jobService.getJobForm();
    if (res) {
      dispatch({
        type: JOB_FORM_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: JOB_FORM_FAILED
    });
  }
};

export const getJobGroups = () => async (dispatch) => {
  try {
    dispatch({
      type: JOB_GROUPS_FETCHING
    });
    const res = await jobService.getJobGroups();
    if (res) {
      dispatch({
        type: JOB_GROUPS_SUCCESS,
        payload: res.data
      })
    }
  } catch (error) {
    dispatch({
      type: JOB_GROUPS_FAILED
    });
  }
};

export const getJobEdit = (idJob) => async (dispatch) => {
  try {
    dispatch({
      type: JOB_EDIT_FETCHING
    });
    const res = await jobService.getJobEdit(idJob);
    if (res) {
      dispatch({
        type: JOB_EDIT_SUCCESS,
        payload: res.data
      })
    }
  } catch (error) {
    dispatch({
      type: JOB_EDIT_FAILED
    });
  }
};

export const getHistoryJobsCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: HISTORY_JOB_COMPANY_FETCHING
    });
    const res = await jobService.getHistoryJobsCompany();
    if (res) {
      dispatch({
        type: HISTORY_JOB_COMPANY_SUCCESS,
        payload: res.data
      })
    }
  } catch (error) {
    dispatch({
      type: HISTORY_JOB_COMPANY_FAILED
    });
  }
};

export const addJobAttachFile = (idJob, formData) => async () => {
  try {
    const res = await jobService.addJobAttachFile(idJob, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const deleteJobAttachFile = (idJob) => async () => {
  try {
    const res = await jobService.deleteJobAttachFile(idJob);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getJobs = (query) => async (dispatch) => {
  try {
    dispatch({
      type: JOBS_FETCHING
    });
    const res = await jobService.getJobs(query);
    if (res) {
      dispatch({
        type: JOBS_SUCCESS,
        payload: res.data
      })
    }
  } catch (error) {
    dispatch({
      type: JOBS_FAILED
    });
  }
};