import React, { useEffect, useState } from "react";
import { Box, Container, Grid, styled, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import FormOne from "./formOne";
import FormTwo from "./formTwo";
import { useDispatch, useSelector } from "react-redux";
import { getJobGroups } from "../../../../../actions/job";
import FormThree from "./formThree";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { addManpower } from "../../../../../actions/manpower";
import Left from "./left";

const StyledRoot = styled(Box)({
  "& .part-expertise": {
    "& .MuiPaper-root": {
      height: "100%",
      minHeight: 96,
      borderRadius: 16,
      position: "relative",
      padding: 16,
      boxSizing: "border-box",
      userSelect: "none",
      "& .wrap-name": {
        width: 160,
        color: "#919eab",
        "& .MuiTypography-root": {
          fontSize: 20,
        },
      },
      "& .wrap-icon": {
        position: "absolute",
        fontSize: 35,
        color: "#919eab",
        right: 16,
        bottom: 8,
      },
    },
    "& .isSelected": {
      borderColor: "#1976d2",
      "& .wrap-name": {
        color: "#1976d2",
      },
      "& .wrap-icon": {
        color: "#1976d2",
      }
    },
  },
  "& .success-wraper": {
    paddingTop: 55,
    textAlign: "center",
    "& .button-wrap": {
      display: "inline-block",
      marginTop: ".6em",
    }
  }
})

const degrees = [
  {
    idDegrees: 1,
    degreeName: "ประถมศึกษา",
  },
  {
    idDegrees: 2,
    degreeName: "มัธยมศึกษา",
  },
  {
    idDegrees: 3,
    degreeName: "ปวช.",
  },
  {
    idDegrees: 4,
    degreeName: "ปวส.",
  },
  {
    idDegrees: 5,
    degreeName: "ปริญญาตรี",
  },
  {
    idDegrees: 6,
    degreeName: "ปริญญาโท",
  },
  {
    idDegrees: 7,
    degreeName: "ปริญญาเอก",
  },
]

const AddManpowerFormPage = () => {

  // const [activeForm, setActiveForm] = useState(0);
  // const matches = useMediaQuery('(min-width:900px)');

  const [isFetching, setIsFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const {result: jobGroups} = useSelector(state => state.jobGroups)

  const [page, setPage] = useState(1);

  const onNextPage = () => {
    setPage(prev => prev + 1)
  }

  const onPrevPage = () => {
    setPage(prev => prev - 1)
  }

  const dispatch = useDispatch();
  // const history = useHistory();

  const useFormOne = useForm({
    defaultValues: {
      title: "",
      firstname: "",
      lastname: "",
      phoneNumber: "",
      email: "",
      dateOfBirth: null,
      resume: null,
      personalId: "",
    }
  })

  const useFormTwo = useForm({
    defaultValues: {
      idJobGroup: "",
      workExperienceY: "",
      workExperienceM: "",
      educationDegree: "",
      currentSalary: "",
      expectedSalary: "",
    }
  })

  const useFormThree = useForm({
    defaultValues: {
      education: [
        {
          startYear: null,
          endYear: null,
          universityName: "",
          degree: "",
          faculty: "",
          major: "",
          gpa: "",
        }
      ],
      work: [
        {
          startYear: null,
          endYear: null,
          companyName: "",
          positionName: "",
          jobGroup: null,
        }
      ],
      certificates: [],
    }
  })

  const onFinalSubmit = () => {

    setIsFetching(true);

    const formData = {
      ...useFormOne.getValues(),
      ...useFormTwo.getValues(),
      ...useFormThree.getValues(),
    }

    formData.idJobGroup = formData.idJobGroup? formData.idJobGroup: null;
    formData.currentSalary = formData.currentSalary? formData.currentSalary: null;
    formData.expectedSalary = formData.expectedSalary? formData.expectedSalary: null;
    formData.personalId = formData.personalId? formData.personalId: null;
    formData.workExperienceY = formData.workExperienceY? formData.workExperienceY: null;
    formData.workExperienceM = formData.workExperienceM? formData.workExperienceM: null;

    formData.education = formData.education.map(education => {
      if(education.startYear){
        education.startYear = dayjs(education.startYear).format("YYYY")
      } else {
        education.startYear = null
      }
      if(education.endYear){
        education.endYear = dayjs(education.endYear).format("YYYY")
      } else {
        education.endYear = null
      }

      education.faculty = (education.faculty || null);
      education.gpa = (education.gpa || null);
      education.major = (education.major || null);

      return education
    })

    formData.work = formData.work.map(work => { 
      return {
        startYear: work.startYear? dayjs(work.startYear).format("YYYY"): null,
        endYear: work.endYear? dayjs(work.endYear).format("YYYY"): null,
        companyName: work.companyName? work.companyName: null,
        positionName: work.positionName? work.positionName: null,
        idJobGroup: (work.jobGroup && work.jobGroup.idJobGroup) ? work.jobGroup.idJobGroup: null
      }
    })

    const formData2 = new FormData;

    Object.keys(formData).map(key => {

      if(key === "certificates"){
        formData[key].map(f => {
          formData2.append(key, f)
        })
        return;
      }

      if(key === "resume"){
        formData2.append(key, formData[key])
        return;
      }

      formData2.append(key, JSON.stringify(formData[key]))
    })
    
    dispatch(addManpower(formData2)).then(res => {
      if(res.status === 200){
        setIsSuccess(true)
      }
      setIsFetching(false);
    })
  }

  useEffect(() => {
    dispatch(getJobGroups());
  }, [dispatch])

  return (
    <Box className="page">
      <StyledRoot>
        {!isSuccess && jobGroups && (
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Left useFormOne={useFormOne} useFormTwo={useFormTwo} useFormThree={useFormThree}/>
              </Grid>

              <Grid item xs={12} md={8}>
                <Box padding="24px 0">
                  <Typography variant="h5">เพิ่มกำลังพล</Typography>
                </Box>

                {page === 1 && <FormOne onNextPage={onNextPage} useForm={useFormOne}/>}
                {page === 2 && <FormTwo onNextPage={onNextPage} onPrevPage={onPrevPage} useForm={useFormTwo} degrees={degrees}/>}
                {page === 3 && <FormThree onFinalSubmit={onFinalSubmit} onPrevPage={onPrevPage} useForm={useFormThree} degrees={degrees} isFetching={isFetching}/>}

              </Grid>
            </Grid>
          </Container>
        )}

        {isSuccess && 
          <Container maxWidth="lg">
            <div className="success-wraper">
              <img 
                style={{ width:300, marginBottom:"20px" }}
                src={`${process.env.PUBLIC_URL}/assets/background/complete.jpg`}
                alt="https://www.freepik.com/free-vector/completed-concept-illustration_10802151.htm"  
              />
              <Typography variant="h4" textAlign="center">เพิ่มกำลังพลสำเร็จ</Typography>
              <div className="button-wrap">
                <ButtonBlue variant="outlined" component={Link} to="/manpower">กำลังพล</ButtonBlue>
              </div>
            </div>
          </Container>
        }

      </StyledRoot>
    </Box>
  )
}

export default AddManpowerFormPage;