import React, { useState } from "react";
import { Box, IconButton, styled, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DialogAddContract from "./dialogAddContract";
import DialogStatus from "../vendor/dialogStatus";
import { useDispatch } from "react-redux";
import { getContractCompany, getContractVendor } from "../../../../actions/contract";
import DialogDeleteContract from "./dialogDeleteContract";

const StyledRoot = styled(Box)({
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      color: "rgb(99, 115, 129)",
      backgroundColor: "rgb(244, 246, 248)"
    }
  },
  
  "& .contract-number-cell": {
    minWidth: 150,
    width: 150,
    boxSizing: "border-box",
  },
  "& .contract-file-cell": {
    minWidth: 200,
    width: 200,
    boxSizing: "border-box",
  },
  "& .start-date-cell": {
    minWidth: 150,
    width: 150,
    boxSizing: "border-box",
  },
  "& .end-date-cell": {
    minWidth: 150,
    width: 150,
    boxSizing: "border-box",
  },
  "& .upload-date-cell": {
    minWidth: 200,
    width: 200,
    boxSizing: "border-box",
  },
  "& .action-cell": {
    minWidth: 100,
    width: 100,
    boxSizing: "border-box",
  }
});

const ContractCompany = (props) => {

  const { id, contractCompany } = props;

  const [pageMyContract, setPageMyContract] = useState(0);
  const [pageParentContract, setPageParentContract] = useState(0);
  const [isOpenAddContractDialog, setIsOpenAddContractDialog] = useState(false);

  const [statusDialogConfig, setStatusDialogConfig] = useState({
    isOpen: false,
    status: null,
    message: null,
  });

  const [deleteDialogConfig, setDeleteDialogConfig] = useState({
    isOpen: false,
    idContractCompany: null,
    filename: null,
  })

  const dispatch = useDispatch();

  const refreshContract = () => {
    dispatch(getContractVendor({idCompany: id}))
  }

  return (
    <StyledRoot>
      <Box display="flex" alignItems="center" marginBottom="24px">
        <Typography style={{ fontWeight: 600, fontSize: 18 }} flexGrow={1}>สัญญา</Typography>
        <ButtonBlue variant="outlined" startIcon={<i className="fa-solid fa-plus"></i>} onClick={()=>{setIsOpenAddContractDialog(true)}}>เพิ่มสัญญา</ButtonBlue>
      </Box>

      {isOpenAddContractDialog && (
        <DialogAddContract
          open={isOpenAddContractDialog}
          onClose={()=>{setIsOpenAddContractDialog(false)}}
          id={id}
          setStatusDialogConfig={setStatusDialogConfig}
        />
      )}

      {statusDialogConfig.isOpen && (
        <DialogStatus
          open={statusDialogConfig.isOpen}
          onClose={()=>{
            setStatusDialogConfig({
              isOpen: false,
              status: null,
              message: null,
            });
          }}
          refreshContract={refreshContract}
          status={statusDialogConfig.status}
          message={statusDialogConfig.message}
        />
      )}

      {deleteDialogConfig.isOpen && (
        <DialogDeleteContract
          open={deleteDialogConfig.isOpen}
          onClose={()=>{
            setDeleteDialogConfig({
              isOpen: false,
              idContractCompany: null,
            });
          }}
          id={deleteDialogConfig.id}
          filename={deleteDialogConfig.filename}
          refreshContract={refreshContract}
        />
      )}

      <Box overflow="auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="contract-number-cell">เลขที่สัญญา</TableCell>
              <TableCell className="contract-file-cell">สัญญา</TableCell>
              <TableCell className="start-date-cell">วันที่เริ่มต้นสัญญา</TableCell>
              <TableCell className="end-date-cell">วันที่สิ้นสุดสัญญา</TableCell>
              <TableCell className="upload-date-cell">อัพโหลดเมื่อ</TableCell>
              <TableCell className="action-cell"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contractCompany.contracts
            .slice(5*pageMyContract, 5*(pageMyContract+1))
            .map((c, index) =>
              <TableRow key={index}>
                <TableCell className="contract-number-cell">{c.idContractVendor}</TableCell>
                <TableCell className="contract-file-cell">{c.filename}</TableCell>
                <TableCell className="start-date-cell">{dayjs(c.startDate).format("DD MMM YYYY")}</TableCell>
                <TableCell className="end-date-cell">{dayjs(c.endDate).format("DD MMM YYYY")}</TableCell>
                <TableCell className="upload-date-cell">{dayjs(c.createdAt).format("DD MMM YYYY HH:mm น.")}</TableCell>
                <TableCell className="action-cell">
                  <Box display="flex" gap="8px" justifyContent="flex-end">
                    <IconButton sx={{"& i": {fontSize: 14}}} component={"a"} href={c.fileURL}>
                      <i className="fa-solid fa-eye"></i>
                    </IconButton>
                    <IconButton sx={{"& i": {fontSize: 14}}} onClick={()=>{setDeleteDialogConfig({isOpen: true, id: c.idContractVendor, filename: c.filename})}}>
                      <i className="fa-solid fa-trash-can"></i>
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {contractCompany.contracts.length > 0 && [...new Array(5 - ((contractCompany.contracts
            .slice(5*pageMyContract, 5*(pageMyContract+1))).length))]
            .map((c, index) =>
              <TableRow key={index} height="63px">
                <TableCell className="contract-number-cell"></TableCell>
                <TableCell className="contract-file-cell"></TableCell>
                <TableCell className="start-date-cell"></TableCell>
                <TableCell className="end-date-cell"></TableCell>
                <TableCell className="upload-date-cell"></TableCell>
                <TableCell className="action-cell">
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {contractCompany.contracts.length <= 0 && (
          <Box paddingTop="24px" position="sticky" left={0} minHeight="300px" display="flex" justifyContent="center" alignItems="center">
            <Typography textAlign="center">ไม่พบสัญญา</Typography>
          </Box>
        )}

        {contractCompany.contracts.length > 5 && (
          <TablePagination
            component={Box}
            count={contractCompany.contracts.length}
            onPageChange={(_, newPage) => {setPageMyContract(newPage)}}
            page={pageMyContract}
            rowsPerPage={5}
            rowsPerPageOptions={[]}
          />
        )}
      </Box>

      {contractCompany.hasParentCompany && (
        <Box marginTop="24px">
          <Typography  style={{ fontWeight: 600, fontSize: 18 }} marginBottom="24px">สัญญาของบริษัทแม่ - {contractCompany.parentCompanyName}</Typography>
          <Box overflow="auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="contract-number-cell">เลขที่สัญญา</TableCell>
                  <TableCell className="contract-file-cell">สัญญา</TableCell>
                  <TableCell className="start-date-cell">วันที่เริ่มต้นสัญญา</TableCell>
                  <TableCell className="end-date-cell">วันที่สิ้นสุดสัญญา</TableCell>
                  <TableCell className="upload-date-cell">อัพโหลดเมื่อ</TableCell>
                  <TableCell className="action-cell"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contractCompany.parentContracts
                .slice(5*pageParentContract, 5*(pageParentContract+1))
                .map((c, index) =>
                  <TableRow key={index}>
                    <TableCell className="contract-number-cell">{c.idContractVendor}</TableCell>
                    <TableCell className="contract-file-cell">{c.filename}</TableCell>
                    <TableCell className="start-date-cell">{dayjs(c.startDate).format("DD MMM YYYY")}</TableCell>
                    <TableCell className="end-date-cell">{dayjs(c.endDate).format("DD MMM YYYY")}</TableCell>
                    <TableCell className="upload-date-cell">{dayjs(c.createdAt).format("DD MMM YYYY HH:mm น.")}</TableCell>
                    <TableCell className="action-cell">
                      <Box display="flex" gap="8px" justifyContent="flex-end">
                        <IconButton sx={{"& i": {fontSize: 14}}} component={"a"} href={c.fileURL}>
                          <i className="fa-solid fa-eye"></i>
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {contractCompany.parentContracts.length > 0 && [...new Array(5 - ((contractCompany.parentContracts
                .slice(5*pageMyContract, 5*(pageMyContract+1))).length))]
                .map((c, index) =>
                  <TableRow key={index} height="63px">
                    <TableCell className="contract-number-cell"></TableCell>
                    <TableCell className="contract-file-cell"></TableCell>
                    <TableCell className="start-date-cell"></TableCell>
                    <TableCell className="end-date-cell"></TableCell>
                    <TableCell className="upload-date-cell"></TableCell>
                    <TableCell className="action-cell">
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {contractCompany.parentContracts.length <= 0 && (
              <Box paddingTop="24px" position="sticky" left={0} minHeight="300px" display="flex" justifyContent="center" alignItems="center">
                <Typography textAlign="center">ไม่พบสัญญา</Typography>
              </Box>
            )}

            {contractCompany.parentContracts.length > 5 && (
              <TablePagination
                component={Box}
                count={contractCompany.parentContracts.length}
                onPageChange={(_, newPage) => {setPageParentContract(newPage)}}
                page={pageParentContract}
                rowsPerPage={5}
                rowsPerPageOptions={[]}
              />
            )}
          </Box>
        </Box>
      )}
    </StyledRoot>
  )
}

export default ContractCompany