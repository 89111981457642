import React, { Fragment, useState } from 'react';

import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    styled,
    Tooltip,
    Typography
} from '@mui/material';

const StyledDialogContent = styled(DialogContent)({
    width: 400,
    height: 200,
    "& .content":{
        padding:"16px 24px",
        "& .text":{
            textIndent: 30
        }
    },
    "& .noContent":{
        height:100,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    }
});

const DrawerNote = (props) => {
    const { note } = props;
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <Tooltip title="โน๊ต" placement="top">
                <IconButton 
                    style={{ width:"100%", height:"100%", color:"#007afe" }}
                    onClick={() => setOpen(true)}
                >
                    <i className="fa-regular fa-memo"></i>
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <StyledDialogContent>
                    <Typography variant='h4'>โน๊ต</Typography>
                    {note ? 
                        <Box className="content">
                            <Typography className="text">{note}</Typography>
                        </Box>
                        :
                        <Box className="content noContent">
                            <Typography>{"ไม่มีการโน๊ต"}</Typography>
                        </Box>
                    }
                </StyledDialogContent>
            </Dialog>
        </Fragment>
    );
};

export default DrawerNote;