import React, { useEffect } from "react";
import { Container, Grid, Rating, styled, Typography } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import TableManpower from "./tableManpower";
import ContactCompany from "./contactCompany";
import GraphManpowerSentSuccess from "./graphManpower-sent-success";
import GraphManpowerIncrease from "./graphManpowerIncrease";
import StyledStatCard from "./statCard";

import { useDispatch, useSelector } from "react-redux";
import { getDashboardVendor } from "../../../../actions/dashboard";
import ManpowerDasboard from "./manpowerDashboard";

const StyledRoot = styled("div")({
  "& .part-header": {
    padding: "24px 0",
  }
})

const StyledCard = styled("div")({
  "& .card-dashboard": {
    padding: 24,
    borderRadius: 20,
    border: "1px solid #ececec",
    position: "relative",
    "& .part-header-card": {
      fontSize: 16,
    },
    "& .part-value": {
      paddingRight: 60,
      fontSize: 36,
      fontWeight: 500,
    },
    "& .part-icon": {
      position: "absolute",
      right: 24,
      bottom: 24,
      fontSize: 48,
    }
  },
})

const StyledDetailCard = styled("div")({
  padding: 24,
  paddingTop: 16,
  "& .part-header-detail": {
    paddingBottom: 24,
  }
})

const VendorDashboard = () => {

  const dispatch = useDispatch();

  const { result: dashboardVendor } = useSelector(state => state.dashboardVendor);

  useEffect(() => {
    dispatch(getDashboardVendor())
  }, [])

  return(
    <StyledRoot className="page">
      <Container maxWidth="xl">

        <div className="part-header">
          <Typography variant="h4">แดชบอร์ด</Typography>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <StyledStatCard title="กำลังพลทั้งหมด" monthlyValue={`+${(dashboardVendor && dashboardVendor.manpowerCurrentMonthCount)}`} value={(dashboardVendor && dashboardVendor.manpowerCount)} unit="คน"/>
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledStatCard title="จำนวนครั้งที่ส่ง" monthlyValue={`+${(dashboardVendor && dashboardVendor.manpowerSentCurrentMonth)}`} value={(dashboardVendor && dashboardVendor.manpowerSent) || "-"} unit="ครั้ง"/>
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledStatCard title="จำนวนครั้งที่สำเร็จ" monthlyValue={`+${(dashboardVendor && dashboardVendor.manpowerSuccessCurrentMonth)}`}  value={(dashboardVendor && dashboardVendor.manpowerSuccess) || "-"} unit="ครั้ง"/>
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledStatCard title="กำลังรอผลสัมภาษณ์" value={(dashboardVendor && dashboardVendor.manpowerWaitingInterview)} unit="คน"/>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CardStyle>
                      <StyledDetailCard>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 100}}>
                          <Rating
                            defaultValue={3.5}
                            precision={0.5}
                            icon={<i className="fa-solid fa-star"></i>}
                            emptyIcon={<i className="fa-regular fa-star"></i>}
                            readOnly
                            style={{fontSize: 48}}
                          />
                          <Typography
                            lineHeight={1}
                            style={{fontSize: 48, paddingLeft: 8, color: "#faaf00", fontWeight: 500}}
                          >
                            3.5
                          </Typography>
                        </div> 
                      </StyledDetailCard>
                    </CardStyle>
                  </Grid>

                  <Grid item xs={12}>
                    <CardStyle>
                      <StyledDetailCard>
                        <div style={{display: "flex", paddingBottom: 16}}>
                          <Typography fontSize={24} style={{flexGrow: 1}}>กำลังพล</Typography>
                          <Typography fontSize={24}>เดือนนี้ {(dashboardVendor && dashboardVendor.manpowerCurrentMonthCount) || 0} คน</Typography>
                        </div>

                        {/* <TableManpower /> */}
                        <ManpowerDasboard value={(dashboardVendor && dashboardVendor.manpower) || []}/>
                      </StyledDetailCard>
                    </CardStyle>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} container>
                <Grid container spacing={2}>
                  <Grid item xs={12} container>
                    <CardStyle>
                      <StyledDetailCard>
                        <Typography className="part-header-detail" fontSize={24}>บริษัทคู่สัญญา</Typography>
                        <ContactCompany count={dashboardVendor && dashboardVendor.companyContractCount} companyContractList={dashboardVendor && dashboardVendor.companyContract}/>
                      </StyledDetailCard>
                    </CardStyle>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CardStyle>
                  <StyledDetailCard>
                    {/* <Typography className="part-header-detail">กราฟกำลังพลที่ส่งไป-ที่ได้รับงาน</Typography> */}
                    <GraphManpowerSentSuccess monthlyManpowerSentList={(dashboardVendor && dashboardVendor.monthlyManpowerSent) || []} monthlyManpowerSuccessList={(dashboardVendor && dashboardVendor.monthlyManpowerSuccess) || []}/>
                  </StyledDetailCard>
                </CardStyle>
              </Grid>

              <Grid item xs={12} md={6}>
                <CardStyle>
                  <StyledDetailCard>
                    {/* <Typography className="part-header-detail">กำลังพลรายเดือน</Typography> */}
                    <GraphManpowerIncrease monthlyManpowerList={(dashboardVendor && dashboardVendor.monthlyManpower) || []}/>
                  </StyledDetailCard>
                </CardStyle>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </StyledRoot>
  )
}

export default VendorDashboard;