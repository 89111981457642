import React from 'react';

const PriceTab = () => {
    return ( 
        <div>
            
        </div>
     );
}
 
export default PriceTab;