import React, { useEffect, useState } from "react";
import { Avatar, Card, Container, Grid, IconButton, styled, Typography } from "@mui/material";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import CardStyle from "../../shared/general/Card";

import locations from "../../assets/data/location";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const StyledRoot = styled("div")({
  "& a": {
    textDecoration: "none"
  },
  "& .part-filter": {
    marginBottom: 24,
    padding: 24,
    backgroundColor: "#f9fafb",
    borderRadius: 20,
    "& .MuiTextField-root": {
      "& input": {
        padding: 0,
      },
      "& fieldset": {
        border: "none",
      },
      "& i": {
        fontSize: 24,
        color: "#919EAB",
      },
      "& .icon": {
        paddingRight: 8,
      },
      "& .MuiIconButton-root": {
        width: 30,
        height: 30,
      }
    }
  },
  "& .not-found": {
    textAlign: "center",
    fontSize: 24,
  },
  "& .part-province-container": {
    display: "grid",
    gap: 24,
    gridTemplateColumns: "repeat(4, 1fr)",
    "& .card": {
      padding: 8,
      boxShadow: "none",
      borderRadius: 16,
      border: "1px solid #919eab3d",
      cursor: "pointer",
      "& .logo": {
        display: "flex",
        justifyContent: "center",
        "& .MuiAvatar-root": {
          width: 100,
          height: 100,
        },
      },
      "& .name": {
        paddingTop: 16,
        textAlign: "center",
        fontSize: 22,
        color: "#212b36",
        wordBreak: "break-word"
      }
    },
    "@media only screen and (max-width: 900px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media only screen and (max-width: 800px)": {
      gridTemplateColumns: "repeat(2, 2fr)",
    },
    "@media only screen and (max-width: 600px)": {
      gridTemplateColumns: "repeat(1, 2fr)",
    },
  }
})

const FindProvincePage = () => {

  const [provinceSearch, setProvinceSearch] = useState("");
  const [filterProvince, setFilterProvince] = useState([]);
  const { result: userMe } = useSelector(state => state.userProfile)

  useEffect(() => {
    setFilterProvince(locations.filter(p => {
      return (provinceSearch === "" || p.name.includes(provinceSearch.trim()))
    }))
    console.log(userMe)
  }, [provinceSearch])

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="part-filter">
          <TextFieldTheme
            placeholder="จังหวัด"
            autoComplete="province"
            InputProps={{
              startAdornment: <i className="fa-light fa-location-dot icon"></i>,
              endAdornment: (provinceSearch.length > 0? <IconButton onClick={()=>{setProvinceSearch("")}}><i className="fa-solid fa-xmark"></i></IconButton>: null)
            }}
            value={provinceSearch}
            onChange={(e)=>{setProvinceSearch(e.target.value)}}
          />
        </div>

        {provinceSearch.length > 0 && filterProvince.length <= 0 && <Typography className="not-found">ไม่พบผลการค้นหา</Typography>}
        
        <div className="part-province-container">
          {filterProvince.map((p, index) => (
            <Link
              key={`province_${index}`}
              to={
                userMe && userMe.idCompany?
                  `/vendor-search?province=${p.name}`
                : `/province/${p.name}`
              }
            >
              <Card className="card">
                <div className="logo">
                  <Avatar src={p.image}>
                  </Avatar>
                </div>
                <Typography className="name">{p.name}</Typography>
              </Card>
            </Link>
          ))}
        </div>
      </Container>
    </StyledRoot>
  )
}

export default FindProvincePage;