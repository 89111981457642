import {
    BIDDINGS_MYJOBS_FETCHING,
    BIDDINGS_MYJOBS_FAILED,
    BIDDINGS_MYJOBS_SUCCESS,
    BIDDINGS_VENDORS_FETCHING,
    BIDDINGS_VENDORS_FAILED,
    BIDDINGS_VENDORS_SUCCESS,
    MYBIDDINGS_VENDOR_FETCHING,
    MYBIDDINGS_VENDOR_FAILED,
    MYBIDDINGS_VENDOR_SUCCESS,
    HISTORY_BIDDINGS_VENDOR_FETCHING,
    HISTORY_BIDDINGS_VENDOR_FAILED,
    HISTORY_BIDDINGS_VENDOR_SUCCESS,
    HISTORY_BIDDINGS_COMPANY_FETCHING,
    HISTORY_BIDDINGS_COMPANY_FAILED,
    HISTORY_BIDDINGS_COMPANY_SUCCESS,
    BIDDING_INFORMATION_FETCHING,
    BIDDING_INFORMATION_FAILED,
    BIDDING_INFORMATION_SUCCESS,
    BIDDING_MIN_GUARANTEE_CONTRACT_FETCHING,
    BIDDING_MIN_GUARANTEE_CONTRACT_FAILED,
    BIDDING_MIN_GUARANTEE_CONTRACT_SUCCESS,
    MYCONTRACT_BIDDINGS_FETCHING,
    MYCONTRACT_BIDDINGS_FAILED,
    MYCONTRACT_BIDDINGS_SUCCESS,
    BIDDINGS_FETCHING,
    BIDDINGS_FAILED,
    BIDDINGS_SUCCESS,
} from "./types";

import BiddingService from "../services/bidding.service";

export const addBidding = (formData) => async () => {
  try {
    const res = await BiddingService.addBidding(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error.response;
  }
};

export const getBiddingsMyJobs = () => async (dispatch) => {
    try {
        dispatch({
            type: BIDDINGS_MYJOBS_FETCHING
        });
        const res = await BiddingService.getBiddingsMyJobs();
        if (res) {
            dispatch({
                type: BIDDINGS_MYJOBS_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: BIDDINGS_MYJOBS_FAILED
        })
    }
};

export const getBiddingVendor = (idBiddings) => async (dispatch) => {
    try {
        dispatch({
            type: BIDDINGS_VENDORS_FETCHING
        });
        const res = await BiddingService.getBiddingVendor(idBiddings);
        if (res) {
            dispatch({
                type: BIDDINGS_VENDORS_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: BIDDINGS_VENDORS_FAILED
        })
    }
};

export const conclusionBidding = (data) => async () => {
    try {
        const res = await BiddingService.conclusionBidding(data);
        if (res) {
            return res.data;
        }
    } catch (error) {
        const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
        return error;
    }
};

export const getMyBiddingsByVendor = () => async (dispatch) => {
    try {
        dispatch({
            type: MYBIDDINGS_VENDOR_FETCHING
        });
        const res = await BiddingService.getMyBiddingsByVendor();
        if (res) {
            dispatch({
                type: MYBIDDINGS_VENDOR_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: MYBIDDINGS_VENDOR_FAILED
        })
    }
};

export const getHistoryBiddingsVendor = () => async (dispatch) => {
    try {
        dispatch({
            type: HISTORY_BIDDINGS_VENDOR_FETCHING
        });
        const res = await BiddingService.getHistoryBiddingsVendor();
        if (res) {
            dispatch({
                type: HISTORY_BIDDINGS_VENDOR_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: HISTORY_BIDDINGS_VENDOR_FAILED
        })
    }
};

export const getHistoryBiddingsCompany = () => async (dispatch) => {
    try {
        dispatch({
            type: HISTORY_BIDDINGS_COMPANY_FETCHING
        });
        const res = await BiddingService.getHistoryBiddingsCompany();
        if (res) {
            dispatch({
                type: HISTORY_BIDDINGS_COMPANY_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: HISTORY_BIDDINGS_COMPANY_FAILED
        });
    }
};


export const getBiddingById = (idBidding) => async (dispatch) => {
  try {
    dispatch({
        type: BIDDING_INFORMATION_FETCHING
    });
    const res = await BiddingService.getBiddingById(idBidding);
    if (res) {
      dispatch({
          type: BIDDING_INFORMATION_SUCCESS,
          payload: res.data
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: BIDDING_INFORMATION_FAILED
    });
    return error.response;
  }
};

export const submitBid = (idBidding, formData) => async () => {
  try {
    const res = await BiddingService.submitBid(idBidding, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error.response;
  }
};

export const getBiddingMinGuaranteeContract = () => async (dispatch) => {
    try {
        dispatch({
            type: BIDDING_MIN_GUARANTEE_CONTRACT_FETCHING
        });
        const res = await BiddingService.getBiddingMinGuaranteeContract();
        if (res) {
            dispatch({
                type: BIDDING_MIN_GUARANTEE_CONTRACT_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: BIDDING_MIN_GUARANTEE_CONTRACT_FAILED
        })
    }
};

export const getMyContractVendor = () => async (dispatch) => {
    try {
        dispatch({
            type: MYCONTRACT_BIDDINGS_FETCHING
        });
        const res = await BiddingService.getMyContractVendor();
        if (res) {
            dispatch({
                type: MYCONTRACT_BIDDINGS_SUCCESS,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch({
            type: MYCONTRACT_BIDDINGS_FAILED
        });
    }
};

export const getMyContractCompany = () => async (dispatch) => {
    try {
        dispatch({
            type: MYCONTRACT_BIDDINGS_FETCHING
        });
        const res = await BiddingService.getMyContractCompany();
        if (res) {
            dispatch({
                type: MYCONTRACT_BIDDINGS_SUCCESS,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch({
            type: MYCONTRACT_BIDDINGS_FAILED
        });
    }
};

export const getAllBiddings = (query) => async (dispatch) => {
  try {
    dispatch({
      type: BIDDINGS_FETCHING
    });
    const res = await BiddingService.getAllBiddings(query);
    if (res) {
      dispatch({
        type: BIDDINGS_SUCCESS,
        payload: res.data
      })
    }
  } catch (error) {
    dispatch({
      type: BIDDINGS_FAILED
    });
  }
};