import React from "react";
import { withRouter, NavLink, useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import dayjs from 'dayjs';

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";

import Utils from "../../../../utils";

import CardStyle from "./Card";
import {} from "@mui/material";

const StyledCardContent = styled(CardContent)({
  borderRadius: 20,
  backgroundColor: "#FFFFFF",
  cursor: "pointer",
  "& .part-one": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    justifyContent: "space-between",
    "& .MuiAvatar-root": {
      width: 64,
      height: 64,
      borderRadius: 8,
      backgroundColor: "white",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
    },
    "& .MuiTypography-h5": {
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 0.75,
    },
  },
  "& .part-two": {
    marginBottom: 16,
    "& .MuiTypography-h5": {
      width: 280,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "& .part-three": {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
  },
  "& .part-four": {},
  "& .part-five": {
    display:"flex",
    "& .left":{
      width:"150px"
    },
    "& .right":{
      width:"170px"
    },
    "& .wrap-item": {
      display: "flex",
      color: "#637381",
      alignItems: "center",
      fontSize:"0.875rem",
      "& .fal": {
        marginRight: 8,
      },
    },
  },
});

const StyledChip = styled(Chip)({
  color: "#b72136",
  backgroundColor: "#ff484229",
  fontWeight: 700,
  borderRadius: 6,
});

const CardRequest = (props) => {
  const { value } = props;
  const history = useHistory();

  const onClickCardHandler = (idJob) => {
    history.push(`/company/request/${value.idJob}`)
  }

  const renderWorkExp = (min, max) => {
    if(Number(min) === 0 && Number(max) === 0){
      return "ไม่กำหนดประสบการณ์"
    } else if (Number(min) > 0 && Number(max) === 0){
      return `${min} ปีขึ้นไป`
    } else {
      return `${min} - ${max} ปี`
    }
  }

  return (
    <CardStyle>
      <div onClick={() => {onClickCardHandler(value.idJob)}} style={{height: "inherit"}}>
        <StyledCardContent>
          <div className={`part-one`}>
            {/* <Avatar variant="rounded" src={value.companyImage? `${process.env.REACT_APP_API_URL}image/company/${value.companyImage}`: ""}> */}
            <Avatar variant="rounded" src={value.companyImageURL}>
              <img src="/assets/logo_default.png" />
            </Avatar>
            {value.isUrgent ? <StyledChip label="รับสมัครด่วน" size="small" /> : <></>}

            <div style={{ textAlign: "end" }}>
              <Typography align="right" variant="h5">
                {value.quota ? value.quota : <i className="fa-solid fa-infinity"></i>}
              </Typography>
              <Typography align="right" variant="caption" color="text.secondary">
                {`เปิดรับสมัคร`}
              </Typography>
            </div>
          </div>
          <div className={`part-two`}>
            <div>
              <Typography
                variant="h5" 
                underline="none" 
                color="text.primary" 
              >
                {value.positionName ? value.positionName : "-"}
              </Typography>
              <Typography variant="body2" style={{ color: "#007afd" }}>
                {value.companyName || "-"}
              </Typography>
            </div>
          </div>
          <div className={`part-three`}>
            <i
              className="fal fa-map-marker-alt"
              style={{ color: "#637381", marginRight: 8 }}
            ></i>
            <Typography variant="body2" style={{ color: "#637381" }}>
              {/* {`${value.district || '-'}, ${value.province || '-'}`} */}
              {value.workLocationDetail}
            </Typography>
          </div>
          <div className={`part-three`}>
            <i 
              className="fal fa-hand-holding-usd" 
              style={{ color: "#637381", marginRight: 8 }} 
            />
            <Typography  variant="body2" style={{ color: "#637381" }}>
              <span style={{ fontSize: 16, fontWeight:400, color:"#0253ab" }}>{`
              ${(value.minSalary || value.maxSalary) ? 
                  (value.minSalary && value.maxSalary) ?
                    `${Utils.numberWithCommas(
                      value.minSalary
                    )} - ${Utils.numberWithCommas(
                      value.maxSalary
                    )}` : value.minSalary ?
                      `ขั้นต่ำ ${Utils.numberWithCommas(
                        value.minSalary
                      )}`
                      :
                      `สูงสุด ${Utils.numberWithCommas(
                        value.maxSalary
                      )}`
                :
                "-"
              }`}</span>{` บาท/${
                value.salaryType && value.salaryType === "monthly" ? "เดือน" : "วัน"
              }`}
            </Typography>
          </div>
          <div className={`part-four`}>
            <Typography variant="caption" color="text.secondary">
              {`วันประกาศ: ${value.postDate ? dayjs(value.postDate).format("DD-MMM-YYYY") : "ไม่ระบุวันประกาศ"}`}
            </Typography>
          </div>
          <Divider
            style={{
              margin: "16px 0px",
              borderWidth: "0px 0px thin",
              borderColor: "rgba(145, 158, 171, 0.24)",
              borderStyle: "dashed",
            }}
          />
          <div className={`part-five`}>
            <div className="left">
              <div className={`wrap-item`}>
                <i className="fal fa-calendar-times"></i>
                <Typography>{value.closeRegisterDate ? dayjs(value.closeRegisterDate).format("DD-MMM-YYYY") : "ไม่มีกำหนดปิดรับสมัคร"}</Typography>
              </div>
              <div className={`wrap-item`}>
                <i className="fal fa-clock"></i>
                <Typography>พนักงานประจำ</Typography>
              </div>
            </div>
            <div className="right">
              <div className={`wrap-item`}>
                <i className="fal fa-paper-plane"></i>
                {/* {value.workExperience > 0 ? (
                  <Typography>
                    {value.workExperience <= 8 ? `${value.workExperience} ปี` : `9 ปีขึ้นไป`}
                  </Typography>
                ) : (
                  <Typography>ไม่กำหนดประสบการณ์</Typography>
                )} */}
                <Typography>
                  {renderWorkExp(value.workExperienceMin, value.workExperienceMax)}
                </Typography>
              </div>
              <div className={`wrap-item`}>
                <i className="fal fa-graduation-cap"></i>
                <Typography> {["ไม่จำกัด", "ประถมศึกษา", "มัธยมศึกษา", "ปวช./ปวส.","ปริญญาตรี","ปริญญาโท","ปริญญาเอก"][value.educationDegree || 0]}</Typography>
              </div>
            </div>
          </div>
        </StyledCardContent>
      </div>
    </CardStyle>
  );
};

export default CardRequest;
