import {
  DEV_INFO_CONTRACT_FETCHING,
  DEV_INFO_CONTRACT_FAILED,
  DEV_INFO_CONTRACT_SUCCESS
} from "../actions/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DEV_INFO_CONTRACT_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case DEV_INFO_CONTRACT_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case DEV_INFO_CONTRACT_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
};