import { httpClient } from "./httpClient";

const getHomepageForGuest = () => {
    return httpClient.get("homepage/guest");
};

const getHomepageForCompany = () => {
    return httpClient.get("homepage/company");
};

const getHomepageForVendor = () => {
    return httpClient.get("homepage/vendor");
};

export default {
  getHomepageForGuest,
  getHomepageForCompany,
  getHomepageForVendor
};