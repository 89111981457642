import React, { Fragment, useEffect, useState } from "react";
import { Autocomplete, Avatar, Box, CircularProgress, Container, FormHelperText, Grid, IconButton, MenuItem, styled, Typography } from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useDispatch, useSelector } from "react-redux";
import { getJobGroups } from "../../../../../actions/job";
import { addManpowerByVendorId, getFormAddManpowerByVendorId } from "../../../../../actions/manpower";
import { useHistory } from "react-router-dom";
import NotFoundPage from "../../../error/NotFoundPage";
import Dropzone from "react-dropzone";
import AddIcon from "../../../assets/add.png"

dayjs.extend(isSameOrAfter);

const degrees = [
  {
    idDegrees: 1,
    degreeName: "ประถมศึกษา",
  },
  {
    idDegrees: 2,
    degreeName: "มัธยมศึกษา",
  },
  {
    idDegrees: 3,
    degreeName: "ปวช.",
  },
  {
    idDegrees: 4,
    degreeName: "ปวส.",
  },
  {
    idDegrees: 5,
    degreeName: "ปริญญาตรี",
  },
  {
    idDegrees: 6,
    degreeName: "ปริญญาโท",
  },
  {
    idDegrees: 7,
    degreeName: "ปริญญาเอก",
  },
]

const StyledRoot = styled(Box)({
  "& .dropzone": {
    cursor: "pointer",
    flexShrink: 0,
    width: "100%",
    height: 144,
    boxSizing: "border-box",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .inner-dropzone": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& img": {
        width: 72,
        height: 72,
      }
    }
  },
  "& .resume-wrap": {
    textDecoration: "none",
    color: "inherit",
    position: "relative",
    cursor: "pointer",
    flexShrink: 0,
    padding: 8,
    width: "100%",
    height: 144,
    boxSizing: "border-box",
    border: "1px solid #D0D0D0",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    "& .icon-part": {
      flexGrow:1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& i": {
        fontSize: 56,
        color: "#D0D0D0"
      }
    },
    "& .filename": {
      flexShrink: 1,
      height: "48px",
      "& .MuiTypography-root": {
        fontSize: 14,
        display: "-webkit-box",
        overflow: "hidden",
        WebkitLineClamp: 2, 
        WebkitBoxOrient: "vertical",
        textAlign: "center",
        wordBreak: "break-all",
      }
    },
    "& .delete": {
      position: "absolute",
      top: 0,
      right: 0,
      padding: 8,
      "& i": {
        fontSize: 16,
      }
    }
  }
})

const AddManpowerByURL = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const { result: jobGroups } = useSelector(state => state.jobGroups)

  const [formAddManpower, setFormAddManpower] = useState(null);
  const [idVendor, setIdVendor] = useState(null);
  const [isGetFormFetching, setIsGetFormFetching] = useState(true);
  const [errorPage, setErrorPage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const { control, handleSubmit, formState: { errors, isSubmitting }, getValues } = useForm({
    mode: "onBlur",
    defaultValues: {
      title: "",
      firstname: "",
      lastname: "",
      phoneNumber: "",
      email: "",
      dateOfBirth: null,
      personalId: "",
      educations: [],
      experiences: [],
      jobGroup: null,
      workExperienceM: "",
      workExperienceY: "",
      educationDegree: "",
      currentSalary: "",
      expectedSalary: "",
      resume: null,
    }
  })

  const { fields: educationField, append: educationAppend, remove: educationRemove } = useFieldArray({
    control: control,
    name: "educations",
  })

  const { fields: experienceField, append: experienceAppend, remove: experienceRemove } = useFieldArray({
    control: control,
    name: "experiences",
  })

  useEffect(() => {
    const vendor_no_query = new URLSearchParams(history.location.search).get("vendor_no");
    if(vendor_no_query){
      setIdVendor(vendor_no_query)
    }
  }, [history])

  useEffect(() => {
    if(idVendor){
      dispatch(getFormAddManpowerByVendorId(idVendor)).then(res => {
        if(res.status === 200){
          setFormAddManpower(res.data);
        } else if(res.status === 403){
          setErrorPage("UnAuthorized")
        } else if(res.status === 400){
          if(res.data.errorCode === "CLOSED_REGISTER"){
            setErrorPage("CLOSED_REGISTER")
          }
        }
        setIsGetFormFetching(false);
      })
    }
  }, [dispatch, idVendor])
  
  useEffect(() => {
    dispatch(getJobGroups())
  }, [dispatch])

  const onSubmit = async (submitData) => {

    const formData = {...submitData};

    formData.idJobGroup = (formData.jobGroup && formData.jobGroup.idJobGroup) ? formData.jobGroup.idJobGroup: null
    formData.currentSalary = formData.currentSalary? formData.currentSalary: null;
    formData.expectedSalary = formData.expectedSalary? formData.expectedSalary: null;
    formData.personalId = formData.personalId? formData.personalId: null;
    formData.workExperienceY = formData.workExperienceY? formData.workExperienceY: null;
    formData.workExperienceM = formData.workExperienceM? formData.workExperienceM: null;

    formData.educations = formData.educations.map(education => {
      if(education.startYear){
        education.startYear = dayjs(education.startYear).format("YYYY")
      } else {
        education.startYear = null
      }
      if(education.endYear){
        education.endYear = dayjs(education.endYear).format("YYYY")
      } else {
        education.endYear = null
      }

      education.faculty = (education.faculty || null);
      education.gpa = (education.gpa || null);
      education.major = (education.major || null);

      return education
    })

    formData.experiences = formData.experiences.map(work => { 
      return {
        startYear: work.startYear? dayjs(work.startYear).format("YYYY"): null,
        endYear: work.endYear? dayjs(work.endYear).format("YYYY"): null,
        companyName: work.companyName? work.companyName: null,
        positionName: work.positionName? work.positionName: null,
        idJobGroup: (work.jobGroup && work.jobGroup.idJobGroup) ? work.jobGroup.idJobGroup: null
      }
    })

    const formData2 = new FormData;

    Object.keys(formData).map(key => {

      if(key === "jobGroup"){
        // formData2.append(key, formData[key])
        return;
      }

      if(key === "resume"){
        formData2.append(key, formData[key])
        return;
      }

      formData2.append(key, JSON.stringify(formData[key]))
    })
    
    const res = await dispatch(addManpowerByVendorId(idVendor, formData2)).then(res => {
      if(res.status === 200){
        setIsSuccess(true)
      }
      // setIsFetching(false);
    })

    console.log(formData)
  }

  return (
    <Box className="page">
      <StyledRoot>
        {!isSuccess && !isGetFormFetching && jobGroups && formAddManpower && <Container maxWidth="sm">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">ลงทะเบียนเป็นกำลังพล</Typography>
                <Box textAlign="center" paddingTop="24px">
                  <Avatar
                    variant="rounded"
                    src={formAddManpower.imagePath}
                    sx={{
                      width: 128,
                      height: 128,
                      margin: "auto",
                      "& img": {
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#ffffff",
                        objectFit: "contain"
                      }
                    }}
                    // src={vendor.vendorImage? `${process.env.REACT_APP_API_URL}image/vendor/${vendor.vendorImage}`: ""}
                    // component={Link}
                    // to={`/vendor/${vendor.vendorNameShort || ""}`}
                  >
                    <img src="/assets/logo_default.png" />
                  </Avatar>
                  <Typography variant="h6" paddingTop="16px">{formAddManpower.vendorName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" gap="8px">
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณากรอกคำนำหน้า"}
                    }}
                    render={({field})=>(
                      <TextFieldTheme
                        {...field}
                        label="คำนำหน้า"
                        // fullWidth
                        helperText={errors && errors.title && errors.title.message}
                        error={errors && errors.title ? true: false}
                        select
                        sx={{maxWidth: "120px"}}
                      >
                        <MenuItem value="นาย">นาย</MenuItem>
                        <MenuItem value="นาง">นาง</MenuItem>
                        <MenuItem value="นางสาว">นางสาว</MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                  <Controller
                    name="firstname"
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณากรอกชื่อ"}
                    }}
                    render={({field})=>(
                      <TextFieldTheme
                        {...field}
                        label="ชื่อ"
                        fullWidth
                        helperText={errors && errors.firstname && errors.firstname.message}
                        error={errors && errors.firstname ? true: false}
                      />
                    )}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="lastname"
                  control={control}
                  rules={{
                    required: {value: true, message: "กรุณากรอกนามสกุล"}
                  }}
                  render={({field})=>(
                    <TextFieldTheme
                      {...field}
                      label="นามสกุล"
                      fullWidth
                      helperText={errors && errors.lastname && errors.lastname.message}
                      error={errors && errors.lastname ? true: false}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: {value: true, message: "กรุณากรอกเบอร์โทร"},
                    validate: (value) => (!isNaN(value) && value.length === 10) || "กรุณากรอกเบอร์โทร 10 หลัก"
                  }}
                  render={({field})=>(
                    <TextFieldTheme
                      {...field}
                      label="เบอร์โทร"
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                      }}
                      inputProps={{
                        allowNegative: false,
                      }}
                      fullWidth
                      helperText={errors && errors.phoneNumber && errors.phoneNumber.message}
                      error={errors && errors.phoneNumber ? true: false}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: {value: true, message: "กรุณากรอกอีเมล"},
                    pattern: {
                      value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z.]{1,}[A-z]$/,
                      message: "กรุณากรอกอีเมลให้ถูกต้อง เช่น 'antjob@example.com'"
                    }
                  }}
                  render={({field})=>(
                    <TextFieldTheme
                      {...field}
                      label="อีเมล"
                      fullWidth
                      helperText={errors && errors.email && errors.email.message}
                      error={errors && errors.email ? true: false}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    name="dateOfBirth"
                    rules={{
                      required: {
                        value: true,
                        message: "กรุณากรอกวันเกิด"
                      }
                    }}
                    render={({field, formState}) => (
                      <DesktopDatePicker
                        {...field}
                        views={["year", "month", "day"]}
                        inputFormat="DD MMMM YYYY"
                        disableFuture
                        disableMaskedInput
                        openTo="year"
                        onChange={(value)=>{
                          field.onChange(value)
                        }}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            label="วัน เดือน ปีเกิด"
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "",
                              readOnly: true
                            }}
                            error={formState.errors && formState.errors[field.name]? true: false}
                            helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="personalId"
                  control={control}
                  rules={{
                    // required: {value: true, message: "กรุณากรอกเลขบัตรประชาชน"},
                    minLength: {value: 13, message: "กรุณากรอกเลขบัตรประชาชนเป็นตัวเลข 13 หลัก"},
                    maxLength: {value: 13, message: "กรุณากรอกเลขบัตรประชาชนเป็นตัวเลข 13 หลัก"}
                  }}
                  render={({field})=>(
                    <TextFieldTheme
                      {...field}
                      label="เลขบัตรประชาชน (ไม่จำเป็น)"
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                      }}
                      inputProps={{
                        
                        allowNegative: false,
                        onValueChange:(values) => {
                          const {value} = values;
                          field.onChange(value)
                        }
                      }}
                      onChange={()=>{}}
                      fullWidth
                      helperText={errors && errors.personalId && errors.personalId.message}
                      error={errors && errors.personalId ? true: false}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name={`jobGroup`}
                  control={control}
                  // rules={{
                  //   required: {value: true, message: "กรุณากรอกกลุ่มงานที่ถนัด"},
                  // }}
                  render={({field, formState}) => (
                    <Autocomplete
                      sx={{
                        "& .MuiInputBase-root": {
                          paddingLeft: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                          "& input": {
                            padding: "13.5px!important",
                          }
                        }
                      }}
                      // disableClearable
                      options={[...jobGroups].sort((a, b) => a.jobGroupName.localeCompare(b.jobGroupName))}
                      getOptionLabel={(option) => option.jobGroupName}
                      isOptionEqualToValue={(option, value) => (option.idJobGroup === value.idJobGroup)}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.idJobGroup}>
                          {option.jobGroupName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          label="กลุ่มงานที่ถนัด (ไม่จำเป็น)"
                          error={formState.errors && formState.errors.jobGroup? true: false}
                          helperText={formState.errors && formState.errors.jobGroup? formState.errors.jobGroup.message: null}
                        />
                      )}
                      noOptionsText="ไม่พบคำที่ค้นหา"
                      value={field.value}
                      onChange={(_, data) => {
                        field.onChange(data);
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="workExperienceY"
                  render={({field})=>(
                    <TextFieldTheme
                      {...field}
                      label="ประสบการณ์ทำงาน(ปี)"
                      helperText={errors && errors.workExperience && errors.workExperience.message}
                      error={errors && errors.workExperience ? true: false}
                      select
                      SelectProps={{
                        displayEmpty: true
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="">ไม่มี</MenuItem>
                      <MenuItem value="1">1 ปี</MenuItem>
                      <MenuItem value="2">2 ปี</MenuItem>
                      <MenuItem value="3">3 ปี</MenuItem>
                      <MenuItem value="4">4 ปี</MenuItem>
                      <MenuItem value="5">5 ปี</MenuItem>
                      <MenuItem value="6">6 ปี</MenuItem>
                      <MenuItem value="7">7 ปี</MenuItem>
                      <MenuItem value="8">8 ปี</MenuItem>
                      <MenuItem value="9">9 ปี</MenuItem>
                      <MenuItem value="10">10 ปี</MenuItem>
                      <MenuItem value="11">11 ปี</MenuItem>
                      <MenuItem value="12">12 ปี</MenuItem>
                      <MenuItem value="13">13 ปี</MenuItem>
                      <MenuItem value="14">14 ปี</MenuItem>
                      <MenuItem value="15">15 ปี</MenuItem>
                      <MenuItem value="16">16 ปี</MenuItem>
                      <MenuItem value="17">17 ปี</MenuItem>
                      <MenuItem value="18">18 ปี</MenuItem>
                      <MenuItem value="19">19 ปี</MenuItem>
                      <MenuItem value="20">20 ปี</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="workExperienceM"
                  render={({field})=>(
                    <TextFieldTheme
                      {...field}
                      label="ประสบการณ์ทำงาน(เดือน)"
                      helperText={errors && errors.workExperienceM && errors.workExperienceM.message}
                      error={errors && errors.workExperienceM ? true: false}
                      select
                      SelectProps={{
                        displayEmpty: true
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="">ไม่มี</MenuItem>
                      <MenuItem value="1">1 เดือน</MenuItem>
                      <MenuItem value="2">2 เดือน</MenuItem>
                      <MenuItem value="3">3 เดือน</MenuItem>
                      <MenuItem value="4">4 เดือน</MenuItem>
                      <MenuItem value="5">5 เดือน</MenuItem>
                      <MenuItem value="6">6 เดือน</MenuItem>
                      <MenuItem value="7">7 เดือน</MenuItem>
                      <MenuItem value="8">8 เดือน</MenuItem>
                      <MenuItem value="9">9 เดือน</MenuItem>
                      <MenuItem value="10">10 เดือน</MenuItem>
                      <MenuItem value="11">11 เดือน</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="educationDegree"
                  rules={{
                    required: {value: true, message: "กรุณาเลือกระดับการศึกษาสูงสุด"},
                  }}
                  render={({field})=>(
                    <TextFieldTheme
                      {...field}
                      label="ระดับการศึกษาสูงสุด"
                      helperText={errors && errors.educationDegree && errors.educationDegree.message}
                      error={errors && errors.educationDegree ? true: false}
                      select
                    >
                      {degrees.map(d => (
                        <MenuItem key={`deegree_${d.idDegrees}`} value={`${d.degreeName}`}>{d.degreeName}</MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="currentSalary"
                  rules={{
                    // required: {value: true, message: "กรุณากรอกเงินเดือนปัจจุบัน"},
                  }}
                  render={({field})=>(
                    <TextFieldTheme
                      {...field}
                      label="เงินเดือนปัจจุบัน (ไม่จำเป็น)"
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                      }}
                      inputProps={{
                        allowNegative: false,
                        thousandSeparator:",",
                        suffix:" บาท",
                        onValueChange:(values) => {
                          const {value} = values;
                          field.onChange(value)
                        }
                      }}
                      onChange={()=>{}}
                      helperText={errors && errors.currentSalary && errors.currentSalary.message}
                      error={errors && errors.currentSalary ? true: false}
                    />  
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="expectedSalary"
                  render={({field})=>(
                    <TextFieldTheme
                      {...field}
                      label="เงินเดือนที่คาดหวัง (ไม่จำเป็น)"
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                      }}
                      inputProps={{
                        allowNegative: false,
                        thousandSeparator:",",
                        suffix:" บาท",
                        onValueChange:(values) => {
                          const {value} = values;
                          field.onChange(value)
                        }
                      }}
                      onChange={()=>{}}
                      helperText={errors && errors.expectedSalary && errors.expectedSalary.message}
                      error={errors && errors.expectedSalary ? true: false}
                    />  
                  )}
                />
              </Grid>

              {/* Education History */}

              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Typography flexGrow={1}>ประวัติการศึกษา</Typography>
                  <ButtonBlue
                    variant="outlined"
                    startIcon={<i className="fa-light fa-plus"></i>}
                    onClick={()=>{educationAppend({
                      startYear: null,
                      endYear: null,
                      universityName: "",
                      degree: "",
                      faculty: "",
                      major: "",
                      gpa: "",
                    })}}
                  >
                    เพิ่มการศึกษา
                  </ButtonBlue>
                </Box>
              </Grid>

              {educationField.map((education, index) => (
                <Grid key={education.id} item xs={12}>
                  <Box>

                    <Box display="flex" alignItems="center" paddingBottom="16px">
                      <Typography flexGrow={1} position="relative">ประวัติการศึกษา {index+1}</Typography>
                      <IconButton
                        color="error"
                        onClick={()=>{educationRemove(index)}}
                        sx={{
                          fontSize: 16
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </IconButton>
                    </Box>

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Controller
                              name={`educations.${index}.startYear`}
                              control={control}
                              rules={{
                                validate: {
                                  endDateMoreThanstart: () => {
                                    if(!getValues(`educations.${index}.startYear`) || !getValues(`educations.${index}.endYear`)){
                                      return true;
                                    } else if(dayjs(getValues(`educations.${index}.endYear`)).isSameOrAfter(dayjs(getValues(`educations.${index}.startYear`)), 'year')){
                                      return true;
                                    } else {
                                      return "ไม่สามารถเลือกวันจบการศึกษาก่อนวันเริ่มการศึกษาได้"
                                    }
                                  }
                                }
                              }}
                              render={({field})=>(
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DesktopDatePicker
                                    {...field}
                                    label="เริ่มการศึกษา"
                                    views={["year"]}
                                    openTo="year"
                                    maxDate={dayjs()}
                                    renderInput={(params) => (
                                      <TextFieldTheme
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "",
                                          readOnly: true
                                        }}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <Box display="flex" alignItems="center" sx={{"& .MuiInputAdornment-root": {margin: 0}}}>
                                              {field.value && <IconButton sx={{width: 25, height: 25, "& i": {fontSize: 16}}} onClick={() => {field.onChange(null)}}><i className="fa-regular fa-xmark"></i></IconButton>}
                                              {params.InputProps.endAdornment}
                                            </Box>
                                          )
                                        }}
                                        helperText={errors && errors.educations && errors.educations[index] && errors.educations[index].startYear && errors.educations[index].startYear.message}
                                        error={errors && errors.educations && errors.educations[index] && errors.educations[index].startYear? true: false}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Controller
                              name={`educations.${index}.endYear`}
                              control={control}
                              rules={{
                                validate: {
                                  endDateMoreThanstart: () => {
                                    if(!getValues(`educations.${index}.startYear`) || !getValues(`educations.${index}.endYear`)){
                                      return true;
                                    } else if(dayjs(getValues(`educations.${index}.endYear`)).isSameOrAfter(dayjs(getValues(`educations.${index}.startYear`)), 'year')){
                                      return true;
                                    } else {
                                      return "ไม่สามารถเลือกวันจบการศึกษาก่อนวันเริ่มการศึกษาได้"
                                    }
                                  }
                                }
                              }}
                              render={({field})=>(
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DesktopDatePicker
                                    {...field}
                                    label="จบการศึกษา"
                                    views={["year"]}
                                    openTo="year"
                                    maxDate={dayjs()}
                                    renderInput={(params) => (
                                      <TextFieldTheme
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "",
                                          readOnly: true
                                        }}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <Box display="flex" alignItems="center" sx={{"& .MuiInputAdornment-root": {margin: 0}}}>
                                              {field.value && <IconButton sx={{width: 25, height: 25, "& i": {fontSize: 16}}} onClick={() => {field.onChange(null)}}><i className="fa-regular fa-xmark"></i></IconButton>}
                                              {params.InputProps.endAdornment}
                                            </Box>
                                          )
                                        }}
                                        helperText={errors && errors.educations && errors.educations[index] && errors.educations[index].endYear && errors.educations[index].endYear.message}
                                        error={errors && errors.educations && errors.educations[index] && errors.educations[index].endYear? true: false}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name={`educations.${index}.universityName`}
                          control={control}
                          // defaultValue={educations.universityName || ""}
                          rules={{
                            required: {value: true, message: "กรุณากรอกชื่อสถาบัน"},
                          }}
                          render={({field}) => (
                            <TextFieldTheme
                              {...field}
                              label="ชื่อสถาบัน"
                              helperText={errors && errors.educations && errors.educations[index] && errors.educations[index].universityName && errors.educations[index].universityName.message}
                              error={errors && errors.educations && errors.educations[index] && errors.educations[index].universityName? true: false}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name={`educations.${index}.degree`}
                          control={control}
                          // defaultValue={educations.degree || ""}
                          rules={{
                            required: {value: true, message: "กรุณาเลือกระดับการศึกษา"},
                          }}
                          render={({field}) => (
                            <TextFieldTheme
                              {...field}
                              label="ระดับการศึกษา"
                              select
                              helperText={errors && errors.educations && errors.educations[index] && errors.educations[index].degree && errors.educations[index].degree.message}
                              error={errors && errors.educations && errors.educations[index] && errors.educations[index].degree? true: false}
                            >
                              {degrees.map(d => (
                                <MenuItem key={`deegree_${d.idDegrees}`} value={`${d.degreeName}`}>{d.degreeName}</MenuItem>
                              ))}
                            </TextFieldTheme>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name={`educations.${index}.faculty`}
                          control={control}
                          // defaultValue={educations.major || ""}
                          rules={{
                            // required: {value: true, message: "กรุณากรอกคณะ"},
                          }}
                          render={({field}) => (
                            <TextFieldTheme
                              {...field}
                              label="คณะ"
                              helperText={errors && errors.educations && errors.educations[index] && errors.educations[index].faculty && errors.educations[index].faculty.message}
                              error={errors && errors.educations && errors.educations[index] && errors.educations[index].faculty? true: false}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name={`educations.${index}.major`}
                          control={control}
                          // defaultValue={educations.major || ""}
                          rules={{
                            // required: {value: true, message: "กรุณากรอกสาขาวิชา"},
                          }}
                          render={({field}) => (
                            <TextFieldTheme
                              {...field}
                              label="สาขาวิชา"
                              helperText={errors && errors.educations && errors.educations[index] && errors.educations[index].major && errors.educations[index].major.message}
                              error={errors && errors.educations && errors.educations[index] && errors.educations[index].major? true: false}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name={`educations.${index}.gpa`}
                          control={control}
                          render={({field}) => (
                            <TextFieldTheme
                              {...field}
                              label="GPA"
                              InputProps={{
                                inputComponent: NumberFormatTheme,
                              }}
                              inputProps={{
                                allowNegative: false,
                                decimalScale: 2,
                                isAllowed: (values) => {
                                  return values.value === "" || values.floatValue <= 4.00 ;
                                }
                              }}
                              helperText={errors && errors.educations && errors.educations[index] && errors.educations[index].gpa && errors.educations[index].gpa.message}
                              error={errors && errors.educations && errors.educations[index] && errors.educations[index].gpa? true: false}
                            />
                          )}
                        />
                      </Grid>

                    </Grid>
                  </Box>
                </Grid>
              ))}

              {educationField.length <= 0 && (
                <Grid item xs={12}>
                  <Typography textAlign="center" color="text.secondary" fontSize={14}>ไม่มีประวัติการศึกษา</Typography>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Typography flexGrow={1}>ประสบการณ์ทำงาน</Typography>
                  <ButtonBlue
                    variant="outlined"
                    startIcon={<i className="fa-light fa-plus"></i>}
                    onClick={()=>{experienceAppend({
                      startYear: null,
                      endYear: null,
                      companyName: "",
                      positionName: "",
                      jobGroup: null,
                    })}}
                  >
                    เพิ่มประสบการณ์
                  </ButtonBlue>
                </Box>
              </Grid>

              {experienceField.map((work, index) => (
                <Grid key={work.id} item xs={12}>
                  <Box>
                    <Box display="flex" alignItems="center" paddingBottom="16px">
                      <Typography flexGrow={1} position="relative">ประสบการณ์ทำงาน {index+1}</Typography>
                      <IconButton
                        color="error"
                        onClick={()=>{experienceRemove(index)}}
                        sx={{
                          fontSize: 16
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </IconButton>
                    </Box>

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Controller
                              name={`experiences.${index}.startYear`}
                              control={control}
                              rules={{
                                // required: {value: true, message: "กรุณาเลือกปีที่เริ่มงาน"},
                                validate: {
                                  endDateMoreThanstart: () => {
                                    if(!getValues(`experiences.${index}.startYear`) || !getValues(`experiences.${index}.endYear`)){
                                      return true;
                                    } else if(dayjs(getValues(`experiences.${index}.endYear`)).isSameOrAfter(dayjs(getValues(`experiences.${index}.startYear`)), 'year')){
                                      return true;
                                    } else {
                                      return "ไม่สามารถเลือกวันออกจากงานก่อนวันเริ่มเริ่มงานได้"
                                    }
                                  }
                                }
                              }}
                              render={({field})=>(
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DesktopDatePicker
                                    {...field}
                                    label="เริ่มงาน"
                                    views={["year"]}
                                    openTo="year"
                                    maxDate={dayjs()}
                                    renderInput={(params) => (
                                      <TextFieldTheme
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "",
                                          readOnly: true,
                                        }}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <Box display="flex" alignItems="center" sx={{"& .MuiInputAdornment-root": {margin: 0}}}>
                                              {field.value && <IconButton sx={{width: 25, height: 25, "& i": {fontSize: 16}}} onClick={() => {field.onChange(null)}}><i className="fa-regular fa-xmark"></i></IconButton>}
                                              {params.InputProps.endAdornment}
                                            </Box>
                                          )
                                        }}
                                        helperText={errors && errors.experiences && errors.experiences[index] && errors.experiences[index].startYear && errors.experiences[index].startYear.message}
                                        error={errors && errors.experiences && errors.experiences[index] && errors.experiences[index].startYear? true: false}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Controller
                              name={`experiences.${index}.endYear`}
                              control={control}
                              rules={{
                                // required: {value: true, message: "กรุณาเลือกปีที่ออกจากงาน"},
                                validate: {
                                  endDateMoreThanstart: () => {
                                    if(!getValues(`experiences.${index}.startYear`) || !getValues(`experiences.${index}.endYear`)){
                                      return true;
                                    } else if(dayjs(getValues(`experiences.${index}.endYear`)).isSameOrAfter(dayjs(getValues(`experiences.${index}.startYear`)), 'year')){
                                      return true;
                                    } else {
                                      return "ไม่สามารถเลือกวันออกจากงานก่อนวันเริ่มเริ่มงานได้"
                                    }
                                  }
                                }
                              }}
                              render={({field})=>(
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DesktopDatePicker
                                    {...field}
                                    label="ออกจากงาน"
                                    views={["year"]}
                                    openTo="year"
                                    maxDate={dayjs()}
                                    renderInput={(params) => (
                                      <TextFieldTheme
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "",
                                          readOnly: true
                                        }}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <Box display="flex" alignItems="center" sx={{"& .MuiInputAdornment-root": {margin: 0}}}>
                                              {field.value && <IconButton sx={{width: 25, height: 25, "& i": {fontSize: 16}}} onClick={() => {field.onChange(null)}}><i className="fa-regular fa-xmark"></i></IconButton>}
                                              {params.InputProps.endAdornment}
                                            </Box>
                                          )
                                        }}
                                        helperText={errors && errors.experiences && errors.experiences[index] && errors.experiences[index].endYear && errors.experiences[index].endYear.message}
                                        error={errors && errors.experiences && errors.experiences[index] && errors.experiences[index].endYear? true: false}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </Grid>
                        </Grid> 
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name={`experiences.${index}.companyName`}
                          control={control}
                          rules={{
                            required: {value: true, message: "กรุณากรอกชื่อบริษัท"},
                          }}
                          render={({field}) => (
                            <TextFieldTheme
                              {...field}
                              label="ชื่อบริษัท"
                              helperText={errors && errors.experiences && errors.experiences[index] && errors.experiences[index].companyName && errors.experiences[index].companyName.message}
                              error={errors && errors.experiences && errors.experiences[index] && errors.experiences[index].companyName? true: false}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name={`experiences.${index}.positionName`}
                          control={control}
                          rules={{
                            required: {value: true, message: "กรุณากรอกตำแหน่งงาน"},
                          }}
                          render={({field}) => (
                            <TextFieldTheme
                              {...field}
                              label="ตำแหน่งงาน"
                              helperText={errors && errors.experiences && errors.experiences[index] && errors.experiences[index].positionName && errors.experiences[index].positionName.message}
                              error={errors && errors.experiences && errors.experiences[index] && errors.experiences[index].positionName? true: false}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name={`experiences.${index}.jobGroup`}
                          control={control}
                          rules={{
                            required: {value: true, message: "กรุณากรอกกลุ่มงาน"},
                          }}
                          render={({field, formState}) => (
                            <Autocomplete
                              sx={{
                                "& .MuiInputBase-root": {
                                  paddingLeft: 0,
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  "& input": {
                                    padding: "13.5px!important",
                                  }
                                }
                              }}
                              disableClearable
                              options={[...jobGroups].sort((a, b) => a.jobGroupName.localeCompare(b.jobGroupName))}
                              getOptionLabel={(option) => option.jobGroupName}
                              isOptionEqualToValue={(option, value) => (option.idJobGroup === value.idJobGroup)}
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.idJobGroup}>
                                  {option.jobGroupName}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextFieldTheme
                                  {...params}
                                  label="กลุ่มงาน"
                                  error={formState.errors && formState.errors.experiences && formState.errors.experiences[index] && formState.errors.experiences[index].jobGroup? true: false}
                                  helperText={formState.errors && formState.errors.experiences && formState.errors.experiences[index] && formState.errors.experiences[index].jobGroup? formState.errors.experiences[index].jobGroup.message: null}
                                />
                              )}
                              noOptionsText="ไม่พบคำที่ค้นหา"
                              value={field.value}
                              onChange={(_, data) => {
                                field.onChange(data);
                              }}
                            />
                          )}
                        />
                      </Grid>

                    </Grid>
                  </Box>
                </Grid>
              ))}

              {experienceField.length <= 0 && (
                <Grid item xs={12}>
                  <Typography textAlign="center" color="text.secondary" fontSize={14}>ไม่มีประสบการณ์ทำงาน</Typography>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography flexGrow={1}>เรซูเม่</Typography>
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="resume"
                  render={({field, formState}) => (
                    <Fragment>
                      {!field.value && 
                      <Dropzone
                        onDrop={(acceptedFiles, rejectedFiles) => {
                          if(acceptedFiles.length > 0){
                            field.onChange(acceptedFiles[0])
                          }
                        }}
                        accept="application/pdf"
                        maxFiles={1}
                        multiple={false}
                        maxSize={10485760}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                        }) => (
                          <div {...getRootProps({ className: "dropzone" })}>

                            {/* {isFetching ? (
                              <Box>
                                <Box className="inner-dropzone">
                                  <CircularProgress />
                                </Box>
                              </Box>
                            ):( */}
                              <Box>
                                <input {...getInputProps()}/>
                                <Box className="inner-dropzone">
                                  <img alt="add" src={AddIcon} />
                                  <Typography paddingTop="16px" fontSize={14} color="text.third">อัพโหลดเรซูเม่ได้ที่นี่</Typography>
                                </Box>
                              </Box>
                            {/* )} */}
                          </div>
                          )
                        }
                      </Dropzone>}

                      {field.value && <Box className="resume-wrap" component={"a"} href={URL.createObjectURL(field.value)} target="_blank">
                        <Box className="icon-part">
                          <i className="fa-solid fa-file"></i>
                        </Box>
                        <Box className="filename">
                          <Typography>{field.value.name}</Typography>
                        </Box>
                        <IconButton className="delete" onClick={(e)=>{e.preventDefault(); field.onChange(null)}}>
                          <i className="fa-solid fa-trash-can"></i>
                        </IconButton>
                      </Box>}

                      <FormHelperText error>*สามารถอัปโหลดไฟล์ได้เพียง 1 ไฟล์ โดยต้องเป็นไฟล์ .pdf เท่านั้น</FormHelperText>
                    </Fragment>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <ButtonBlue
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={isSubmitting}
                >
                  ลงทะเบียน
                </ButtonBlue>
              </Grid>


            </Grid>
          </form>
        </Container>}

        {isSuccess && !isGetFormFetching && jobGroups && formAddManpower &&
          <Container maxWidth="sm">
            <Box textAlign="center" paddingTop="24px">
              <Avatar
                variant="rounded"
                src={formAddManpower.imagePath}
                sx={{
                  width: 128,
                  height: 128,
                  margin: "auto",
                  "& img": {
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#ffffff",
                    objectFit: "contain"
                  }
                }}
                // src={vendor.vendorImage? `${process.env.REACT_APP_API_URL}image/vendor/${vendor.vendorImage}`: ""}
                // component={Link}
                // to={`/vendor/${vendor.vendorNameShort || ""}`}
              >
                <img src="/assets/logo_default.png" />
              </Avatar>
              <Typography variant="h6" paddingTop="16px">{formAddManpower.vendorName}</Typography>
            </Box>
            <Box marginTop="24px">
              <Typography textAlign="center" fontSize={24}>ลงทะเบียนเป็นกำลังพลสำเร็จ</Typography>
              <Typography textAlign="center" fontSize={24}>กรุณารอการติดต่อกลับ</Typography>
            </Box>
          </Container>
        }

        {isGetFormFetching && <Container maxWidth="sm">
          <Box display="flex" alignItems="center" justifyContent="center" gap="8px">
            <CircularProgress /><Typography variant="h5" textAlign="center">กำลังโหลด</Typography>
          </Box>
        </Container>}

        {errorPage === "CLOSED_REGISTER" && <Container maxWidth="sm">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h5" textAlign="center">ขณะนี้ยังไม่เปิดรับสมัครกำลังพล</Typography>
          </Box>
        </Container>}

        {errorPage === "UnAuthorized" && <Container maxWidth="sm">
          <Box display="flex" alignItems="center" justifyContent="center">
            <NotFoundPage />
          </Box>
        </Container>}
      </StyledRoot>
    </Box>
  )
}

export default AddManpowerByURL