import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  Avatar, 
  Box,
  Checkbox,
  Chip, 
  Container, 
  FormControl,
  MenuItem,
  Select,
  styled, 
  Table,
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Tooltip,
  Typography 
} from "@mui/material";

import { Link, useLocation } from "react-router-dom";
import ButtonBlue from "../../shared/general/ButtonBlue";
import clsx from "clsx";
import dayjs from 'dayjs';

import { DoneAll } from "@mui/icons-material";

import DialogChangeStatus from "./dialogChangeStatus";
import SentManpower from "./sentManpower";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { getAllUsersByIdVendor, updateStatusManpower } from "../../../../actions/user";
import { getJobGroups } from "../../../../actions/job";
import { getVendorNotification, updateVendorNotification } from "../../../../actions/vendorNotification";
import DialogExcel from "./dialogExcel";
import DialogLinkRegisterManpower from "./dialogLinkRegisterManpower";
import { stringToColor } from "../../../../utils";

const StyledRoot = styled("div")({
  "& .header-container": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .part-total": {
      textAlign: "right",
    }
  },
  "& .part-add-manpower": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "16px",
    "& .search-name": {
      width: 250,
    }
  },
  "& .expertise": {
    whiteSpace: "nowrap",
    fontSize: 14,
    fontWeight: 600,
  },
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      width: 40,
      height: 40,
      marginRight: 8,
    }
  }
})

const StyledTable = styled(TableContainer)({
  minHeight: 600,
  maxHeight: 600,
  "& .MuiTableRow-root": {
    "& .MuiTableCell-root": {
      backgroundColor: "#ffffff",
      borderBottom: "none",
      zIndex: 2,
    }, 
    "& .StickyColumn": {
      position: "sticky",
      left: 0,
      zIndex: 4,
      boxShadow: "rgb(238 238 238) 8px 20px 20px",
    }
  },
  "& .MuiTableHead-root": {
    borderRadius: 20,
    "& .MuiTableCell-root": {
      color: "rgb(99, 115, 129)",
      backgroundColor: "rgb(244, 246, 248)",  
      "&:last-of-type": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
    "& .StickyHeaderColumn": {
      zIndex: 5,
      boxShadow: "rgb(238 238 238) 8px -10px 20px",
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      width: 40,
      height: 40,
      marginRight: 8,
    }
  }
})

const StyledChipFree = styled(Chip)({
  borderRadius: 4,
  height: "unset",
  paddingTop: 4,
  paddingBottom: 4,
  fontSize: 14,
  backgroundColor: "#76d576",
  color: "#0b5f10",
  "&:hover":{
    backgroundColor: "#76d576",
    color: "#0b5f10",
  }
})

const StyledChipWait = styled(Chip)({
  borderRadius: 4,
  height: "unset",
  paddingTop: 4,
  paddingBottom: 4,
  backgroundColor:"#fff0ce",
  color:"#ff9800",
  fontSize: 14,
  "&:hover":{
    backgroundColor:"#fff0ce",
    color:"#ff9800",
  }
})

const StyledChipNotFree = styled(Chip)({
  borderRadius: 4,
  height: "unset",
  paddingTop: 4,
  paddingBottom: 4,
  backgroundColor:"#eb7d7d",
  color:"#b81a1a",
  fontSize: 14,
  "&:hover":{
    backgroundColor:"#eb7d7d",
    color:"#b81a1a",
  }
})

const StyledFormControl = styled(FormControl)({
  width: "300px",
  marginLeft: "10px",
  "& .MuiInputBase-root":{
    lineHeight: "1.4375em",
    borderRadius: "8px",
    "& .MuiSelect-select":{
      padding: "13.5px 14px"
    }
  }
})

const ManpowerComponent = (props) => {
  const dispatch = useDispatch();
  const { allUsersFree, isFetching, isNotification, isOpenRegisterManpower, setIsOpenRegisterManpower } = props;
  const location = useLocation();
  const refCandidate = useRef(null);
  const { result: AllJobGroups } = useSelector(state => state.jobGroups);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [jobGroup, setJobGroup] = useState("all");
  const [isSelected, setIsSelected] = useState(false);
  const [listSelected, setListSelected] = useState([]);
  const [openDialogChangeStatus, setOpenDialogChangeStatus] = useState({ status: false, idStatus: null, idManpower: null });

  const [isOpenModalImportExcel, setIsOpenModalImportExcel] = useState(false);
  const [dialogLinkRegisterManpowerConfig, setDialogLinkRegisterManpowerConfig] = useState({isOpen: false, isOpenRegisterManpower: isOpenRegisterManpower});

  useEffect(() => {
    if(location.state && location.state.id){
      setIsSelected(true);
    }
  },[]);

  useEffect(() => {
    if(isNotification){
      refCandidate.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start"
      });
    }
  },[isNotification]);

  const handleCloseDialog = () => {
    setOpenDialogChangeStatus({ status: false, idStatus: null, idManpower: null });
  };

  const handleChangeStatus = async (idManpower, idStatus) => {
    let result = await dispatch(updateStatusManpower({ idManpower: idManpower, idStatus: idStatus}));
    if (result && result.message === "success") {
      handleCloseDialog();
      dispatch(getAllUsersByIdVendor());
    }
  };

  const Displayage = (date) => {
    let month = dayjs().diff(date, 'month');
    return `${Math.floor(month / 12)} ปี${ month % 12 > 0 ? ` ${month % 12} เดือน` : ''}`;
  };
  
  const columns = [
    {
      field: "name",
      headerName: "ชื่อ-สกุล",
      width: 250,
      disableColumnMenu: true,
      format: (params) => (
        <Box className="column-name" component={Link} to={`/manpower/profile/${params.idManpower}`} style={{ textDecoration: "none", color: "black" }}>
          {/* <Avatar src={`${process.env.REACT_APP_API_URL}image/profile/${parseInt(Math.random() * 20) + 1}.jpg`} /> */}
          <Avatar sx={{bgcolor: stringToColor(`${params.name} ${params.lastname}`)}} children={`${params.name[0]}${params.lastname[0]}`}/>
          <Typography>{params ? `${params.name} ${params.lastname}` : '-'}</Typography>
        </Box>
      )
    },
    {
      field: "status",
      headerName: "สถานะ",
      width: 150,
      disableColumnMenu: true,
      format: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Box>
              {(params && params.idStatus === 6) ?
                <Tooltip
                  title={
                    <Box style={{ display: "flex", padding: "5px 10px" }}>
                      <StyledChipNotFree
                        label="ไม่ว่าง"
                        onClick={() => setOpenDialogChangeStatus({ status: true, idStatus: 7, idManpower: params.idManpower })}
                      />
                    </Box>
                  }
                  placement="top"
                >
                  <StyledChipFree label="ว่าง" />
                </Tooltip>
                :
                params.idStatus === 7 ?
                  <Tooltip
                    title={
                      <Box style={{ display: "flex", padding: "5px 10px" }}>
                        <StyledChipFree
                          label="ว่าง"
                          style={{
                            marginRight: (params.idStatus !== 6 && params.idStatus !== 7) ? "16px" : 0
                          }}
                          onClick={() => setOpenDialogChangeStatus({ status: true, idStatus: 6, idManpower: params.idManpower })}
                        />
                      </Box>
                    }
                    placement="top"
                  >
                    <StyledChipNotFree label="ไม่ว่าง" />
                  </Tooltip>
                  :
                  <StyledChipWait label="รอดำเนินการ" />
              }
            </Box>
        </div>
      )
    },
    {
      field: "prevPositionName",
      headerName: "ตำแหน่งงานล่าสุด",
      width: 250,
      disableColumnMenu: true,
      format: (params) => (
        <Typography>{params.prevJob && params.prevJob.lastPositionName || "-"}</Typography>
      )
    },
    {
      field: "prevJobGroupName",
      headerName: "กลุ่มงานล่าสุด",
      width: 250,
      disableColumnMenu: true,
      format: (params) => (
        <Typography>{params.prevJob && params.prevJob.lastJobGroupName || "-"}</Typography>
      )
    },
    {
      field: "age",
      headerName: "อายุ",
      width: 150,
      disableColumnMenu: true,
      format: (params) => (
        <Typography>
          {params && params["dateOfBirth"] ? Displayage(params["dateOfBirth"]) : "ไม่ระบุอายุ"}
        </Typography>
      )
    },
    {
      field: "experienceYear",
      headerName: "อายุงาน",
      width: 150,
      disableColumnMenu: true,
      format: (params) => (
        // <Typography>{params && params['OESY'] ? `${params['OESY']} ปี` : 'ไม่ระบุอายุงาน'}</Typography>
        // <Typography>{params && params['OESY(Y)'] && `${params['OESY(Y)']} ปี`} {params && params['OESY(M)'] && `${params['OESY(M)']} เดือน`} {params && !params['OESY(Y)'] && !params['OESY(M)'] && "ไม่ระบุอายุงาน"}</Typography>
        <Typography>{params['OESY(Y)'] && `${params['OESY(Y)']} ปี`} {params['OESY(M)'] && `${params['OESY(M)']} เดือน`} {params && !params['OESY(Y)'] && !params['OESY(M)'] && "ไม่มีประสบการณ์ทำงาน"}</Typography>
      )
    },
    {
      field: "jobGroup",
      headerName: "กลุ่มงาน",
      width: 300,
      disableColumnMenu: true,
      format: (params) => (
        <Typography className="expertise">
          {params && params.jobGroupName || "-"}
        </Typography>
      )
    }
    // {
    //   field: "expertise",
    //   headerName: "ความเชี่ยวชาญ",
    //   width: 300,
    //   disableColumnMenu: true,
    //   format: (params) => (
    //     <Typography className="expertise">
    //       {params && params.expertiseName || "-"}
    //     </Typography>
    //   )
    // }
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCheckedSelected = (idManpower) => {
    let result =false;
    listSelected.map((manpower) => {
      if(manpower.idManpower === idManpower){
        result = true;
        return ;
      }
    });
    return result;
  };

  const handleChangeCheckbox = (e, value) => {
   if(!e.target.checked){
    setListSelected([...listSelected.filter(manpower  => manpower.idManpower !== value.idManpower)]);
   } else {
    setListSelected([...listSelected, { 
      idManpower: value.idManpower, 
      name: value.name,
      lastname: value.name,
      telephoneMobile: value.telephoneMobile
    }])
   }
  }

  return (
    <Fragment>
      <div className="part-add-manpower">
        <div>
          <TextFieldTheme 
            className="search-name" 
            placeholder="ค้นหาชื่อ..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <StyledFormControl>
            <Select
              value={jobGroup}
              onChange={(e) => setJobGroup(e.target.value)}
            >
              <MenuItem value={"all"}>กลุ่มงานทั้งหมด</MenuItem>
              {AllJobGroups && AllJobGroups.map((job) => {
                  return (
                    <MenuItem 
                      key={job.idJobGroup}
                      value={job.jobGroupName}
                    >{job.jobGroupName}</MenuItem>
                  );
              })}
            </Select>
          </StyledFormControl>
        </div>
        {!isSelected &&
          <Box display="flex" gap="8px">
            <ButtonBlue 
              variant="outlined" 
              startIcon={<i className="fa-regular fa-plus"></i>} 
              component={Link} 
              to="/manpower/form"
            >
              เพิ่มกำลังพล
            </ButtonBlue>
            <ButtonBlue variant="outlined" startIcon={<i className="fa-regular fa-up"></i>} onClick={()=>{setIsOpenModalImportExcel(true)}} style={{textTransform: "none"}}>อัพโหลด Excel</ButtonBlue>
            {isOpenModalImportExcel && (
              <DialogExcel
                open={isOpenModalImportExcel}
                onClose={()=>{setIsOpenModalImportExcel(false)}}
              />
            )}
            <ButtonBlue variant="outlined" startIcon={<i className="fa-regular fa-link"></i>} onClick={()=>{setDialogLinkRegisterManpowerConfig({isOpen: true})}} style={{textTransform: "none"}}>ลิงค์รับสมัครกำลังพล</ButtonBlue>
            {dialogLinkRegisterManpowerConfig.isOpen && (
              <DialogLinkRegisterManpower
                open={dialogLinkRegisterManpowerConfig.isOpen}
                onClose={()=>{setDialogLinkRegisterManpowerConfig({isOpen: false})}}
                isOpenRegisterManpower={isOpenRegisterManpower}
                setIsOpenRegisterManpower={setIsOpenRegisterManpower}
              />
            )}
          </Box>
        }
      </div>
      <StyledTable>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {isSelected && 
                <TableCell 
                  className="StickyColumn StickyHeaderColumn"
                  style={{ 
                    boxShadow:"none",
                    borderTopLeftRadius:"8px !important",
                    borderBottomLeftRadius:"8px !important"
                  }}
                ></TableCell>
              }
              {columns.map((column, index) => (
                <TableCell
                  key={column.field}
                  align={column.align}
                  style={{
                    minWidth: column.width, 
                    maxWidth: column.width, 
                    width: column.width,
                    left: index === 0 ? isSelected ? 74 : 0 : 20,
                    borderTopLeftRadius: index === 0 ? isSelected ? 0 : 8 : 0,
                    borderBottomLeftRadius: index === 0 ? isSelected ? 0 : 8 : 0
                  }}
                  className={clsx({"StickyColumn StickyHeaderColumn" : column.field === "name"})}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(isFetching) && 
              <TableRow style={{ height:"525px" }}>
                <TableCell align="center">กำลังโหลดข้อมูล</TableCell>
                <TableCell align="center" colSpan={6}>
                  กำลังโหลดข้อมูล
                </TableCell>
              </TableRow>
            }
            {(!isFetching && allUsersFree && allUsersFree.length <= 0) && 
              <TableRow style={{ height:"525px" }}>
                <TableCell align="center">ไม่พบรายชื่อพนักงาน</TableCell>
                <TableCell align="center" colSpan={6}>
                  ไม่พบรายชื่อพนักงาน
                </TableCell>
              </TableRow>
            }
            {(!isFetching && allUsersFree.length > 0) && allUsersFree
              .filter(user => (jobGroup === "all" ? true : jobGroup === user.prevJobGroupName) && (user.name.includes(search) || user.lastname.includes(search)))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.idManpower}>
                  {isSelected && 
                    <TableCell className="StickyColumn">
                      <Checkbox 
                        checked={handleCheckedSelected(row.idManpower)} 
                        onChange={(e) => handleChangeCheckbox(e, row)}
                      />
                    </TableCell>
                  }
                  {columns
                  .map((column, index) => (
                    <TableCell 
                      key={column.field} 
                      align={column.align}
                      style={{ 
                        left: index === 0 ? isSelected ? 74 : 0 : "initial"
                      }}
                      className={clsx({"StickyColumn" : column.field === "name"})}
                    >
                      {
                        column.format
                          ? column.format(row)
                          : row[column.field]
                      }
                    </TableCell>
                  ))}
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </StyledTable>
      <div
        style={{
          width: "100%",
          display: "flex",
          position: "relative",
          justifyContent: "flex-end",
          margin: "20px 0 40px",
          alignItems: "center"
        }}
        ref={refCandidate}
      >
        <TablePagination
          rowsPerPageOptions={[2, 10, 25, 100]}
          component="div"
          count={allUsersFree.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      {openDialogChangeStatus.status &&
        <DialogChangeStatus 
          openDialog={openDialogChangeStatus}
          onClose={handleCloseDialog}
          handleSubmit={handleChangeStatus}
        />
      }
      {isSelected &&
        <div style={{ display:"flex", justifyContent:"flex-end" }}>
          <ButtonBlue
            component={Link}
            to={{
              pathname: location.state.type === "job" ? `/company/request/${location.state.id}` : `bidding/${location.state.id}`,
              state: {
                listSelected: listSelected
              }
            }}
            variant="contained"
            startIcon={<DoneAll />}
          >
            ยืนยันการเลือก
          </ButtonBlue>
        </div>
      }
    </Fragment>
  );
};

const VendorManpowerPage = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { result : UserProfile } = useSelector(state => state.userProfile);
  const { isFetching: isFetchingAllUsers, result : AllUsers } = useSelector(state => state.users);
  const { result: vendorNotification } = useSelector((state) => state.vendorNotification);
  const [allUsersFree, setAllUsersFree] = useState([]);
  const [allUsersWorking, setAllUsersWorking] = useState([]);
  let isEnding = false;
  const [isOpenRegisterManpower, setIsOpenRegisterManpower] = useState(false);

  useEffect(() => {
    return async () => {
      isEnding = true;
      if(isEnding){
        if(vendorNotification && vendorNotification.listNotifications && vendorNotification.listNotifications.length > 0){
          if(location.pathname === "/manpower"){
            const res = await dispatch(updateVendorNotification({ 
              listVendorNotification: vendorNotification.listNotifications,
              listCandidate: vendorNotification.listCandidates
            }));
            if(res.message === "success"){
              dispatch(getVendorNotification());
            }
          }
        }
      }
    }
  },[location]);

  useEffect(() => {
    if(UserProfile){
      dispatch(getAllUsersByIdVendor()).then(res => {
        if(res.status === 200){
          setIsOpenRegisterManpower(res.data.setting.isOpenRegisterManpower)
        }
      });
      dispatch(getJobGroups());
    }
  },[UserProfile]);

  useEffect(() => {
    if (
      AllUsers && AllUsers.message && AllUsers.message === "success" && 
      AllUsers.data && AllUsers.data.length > 0 && vendorNotification 
    ) {
      let tempAllFreeUsers = [];
      let tempAllWorkingUser = [];
      let listCandidateSuccess = [
        ...vendorNotification && vendorNotification.listCandidates && vendorNotification.listCandidates.length > 0 ? 
        vendorNotification.listCandidates.map((candidate) => candidate.idManpower) : []
      ];
      AllUsers.data.map((manpower) => {
        if(manpower.idStatus === 7 && manpower.idCompany){
          tempAllWorkingUser.push({ ...manpower, isRead: 
            listCandidateSuccess.includes(manpower.idManpower) ? 0 : 1 
          });
        } else {
          tempAllFreeUsers.push({ ...manpower });
        }
      });
      setAllUsersFree([...tempAllFreeUsers]);
      setAllUsersWorking([...tempAllWorkingUser]);
    } else {
      setAllUsersFree([]);
      setAllUsersWorking([]);
    }
  },[AllUsers, vendorNotification]);

  return(
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="header-container">
          <Typography variant="h4">กำลังพล</Typography>
          <div className="part-total">
            <Typography variant="h4">{allUsersFree.length}</Typography>
            <Typography color="text.secondary">{`กำลังพลทั้งหมด`}</Typography>
          </div>
        </div>
        <ManpowerComponent allUsersFree={allUsersFree} isFetching={isFetchingAllUsers} isNotification={location.state ? location.state.id : null} isOpenRegisterManpower={isOpenRegisterManpower} setIsOpenRegisterManpower={setIsOpenRegisterManpower}/>

        {!(location.state && location.state.id) && 
          <SentManpower candidate={allUsersWorking} />
        }

      </Container>
    </StyledRoot>
  )
}

export default VendorManpowerPage;