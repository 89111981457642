import React from "react";
import { Divider, styled, Typography } from "@mui/material";

const StyledRoot = styled("div")({
  paddingLeft: 16,
  paddingRight: 16,
  "& .branch-name": {
    fontSize: 18,
  },
  "& .branch-address": {
    
  },
  "& i": {
    marginRight: 8
  },
  "& .part-main-contact": {
    display: "flex",
    flexFlow: "row wrap",
    "& .MuiTypography-root": {
      marginRight: 16,
    }
  },
  "& .MuiDivider-root": {
    marginTop: 16,
    marginBottom: 16,
  }
})

const StyledBranch = (props) => {
  const { branch } = props;

  return(
    <StyledRoot>
      <Typography className="branch-name">{`${branch.branchName}`}</Typography>
      <Typography className="branch-address" color="text.secondary">
        <i className="far fa-map-marker-alt"></i>
        {`${branch.address || "-"}`}
      </Typography>
      <div className="part-main-contact">
        <Typography><i className="fa-regular fa-user-headset"></i>{`${branch.mainContactName || "-"}`}</Typography>
        <Typography><i className="fa-regular fa-phone"></i>{`${branch.mainContactPhone || "-"}`}</Typography>
        <Typography><i className="fa-regular fa-envelope"></i>{`${branch.mainContactEmail || "-"}`}</Typography>
      </div>
      <Divider />
    </StyledRoot>
  )
}

export default StyledBranch;