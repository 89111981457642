import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import { Typography } from "@mui/material";

import ButtonBlue from "../shared/general/ButtonBlue";
import CardBidding from "../user/shared/CardBidding";
import CardRequestSkeleton from "../shared/general/CardRequestSkeleton";

const StyledRoot = styled("div")({
  "& a": {
    textDecoration: "none",
  },
  "&.bidding": {
    paddingBottom: 64,
    "& .bidding-head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 16,
      ["@media only screen and (max-width: 600px)"]:{
        flexDirection: "column"
      }
    },
    "& .wrap-card": {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      columnGap: 12,
      ["@media only screen and (max-width: 600px)"]:{
        gridTemplateColumns: "repeat(1, 1fr)",
      }
    },
  },
});

const Bidding = (props) => {
  const { request, isFetching, isCompany } = props;
  return (
    <StyledRoot className="bidding">
      <div className="bidding-head">
        <Typography variant="h4" gutterBottom>
          {
            isCompany? `ประมูลที่เปิดล่าสุด`
            : `ประมูล / จัดจ้างใหม่`
          }
        </Typography>
        <div>
          <ButtonBlue
            component={Link}
            to={`/biddings`}
          >
            ทั้งหมด{" "}
            <i
              style={{ lineHeight: 0, paddingLeft: 6 }}
              className="fi fi-br-angle-small-right"
            ></i>
          </ButtonBlue>
        </div>
      </div>

      {isFetching && (
        <div className="wrap-card">
          <CardRequestSkeleton />
          <CardRequestSkeleton />
          <CardRequestSkeleton />
        </div>
      )}

      {!isFetching && request.length > 0 && (
        <div className="wrap-card">
          {request.map((value, index) => {
            return <CardBidding key={value.idBiddings} bidding={value} />;
          })}
        </div>
      )}

      {!isFetching && request.length === 0 && (
        <Fragment>
          {isCompany? 
              <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Link to="/company/myjobs/biddingForm">
                  <ButtonBlue variant="outlined" startIcon={<i className="fa-solid fa-plus" style={{fontSize: "inherit"}}></i>} style={{fontSize: 24}}>เปิดประมูลเลย</ButtonBlue>
                </Link>
              </div>
            : <Typography variant="h5" textAlign="center" pt="48px">ไม่พบการเปิดประมูล</Typography>
          }
      </Fragment>
      )}

    </StyledRoot>
  );
};

export default Bidding;
