import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { 
    Avatar,
    Box, 
    Container,
    styled,
    Typography
} from '@mui/material';

import TableTheme from '../../shared/general/TableTheme';

import { getHistoryCandidatesJobsMatchingVendor } from '../../../../actions/candidate';

const StyledRoot = styled(Box)({
    "& .BoxIconAndText":{
        display:"flex",
        alignItems:"center",
        "& .Icon":{
            color: "#007afe", 
            fontSize:"1.1rem", 
            marginRight:"10px"
        }
    }
});

const JobMatching = () => {
    const dispatch = useDispatch();
    const { result: AllHistoryCandidates } = useSelector(state => state.historyCandidatesJobsMatching);

    useEffect(() => {
        dispatch(getHistoryCandidatesJobsMatchingVendor());
    },[]);

    const columns = [
        {
            field:"name",
            headerName:(
                <Box className="BoxIconAndText">
                    <i className="fa-solid fa-user-group Icon" />
                    <Typography>ชื่อ-สกุล</Typography>
                </Box>
            ),
            width: 250,
            format:(params)=>(
                <Box style={{ display:"flex", alignItems:"center" }}>
                    <Avatar />
                    <Typography style={{ marginLeft:16 }}>{`${params.name} ${params.lastname}`}</Typography>
                </Box>
            )
        },
        {
            field:"companyName",
            headerName:(
                <Box className="BoxIconAndText">
                    <i className="fa-solid fa-building Icon" />
                    <Typography>บริษัท</Typography>
                </Box>
            ),
            width: 250,
            format:(params)=>(
                <Box>
                    <Typography>{params.companyName}</Typography>
                </Box>
            )
        },
        {
            field:"positionName",
            headerName:(
                <Box className="BoxIconAndText">
                    <i className="fa-solid fa-briefcase Icon" />
                    <Typography>ตำแหน่งงาน</Typography>
                </Box>
            ),
            width: 288,
            format:(params)=>(
                <Box>
                    <Typography>{params.positionName}</Typography>
                </Box>
            )
        },
        {
            field:"workingStartDate",
            headerName:(
                <Box className="BoxIconAndText">
                    <i className="fa-solid fa-calendar Icon" />
                    <Typography>วันที่เข้างาน</Typography>
                </Box>
            ),
            width: 120,
            format:(params)=>(
                <Box>
                    <Typography>{dayjs(params.workingStartDate).format("DD MMM YYYY")}</Typography>
                </Box>
            )
        }
    ];

    return (
        <Box>
            <Typography variant="h4">ประวัติการ Matching</Typography>
            <Box style={{ marginTop: 16 }}>
                <TableTheme 
                    columns={columns}
                    rows={AllHistoryCandidates || []}
                    textEmpty="ไม่พบการ Matching"
                    minHeight={560}
                />
            </Box>
        </Box>
    );
};

const JobMatchingByPass = () => {
    const rowMock = [
        {
            id:1,
            noJobByPass:"202210212340",
            companyName:"เอสซีจี เคมิคอลส์",
            quota:4,
            workingStartDate:"2022-10-21"
        },
        {
            id:2,
            noJobByPass:"202204101956",
            companyName:"เอสซีจี",
            quota:2,
            workingStartDate:"2022-04-10"
        }
    ];

    const columns = [
        {
            field:"noJobByPass",
            headerName:(
                <Box className="BoxIconAndText">
                    <i className="fa-solid fa-file-invoice Icon" />
                    <Typography>เลขที่เปิดขอ</Typography>
                </Box>
            ),
            width: 250,
            format:(params)=>(
                <Box>
                    <Typography>{params.noJobByPass}</Typography>
                </Box>
            )
        },
        {
            field:"companyName",
            headerName:(
                <Box className="BoxIconAndText">
                    <i className="fa-solid fa-building Icon" />
                    <Typography>บริษัท</Typography>
                </Box>
            ),
            width: 250,
            format:(params)=>(
                <Box>
                    <Typography>{params.companyName}</Typography>
                </Box>
            )
        },
        {
            field:"quota",
            headerName:(
                <Box className="BoxIconAndText">
                    <i className="fa-solid fa-users Icon" />
                    <Typography>จำนวน</Typography>
                </Box>
            ),
            width: 50,
            format:(params)=>(
                <Box>
                    <Typography>{params.quota}</Typography>
                </Box>
            )
        },
        {
            field:"workingStartDate",
            headerName:(
                <Box className="BoxIconAndText">
                    <i className="fa-solid fa-calendar Icon" />
                    <Typography>วันที่เข้างาน</Typography>
                </Box>
            ),
            width: 120,
            format:(params)=>(
                <Box>
                    <Typography>{dayjs(params.workingStartDate).format("DD MMM YYYY")}</Typography>
                </Box>
            )
        }
    ];

    return (
        <Box>
            <Typography variant="h4">ประวัติงานแบบฝากเข้าสังกัดที่สำเร็จ</Typography>
            <Box style={{ marginTop: 16 }}>
                <TableTheme 
                    columns={columns}
                    rows={rowMock}
                    textEmpty="ไม่พบงานที่สำเร็จ"
                    minHeight={560}
                />
            </Box>
        </Box>
    );
};

const VendorMatchingPage = () => {
    return (
        <StyledRoot className="page">
            <Container maxWidth='lg'>
                <JobMatching />
                <JobMatchingByPass />
            </Container>
        </StyledRoot>
    );
};

export default VendorMatchingPage;