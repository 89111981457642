import axios from "axios";
import { httpClient } from "./httpClient";

const API_URL = process.env.REACT_APP_API_URL + "auth/";

const login = (email, password, rememberMe) => {
  return axios
    .post(API_URL + "signin", {
      email,
      password,
      rememberMe
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const changePassword = (formData) => {
  return httpClient.post(`auth/password/change`, formData);
};

const requestResetPassword = (formData) => {
  return httpClient.post(`auth/password/reset_password/request`, formData);
};

const verifyResetPassword = (query) => {
  return httpClient.get(`auth/password/reset_password`, {params: query});
};

const resetPassword = (formData) => {
  return httpClient.post(`auth/password/reset_password`, formData);
};

const verifyEmail = (formData) => {
  return httpClient.post(`auth/email/verify_email`,formData);
};

const changeEmail = (formData) => {
  return httpClient.post(`auth/email/change_email`,formData);
};

export default {
  login,
  logout,
  changePassword,
  requestResetPassword,
  verifyResetPassword,
  resetPassword,
  verifyEmail,
  changeEmail
};
