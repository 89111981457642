import React, { Fragment, useEffect, useState } from "react";
import { Container, Divider, Grid, styled, Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createPassword, verifyEmail } from "../../../actions/signup";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../shared/general/TextFieldTheme";
import ButtonBlue from "../shared/general/ButtonBlue";
import CreatePasswordSuccess from "./createPasswordSuccess";

const StyledRoot = styled("div")({
  paddingBottom: 200,
  "& .create-pass-container": {
    margin: "0 auto",
    maxWidth: 350,
    "& .MuiDivider-root": {
      margin: "28px 0",
    },
    "& .create-button": {
      lineHeight: 2.5,
    }
  },
})

const CreatePassword = () => {

  const search = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const veificationCode = new URLSearchParams(search.search).get("verificationCode") || null;

  const { control, handleSubmit, getValues, formState: { errors }, setError } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    }
  });

  const onSubmit = async (formData) => {

    const cleansingForm = {
      password: formData.password,
      verificationCode: veificationCode
    }
    
    const response = await dispatch(createPassword(cleansingForm))

    if (response.status === 200){
      console.log("success")
      setIsSuccess(true);
    } else {
      console.log("failed")
    }
  }
  
  useEffect(async () => {
    if(veificationCode !== null){
      const response = await dispatch(verifyEmail({verificationCode: veificationCode}))

      if(response.status === 200){
        setIsloading(false)
      }
      else if(response.response && response.response.status === 403){
        history.push("/")
      }
      console.log(veificationCode)
    }else{
      history.push("/")
    }
  }, [])

  return(
    <StyledRoot className={`page`}>
      {!isLoading &&  !isSuccess && (
        <div className="create-pass-container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h4">สร้างรหัสผ่าน</Typography>
            <Divider />
            <Grid container spacing={3}>
              
              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: {value: true, message: "กรุณากรอกรหัสผ่าน"},
                    validate: (value) => (value.length >= 6) || "กรุณากรอกรหัสผ่าน อย่างน้อย 6 หลัก",
                    pattern: {
                      value: /^[A-Za-z0-9_]+$/,
                      message: "กรุณากรอกรหัสผ่าน เป็นตัวภาษาอังกฤษ ตัวเลข หรือเครื่องหมาย_เท่านั้น"
                    }
                  }}
                  render={({field})=>(
                    <Fragment>
                      <Typography className="fieldLabel" gutterBottom>รหัสผ่าน</Typography>
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        type="password"
                        helperText={errors && errors.password && errors.password.message}
                        error={errors && errors.password ? true: false}
                      />
                    </Fragment>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{
                    required: {value: true, message: "กรุณากรอกยืนยันรหัสผ่าน"},
                    validate: (value) => (value === getValues("password")) || "รหัสผ่านไม่ตรงกัน",
                  }}
                  render={({field})=>(
                    <Fragment>
                      <Typography className="fieldLabel" gutterBottom>ยืนยันรหัสผ่าน</Typography>
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        type="password"
                        helperText={errors && errors.confirmPassword && errors.confirmPassword.message}
                        error={errors && errors.confirmPassword ? true: false}
                      />
                    </Fragment>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <ButtonBlue className="create-button" type="submit" variant="contained" fullWidth>สร้างรหัสผ่าน</ButtonBlue>
              </Grid>

              <Grid item xs={12}>
                <Typography color="text.secondary">*การยืนยันตัวตนจะสำเร็จเมื่อสร้างรหัสผ่าน</Typography>
              </Grid>

            </Grid>
          </form>
        </div>
      )}

      {isSuccess && (
        <CreatePasswordSuccess />
      )}
    </StyledRoot>
  )
}

export default CreatePassword;