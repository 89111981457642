import React from "react";
import { Box, Dialog, styled, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";

const StyledRoot = styled(Box)({
  padding: "24px 16px",
  display:"flex",
  flexDirection:"column",
  boxSizing: "border-box",
})

const DialogAddToMyVendor = (props) => {

  const {open, onClose, onConfirm} = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <StyledRoot>
        <Box className="text" display="flex" justifyContent="center" alignItems="center">
          {/* <i className="fa-regular fa-triangle-exclamation" style={{marginRight: 8, color: "#ffcc00"}}></i> */}
          <Typography fontSize={20}>คุณต้องการขึ้นทะเบียนคู่ธุรกิจนี้ เป็นคู่สัญญา ?</Typography>
        </Box>
        {/* <Typography marginTop="24px" textAlign="center">{filename}</Typography> */}
        <Box display="flex" justifyContent="space-between" paddingTop="32px">
          <ButtonBlue variant="outlined" onClick={onClose}>ยกเลิก</ButtonBlue>
          <ButtonBlue variant="contained" onClick={() => {onConfirm(); onClose()}} >ยืนยัน</ButtonBlue>
        </Box>
      </StyledRoot>
    </Dialog>
  )
}

export default DialogAddToMyVendor