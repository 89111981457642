import {
  COMPANY_MYVENDORS_FETCHING,
  COMPANY_MYVENDORS_FAILED,
  COMPANY_MYVENDORS_SUCCESS,
} from "./types";
import AdminCompany from "../services/adminCompany.service";

export const getMyVendors = () => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_MYVENDORS_FETCHING
    });
    const res = await AdminCompany.getMyVendors();
    if (res) {
      dispatch({
        type: COMPANY_MYVENDORS_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: COMPANY_MYVENDORS_FAILED
    });
  }
};

export const updateVendorHidden = (formData) => async () => {
  try {
    const res = await AdminCompany.updateVendorHidden(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error.response;
  }
};

export const addMyVendor = (formData) => async () => {
  try {
    const res = await AdminCompany.addMyVendor(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addAdminCompany = (formData) => async () => {
  try {
    const res = await AdminCompany.addAdminCompany(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const verifyTokenAddAdminCompany = (query) => async () => {
  try {
    const res = await AdminCompany.verifyTokenAddAdminCompany(query);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const createPasswordAdminCompany = (formData) => async () => {
  try {
    const res = await AdminCompany.createPasswordAdminCompany(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteAdminCompany = (idAdminAntJob) => async () => {
  try {
    const res = await AdminCompany.deleteAdminCompany(idAdminAntJob);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};