import { httpClient } from "./httpClient";

const addBidding = (formData) => {
  return httpClient.post(`biddings`, formData);
};

const getBiddingsMyJobs = () => {
  return httpClient.get("biddings/myJob");
};

const getBiddingVendor = (idBiddings) => {
  return httpClient.get(`bidding/${idBiddings}/vendor-biddings`);
};

const conclusionBidding = (data) => {
  return httpClient.put("bidding/conclusion",data);
};

const getMyBiddingsByVendor = () => {
  return httpClient.get("bidding/vendor/mybiddings");
};

const getHistoryBiddingsVendor = () => {
  return httpClient.get("bidding/vendor/history");
}

const getHistoryBiddingsCompany = () => {
  return httpClient.get("biddings/company/history");
};

const getBiddingById = (idBidding) => {
  return httpClient.get(`biddings/${idBidding}`);
};

const submitBid = (idBidding, formData) => {
  return httpClient.post(`biddings/${idBidding}/submit-bid`, formData);
};

const getBiddingMinGuaranteeContract = () => {
  return httpClient.get(`biddings/getBiddingMinGuaranteeContract`);
};

const getMyContractVendor = () => {
  return httpClient.get(`vendor/mycontract`);
};

const getMyContractCompany = () => {
  return httpClient.get(`company/mycontract`);
};

const getAllBiddings = (query) => {
  return httpClient.get(`biddings`, { params: query });
};

export default {
  addBidding,
  getBiddingsMyJobs,
  getBiddingVendor,
  conclusionBidding,
  getMyBiddingsByVendor,
  getHistoryBiddingsVendor,
  getHistoryBiddingsCompany,
  getBiddingById,
  submitBid,
  getBiddingMinGuaranteeContract,
  submitBid,
  getMyContractVendor,
  getMyContractCompany,
  getAllBiddings
};