import ContactUsService from "../services/contactus.service";

export const postContactUs = (formData) => async () => {
  try {
    const res = await ContactUsService.postContactUs(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};