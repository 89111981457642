import React from "react";
import { Box, Button } from "@mui/material";
import dayjs from "dayjs";

import { PDFDocument, TextAlignment, rgb, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import FontFile from "./font/THSarabunNew.ttf";
import PDFFile from "./files/หนังสือรับรองเงินเดือน.pdf";

function certificateSalary() {
  const handleOnCertSalary = async () => {
    const url = PDFFile;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    //Custom font
    const urlFont = FontFile;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const pages = pdfDoc.getPages();
    const page1 = pages[0];

    const font = await pdfDoc.embedFont(fontBytes);

    const fontSize = 16;
    const contentWidth = 480;

    const emp = "นายพุทธินันท์ ม่วงประเสริฐ";
    const companyParent = "เอสซีจี (SCG)";
    const date = "1 ตุลาคม 2550";
    const position = "Manager-HR Business Partner";
    const department =
      "HR Business Partner-Polyolefins Manufacturing, Polyolefins Manufacturing, Operations, Operations and Thailand Olefins Chain";
    const companyChild = "บริษัท ไทยโพลิเอททีลีน จํากัด";
    const certDate = "ออกให้ ณ วันที่ 9 มกราคม 2566";

    const text1 = `${Array(20)
      .fill("\xa0")
      .join(
        ""
      )}โดยหนังสือฉบับนี้ขอรับรองว่า ${emp} เป็นพนักงานของ ${companyParent} เข้าปฏิบัติงานตั้งแต่วันที่ ${date} ปัจจุบันปฏิบัติงานในตําแหน่ง ${position} สังกัด ${department} ${companyChild} ได้รับค่าจ้างเดือนละ ... บาท`;

    page1.drawText(text1, {
      x: 70,
      y: 635,
      font,
      size: fontSize,
      maxWidth: contentWidth,
    });

    page1.drawText(certDate, {
      x: 310,
      y: 500,
      font,
      size: fontSize,
    });

    //generate pdf
    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);

    //Open PDF File in new tab
    window.open(docUrl, "_blank");
  };

  return (
    <Box sx={{ margin: 2 }}>
      <Button variant="contained" onClick={handleOnCertSalary}>
        หนังสือรับรองเงินเดือน
      </Button>
    </Box>
  );
}

export default certificateSalary;
