import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Slider from "react-slick";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Typography } from "@mui/material";

const WrapAvatar = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: 84,
  height: 96,
}));

const StyledBox = styled(Box)(({}) => ({
  "&.arrow": {
    position: "absolute",
    marginTop: -20,
    top: "50%",
    zIndex: 9,
  },
  "&.right": {
    right: 0,
  },
  "&.left": {
    left: 0,
  },
}));

const StyledContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 20,
  padding: "40px 24px",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "rgb(145 158 171 / 24%) -24px 24px 72px -8px",
  },
  "& .MuiAvatar-root": {
    width: 128,
    height: 64,
    marginBottom: 16,
    backgroundColor: "white",
    color: "inherit",
    "& img": {
      width: "inherit",
      height: "inherit",
      objectFit: "contain",
    },
    "& i": {
      fontSize: 64,
      color: "inherit",
    }
  },
  "& .MuiTypography-h6": {
    fontSize: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 220,
  },
});

const ReactSlickDemo = (props) => {
  const { company } = props;
  const history = useHistory();
  const [state, setState] = useState({ nav1: null, nav2: null });
  const slider1 = useRef();
  const slider2 = useRef();

  const [xMouseDown, setXMouseDown] = useState(null);

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    });
  }, []);

  const { nav1, nav2 } = state;

  const gotoNext = () => {
    slider1.current.slickNext();
  };

  const gotoPrev = () => {
    slider1.current.slickPrev();
  };

  const handleClickCompany = (link) => {
    history.push("/company/" + link);
  }

  return (
    <div
      style={{
        margin: "auto",
        //width: 1050,
      }}
    >
      <StyledBox className={`arrow left`}>
        <IconButton aria-label="prev" size="large" onClick={() => gotoPrev()}>
          <ArrowBackIcon style={{ color: "#999999" }} />
        </IconButton>
      </StyledBox>
      <Box>
        <Slider
          arrows={false}
          asNavFor={nav2}
          ref={(slider) => (slider1.current = slider)}
          infinite={true}
          slidesToShow={4}
          slidesToScroll={4}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {company.map((value, index) => {
            return (
              <div key={`top_company_${index+1}`}>
                <div style={{ padding: "60px 0" }}>
                  <StyledContent onMouseDown={(e)=>{setXMouseDown(e.clientX)}} onClick={(e) => {if(e.clientX !== xMouseDown){e.preventDefault()}else{handleClickCompany(value.companyNameShort)}}}>
                    {/* <Avatar variant="rounded" src={value.companyImage? `${process.env.REACT_APP_API_URL}image/company/${value.companyImage}`: ""}> */}
                    <Avatar variant="rounded" src={value.companyImageURL}>
                      {/* <i className="fa-regular fa-buildings"/> */}
                      <img src="/assets/logo_default.png" />
                    </Avatar>
                    {/* {value.companyImage? (
                      <Avatar variant="rounded" src={`${process.env.REACT_APP_API_URL}image/company/${value.companyImage}`}>
                        <i className="fa-regular fa-buildings"/>
                      </Avatar>
                    ):(
                      <i className="fa-regular fa-buildings"/>
                    )} */}
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      gutterBottom
                    >{`${value.openJobCount || "0"} งานที่เปิดรับ`}</Typography>
                    <Typography variant="h6" align="center" gutterBottom>
                      {value.companyName}
                    </Typography>
                  </StyledContent>
                </div>
              </div>
            );
          })}
        </Slider>
      </Box>
      <StyledBox className={`arrow right`}>
        <IconButton aria-label="next" size="large" onClick={() => gotoNext()}>
          <ArrowForwardIcon style={{ color: "#999999" }} />
        </IconButton>
      </StyledBox>
    </div>
  );
};

export default ReactSlickDemo;
