import {
  COMPANY_INFORMATION_FETCHING,
  COMPANY_INFORMATION_FAILED,
  COMPANY_INFORMATION_SUCCESS,
  COMPANY_MANAGE_FETCHING,
  COMPANY_MANAGE_FAILED,
  COMPANY_MANAGE_SUCCESS,
  COMPANYS_FETCHING,
  COMPANYS_FAILED,
  COMPANYS_SUCCESS,
  VENDOR_MYCOMPANYS_FETCHING,
  VENDOR_MYCOMPANYS_FAILED,
  VENDOR_MYCOMPANYS_SUCCESS
} from "./types";
import CompanyService from "../services/company.service";

export const getCompanyByNameShort = (companyNameShort) => async (dispatch) => {
  try {
      dispatch({
          type: COMPANY_INFORMATION_FETCHING
      });
      const res = await CompanyService.getCompanyByNameShort(companyNameShort);
      if (res) {
          dispatch({
              type: COMPANY_INFORMATION_SUCCESS,
              payload: res.data
          });
      }
  } catch (error) {
      dispatch({
          type: COMPANY_INFORMATION_FAILED
      });
  }
};

export const getCompanyManage = () => async (dispatch) => {
  try {
      dispatch({
          type: COMPANY_MANAGE_FETCHING
      });
      const res = await CompanyService.getCompanyManage();
      if (res) {
          dispatch({
              type: COMPANY_MANAGE_SUCCESS,
              payload: res.data
          });
      }
  } catch (error) {
      dispatch({
          type: COMPANY_MANAGE_FAILED
      });
  }
};

export const updateCompanyManage = (formData) => async () => {
  try {
    const res = await CompanyService.updateCompanyManage(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addCompanyLogo = (formData) => async () => {
  try {
    const res = await CompanyService.addCompanyLogo(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteCompanyLogo = () => async () => {
  try {
    const res = await CompanyService.deleteCompanyLogo();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addCompanyAuthorizedSignatureOne = (formData) => async () => {
  try {
    const res = await CompanyService.addCompanyAuthorizedSignatureOne(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteCompanyAuthorizedSignatureOne = () => async () => {
  try {
    const res = await CompanyService.deleteCompanyAuthorizedSignatureOne();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addCompanyAuthorizedSignatureTwo = (formData) => async () => {
  try {
    const res = await CompanyService.addCompanyAuthorizedSignatureTwo(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteCompanyAuthorizedSignatureTwo = () => async () => {
  try {
    const res = await CompanyService.deleteCompanyAuthorizedSignatureTwo();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addCompanyWitnessSignature = (formData) => async () => {
  try {
    const res = await CompanyService.addCompanyWitnessSignature(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteCompanyWitnessSignature = () => async () => {
  try {
    const res = await CompanyService.deleteCompanyWitnessSignature();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const updateCompanyManageBankDetail= (formData) => async () => {
  try {
    const res = await CompanyService.updateCompanyManageBankDetail(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addCompanyManageBranch = (formData) => async () => {
  try {
    const res = await CompanyService.addCompanyManageBranch(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getAllCompanys = (query) => async (dispatch) => {
  try {
    dispatch({
        type: COMPANYS_FETCHING
    });
    const res = await CompanyService.getAllCompanys(query);
    if (res) {
        dispatch({
            type: COMPANYS_SUCCESS,
            payload: res.data
        });
    }
  } catch (error) {
    dispatch({
        type: COMPANYS_FAILED
    });
}
};

export const getMyCompanys = () => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_MYCOMPANYS_FETCHING
    });
    const res = await CompanyService.getMyCompanys();
    if(res){
      dispatch({
        type: VENDOR_MYCOMPANYS_SUCCESS,
        payload: res.data
    });
    }
  } catch (error) {
    dispatch({
      type: VENDOR_MYCOMPANYS_FAILED
    });
  }
};

export const generatorTokenChildsCompany = () => async () => {
  try {
    const res = await CompanyService.generatorTokenChildsCompany();
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const decodeTokenChildsCompany = (token) => async () => {
  try {
    const res = await CompanyService.decodeTokenChildsCompany(token);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const setParentCompany = (data) => async () => {
  try {
    const res = await CompanyService.setParentCompany(data);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};