import React, { useEffect, useState } from "react";
import { Container, styled, Typography } from "@mui/material";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword, verifyResetPassword } from "../../../../../../actions/auth";

const StyledRoot = styled("div")({
  "& .reset-container": {
    margin: "0 auto",
    maxWidth: 480,
    "& .reset-title": {
      marginBottom: 24,
      fontSize: 24,
      textAlign: 'center',
    },
    "& .reset-item": {
      marginBottom: 16,
    }
  },
})

const ResetPasswordPage = (props) => {

  const { match } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const { control, handleSubmit, formState: { errors, isSubmitting }, getValues } = useForm({
    defaultValues: {
      newPassword: "",
      confirmNewPassword: "",
    }
  })

  const [isVerify, setIsVerify] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = async (formData) => {

    const cleansingData = {
      auth: new URLSearchParams(history.location.search).get("auth"),
      newPassword: formData.newPassword,
      confirmNewPassword: formData.confirmNewPassword,
    }
    
    const response = await (dispatch(resetPassword(cleansingData)))
  
    if(response.status === 200){
      setIsSuccess(true);
    }
  }

  useEffect(() => {
    const auth = new URLSearchParams(history.location.search).get("auth");
    dispatch(verifyResetPassword({auth: auth}))
      .then(res => {
        if(res.status === 200){
          setIsVerify(true)
        } else {
          setIsVerify(false)
        }
      })
  }, [])

  useEffect(() => {
    if(isSuccess){
      setTimeout(() => {history.push("/")}, 3000);
    }
  }, [isSuccess])

  return (
    <StyledRoot className="page">
      {(!isSuccess && isVerify === true) && <Container maxWidth="lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="reset-container">
            <Typography className="reset-title">รีเซ็ตรหัสผ่าน</Typography>
            <div className="reset-item">
              <Controller
                name="newPassword"
                control={control}
                rules={{
                  required: {value: true, message: "กรุณากรอกรหัสผ่านใหม่"},
                  validate: (value) => (value.length >= 8) || "กรุณากรอกรหัสผ่าน อย่างน้อย 8 ตัว",
                  pattern: {
                    value: /^[A-Za-z0-9_]+$/,
                    message: "กรุณากรอกรหัสผ่าน เป็นตัวภาษาอังกฤษ ตัวเลข หรือเครื่องหมาย_เท่านั้น"
                  }
                }}
                render={({field})=>(
                  <TextFieldTheme
                    {...field}
                    fullWidth
                    label="รหัสผ่านใหม่"
                    type="password"
                    helperText={errors && errors.newPassword && errors.newPassword.message}
                    error={errors && errors.newPassword ? true: false}
                  />
                )}
              />
            </div>
            <div className="reset-item">
              <Controller
                name="confirmNewPassword"
                control={control}
                rules={{
                  required: {value: true, message: "กรุณากรอกยืนยันรหัสผ่านใหม่"},
                  validate: (value) => (value === getValues("newPassword")) || "รหัสผ่านไม่ตรงกัน",
                }}
                render={({field})=>(
                  <TextFieldTheme
                    {...field}
                    fullWidth
                    label="ยืนยันรหัสผ่านใหม่"
                    type="password"
                    helperText={errors && errors.confirmNewPassword && errors.confirmNewPassword.message}
                    error={errors && errors.confirmNewPassword ? true: false}
                  />
                )}
              />
            </div>
            <div className="reset-item">
              <ButtonBlue variant="contained" fullWidth type="submit" disabled={isSubmitting}>รีเซ็ตรหัสผ่าน</ButtonBlue>
            </div>
          </div>
        </form>
      </Container>}

      {(!isSuccess && isVerify === false) && <Container maxWidth="lg">
        <Redirect to="/" />
      </Container>}

      {isSuccess && <Container maxWidth="lg">
        <Typography fontSize={24} textAlign="center">รีเซ็ตรหัสผ่านเรียบร้อยแล้ว</Typography>
      </Container>}
    </StyledRoot>
  )

}

export default ResetPasswordPage;