import React, { useState } from "react";
import { Grid, styled, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerProfile from "./drawerProfile";
import Utils, { socialSecurityFormat } from "../../../../utils";

const StyledRoot = styled("div")({

})

const ProfilePanel = (props) => {

  const { vendor } = props;

  const [isOpenDrawerProfile, setIsOpenDrawerProfile] = useState(false);

  return(
    <StyledRoot>
      <Grid container spacing={1}>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Typography className="header-text" variant="h7">Vendor</Typography>
          <ButtonBlue className="edit-button" variant="outlined" size="small" onClick={()=>{setIsOpenDrawerProfile(true)}}><i className="fa-regular fa-pen"></i>แก้ไข</ButtonBlue>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">รหัสบริษัท</Typography>
          <Typography>{vendor.vendorCode || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">เลขประจำตัวผู้เสียภาษี</Typography>
          <Typography>{(vendor.socialSecurityAccount && socialSecurityFormat(vendor.socialSecurityAccount)) || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">ทุนจดทะเบียน</Typography>
          <Typography>{(vendor.registeredCapital && `${Utils.numberWithCommas(vendor.registeredCapital)} บาท`) || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">เลขบัญชีกองทุนสำรองเลี้ยงชีพ</Typography>
          <Typography>{vendor.providentFundCode || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">ปีที่ก่อตั้ง</Typography>
          <Typography>{vendor.establishedYear || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">รายได้บริษัท(ปีล่าสุด)</Typography>
          <Typography>{(vendor.vendorRevenue && `${Utils.numberWithCommas(vendor.vendorRevenue)} บาท`) || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">ชื่อผู้มีอำนาจของบริษัท คนที่ 1</Typography>
          <Typography>{vendor.authorizedSignatureOneName || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">ตำแหน่งผู้มีอำนาจของบริษัท คนที่ 1</Typography>
          <Typography>{vendor.authorizedSignatureOnePosition || "-"}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">ชื่อผู้มีอำนาจของบริษัท คนที่ 2</Typography>
          <Typography>{vendor.authorizedSignatureTwoName || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">ตำแหน่งผู้มีอำนาจของบริษัท คนที่ 2</Typography>
          <Typography>{vendor.authorizedSignatureTwoPosition || "-"}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">ชื่อพยาน</Typography>
          <Typography>{vendor.witnessSignatureName || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">ตำแหน่งพยาน</Typography>
          <Typography>{vendor.witnessSignaturePosition || "-"}</Typography>
        </Grid>
      </Grid>

      {isOpenDrawerProfile && (
        <DrawerProfile
          open={isOpenDrawerProfile}
          onClose={()=>{setIsOpenDrawerProfile(false)}}
          vendor={vendor}
        />
      )}
    </StyledRoot>
  )
};

export default ProfilePanel;
