import {
  HOMEPAGE_GUEST_FETCHING,
  HOMEPAGE_GUEST_FAILED,
  HOMEPAGE_GUEST_SUCCESS,
  HOMEPAGE_COMPANY_FETCHING,
  HOMEPAGE_COMPANY_FAILED,
  HOMEPAGE_COMPANY_SUCCESS,
  HOMEPAGE_VENDOR_FETCHING,
  HOMEPAGE_VENDOR_FAILED,
  HOMEPAGE_VENDOR_SUCCESS,
} from "./types";
import HomepageService from "../services/homepage.service";

export const getHomepageForCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: HOMEPAGE_COMPANY_FETCHING
    });
    const res = await HomepageService.getHomepageForCompany();
    if (res) {
      dispatch({
        type: HOMEPAGE_COMPANY_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: HOMEPAGE_COMPANY_FAILED
    });
  }
};

export const getHomepageForGuest = () => async (dispatch) => {
  try {
    dispatch({
      type: HOMEPAGE_GUEST_FETCHING
    });
    const res = await HomepageService.getHomepageForGuest();
    if (res) {
      dispatch({
        type: HOMEPAGE_GUEST_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: HOMEPAGE_GUEST_FAILED
    });
  }
};

export const getHomepageForVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: HOMEPAGE_VENDOR_FETCHING
    });
    const res = await HomepageService.getHomepageForVendor();
    if (res) {
      dispatch({
        type: HOMEPAGE_VENDOR_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: HOMEPAGE_VENDOR_FAILED
    });
  }
};