import { httpClient } from "./httpClient";

const getAllExpertises = () => {
    return httpClient.get("expertises");
};

const updateVendorExpertise = (formData) => {
    return httpClient.put("expertises/vendorExpertise", formData);
};

export default {
    getAllExpertises,
    updateVendorExpertise
};