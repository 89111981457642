import React, { useState } from "react";
import { Box, IconButton, styled, Typography } from "@mui/material";
import Dropzone from "react-dropzone";
import AddImageIcon from "../../assets/add_image.png"
import ModalCrop from "../../shared/general/ModalCrop";
import { addCompanyAuthorizedSignatureOne, deleteCompanyAuthorizedSignatureOne, getCompanyManage } from "../../../../actions/company";
import { useDispatch } from "react-redux";
import DeleteDialog from "./deleteDialog";

const StyledRoot = styled(Box)({
  "& .dropzone": {
    cursor: "pointer",
    flexShrink: 0,
    width: "100%",
    height: 144,
    boxSizing: "border-box",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .inner-dropzone": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& img": {
        width: 72,
        height: 72,
      }
    }
  },
  "& .image-wrap": {
    textDecoration: "none",
    color: "inherit",
    position: "relative",
    cursor: "pointer",
    flexShrink: 0,
    overflow: "hidden",
    width: "100%",
    height: 144,
    boxSizing: "border-box",
    border: "1px solid #D0D0D0",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    },
    "& .icon-part": {
      flexGrow:1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& i": {
        fontSize: 56,
        color: "#D0D0D0",
        margin: 0,
      }
    },
    "& .delete": {
      position: "absolute",
      top: 0,
      right: 0,
      padding: 8,
      "& i": {
        fontSize: 16,
        margin: 0,
      }
    }
  }
})

const AuthorizedSignatureOne = (props) => {

  const { data } = props;

  const [authorizedSignatureOneImageURL, setAuthorizedSignatureOneImageURL] = useState(data);
  
  const [modalAvatarCropState, setModalAvatarCropState] = useState({
    isOpen: false,
    image: null,
  })

  const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false)

  const dispatch = useDispatch();

  const onComfirmCropAvatar = async (file) => {

    const formData = new FormData();

    formData.append("authorizedSignatureOne", file, "authorizedSignatureOne.png")

    const response = await dispatch(addCompanyAuthorizedSignatureOne(formData))

    if(response.status === 200){
      setModalAvatarCropState({open: false, image: null})
      dispatch(getCompanyManage())
    }
  }

  const onConfirmDelete = async () => {
    const response = await dispatch(deleteCompanyAuthorizedSignatureOne())
    if(response.status === 200){
      setAuthorizedSignatureOneImageURL(null)
      setIsOpenConfirmDeleteDialog(false)
    }
  }

  return (
    <StyledRoot>
      <Typography marginBottom="8px" fontSize={12} color="text.third">ลายเซ็นผู้มีอำนาจของบริษัท คนที่ 1</Typography>

      {!authorizedSignatureOneImageURL && <Dropzone
        onDrop={(acceptedFiles, rejectedFiles) => {
          if(acceptedFiles.length > 0){
            setModalAvatarCropState({
              isOpen: true,
              image: URL.createObjectURL(acceptedFiles[0]),
            })
          }
        }}
        accept="image/jpeg, image/png"
        maxSize={10485760}
      >
        {({
          getRootProps,
          getInputProps,
        }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <Box>
              <input {...getInputProps()}/>
              <Box className="inner-dropzone">
                <img alt="add" src={AddImageIcon} />
              </Box>
            </Box>
          </div>
          )
        }
      </Dropzone>}

      {authorizedSignatureOneImageURL && <Box className="image-wrap" component={"a"} href={authorizedSignatureOneImageURL} target="_blank">
        <img src={authorizedSignatureOneImageURL} alt="authorizedSignatureOne"/>
        <IconButton className="delete" onClick={(e)=>{e.preventDefault(); setIsOpenConfirmDeleteDialog(true)}}>
          <i className="fa-solid fa-trash-can"></i>
        </IconButton>
      </Box>}

      {modalAvatarCropState.isOpen && (
        <ModalCrop
          title="อัพโหลดลายเซ็นผู้มีอำนาจของบริษัท คนที่ 1"
          open={modalAvatarCropState.isOpen}
          onClose={()=>{setModalAvatarCropState({isOpen: false, image: null})}}
          image={modalAvatarCropState.image}
          aspect={16/9}
          imgOutputWidth={640}
          imgOutputHeight={360}
          onConfirm={onComfirmCropAvatar}
        />
      )}

      {isOpenConfirmDeleteDialog && (
        <DeleteDialog open={isOpenConfirmDeleteDialog} onClose={()=>{setIsOpenConfirmDeleteDialog(false)}} onConfirm={onConfirmDelete}/>
      )}

    </StyledRoot>
  )
}

export default AuthorizedSignatureOne