import React from "react";
import { Avatar, styled, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const StyledTable = styled(TableContainer)({
  border: "1px solid rgba(224, 224, 224, 1)",
  borderRadius: 8,
  // borderBottom: "none",
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      marginRight: 8,
    }
  }
})

const TableManpower = () => {
  return(
    <StyledTable>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>ชื่อ-สกุล</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <div className="column-name">
                <Avatar />
                <Typography>หนองมน คนบ้านเดียวกัน</Typography>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </StyledTable>
  )
}

export default TableManpower;