import React, { Fragment, useEffect, useState } from "react";
import { Autocomplete, Chip, Container, Divider, Grid, IconButton, MenuItem, styled, TextField, Typography } from "@mui/material";
import NumberFormat from "react-number-format";
import CardStyle from "../../shared/general/Card";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from "dayjs";
import { getOptionAllTimesColon, getOptionCharge } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getJobGroups } from "../../../../actions/job";
import { addBidding } from "../../../../actions/bidding";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import { useHistory } from "react-router-dom"

const StyledRoot = styled("div")({
  "& .part-form": {
    padding: 24,
    paddingTop: 16,
    "& .autocomplete": {
      "& .MuiInputBase-root": {
        paddingTop: "13.5px",
        paddingBottom: "13.5px",
        paddingLeft: "14px",
      },
      "& input": {
        padding: 0,
      }
    },
    "& .time": {
      "& .MuiAutocomplete-popupIndicator": {
        transform: "none"
      }
    },
    "& .submit-button": {
      lineHeight: 1,
      paddingTop: 16,
      paddingBottom: 16,
    },
    "& .part-position": {
      display: "flex",
      gap: 16,
    },
    "& .number-input": {
      "& input": {
        textAlign: "center",
      }
    },
    "& .dropzone": {
      // marginBottom: 24,
      border: `2px solid rgba(0, 0, 0, 0.23)`,
      borderRadius: 10,
      background: "white",
      minHeight: 100,
      outline: "none",
      padding: 20,
      "& .MuiTypography-root, & svg": {
        color: "#919eab"
      },
      "& .file-show": {
        display: "flex",
        flexWrap: "wrap",
        gap: 10,
      },
      "& .empty-file": {
        minHeight: "inherit",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }
    },
  }
})

const BiddingForm = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [isFetching, setIsFetching] = useState(false);

  const { result: jobGroupsStore } = useSelector(state => state.jobGroups)

  const [jobGroups, setJobGroups] = useState([]);

  const { control, handleSubmit, formState: { errors }, getValues, setValue, watch } = useForm({
    defaultValues: {
      biddingName: "",
      jobGroups: [],
      province: "",
      district: "",
      location: "",
      employeeType: "",
      paymentType: "",
      positions: [
        {positionName: "", quota: ""}
      ],
      jobDescription: "",
      contractTerms: [],
      paymentTerms: [],
      bidType: "1",
      manpowerSendingType: "1",
      manpowerSendingDueDate: null,
      chargeSalaryMax: "ไม่ระบุ",
      chargeOtMax: "ไม่ระบุ",
      chargeHireMax: "ไม่ระบุ",
      chargeCompensationMax: "ไม่ระบุ",
      budgetMax: "",
      biddingStartDate: null,
      biddingStartTime: null,
      biddingEndDate: null,
      biddingEndTime: null,
    }
  })

  const bidTypeWatch = watch("bidType")
  const manpowerSendingTypeWatch = watch("manpowerSendingType")

  const { fields: positionFields, append: positionAppend, remove: positionRemove } = useFieldArray({
    control,
    name: "positions"
  });

  const onDeleteContractTerms = (index) => {
    let arr = getValues("contractTerms");
    arr.splice(index, 1);
    setValue("contractTerms", arr)
  }

  const onDeletePaymentTerms = (index) => {
    let arr = getValues("paymentTerms");
    arr.splice(index, 1);
    setValue("paymentTerms", arr)
  }


  const onSubmit = async (formData) => {
    
    setIsFetching(true)

    const cleansingData = new FormData();

    cleansingData.append("biddingName", formData.biddingName)
    cleansingData.append("province", formData.province)
    cleansingData.append("district", formData.district)
    cleansingData.append("location", formData.location)
    cleansingData.append("employeeType", formData.employeeType)
    cleansingData.append("paymentType", formData.paymentType)
    cleansingData.append("jobGroups", JSON.stringify(formData.jobGroups))
    cleansingData.append("positions", JSON.stringify(formData.positions))
    cleansingData.append("jobDescription", formData.jobDescription)
    cleansingData.append("manpowerSendingType", formData.manpowerSendingType)
    cleansingData.append("manpowerSendingDueDate", JSON.stringify(formData.manpowerSendingDueDate))
    cleansingData.append("bidType", formData.bidType)
    cleansingData.append("chargeSalaryMax", formData.chargeSalaryMax)
    cleansingData.append("chargeOtMax", formData.chargeOtMax)
    cleansingData.append("chargeHireMax", formData.chargeHireMax)
    cleansingData.append("budgetMax", formData.budgetMax)
    cleansingData.append("chargeCompensationMax", formData.chargeCompensationMax)
    cleansingData.append("biddingStartAt", dayjs(new Date(formData.biddingStartDate).setHours(Number(formData.biddingStartTime.split(":")[0]), Number(formData.biddingStartTime.split(":")[1]))).format("YYYY-MM-DD HH:mm:ss"))
    cleansingData.append("biddingEndAt", dayjs(new Date(formData.biddingEndDate).setHours(Number(formData.biddingEndTime.split(":")[0]), Number(formData.biddingEndTime.split(":")[1]))).format("YYYY-MM-DD HH:mm:ss"))

    formData.contractTerms.map((f) => {
      cleansingData.append("contractTerms", f)
    })

    formData.paymentTerms.map((f) => {
      cleansingData.append("paymentTerms", f)
    })

    const response = await dispatch(addBidding(cleansingData))

    setIsFetching(false);

    if(response.status === 200){
      history.push("/company/myjobs")
    }

  }

  useEffect(() => {
    dispatch(getJobGroups())
  }, [])

  useEffect(() => {
    if(jobGroupsStore !== null){
      setJobGroups([...jobGroupsStore])
    }
  }, [jobGroupsStore])

  return(
    <StyledRoot className="page">

      <Container maxWidth="lg">
        <Typography variant="h4" mb="24px">เปิดประมูลสัญญาใหม่</Typography>

        <CardStyle>
          <div className="part-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={`biddingName`}
                    rules={{
                      required: {value: true, message: "กรุณากรอกชื่อรายการ Bidding"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="ชื่อรายการ Bidding"
                        helperText={errors && errors.biddingName && errors.biddingName.message}
                        error={errors && errors.biddingName ? true: false}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={`province`}
                    rules={{
                      required: {value: true, message: "กรุณากรอกจังหวัด"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="จังหวัด"
                        helperText={errors && errors.province && errors.province.message}
                        error={errors && errors.province ? true: false}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={`district`}
                    rules={{
                      required: {value: true, message: "กรุณากรอกอำเภอ"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="อำเภอ"
                        helperText={errors && errors.district && errors.district.message}
                        error={errors && errors.district ? true: false}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={`location`}
                    rules={{
                      required: {value: true, message: "กรุณากรอกสถานที่ทำงาน"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="สถานที่ทำงาน"
                        helperText={errors && errors.location && errors.location.message}
                        error={errors && errors.location ? true: false}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={`employeeType`}
                    rules={{
                      required: {value: true, message: "กรุณาเลือกประเภทพนักงาน"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="ประเภทพนักงาน"
                        select
                        helperText={errors && errors.employeeType && errors.employeeType.message}
                        error={errors && errors.employeeType ? true: false}
                      >
                        <MenuItem value="1">รายวัน</MenuItem>
                        <MenuItem value="2">รายเดือน</MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={`paymentType`}
                    rules={{
                      required: {value: true, message: "กรุณาเลือกประเภทการจ่ายค่าจ้าง"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="ประเภทการจ่ายค่าจ้าง"
                        select
                        helperText={errors && errors.paymentType && errors.paymentType.message}
                        error={errors && errors.paymentType ? true: false}
                        >
                        <MenuItem value="1">15วันครั้ง</MenuItem>
                        <MenuItem value="2">เดือนละครั้ง</MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="jobGroups"
                    rules={{
                      required: {value: true, message: "กรุณากรอกกลุ่มงาน"},
                    }}
                    render={({field})=>(
                      <Autocomplete
                        className="autocomplete"
                        disableClearable
                        options={jobGroups}
                        multiple
                        getOptionLabel={(option) => option.jobGroupName}
                        isOptionEqualToValue={(option, value) => (option.idJobGroup === value.idJobGroup)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.idJobGroup}>
                            {option.jobGroupName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            label="กลุ่มงาน"
                            helperText={errors && errors.jobGroups && errors.jobGroups.message}
                            error={errors && errors.jobGroups ? true: false}
                          />
                        )}
                        // value={field.value.map(v => { return {idJobGroup: v}})}
                        value={field.value}
                        onChange={(_, data) => {
                          field.onChange(data);
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <TotalQuota control={control}/>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {positionFields.map((p, index) => (
                      <Grid key={p.id} item xs={12}>
                        <div className="part-position">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Controller
                                control={control}
                                name={`positions.${index}.positionName`}
                                rules={{
                                  required: {value: true, message: "กรุณากรอกตำแหน่งงาน"},
                                }}
                                render={({field}) => (
                                  <TextFieldTheme
                                    {...field}
                                    label="ตำแหน่งงาน"
                                    helperText={errors && errors.positions && errors.positions[index] && errors.positions[index].positionName && errors.positions[index].positionName.message}
                                    error={errors && errors.positions && errors.positions[index] && errors.positions[index].positionName ? true: false}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Controller
                                control={control}
                                name={`positions.${index}.quota`}
                                rules={{
                                  required: {value: true, message: "กรุณากรอกจำนวน"},
                                  validate: {
                                    moreThanZero: (value) => value > 0 || "กรุณากรอกจำนวนให้ถูกต้อง และต้องมีค่ามากกว่า 0",
                                  }
                                }}
                                render={({field}) => (
                                  <NumberFormat
                                    {...field}
                                    className="number-input"
                                    thousandSeparator=","
                                    allowNegative={false}
                                    isAllowed={(values, sourceInfo) => {
                                      const { value } = values;
                                      return value < 1000;
                                    }}
                                    customInput={TextFieldTheme}
                                    label="จำนวน"
                                    InputProps={{
                                      startAdornment: <IconButton onClick={()=>{if(Number(field.value) > 1) setValue(`positions.${index}.quota`, Number(field.value) - 1, { shouldValidate: true })}}><i className="fa-regular fa-circle-minus"></i></IconButton>,
                                      endAdornment: <IconButton onClick={()=>{if(Number(field.value) < 999) setValue(`positions.${index}.quota`, Number(field.value) + 1, { shouldValidate: true })}}><i className="fa-regular fa-circle-plus"></i></IconButton>
                                    }}
                                    placeholder="จำนวน"
                                    helperText={errors && errors.positions && errors.positions[index] && errors.positions[index].quota && errors.positions[index].quota.message}
                                    error={errors && errors.positions && errors.positions[index] && errors.positions[index].quota ? true: false}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <div>
                            <IconButton onClick={()=>{positionRemove(index)}} disabled={positionFields.length <= 1 ? true: false}><i className="fa-regular fa-trash"></i></IconButton>
                          </div>
                        </div>
                      </Grid>
                    ))}

                    
                    <Grid item xs={12}>
                      <ButtonBlue startIcon={<i className="fa-regular fa-circle-plus"></i>} variant="outlined" fullWidth onClick={()=>{positionAppend({positionName: "", quota: ""})}}>เพิ่มตำแหน่งงาน</ButtonBlue>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={`jobDescription`}
                    rules={{
                      required: {value: true, message: "กรุณากรอกรายละเอียดงาน"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="รายละเอียดงาน"
                        multiline
                        rows={5}
                        helperText={errors && errors.jobDescription && errors.jobDescription.message}
                        error={errors && errors.jobDescription ? true: false}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="contractTerms"
                    rules={{
                      required: { value: true, message: 'กรุณาอัพโหลดรายละเอียดสัญญา' },
                    }}
                    render={({field, fieldState}) => (
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          if(acceptedFiles.length + getValues("contractTerms").length <= 1)
                          setValue("contractTerms", [...getValues("contractTerms"), ...acceptedFiles], {
                            shouldValidate: true,
                          })
                        }}
                        accept="application/pdf"
                        maxFiles={1}
                        multiple
                        maxSize={10485760}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          open,
                          isDragActive,
                          acceptedFiles,
                        }) => (
                          <div>
                            <Typography>รายละเอียดสัญญา / เงื่อนไข</Typography>
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()}/>

                              {getValues("contractTerms").length > 0? (
                                <div className="file-show">
                                  {getValues("contractTerms").map((f, index) => <Chip key={`contractTerms_${index}`} label={f.name} onClick={(e)=>{e.stopPropagation()}} onDelete={()=>{onDeleteContractTerms(index)}}/>)}
                                </div>
                              ) : (
                                <div className="empty-file">
                                  <Typography>คลิก หรือ ลากไฟล์วางไว้ที่นี่</Typography>
                                  <Typography fontSize={12}>*อัพโหลดได้เฉพาะ .pdf เท่านั้น สูงสุด 1 ไฟล์ ไม่เกิน 10MB</Typography>
                                </div>
                              )}
                            </div>

                            {fieldState.error && (
                              <Typography fontSize={12} color="error">{fieldState.error.message}</Typography>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={control}
                        name="manpowerSendingType"
                        rules={{
                          required: {value: true, message: "กรุณากรอกประเภทการส่งคน"},
                        }}
                        render={({field}) => (
                          <TextFieldTheme
                            {...field}
                            label="ประเภทการส่งคน"
                            select
                            onChange={(e) => {
                              setValue("manpowerSendingDueDate", null)
                              field.onChange(e)
                            }}
                            helperText={errors && errors.manpowerSendingType && errors.manpowerSendingType.message}
                            error={errors && errors.manpowerSendingType ? true: false}
                          >
                            <MenuItem value="1">การันตีขั้นต่ำ (ไม่จำกัดเวลา)</MenuItem>
                            <MenuItem value="2">จำกัดเวลา</MenuItem>
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>

                    {manpowerSendingTypeWatch === "2" && <Grid item xs={12} md={6}>
                      <Controller
                        control={control}
                        name="manpowerSendingDueDate"
                        rules={{
                          required: {value: true, message: "กรุณากรอกวันที่กำหนดส่งกำลังพลแล้วเสร็จ"},
                          validate: {
                            dateValidation : (value) => !isNaN(value) || "กรุณากรอกวันที่ให้ถูกต้อง",
                            disblePast: (value) => ((new Date(value)) >= new Date(new Date().setHours(0,0,0,0))) || "ไม่สามารถเลือกวันที่ผ่านมาแล้วได้",
                          }
                        }}
                        render={({field})=>(
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              {...field}
                              label="วันที่กำหนดส่งกำลังพลแล้วเสร็จ"
                              inputFormat="DD MMMM YYYY"
                              disablePast
                              disableMaskedInput
                              onChange={(value) => {
                                field.onChange(value)
                              }}
                              renderInput={(params) => (
                                <TextFieldTheme
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    placeholder: "วัน เดือน ปี"
                                  }}
                                  helperText={errors && errors.manpowerSendingDueDate && errors.manpowerSendingDueDate.message}
                                  error={errors && errors.manpowerSendingDueDate ? true: false}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        control={control}
                        name="bidType"
                        rules={{
                          required: {value: true, message: "กรุณากรอกประเภทการเสนอ"},
                        }}
                        render={({field}) => (
                          <TextFieldTheme
                            {...field}
                            label="ประเภทการเสนอ"
                            select
                            onChange={(e) => {
                              setValue("chargeSalaryMax", "ไม่ระบุ")
                              setValue("chargeOtMax", "ไม่ระบุ")
                              setValue("chargeHireMax", "ไม่ระบุ")
                              setValue("chargeCompensationMax", "ไม่ระบุ")
                              setValue("budgetMax", "")
                              field.onChange(e)
                            }}
                            helperText={errors && errors.bidType && errors.bidType.message}
                            error={errors && errors.bidType ? true: false}
                          >
                            <MenuItem value="1">ค่าชาร์จ</MenuItem>
                            <MenuItem value="2">งบประมาณรายเดือน</MenuItem>
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>

                    {bidTypeWatch === "2" && <Fragment>
                      <Grid item xs={12} md={6}>
                        <Controller
                        control={control}
                        name="budgetMax"
                        render={({field}) => (
                          <NumberFormat
                            {...field}
                            thousandSeparator=","
                            suffix=" บาท"
                            allowNegative={false}
                            customInput={TextFieldTheme}
                            label="งบประมาณรายเดือน ไม่เกิน"
                            onValueChange={(values) => {
                              const {formattedValue, value, floatValue} = values;
                              field.onChange(value)
                            }}
                            onChange={()=>{}}
                            // InputProps={{
                            //   endAdornment: <Typography color="text.secondary">บาท</Typography>
                            // }}
                          />
                        )}
                        />
                      </Grid>
                    </Fragment>}
                  </Grid>
                </Grid>


                {bidTypeWatch === "1" && <Fragment>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="chargeSalaryMax"
                      rules={{
                        required: {value: true, message: "กรุณากรอกชาร์จค่าแรง"},
                      }}
                      render={({field})=>(
                        <Autocomplete
                          className="autocomplete"
                          disableClearable
                          options={getOptionCharge()}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              label="ชาร์จค่าแรง ไม่เกิน"
                              helperText={errors && errors.chargeSalaryMax && errors.chargeSalaryMax.message}
                              error={errors && errors.chargeSalaryMax ? true: false}
                            />
                          )}
                          onChange={(_, data) => {
                            field.onChange(data.value);
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="chargeOtMax"
                      rules={{
                        required: {value: true, message: "กรุณากรอกชาร์จค่าOT"},
                      }}
                      render={({field})=>(
                        <Autocomplete
                          className="autocomplete"
                          disableClearable
                          options={getOptionCharge()}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              label="ชาร์จค่าOT ไม่เกิน"
                              helperText={errors && errors.chargeOtMax && errors.chargeOtMax.message}
                              error={errors && errors.chargeOtMax ? true: false}
                            />
                          )}
                          onChange={(_, data) => {
                            field.onChange(data.value);
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="chargeHireMax"
                      rules={{
                        required: {value: true, message: "กรุณากรอกชาร์จค่าเหมาจ่าย"},
                      }}
                      render={({field})=>(
                        <Autocomplete
                          className="autocomplete"
                          disableClearable
                          options={getOptionCharge()}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              label="ชาร์จค่าเหมาจ่าย ไม่เกิน"
                              helperText={errors && errors.chargeHireMax && errors.chargeHireMax.message}
                              error={errors && errors.chargeHireMax ? true: false}
                            />
                          )}
                          onChange={(_, data) => {
                            field.onChange(data.value);
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="chargeCompensationMax"
                      rules={{
                        required: {value: true, message: "กรุณากรอกชาร์จค่าชดเชย"},
                      }}
                      render={({field})=>(
                        <Autocomplete
                          className="autocomplete"
                          disableClearable
                          options={getOptionCharge()}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              label="ชาร์จค่าชดเชย ไม่เกิน"
                              helperText={errors && errors.chargeCompensationMax && errors.chargeCompensationMax.message}
                              error={errors && errors.chargeCompensationMax ? true: false}
                            />
                          )}
                          onChange={(_, data) => {
                            field.onChange(data.value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Fragment>}

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="paymentTerms"
                    rules={{
                      required: { value: true, message: 'กรุณาอัพโหลดรายละเอียดสัญญา' },
                    }}
                    render={({field, fieldState}) => (
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          if(acceptedFiles.length + getValues("paymentTerms").length <= 1)
                          setValue("paymentTerms", [...getValues("paymentTerms"), ...acceptedFiles], {
                            shouldValidate: true,
                          })
                        }}
                        accept="application/pdf"
                        maxFiles={1}
                        multiple
                        maxSize={10485760}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          open,
                          isDragActive,
                          acceptedFiles,
                        }) => (
                          <div>
                            <Typography>เงื่อนไขการจ่ายเงิน</Typography>
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()}/>

                              {getValues("paymentTerms").length > 0? (
                                <div className="file-show">
                                  {getValues("paymentTerms").map((f, index) => <Chip key={`paymentTerms_${index}`} label={f.name} onClick={(e)=>{e.stopPropagation()}} onDelete={()=>{onDeletePaymentTerms(index)}}/>)}
                                </div>
                              ) : (
                                <div className="empty-file">
                                  <Typography>คลิก หรือ ลากไฟล์วางไว้ที่นี่</Typography>
                                  <Typography fontSize={12}>*อัพโหลดได้เฉพาะ .pdf เท่านั้น สูงสุด 1 ไฟล์ ไม่เกิน 10MB</Typography>
                                </div>
                              )}
                            </div>

                            {fieldState.error && (
                              <Typography fontSize={12} color="error">{fieldState.error.message}</Typography>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    control={control}
                    name="biddingStartDate"
                    rules={{
                      required: {value: true, message: "กรุณากรอกวันเริ่มต้น"},
                      validate: {
                        dateValidation : (value) => !isNaN(value) || "กรุณากรอกวันที่ให้ถูกต้อง",
                        disblePast: (value) => ((new Date(value)) >= new Date(new Date().setHours(0,0,0,0))) || "ไม่สามารถเลือกวันที่ผ่านมาแล้วได้",
                      }
                    }}
                    render={({field})=>(
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          {...field}
                          label="เริ่มต้นการให้ Bidding วันที่"
                          inputFormat="DD MMMM YYYY"
                          disablePast
                          disableMaskedInput
                          onChange={(value) => {
                            field.onChange(value)
                            setValue("biddingStartTime", null)
                          }}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "วัน เดือน ปี"
                              }}
                              helperText={errors && errors.biddingStartDate && errors.biddingStartDate.message}
                              error={errors && errors.biddingStartDate ? true: false}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    control={control}
                    name="biddingStartTime"
                    rules={{
                      required: {value: true, message: "กรุณากรอกเวลาเริ่มต้น"},
                      validate: {
                        disblePast: (value) => ((new Date(getValues("biddingStartDate")).setHours(Number(value.split(":")[0]), Number(value.split(":")[1]))) >= new Date()) || "ไม่สามารถเลือกเวลาผ่านมาแล้วได้",
                      }
                    }}
                    render={({field})=>(
                      <Autocomplete
                        className="autocomplete time"
                        popupIcon={<AccessTimeIcon />}
                        options={getOptionAllTimesColon()}
                        value={field.value}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            label="เวลาเริ่ม"
                            helperText={errors && errors.biddingStartTime && errors.biddingStartTime.message}
                            error={errors && errors.biddingStartTime ? true: false}
                          />
                        )}
                        onChange={(_,data) => {
                          field.onChange(data);
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    control={control}
                    name="biddingEndDate"
                    rules={{
                      required: {value: true, message: "กรุณากรอกวันสิ้นสุด"},
                      validate: {
                        dateValidation : (value) => !isNaN(value) || "กรุณากรอกวันที่ให้ถูกต้อง",
                        disblePast: (value) => ((new Date(value)) >= new Date(new Date().setHours(0,0,0,0))) || "ไม่สามารถเลือกวันที่ผ่านมาแล้วได้",
                        disbleLessThanStart: (value) => ((new Date(value)) >= new Date(new Date(getValues("biddingStartDate")).setHours(0,0,0,0))) || "ไม่สามารถเลือกน้อยกว่าวันที่เริ่มได้",
                      }
                    }}
                    render={({field})=>(
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          {...field}
                          label="สิ้นสุดการให้ Bidding วันที่"
                          inputFormat="DD MMMM YYYY"
                          disablePast
                          disableMaskedInput
                          onChange={(value) => {
                            field.onChange(value)
                            setValue("biddingEndTime", null)
                          }}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "วัน เดือน ปี"
                              }}
                              helperText={errors && errors.biddingEndDate && errors.biddingEndDate.message}
                              error={errors && errors.biddingEndDate ? true: false}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    control={control}
                    name="biddingEndTime"
                    rules={{
                      required: {value: true, message: "กรุณากรอกเวลาสิ้นสุด"},
                      validate: {
                        disblePast: (value) => ((new Date(getValues("biddingEndDate")).setHours(Number(value.split(":")[0]), Number(value.split(":")[1]))) >= new Date()) || "ไม่สามารถเลือกเวลาผ่านมาแล้วได้",
                        disbleLessThanOrEqualStart: (value) => {
                          if(getValues("biddingStartTime")){
                            if ((new Date(getValues("biddingEndDate")).setHours(Number(value.split(":")[0]), Number(value.split(":")[1]))) > (new Date(getValues("biddingStartDate")).setHours(Number(getValues("biddingStartTime").split(":")[0]), Number(getValues("biddingStartTime").split(":")[1])))) {
                              return true;
                            } else {
                              return "กรุณากรอกให้มากกว่าเวลาที่เริ่ม";
                            }
                          }
                        }
                      }
                    }}
                    render={({field})=>(
                      <Autocomplete
                        className="autocomplete time"
                        popupIcon={<AccessTimeIcon />}
                        options={getOptionAllTimesColon()}
                        value={field.value}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            label="เวลาสิ้นสุด"
                            helperText={errors && errors.biddingEndTime && errors.biddingEndTime.message}
                            error={errors && errors.biddingEndTime ? true: false}
                          />
                        )}
                        onChange={(_,data) => {
                          field.onChange(data);
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems="center" justifyContent="flex-end">
                    <ButtonBlue className="submit-button" variant="contained" type="submit" disabled={isFetching}>ลงการประมูล</ButtonBlue>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </CardStyle>
      </Container>
      
    </StyledRoot>
  )
}

const TotalQuota = (props) => {

  const { control } = props;

  const positions = useWatch({
    control,
    name: "positions"
  })

  return (
    // <NumberFormat
    //   className="number-input"
    //   thousandSeparator=","
    //   allowNegative={false}
    //   isAllowed={(values, sourceInfo) => {
    //     const { value } = values;
    //     return value < 1000;
    //   }}
    //   customInput={TextFieldTheme}
    //   label="จำนวนรวม"
    //   value={positions.reduce((partialSum, a) => partialSum + Number(a.quota), 0)}
    //   inputProps={
    //     { readOnly: true, }
    //   }
    //   placeholder="จำนวนรวม"
    // />
    <Typography fontSize={20}>{`จำนวนรวม ${positions.reduce((partialSum, a) => partialSum + Number(a.quota), 0)}`}</Typography>
  )
}

export default BiddingForm;