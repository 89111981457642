import React from "react";
import { CardContent, Skeleton, styled } from "@mui/material";
import CardStyle from "./Card";

const StyledCardContent = styled(CardContent)({
  padding: 24,
  "& a": {
    textDecoration: "none",
    color: "inherit",
  },
  "& .card-header": {
    paddingBottom: 16,
    "& .MuiSkeleton-root": {
      width: 60,
    }
  },
  "& .card-logo": {
    paddingBottom: 16,
    "& .MuiSkeleton-root": {
      width: 180,
      height: 180,
      margin: "0 auto",
    }
  },
  "& .card-vendor-name": {
    textAlign: "center",
    "& .MuiTypography-root": {
      fontSize: 22,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },

  }
})

const CardVendorSkeleton = () => {

  return(
    <CardStyle>
      <StyledCardContent>
        <div className="card-header">
          <Skeleton variant="text" />
        </div>
        <div className="card-logo">
          <Skeleton variant="rectangular"/>
        </div>
        <div className="card-vendor-name">
          <Skeleton variant="text" />
        </div>
      </StyledCardContent>
    </CardStyle>
  )
}

export default CardVendorSkeleton;