import React, { Fragment, useState } from "react";
import { Link, Link as LinkR, NavLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import {
    Avatar,
    Box,
    CardContent,
    Divider,
    ListItemIcon,
    Grid,
    Menu,
    MenuItem,
    styled,
    Typography
} from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyled from "../../shared/general/Card";

const StyledCardContent = styled(CardContent)({
    height:"calc(100% - 48px)",
    position:"relative",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    cursor: "pointer",
    "& .part-company":{
        display:"flex",
        marginBottom:10,
        alignItems:"flexStart"
    },
    "& .part-name": {
        "& .MuiAvatar-root": {
          width: 64,
          height: 64,
          borderRadius: 8,
          backgroundColor: "white",
          marginRight:20,
          "& img": {
            width: "inherit",
            height: "inherit",
            objectFit: "contain",
          },
        }
    },
    "& .part-two": {
        width: "100%",
        marginTop:"10px",
        "& .MuiTypography-h5": {
            width: 280,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    },
    "& .part-three": {
        display: "flex",
        alignItems: "flex-start",
        marginBottom: 10,
    },
    "& .part-four": {
        "& span": {
            marginLeft: 10
        },
    },
    "& .part-manage": {
        marginBottom: 16,
        display: "flex",
        flexDirection:"row-reverse",
        justifyContent: "space-between",
        alignItems: "center",
        position:"absolute",
        right:24,
        top:24
    }
});

const BoxCountVendors = styled(Box)({
  display:"flex", 
  flexDirection:"column", 
  alignItems:"flex-end",
  "& .CountVendors":{
    fontSize:"36px",
    lineHeight:"1",
    fontWeight:"600"
  },
  "& .TextCountVendors":{
    fontSize:"0.75rem",
    color:"#919eab",
    lineHeight:"1.66"
  }
});

const BoxBiddingAt = styled(Box)({
  marginTop: "15px",
  "& .BoxText":{
    display: "flex", 
    flexDirection:"column", 
    alignItems: "center",
    "& .textDate":{
      fontSize:"28px", 
      marginTop:"5px"
    }
  }
});

const BoxContractCompany = styled(Box)({
  marginTop: "15px",
  "& .TopicContract":{
    fontSize:"0.875rem",
    color:"rgb(99, 115, 129)"
  },
  "& .BoxText":{
    color:"rgb(0, 122, 253)",
    "& .Text":{
      fontSize:"1.5rem",
      lineHeight:"2.5"
    }
  }
});

const CardBiddingManager = ({ bidding, isManage, isHistory, isContract }) => {
    const { result : UserProfile } = useSelector((state) => state.userProfile);

    const history = useHistory();
    // const [anchorEl, setAnchorEl] = useState(null);
    // const isMenuOpen = Boolean(anchorEl);

    // const handleMenuClose = () => {
    //     setAnchorEl(null);
    // };

    // const renderMenu = (
    //     <Menu
    //       anchorEl={anchorEl}
    //       open={isMenuOpen}
    //       onClose={handleMenuClose}
    //       PaperProps={{
    //         elevation: 0,
    //         sx: {
    //           overflow: "visible",
    //           filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    //           mt: 1.5,
    //           borderRadius: "20px",
    //           width: "200px",
    //           "& .MuiAvatar-root": {
    //             width: 32,
    //             height: 32,
    //             ml: -0.5,
    //             mr: 1,
    //           },
    //         },
    //       }}
    //       transformOrigin={{ horizontal: "right", vertical: "top" }}
    //       anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    //     >
    //       <MenuItem
    //         // component={LinkR}
    //         // to={{
    //         //   pathname: `/company/myjobs/jobForm`,
    //         //   search: "?edit=1",
    //         //   state: {
    //         //     idJob: value.idJob,
    //         //   }
    //         // }}
    //       >
    //         <ListItemIcon>
    //           <i className="fa-solid fa-pen" ></i>
    //         </ListItemIcon>
    //         แก้ไข
    //       </MenuItem>
    //       {dayjs().isAfter(dayjs(bidding.biddingEndAt)) &&
    //         <MenuItem
    //           component={LinkR} to={`/company-bidding/${bidding.idBiddings}`}
    //         >
    //           <ListItemIcon>
    //             <i className="fa-solid fa-users" />
    //           </ListItemIcon>
    //           คู่ธุรกิจที่ประมูล
    //         </MenuItem>
    //       }
    //     </Menu>
    // );

    const onClickCardHandler = (idBiddings) => {
      history.push(`/bidding/${idBiddings}`)
    }

    return (
      <CardStyled style={{ height: "100%", minHight: "fit-content" }}>
        <div onClick={() => {onClickCardHandler(bidding.idBiddings)}} style={{height: "inherit"}}>
          <StyledCardContent>
            <Box>
              <div className={`part-manage`}>
                {/* {isManager && 
                  <ButtonBlue
                    variant="outlined"
                    startIcon={<i className="fa-regular fa-gear" />}
                    size="small"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    จัดการ
                  </ButtonBlue>
                } */}
                {/* {((!UserProfile || !(UserProfile && UserProfile.idCompany)) && !isContract) && */}
                {/* {((!UserProfile || !(UserProfile && UserProfile.idCompany)) && !isContract) && */}
                  <BoxCountVendors>
                    <Typography className="CountVendors">
                      {bidding.totalQuota || 0}
                    </Typography>
                    <Typography className="TextCountVendors">
                      จำนวนที่รับ
                    </Typography>
                    {/* <Typography className="CountVendors">
                      {bidding.countVendors || 0}
                    </Typography>
                    <Typography className="TextCountVendors">
                      คู่ธุรกิจที่ประมูล
                    </Typography> */}
                  </BoxCountVendors>
                {/* } */}
              </div>
              <div className={`part-name`}>
                <Avatar
                  variant="rounded"
                  // src={
                  //   ((UserProfile && UserProfile.idCompany) ? 
                  //     (bidding.JobGroups && bidding.JobGroups.length) > 0 ? 
                  //       `${process.env.REACT_APP_API_URL}image/jobGroup/${bidding.JobGroups[0]}.png`
                  //       : 
                  //       "/assets/logo_default.png"
                  //     : 
                  //     `${process.env.REACT_APP_API_URL}image/company/${bidding.companyImage}`
                  //   )
                  // }
                  src={bidding.companyImageURL}
                >
                  <img src="/assets/logo_default.png" />
                </Avatar>
                <div className={`part-two`}>
                  <Typography
                    // component={NavLink}
                    // to={`/bidding/${bidding.idBiddings}`}
                    style={{
                      color: "#000000",
                      textDecoration: "none",
                      width: "100%",
                      fontWeight: 400,
                      fontSize: "1.5rem",
                      lineHeight: 1.4,
                      wordBreak: "break-word",
                    }}
                    noWrap={false}
                  >
                    {(bidding && bidding.biddingName) || "-"}
                  </Typography>
                  {(!UserProfile || !UserProfile.idCompany) && 
                    <Typography 
                      style={{ 
                        fontSize: "0.875rem",
                        color:"rgb(0, 122, 253)"
                      }}
                    >
                      {(bidding && bidding.companyName) || "-"}
                    </Typography>
                  }
                </div>
              </div>
            </Box>
            <Box style={{ marginTop:"16px", }}>
              <div className="part-company">
                <i
                  className="fa-solid fa-building"
                  style={{ color: "#637381", marginRight: 8 }}
                />
                <Typography variant="body2" style={{ color: "#637381" }}>
                  {(bidding && `${bidding.district}, ${bidding.province}`) || "-"}
                </Typography>
              </div>
              <div className={`part-three`}>
                <i
                  className="fal fa-map-marker-alt"
                  style={{ color: "#637381", marginRight: 8 }}
                />
                <Typography variant="body2" style={{ color: "#637381" }}>
                  {(bidding && bidding.location) || "-"}
                </Typography>
              </div>
              <div className={`part-four`}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ display: "flex", color:"rgb(99, 115, 129)" }}>
                    <i
                      className="fa-solid fa-calendar-pen"
                      style={{ marginRight: 8 }}
                    />
                    <Typography variant="body2">
                      วันเวลาที่เริ่มประมูล:
                    </Typography>
                  </div>
                  <Box>
                    {bidding && bidding.biddingStartAt ? (
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color:"rgb(99, 115, 129)"
                        }}
                      >
                        <Typography variant="body2">
                          {dayjs(bidding.biddingStartAt).format(
                            "DD-MMM-YYYY ( HH:mm )"
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography variant="body2">
                        ไม่ระบุวันเวลาที่เริ่มการประมูล
                      </Typography>
                    )}
                  </Box>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", color:"rgb(99, 115, 129)" }}>
                    <i
                      className="fa-solid fa-calendar-xmark"
                      style={{ marginRight: 8 }}
                    />
                    <Typography variant="body2">
                      วันเวลาที่ปิดประมูล:
                    </Typography>
                  </div>
                  <Box>
                    {bidding && bidding.biddingEndAt ? (
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color:"rgb(99, 115, 129)"
                        }}
                      >
                        <Typography variant="body2">
                          {dayjs(bidding.biddingEndAt).format(
                            "DD-MMM-YYYY ( HH:mm )"
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography variant="body2">
                        ไม่ระบุวันเวลาที่ปิดการประมูล
                      </Typography>
                    )}
                  </Box>
                </div>
              </div>
              {(isManage || (isHistory && UserProfile.idVendor) || (isContract && UserProfile.idCompany)) &&  
                <Divider
                  style={{
                    margin: "16px 0px 0px",
                    borderWidth: "0px 0px thin",
                    borderColor: "rgba(145, 158, 171, 0.24)",
                    borderStyle: "dashed",
                  }}
                />
              }
              {isManage && 
                <div style={{ marginTop: "15px" }}>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {dayjs().isBefore(dayjs(bidding.biddingStartAt)) && (
                        <Box
                          style={{
                            height: "71px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h5"
                            style={{ marginTop: "5px", color: "#ff9800" }}
                          >
                            ยังไม่เริ่มการประมูล
                          </Typography>
                        </Box>
                      )}
                      {!dayjs().isBefore(dayjs(bidding.biddingStartAt)) &&
                        !dayjs().isAfter(dayjs(bidding.biddingEndAt)) && (
                          <Fragment>
                            <Typography>คู่ธุรกิจที่ประมูล</Typography>
                            <Typography
                              variant="h4"
                              style={{ marginTop: "5px", color: "#1976d2", textDecoration: "none" }}
                              // component={Link}
                              // to={`/company-bidding/${bidding.idBiddings}`}
                              onClick={(e)=>{
                                e.stopPropagation();
                                history.push(`/company-bidding/${bidding.idBiddings}`)
                              }}
                            >
                              {bidding.countVendor || 0}
                            </Typography>
                          </Fragment>
                        )}
                      {dayjs().isAfter(dayjs(bidding.biddingEndAt)) && (
                        <Box
                          style={{
                            height: "71px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h5"
                            style={{ marginTop: "5px", color: "#bdbdbd" }}
                          >
                            จบการประมูล
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </div>
              }
              {(isHistory && UserProfile.idVendor) && 
                <BoxBiddingAt>
                  <Box className="BoxText">
                    <Typography>ประมูลวันที่</Typography>
                    <Typography className="textDate">
                      {dayjs(bidding.biddingAt).format("D MMM YYYY ( HH:mm )")}
                    </Typography>
                  </Box>
                </BoxBiddingAt>
              }
              {(isContract && UserProfile && UserProfile.idCompany) &&
                <BoxContractCompany>
                  <Box>
                    <Typography className="TopicContract">{`คู่ธุรกิจที่สัญญา : `}</Typography>
                    <Box className="BoxText">
                      <Typography className="Text" align="center">{bidding.vendorName.replace("บริษัท","").replace("จำกัด","")}</Typography>
                    </Box>
                  </Box>
                </BoxContractCompany>
              }
            </Box>
          </StyledCardContent>
        {/* {renderMenu} */}
        </div>
      </CardStyled>
    );
};

export default CardBiddingManager;