import {
    EXPERTISES_FETCHING,
    EXPERTISES_FAILED,
    EXPERTISES_SUCCESS
} from "./types";
import ExpertiseService from "../services/expertise.service";

export const getAllExpertises = () => async (dispatch) => {
    try {
        dispatch({
            type: EXPERTISES_FETCHING
        });
        const res = await ExpertiseService.getAllExpertises();
        if(res){
            dispatch({
                type: EXPERTISES_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: EXPERTISES_FAILED
        });
    }
};

export const updateVendorExpertise = (formData) => async () => {
    try {
        const res = await ExpertiseService.updateVendorExpertise(formData);
        if (res) {
            return res;
        }
    } catch (error) {
        const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
        return error;
    }
};