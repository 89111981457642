import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Alert,
    Box,
    Snackbar,
    styled
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import TextFieldTheme from '../../../shared/general/TextFieldTheme';
import ButtonBlue from '../../../shared/general/ButtonBlue';

import { verifyEmail } from '../../../../../actions/auth';

const StyledRoot = styled(Box)({
    "& .item": {
        marginBottom: 8,
    },
    "& .MuiTextField-root": {
        maxWidth: 480,
    },
});

const ChangeEmail = () => {
    const dispatch = useDispatch();
    const [snackbarState, setSnackbarState] = useState({
        isOpen: false,
        severity: "success",
        message: "",
      })

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues:{
            newEmail: ""
        }
    });

    const handleCloseSnackbar = (_, reason) => {
        if(reason === "clickaway"){
          return
        }
        setSnackbarState({
          ...snackbarState,
          isOpen: false,
        });
    };

    const onSubmitHandler = async (formData) => {
        const res = await dispatch(verifyEmail(formData));
        if (res.status === 200) {
            setSnackbarState({
                isOpen: true,
                severity: "success",
                message: "กรุณาไปยืนยันตัวตนที่อีเมล"
            });
            reset()
        } else if(res.status === 400) {
            setSnackbarState({
              isOpen: true,
              severity: "error",
              message: "มีอีเมลนีั้ในระบบ"
            });
        } else {
            setSnackbarState({
                isOpen: true,
                severity: "warning",
                message: "เกิดข้อผิดพลาดของระบบ"
            });
        }
    };

    return (
        <StyledRoot>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Box>
                    <Controller 
                        control={control}
                        name="newEmail"
                        rules={{
                            required: {value: true, message: "กรุณากรอกอีเมล"},
                            pattern: {
                                value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z.]{1,}[A-z]$/,
                                message: "กรุณากรอกอีเมลให้ถูกต้อง เช่น 'antjob@example.com'"
                              }
                        }}
                        render={({ field }) => (
                            <TextFieldTheme 
                                {...field}
                                className="item"
                                label="อีเมลใหม่"
                                autoComplete="email"
                                helperText={errors && errors.newEmail && errors.newEmail.message}
                                error={errors && errors.newEmail ? true: false}
                            />
                        )}
                    />
                </Box>
                <Box className="item">
                    <ButtonBlue variant="contained" type="submit">
                        เปลี่ยนอีเมล
                    </ButtonBlue>
                </Box>
            </form>
            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.isOpen}
                onClose={handleCloseSnackbar}
                onClick={handleCloseSnackbar}
            >
                <Alert severity={snackbarState.severity}>{snackbarState.message}</Alert>
            </Snackbar>
        </StyledRoot>
    );
};

export default ChangeEmail;