import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, Container, Grid, IconButton, Pagination, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import CardBidding from "../shared/CardBidding";

import { getAllBiddings } from "../../../../actions/bidding";
import { getAllWorkingLocations } from "../../../../actions/address";
import { getJobGroups } from "../../../../actions/job";
import { useLocation } from "react-router-dom";

const StyledRoot = styled("div")({
  "& .part-filter": {
    marginBottom: 48,
    padding: 24,
    "& .card-filter":{
      padding: "12px 24px",
      backgroundColor: "#f9fafb",
      borderRadius: 20,
    },
    "& .MuiAutocomplete-root, & .MuiTextField-root": {
      "& input": {
        padding: 0,
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiInputAdornment-root": {
        "& i": {
          fontSize: 24,
          color: "#919EAB",
        }
      },
    }
  },
  "& .part-grid": {
    display: "grid",
    gap: 16,
    gridTemplateColumns: "repeat(3, 1fr)",
    "@media only screen and (max-width: 1200px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media only screen and (max-width: 800px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  "& .part-pagination": {
    paddingTop: 48,
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .status-text": {
    fontSize: 24,
    textAlign: "center",
  }
})

const BoxFilter = styled(Box)({
  display:"flex", 
  alignItems:"center",
  padding: 24,
  backgroundColor: "#f9fafb",
  borderRadius: 20,
  "& .MuiButtonBase-root":{
    "&:hover":{
      backgroundColor:"#f9fafb"
    }
  },
  "& .MuiAutocomplete-root": {
    "& .MuiInputBase-root":{
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  "& .icon": {
    marginRight: 8,
    fontSize: "24px",
    color: "#919EAB"
  },
  "& .MuiTypography-root": {
    position: "relative",
  },
  "& .clear-button": {
    width: 30,
    height: 30,
    position: "absolute",
    right: 0,
    "& i": {
      fontSize: 24,
    }
  },
});

const BiddingSearchPage = () => {

  const dispatch = useDispatch();
  const search = useLocation();

  const { result: BiddingStore, isFetching } = useSelector(state => state.biddings);
  const { result: WorkingLocationStore } = useSelector((state) => state.workingLocation);
  const { result: JobGroupStore } = useSelector((state) => state.jobGroups);

  const [biddings, setBiddings] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  let formChangeTime;

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      biddingName: "",
      companyName: "",
      jobGroup: null,
      province: null,
      page: 1,
    }
  })

  useEffect(() => {
    let province = new URLSearchParams(search.search).get("province");
    let job_group = new URLSearchParams(search.search).get("job_group");

    if(!(province || job_group)){
      dispatch(getAllBiddings())
    }

    dispatch(getAllWorkingLocations())
    dispatch(getJobGroups())
  }, [])

  useEffect(() => {
    let province = new URLSearchParams(search.search).get("province");
    let job_group = new URLSearchParams(search.search).get("job_group");

    if(WorkingLocationStore && JobGroupStore ){

      console.log("test")

      if(province !== null){
        let provinceVal = WorkingLocationStore.find(location => location.workingLocationNameTH.includes(province));
        setValue("province",provinceVal);
      }

      if(job_group !== null){
        let jobGroupVal = JobGroupStore.find(group => parseInt(group.idJobGroup) === parseInt(job_group));
        setValue("jobGroup",jobGroupVal);
      }

      handleSubmit(onSubmit)();
    }


  }, [WorkingLocationStore, JobGroupStore])

  useEffect(() => {
    if(BiddingStore !== null){
      setBiddings(BiddingStore.data);
      if(BiddingStore.total > 0){
        setPageCount(Math.ceil(BiddingStore.total / 12))
      }
    }
  }, [BiddingStore])

  const onFormChange = () => {

    setValue("page", 1)
    clearTimeout(formChangeTime)
    formChangeTime = setTimeout(() => {
      handleSubmit(onSubmit)()
    }, 700)
  }

  const onSubmit = (formData) => {
    const query = {
      company_name: formData.companyName? formData.companyName.trim(): undefined,
      bidding_name: formData.biddingName? formData.biddingName.trim(): undefined,
      page: formData.page? formData.page: 1,
      province: (formData.province && formData.province.workingLocationNameTH)? formData.province.workingLocationNameTH: undefined,
      job_group: (formData.jobGroup && formData.jobGroup.idJobGroup)? formData.jobGroup.idJobGroup: undefined,
    }
    dispatch(getAllBiddings(query))
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="part-filter">
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <BoxFilter>
                  <i className="fa-light fa-square-list icon"></i>
                  <Controller
                    control={control}
                    name="biddingName"
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        placeholder="ชื่อการประมูล"
                        autoComplete="biddingName"
                        InputProps={{
                          endAdornment: (field.value.length > 0)? 
                              (<IconButton
                                className="clear-button"
                                onClick={()=>{
                                  field.onChange("");
                                  handleSubmit(onSubmit)();
                                }}
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </IconButton>)
                            : 
                              ""
                        }}
                        onChange={(e)=>{
                          field.onChange(e.target.value);
                          onFormChange()
                        }}
                      />
                    )}
                  />
                </BoxFilter>
              </Grid>

              <Grid item xs={12} md={6}>
                <BoxFilter>
                  <i className="fa-light fa-building-user icon"></i>
                  <Controller
                    control={control}
                    name="companyName"
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        placeholder="ชื่อบริษัท"
                        autoComplete="companyName"
                        InputProps={{
                          endAdornment: (field.value.length > 0)? 
                              (<IconButton
                                className="clear-button"
                                onClick={()=>{
                                  field.onChange("");
                                  handleSubmit(onSubmit)();
                                }}
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </IconButton>)
                            : 
                              ""
                        }}
                        onChange={(e)=>{
                          field.onChange(e.target.value);
                          // handleSubmit(onSubmit)();
                          onFormChange()
                        }}
                      />
                    )}
                  />
                </BoxFilter>
              </Grid>

              <Grid item xs={12} md={6}>
                <BoxFilter>
                  <i className="fa-light fa-briefcase icon"></i>
                  <Controller
                    control={control}
                    name="jobGroup"
                    render={({field}) => (
                      <Autocomplete
                        fullWidth
                        options={JobGroupStore || []}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => option.jobGroupName}
                        noOptionsText="ไม่พบผลลัพธ์"
                        isOptionEqualToValue={(option, value) => option.jobGroupName === value.jobGroupName}
                        renderOption={(props, option) => (
                          <li {...props}>
                            {option.jobGroupName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            placeholder="ประเภทกลุ่มงาน"
                            InputProps={{
                              ...params.InputProps
                            }}
                          />
                        )}
                        onChange={(_, data) => {
                          field.onChange(data);
                          handleSubmit(onSubmit)()
                        }}
                        value={field.value}
                      />
                    )}
                  />
                </BoxFilter>
              </Grid>

              <Grid item xs={12} md={6}>
                <BoxFilter>
                  <i className="fa-light fa-location-dot icon"></i>
                  <Controller
                    control={control}
                    name="province"
                    render={({field}) => (
                      <Autocomplete
                        fullWidth
                        options={WorkingLocationStore || []}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => option.workingLocationNameTH}
                        noOptionsText="ไม่พบผลลัพธ์"
                        isOptionEqualToValue={(option, value) => option.workingLocationNameTH === value.workingLocationNameTH}
                        renderOption={(props, option) => (
                          <li {...props}>
                            {option.workingLocationNameTH}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            placeholder="จังหวัด"
                            InputProps={{
                              ...params.InputProps
                            }}
                          />
                        )}
                        onChange={(_, data) => {
                          field.onChange(data);
                          handleSubmit(onSubmit)()
                        }}
                        value={field.value}
                      />
                    )}
                  />
                </BoxFilter>
              </Grid>

            </Grid>
          </form>
        </div>

        {(isFetching) && (
          <Typography className="status-text">กำลังโหลดข้อมูล</Typography>
        )}

        {(!isFetching && biddings.length <= 0) && (
          <Typography className="status-text">ไม่พบผลลัพธ์การค้นหา</Typography>
        )}

        {(!isFetching && biddings.length > 0) && (
          <div className="part-grid">
            {biddings.map((bidding, index) => (
              <CardBidding key={`bidding_${index}`} bidding={bidding}/>
            ))}
          </div>
        )}

        {biddings.length > 0 && <div className="part-pagination">
          <Controller
            control={control}
            name="page"
            render={({field})=>(
              <Pagination
                count={pageCount}
                page={field.value}
                shape="rounded"
                onChange={(_, page) => {
                  if(field.value !== page){
                    field.onChange(page)
                    handleSubmit(onSubmit)()
                  }
                }}
              />
            )}
          />
        </div>}
      </Container>
    </StyledRoot>
  )
}

export default BiddingSearchPage;