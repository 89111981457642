import React from "react";
import { styled, Typography } from "@mui/material";
import ButtonBlue from "../shared/general/ButtonBlue";
import { Link } from "react-router-dom";

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "calc(100vh - 100px)",
  "& .part-text": {
    margin: "auto",
    paddingTop: 48,
    textAlign: "center",
    "& .success": {
      fontSize: 48,
      paddingBottom: 24,
    },
    "& .home-button": {
      marginTop: 24,
    }
  }
})

const CreatePasswordSuccess = () => {
  return(
    <StyledRoot>
      <div className="part-text">
        <Typography className="success">ยืนยันตัวตนสำเร็จ</Typography>
        <ButtonBlue className="home-button" variant="outlined" component={Link} to="/login">เข้าสู่ระบบ</ButtonBlue>
      </div>
    </StyledRoot>
  )
}

export default CreatePasswordSuccess;