import {
  ANTHR_EMPLOYEES_FETCHING,
  ANTHR_EMPLOYEES_FAILED,
  ANTHR_EMPLOYEES_SUCCESS,
} from "./types";
import AntHRService from "../services/anthr.service";

export const getAntHREmployees = () => async (dispatch) => {
  try {
    dispatch({
      type: ANTHR_EMPLOYEES_FETCHING
    });
    const res = await AntHRService.getAntHREmployees();
    if (res) {
      dispatch({
        type: ANTHR_EMPLOYEES_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: ANTHR_EMPLOYEES_FAILED
    });
  }
};