import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Avatar, Box, Container, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import dayjs from 'dayjs';

import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { getInterviewedCandidatesByIdVendor } from "../../../../actions/candidate";

const StyledRoot = styled("div")({
  "& .part-header": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .part-total": {
      textAlign: "right",
    }
  },
  "& .part-filter": {
    marginBottom: 24,
    "& .search-name": {
      width: 250,
    }
  },
  "& .part-action": {
    display: "flex",
    justifyContent: "right",
  }
})

const StyledTable = styled(TableContainer)({
  maxHeight: 600,
  "& .MuiTableRow-root": {
    "& .MuiTableCell-root": {
      backgroundColor: "#ffffff",
      borderBottom: "none",
      zIndex: 2,
      "&:first-of-type": {
        position: "sticky",
        left: 0,
        zIndex: 4,
        boxShadow: "rgb(238 238 238) 8px 20px 20px",
      }
    }
  },
  "& .MuiTableHead-root": {
    borderRadius: 20,
    "& .MuiTableCell-root": {
      color: "rgb(99, 115, 129)",
      backgroundColor: "rgb(244, 246, 248)",
      "&:first-of-type": {
        zIndex: 5,
        boxShadow: "rgb(238 238 238) 20px -10px 20px",
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      "&:last-of-type": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    }
  },
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      width: 40,
      height: 40,
      marginRight: 8,
    }
  }
})

const SplitStartTime = (params) => {
  let splitTime = params.appointmentStartTime.split(":");
  return dayjs().hour(splitTime[0]).minute(splitTime[1]).format("HH.mm");
};

const columns = [
  {
    field: "name",
    headerName: "ชื่อ-สกุล",
    width: 250,
    format: (params) => (
      <Box className="column-name" component={Link} to={`/manpower/profile/${params.idManpower}`} style={{ textDecoration:"none", color:"black" }}>
        <Avatar src={`${process.env.REACT_APP_API_URL}image/profile/${parseInt(Math.random()*20)+1}.jpg`}/>
        <Typography>{`${params.name} ${params.lastName}`}</Typography>
      </Box>
    )
  },
  {
    field: "position",
    headerName: "ตำแหน่งงานที่เรียกสัมภาษณ์",
    width: 300,
    format: (params) => (
      <Typography>{params.positionName}</Typography>
    )
  },
  {
    field: "company",
    headerName: "บริษัทที่เรียกสัมภาษณ์",
    width: 350,
    format: (params) => (
      <Typography>{params.companyName}</Typography>
    )
  },
  {
    field: "interview",
    headerName: "การนัดสัมภาษณ์",
    width: 320,
    format: (params) => (
      <Box>
        <Typography>{`${dayjs(params.appointmentDate).format("DD MMM YYYY")} (${SplitStartTime(params)})`}</Typography>
        <Typography>{params.appointmentPlace}</Typography>
      </Box>
    )
  },
  {
    field: "contact",
    headerName: "การติดต่อ",
    width: 250,
    format: (params) => (
        <Box>
          <Typography>{params.contactName}</Typography>
          <Typography>{params.contactPhone}</Typography>
        </Box>
    )
  }
]

const VendorInterviewPage = () => {
  const dispatch = useDispatch();
  const { result: ListInterviewedCandidate } = useSelector(state => state.interviewedCandidate);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterCandidate, setFilterCandidate] = useState([]);
  const [searchName, setSearchName] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSearchName = (event) => {
    setSearchName(event.target.value);
  }

  useEffect(() => {
    dispatch(getInterviewedCandidatesByIdVendor());
  },[]);

  useEffect(()=>{
    if(ListInterviewedCandidate && ListInterviewedCandidate.length > 0){
      setFilterCandidate(ListInterviewedCandidate.filter(candidate => candidate.name.includes(searchName)));
    }
  }, [ListInterviewedCandidate, searchName])

  return(
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="part-header">
          <Typography variant="h4">รายชื่อผู้ถูกเรียกสัมภาษณ์</Typography>
          <div className="part-total">
            <Typography variant="h4">{ListInterviewedCandidate && ListInterviewedCandidate.length || 0}</Typography>
            <Typography color="text.secondary">ผู้ถูกเรียกสัมภาษณ์ทั้งหมด</Typography>
          </div>
        </div>

        <div className="part-filter">
          <TextFieldTheme
            className="search-name"
            placeholder="ค้นหา..."
            value={searchName}
            onChange={handleChangeSearchName}
          />
        </div>

        <StyledTable>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align={column.align}
                    style={{minWidth: column.width, maxWidth: column.width, width: column.width}}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
            {filterCandidate && filterCandidate.length > 0 ?
              filterCandidate.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.idCandidate}>
                  {columns
                  .map((column) => (
                    <TableCell key={column.field} align={column.align}>
                      {
                        column.format(row)
                      }
                    </TableCell>
                  ))}
                </TableRow>
              ))
              :
              <TableRow style={{ height: 300 }}>
                <TableCell align="center">
                  <Typography>ไม่พบรายชื่อพนักงาน</Typography>
                </TableCell>
                <TableCell colSpan={5}  align="center">
                  <Typography>ไม่พบรายชื่อพนักงาน</Typography>
                </TableCell>
              </TableRow>
            }
            </TableBody>
          </Table>
        </StyledTable>
        <TablePagination
          rowsPerPageOptions={[2, 10, 25, 100]}
          component="div"
          count={filterCandidate.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
    </StyledRoot>
  )
}

export default VendorInterviewPage;