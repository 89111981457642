import {
  VENDOR_SEARCH_FETCHING,
  VENDOR_SEARCH_FAILED,
  VENDOR_SEARCH_SUCCESS
} from "../actions/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
      case VENDOR_SEARCH_FETCHING:
          return { ...state, result: null, isFetching: true, isError: false };
      case VENDOR_SEARCH_FAILED:
          return { ...state, result: null, isFetching: false, isError: true };
      case VENDOR_SEARCH_SUCCESS:
          return { ...state, result: payload, isFetching: false, isError: false };
      default:
          return state;
  }
};