import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import dayjs from 'dayjs';

import {
	Autocomplete,
	Box,
	Divider,
	Grid,
	IconButton,
	styled,
	Typography
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
	Add,
	Delete
} from "@mui/icons-material";

import ButtonBlue from '../../../shared/general/ButtonBlue';
import DrawerCustom from '../../../shared/general/Drawer';
import TextFieldTheme from '../../../shared/general/TextFieldTheme';
import { getManpowerById, addManpowerExperience, updateManpowerExperience } from '../../../../../actions/manpower';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

const StyledRoot = styled("div")({
	width: 450,
	padding: 16,
});

const StyledDivider = styled(Divider)({
	margin: "15px 0",
	borderBottom: "2px dashed rgba(145, 158, 171, 0.2)"
});

const DrawerExperience = (props) => {
	const dispatch = useDispatch();
	const { open, onClose, idManpower, isEdit, data, idManpowerExperience } = props;
	const { result: AllJobGroups } = useSelector((state) => state.jobGroups);

	const { control, handleSubmit, getValues, formState: { errors, dirtyFields } } = useForm({

		...(!isEdit && {
				defaultValues: {
					startYear: null,
					endYear: null,
					companyName: "",
					positionName: "",
					jobGroup: null,
			}
		}),

		...(isEdit && data && {
			defaultValues: {
				startYear: data.startYear? dayjs().year(data.startYear): null,
				endYear: data.endYear? dayjs().year(data.endYear): null,
				companyName: data.companyName || "",
				positionName: data.positionName || "",
				jobGroup: AllJobGroups.find(j => j.idJobGroup === data.idJobGroup) || null,
			}
		}),
		
	});

	const handleClose = () => {
		onClose();
	};

	const onSubmitHandler = async (submitData) => {
		if(!isEdit) {
			const formData = {};

			for (const key in submitData) {
				if(key === 'jobGroup'){
					formData['idJobGroup'] = submitData['jobGroup'].idJobGroup || "";
					continue;
				}
				if(key === 'startYear' || key === 'endYear') {
					formData[key] = dayjs(submitData[key]).get("year");
					continue;
				}
				formData[key] = submitData[key];
			}

			dispatch(addManpowerExperience(idManpower, formData)).then(res => {
				dispatch(getManpowerById(idManpower));
			})
			onClose();

		} else {

			const formData = {};

			for (const key in dirtyFields) {
				if(key === 'jobGroup'){
					formData['idJobGroup'] = submitData['jobGroup'].idJobGroup || "";
					continue
				}
				if(key === 'startYear' || key === 'endYear') {
					formData[key] = dayjs(submitData[key]).get("year");
					continue;
				}
				formData[key] = submitData[key];
			}
			dispatch(updateManpowerExperience(idManpower, idManpowerExperience, formData)).then(res => {
				dispatch(getManpowerById(idManpower));
			})
			onClose();

		}
	};

	return (
		<DrawerCustom
			open={open}
			title={isEdit ? "แก้ไขประสบการณ์ทำงาน" : "เพิ่มประสบการณ์ทำงาน"}
			anchor="right"
		>
			<StyledRoot>
				<form onSubmit={handleSubmit(onSubmitHandler)}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Controller
								name={`startYear`}
								control={control}
								rules={{
									// required: {value: true, message: "กรุณาเลือกปีที่เริ่มงาน"},
									validate: {
										endDateMoreThanstart: () => {
											if(!getValues(`startYear`) || !getValues(`endYear`)){
												return true;
											} else if(dayjs(getValues(`endYear`)).isSameOrAfter(dayjs(getValues(`startYear`)), 'year')){
												return true;
											} else {
												return "ไม่สามารถเลือกวันออกจากงานก่อนวันเริ่มเริ่มงานได้"
											}
										}
									}
								}}
								render={({ field }) => (
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DesktopDatePicker
											{...field}
											label="เริ่มงาน"
											views={["year"]}
											openTo="year"
											maxDate={dayjs()}
											renderInput={(params) => (
												<TextFieldTheme
													{...params}
													inputProps={{
														...params.inputProps,
														placeholder: "",
														readOnly: true
													}}
													helperText={errors && errors.startYear && errors.startYear.message}
													error={errors && errors.startYear ? true : false}
												/>
											)}
										/>
									</LocalizationProvider>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								name={`endYear`}
								control={control}
								rules={{
									// required: {value: true, message: "กรุณาเลือกปีที่ออกจากงาน"},
									validate: {
										endDateMoreThanstart: () => {
											if(!getValues(`startYear`) || !getValues(`endYear`)){
												return true;
											} else if(dayjs(getValues(`endYear`)).isSameOrAfter(dayjs(getValues(`startYear`)), 'year')){
												return true;
											} else {
												return "ไม่สามารถเลือกวันออกจากงานก่อนวันเริ่มเริ่มงานได้"
											}
										}
									}
								}}
								render={({ field }) => (
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DesktopDatePicker
											{...field}
											label="ออกจากงาน"
											views={["year"]}
											openTo="year"
											maxDate={dayjs()}
											renderInput={(params) => (
												<TextFieldTheme
													{...params}
													inputProps={{
														...params.inputProps,
														placeholder: "",
														readOnly: true
													}}
													helperText={errors && errors.endYear && errors.endYear.message}
													error={errors && errors.endYear ? true : false}
												/>
											)}
										/>
									</LocalizationProvider>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={`companyName`}
								control={control}
								rules={{
									required: { value: true, message: "กรุณากรอกชื่อบริษัท" },
								}}
								render={({ field }) => (
									<TextFieldTheme
										{...field}
										label="ชื่อบริษัท"
										helperText={errors && errors.companyName && errors.companyName.message}
										error={errors && errors.companyName ? true : false}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={`positionName`}
								control={control}
								rules={{
									required: { value: true, message: "กรุณากรอกตำแหน่งงาน" },
								}}
								render={({ field }) => (
									<TextFieldTheme
										{...field}
										label="ตำแหน่งงาน"
										helperText={errors && errors.positionName && errors.positionName.message}
										error={errors && errors.positionName ? true : false}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name={`jobGroup`}
								control={control}
								rules={{
									required: { value: true, message: "กรุณากรอกกลุ่มงาน" },
								}}
								render={({ field, formState }) => (
									<Autocomplete
										sx={{
											"& .MuiInputBase-root": {
												paddingLeft: 0,
												paddingTop: 0,
												paddingBottom: 0,
												"& input": {
													padding: "13.5px !important"
												}
											}
										}}
										options={[...AllJobGroups].sort((a, b) => a.jobGroupName.localeCompare(b.jobGroupName))}
										getOptionLabel={(option) => option.jobGroupName}
										isOptionEqualToValue={(option, value) => (option.idJobGroup === value.idJobGroup)}
										renderOption={(props, option) => (
											<li {...props} key={option.idJobGroup}>
												{option.jobGroupName}
											</li>
										)}
										renderInput={(params) => (
											<TextFieldTheme
												label="กลุ่มงาน"
												{...params}
												error={formState.errors && formState.errors.jobGroup ? true : false}
												helperText={formState.errors && formState.errors.jobGroup ? formState.errors.jobGroup.message : null}
											/>
										)}
										noOptionsText="ไม่พบคำที่ค้นหา"
										value={field.value}
										onChange={(_, data) => {
											field.onChange(data);
										}}
									/>
								)}
							/>
						</Grid>
					</Grid>

					<div style={{ display: "flex", justifyContent: "space-between", margin: "20px 0" }}>
						<ButtonBlue
							variant="outlined"
							onClick={handleClose}
						>ยกเลิก</ButtonBlue>
						<ButtonBlue
							type="submit"
							variant="contained"
							disabled={(isEdit && Object.keys(dirtyFields).length <= 0)}
						>ยืนยัน</ButtonBlue>
					</div>
				</form>
			</StyledRoot>
		</DrawerCustom>
	);
};

export default DrawerExperience;