import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import { Typography } from "@mui/material";

import ButtonBlue from "../shared/general/ButtonBlue";
import CardRequest from "../shared/general/CardRequest";
import CardRequestSkeleton from "../shared/general/CardRequestSkeleton";

const StyledRoot = styled("div")({
  "& a": {
    textDecoration: "none",
  },
  "&.request": {
    paddingBottom: 64,
    "& .request-head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 16,
      ["@media only screen and (max-width: 600px)"]:{
        flexDirection: "column"
      }
    },
    "& .wrap-card": {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      columnGap: 12,
      ["@media only screen and (max-width: 600px)"]:{
        gridTemplateColumns: "repeat(1, 1fr)",
      }
    },
  },
});

const Request = (props) => {
  const { request, isFetching, isCompany } = props;

  return (
    <StyledRoot className="request">
      <div className="request-head">
        <Typography variant="h4" gutterBottom>
          {
            isCompany? `งานที่เปิดล่าสุด`
            : `รับสมัครงาน`
          }
        </Typography>
        <div>
          <ButtonBlue
            component={Link}
            to={`/jobs`}
          >
            ทั้งหมด{" "}
            <i
              style={{ lineHeight: 0, paddingLeft: 6 }}
              className="fi fi-br-angle-small-right"
            ></i>
          </ButtonBlue>
        </div>
      </div>

      {/* <div className="wrap-card">
        {request.length > 0 && !isFetching? 
          request.map((value, index) => {
            return <CardRequest key={index} value={value}></CardRequest>;
          })
          : request.length === 0 && isFetching?
            (
              <Fragment>
                <CardRequestSkeleton />
                <CardRequestSkeleton />
                <CardRequestSkeleton />
              </Fragment>
            )
          : <Typography>ไม่พบการประกาศจ้างแรงงาน</Typography>
        }
      </div> */}

      {isFetching && (
        <div className="wrap-card">
          <CardRequestSkeleton />
          <CardRequestSkeleton />
          <CardRequestSkeleton />
        </div>
      )}

      {!isFetching && request.length > 0 && (
        <div className="wrap-card">
          {request.map((value, index) => {
            return <CardRequest key={index} value={value}></CardRequest>;
          })}
        </div>
      )}

      {!isFetching && request.length === 0 && (
        <Fragment>
          {isCompany? 
              // <Typography variant="h5" textAlign="center" pt="48px">เปิดรับสมัครเลย</Typography>
              <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Link to="/company/myjobs/jobForm">
                  <ButtonBlue variant="outlined" startIcon={<i className="fa-solid fa-plus" style={{fontSize: "inherit"}}></i>} style={{fontSize: 24}}>เปิดรับสมัครเลย</ButtonBlue>
                </Link>
              </div>
            : <Typography variant="h5" textAlign="center" pt="48px">ไม่พบการเปิดรับสมัครงาน</Typography>
          }
        </Fragment>
      )}
    </StyledRoot>
  );
};

export default Request;
