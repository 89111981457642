import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Box, Grid } from '@mui/material';

import DrawerCustom from '../../../shared/general/Drawer';
import TextFieldTheme from '../../../shared/general/TextFieldTheme';
import ButtonBlue from '../../../shared/general/ButtonBlue';
import { useDispatch } from 'react-redux';
import { updateManpower, getManpowerById } from '../../../../../actions/manpower';

const DrawerAbout = (props) => {
  const { open, onClose, data, idManpower } = props;
  const { handleSubmit, control, formState: { errors, dirtyFields, isDirty } } = useForm({
    defaultValues: {
      about: data.about || "",
    }
  });

  const dispatch = useDispatch();

	const HandlerSubmit = (submitData) => {
    const formData = {};

    for (const key in dirtyFields) {
      formData[key] = submitData[key];
    }
    dispatch(updateManpower(idManpower, formData)).then(res => {
      dispatch(getManpowerById(idManpower));
    })
    onClose();
  }

  return (
    <DrawerCustom
      open={open}
      title={"แก้ไขนำแนะตัว"}
      anchor="right"
    >
      <Box style={{ width: 400, padding: 16 }}>
        <form onSubmit={handleSubmit(HandlerSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="about"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label="แนะนำตัว"
                    fullWidth
                    multiline
                    minRows={4}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <ButtonBlue
                  variant="outlined"
                  onClick={onClose}
                >ยกเลิก</ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={!isDirty}
                >ยืนยัน</ButtonBlue>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </DrawerCustom>
  );
};

export default DrawerAbout;