import {
  SIGNUP_COMPANY_FORM_FETCHING,
  SIGNUP_COMPANY_FORM_FAILED,
  SIGNUP_COMPANY_FORM_SUCCESS
} from "./types";
import signupService from "../services/signup.service";

export const signupCompany = (formData) => async () => {
  try {
    const res = await signupService.signupCompany(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getSignupCompanyForm = () => async (dispatch) => {
  try {
    dispatch({
      type: SIGNUP_COMPANY_FORM_FETCHING
    });
    const res = await signupService.getSignupCompanyForm();
    if (res) {
      dispatch({
        type: SIGNUP_COMPANY_FORM_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: SIGNUP_COMPANY_FORM_FAILED
    });
  }
};

export const signupVendor = (formData) => async () => {
  try {
    const res = await signupService.signupVendor(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const verifyEmail = (query) => async () => {
  try {
    const res = await signupService.verifyEmail(query);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const createPassword = (formData) => async () => {
  try {
    const res = await signupService.createPasword(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};