import React from "react";
import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const StyledRoot = styled("div")({
  "& .part-company-contact-count": {
    marginBottom: 16,
    textAlign: "center",
    fontSize: 36,
    fontWeight: 500,
    "& .MuiTypography-root": {
      fontSize: "inherit",
      fontWeight: "inherit",
    }
  }
})

const StyledTable = styled("table")({
  width: "100%",
  tableLayout: "fixed",
  "& th": {
    "& .MuiTypography-root": {
      fontWeight: 500
    }
  },
  "& td, & th": {
    padding: "16px 0",
    borderBottom: "solid 1px #ececec"
  },
  "& .col-company-name": {
    width: "70%",
    textAlign: "left",
    "& .MuiTypography-root": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    }
  },
  "& .col-openjob-count": {
    width: "30%",
    textAlign: "right",
    "& .MuiTypography-root": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    }
  },
})

const ContactCompany = (props) => {

  const {count, companyContractList} = props;

  return(
    <StyledRoot>
      <div className="part-company-contact-count">
        <Typography>{count}</Typography>
      </div>

      <StyledTable>
        <thead>
          <tr>
            <th className="col-company-name">
              <Typography>ชื่อบริษัท</Typography>
            </th>
            <th className="col-openjob-count">
              <Typography>จำนวนงานที่เปิดรับ</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {companyContractList && companyContractList.map((company, index) => (
            <tr key={`company_${index}`}>
              <td className="col-company-name">
                <Typography>{company.companyNameShort}</Typography>
              </td>
              <td className="col-openjob-count">
                <Typography>{company.openJobCount}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </StyledRoot>
  )
}

export default ContactCompany;