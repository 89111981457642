import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Container,
  styled,
  Typography
} from "@mui/material";

import BiddingContract from './biddingContract';
import VendorContract from './vendorContract';

import { getMyContractCompany } from "../../../../actions/bidding";
// import { getMyVendorContracts } from '../../../../actions/adminCompany';

const StyledRoot = styled("div")({
  "& .header-text": {
    marginBottom: 24,
  },
});

const CompanyMyContract = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyContractCompany());
    // dispatch(getMyVendorContracts());
  }, []);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography className="header-text" variant="h4">
          สัญญาของฉัน
        </Typography>
        <BiddingContract type="LimitTime" />
        <BiddingContract type="Guarantee" />
        {/* <VendorContract /> */}
      </Container>
    </StyledRoot>
  );
};

export default CompanyMyContract;