const branch = [
  {
    idCompany: 1,
    branchName: "บริษัท ปูนซิเมนต์ไทย จำกัด (มหาชน) สาขาระยอง",
    address: "เลขที่ 99 ถนนตัดเก่า แขวงบางครั้ง เขตฐานทัพ 10100 กรุงเทพมหานคร ประเทศไทย",
    mainContactName: "หนองมน คนบ้านเดียวกัน",
    mainContactPhone: "0999999999",
    mainContactEmail: "nongmon@gmail.com",
  },
  {
    idCompany: 1,
    branchName: "บริษัท ปูนซิเมนต์ไทย จำกัด (มหาชน) สาขาเชียงใหม่",
    address: "เลขที่ 99 ถนนตัดเก่า แขวงบางครั้ง เขตฐานทัพ 10100 กรุงเทพมหานคร ประเทศไทย",
    mainContactName: "บางรัก ซอยเก้า",
    mainContactPhone: "0999999999",
    mainContactEmail: "nongmon@gmail.com",
  },
  {
    idCompany: 1,
    branchName: "บริษัท ปูนซิเมนต์ไทย จำกัด (มหาชน) สาขาเชียงราย",
    address: "เลขที่ 99 ถนนตัดเก่า แขวงบางครั้ง เขตฐานทัพ 10100 กรุงเทพมหานคร ประเทศไทย",
    mainContactName: "บางรัก ซอยเก้า",
    mainContactPhone: "0999999999",
    mainContactEmail: "nongmon@gmail.com",
  },
  {
    idCompany: 1,
    branchName: "บริษัท ปูนซิเมนต์ไทย จำกัด (มหาชน) สาขาตราด",
    address: "เลขที่ 99 ถนนตัดเก่า แขวงบางครั้ง เขตฐานทัพ 10100 กรุงเทพมหานคร ประเทศไทย",
    mainContactName: "บางรัก ซอยเก้า",
    mainContactPhone: "0999999999",
    mainContactEmail: "nongmon@gmail.com",
  },
  {
    idCompany: 1,
    branchName: "บริษัท ปูนซิเมนต์ไทย จำกัด (มหาชน) สาขาแม่ฮ่องสอน",
    address: "เลขที่ 99 ถนนตัดเก่า แขวงบางครั้ง เขตฐานทัพ 10100 กรุงเทพมหานคร ประเทศไทย",
    mainContactName: "บางรัก ซอยเก้า",
    mainContactPhone: "0999999999",
    mainContactEmail: "nongmon@gmail.com",
  },
];

export default branch