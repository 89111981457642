import { httpClient } from "./httpClient";

const getAllFavoriteVendors = () => {
    return httpClient.get("company/favorite-vendors");
};

const addFavoriteVendor = (idVendor) => {
    return httpClient.post("company/favorite-vendor/add",{ id: idVendor });
};

const deleteFavoriteVendor = (idVendor) => {
    return httpClient.delete("company/favorite-vendor/delete/"+idVendor);
};

export default {
    getAllFavoriteVendors,
    addFavoriteVendor,
    deleteFavoriteVendor
};