import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { Link } from "react-router-dom";
import RequestPanel from "./requestPanel";
import BiddingPanel from "./bidding/biddingPanel";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyMyJob } from "../../../../actions/job";
import { getBiddingsMyJobs } from "../../../../actions/bidding";
import dayjs from "dayjs";

const StyledRoot = styled("div")({
  "& .header-text": {
    marginBottom: 24,
  },
  "& .part-one": {
    marginBottom: 16,
    "& .search-field": {
      width: 250,
    },
  },
  "& .MuiTabs-root": {
    marginBottom: 24,
  }
});

const FormControlStyled = styled(FormControl)(({ type }) => ({
  width:"220px", 
  marginLeft:"20px",
  "& .MuiInputBase-root":{
    borderRadius:"8px",
    color: type === "" ? "#bdbdbd" : "initail"
  },
  "& .MuiSelect-select":{
    padding:"13.5px 32px 13.5px 14px"
  }
}));

const CompanyRequestPage = () => {

  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [type, setType] = useState("");
  
  const { isFetching: isJobFetching, result: jobStore } = useSelector(state => state.jobs);
  const { isFetching: isBiddingFetching, result : biddingStore } = useSelector((state) => state.biddingsMyJobs);

  const [jobFiltered, setJobFiltered] = useState([]);
  const [biddingFiltered, setBiddingFiltered] = useState([]);

  const [searchName, setSearchName] = useState("");

  const listJobMenuItem = [
    // {
    //   label:"ยังไม่เริ่มเปิดงาน",
    //   value:"notOpen"
    // },
    {
      label:"เปิดรับสมัคร",
      value:"opening"
    },
    {
      label:"ปิดรับสมัคร",
      value:"ending"
    },
    {
      label:"งานถูกยกเลิก",
      value:"canceling"
    },
  ];

  const listBiddingMenuItem = [
    {
      label:"ยังไม่เริ่มการประมูล",
      value:"notOpen"
    },
    {
      label:"กำลังประมูล",
      value:"opening"
    },
    {
      label:"จบการประมูล",
      value:"ending"
    }
  ];

  const onChangeSearchNameHandler = (event) => {
    const newValue = event.target.value;
    setSearchName(newValue);
  }

  const onChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
    setType("");
  }

  useEffect(()=>{
    dispatch(getCompanyMyJob());
    dispatch(getBiddingsMyJobs());
  }, [])

  useEffect(()=>{
    if(jobStore !== null){
      setJobFiltered([...jobStore])
    }
  }, [jobStore])

  useEffect(()=>{
    if(biddingStore !== null){
      setBiddingFiltered([...biddingStore])
    }
  }, [biddingStore])

  useEffect(()=>{
    if(jobStore !== null && biddingStore !== null){
      setJobFiltered(jobStore.filter(job => {
        return (searchName === "" || (job.positionName.toLowerCase().includes(searchName.toLowerCase())))
          && (type === "" ||
          // (type === "notOpen" &&
          //   dayjs().isBefore(dayjs(job.createdDate))) 
          (type === "opening" &&
            dayjs().isBefore(dayjs(job.closeRegisterDate))) ||
          (type === "ending" &&
            dayjs().isAfter(dayjs(job.closeRegisterDate)))
          // (type === "canceling" &&
          //   !dayjs().isBefore(dayjs(bidding.biddingStartAt)) &&
          //   !dayjs().isAfter(dayjs(bidding.biddingEndAt)))
          )
        // && (type === "" || (job.positionName.toLowerCase().includes(searchName.toLowerCase())))
      }))

      setBiddingFiltered(biddingStore.filter(bidding => {
        return (searchName === "" || (bidding.biddingName.toLowerCase().includes(searchName.toLowerCase())))
          && (type === "" ||
          (type === "notOpen" &&
            dayjs().isBefore(dayjs(bidding.biddingStartAt))) ||
          (type === "ending" &&
            dayjs().isAfter(dayjs(bidding.biddingEndAt))) ||
          (type === "opening" &&
            !dayjs().isBefore(dayjs(bidding.biddingStartAt)) &&
            !dayjs().isAfter(dayjs(bidding.biddingEndAt)))
          )
      }))

    }
  }, [searchName, type])

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography className="header-text" variant="h4">
          รายการคำร้องของฉัน
        </Typography>
        <Grid
          className="part-one"
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <TextFieldTheme
              className="search-field"
              placeholder="ค้นหา..."
              value={searchName}
              onChange={onChangeSearchNameHandler}
              InputProps={{
                endAdornment: searchName.length > 0? <IconButton onClick={()=>{setSearchName("")}} style={{width: 30, height: 30}}><i className="fa-solid fa-xmark"></i></IconButton>: ""
              }}
            />
            <FormControlStyled type={type}>
              <Select
                displayEmpty
                value={type}
                onChange={(e) => setType(e.target.value)}
                endAdornment={
                  type.length > 0? <IconButton onClick={()=>{setType("")}} style={{width: 30, height: 30, marginRight: 14}}><i className="fa-solid fa-xmark"></i></IconButton>: ""
                }
              >
                <MenuItem disabled value="">
                  {`เลือกประเภท${tabValue === 0 ? "งาน" : "การประมูล"}`}
                </MenuItem>
                {
                  tabValue === 0 &&
                    listJobMenuItem.map((value) => (
                      <MenuItem value={value.value} key={value.label}>{value.label}</MenuItem>
                    ))
                }
                {
                  tabValue === 1 &&
                    listBiddingMenuItem.map((value) => (
                      <MenuItem value={value.value} key={value.label}>{value.label}</MenuItem>
                    ))
                }
              </Select>
            </FormControlStyled>
          </Box>
          <ButtonBlue
            component={Link}
            to={tabValue === 0? "myjobs/jobForm": tabValue === 1? "myjobs/biddingForm": ""}
            variant="outlined"
            startIcon={<i className="fa-regular fa-plus"></i>}
          >
            {tabValue === 0? "คำร้องใหม่": tabValue === 1? "เปิดประมูลสัญญาใหม่": ""}
          </ButtonBlue>
        </Grid>

        <Tabs value={tabValue} onChange={onChangeTabValue}>
          <Tab label="จ้างงาน" />
          <Tab label="ประมูลสัญญา" />
        </Tabs>

        {tabValue === 0 && <RequestPanel type={type} data={jobFiltered}/>}
        {tabValue === 1 && <BiddingPanel type={type} data={biddingFiltered}/>}
      </Container>
    </StyledRoot>
  );
}

export default CompanyRequestPage;