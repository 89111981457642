import React, { useEffect } from "react";
import { Box, Dialog, styled, Typography } from "@mui/material";

const StyledRoot = styled(Box)({
  padding: "24px 16px",
  // maxWidth: 350,
  // width: "100%",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  boxSizing: "border-box",
  "& .icon":{
    fontSize: 112
  },
  "& .text":{
    marginTop: 24,
    fontSize: 20,
  },
  "& .success":{
    color: "#4b924f"
  },
  "& .error":{
    color:"#e93d3d"
  }
})

const DialogStatus = (props) => {

  const { open, status, message, onClose, refreshContract } = props;

  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose();
      if(status === "success"){
        refreshContract();
      }
    }, 2000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        if(status === "success"){
          refreshContract();
        }
      }}
      maxWidth="xs"
      fullWidth
    >
      <StyledRoot>
        {status === "success" && <i className={`fa-regular fa-circle-check icon success`}></i>}
        {status === "error" && <i className={`fa-regular fa-circle-xmark icon error`}></i>}
        <Typography className={`text ${status}`}>{message}</Typography>
      </StyledRoot>
    </Dialog>
  )
}

export default DialogStatus