import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
    Avatar,
    Box,
    Container,
    IconButton,
    styled,
    Tooltip,
    Typography
} from '@mui/material';

import { Visibility } from '@mui/icons-material';

import ButtonBlue from "../../shared/general/ButtonBlue";
import DialogConfirmConclusion from './dialogConfirmConclusion';
import DialogDescription from "./dialogDescription";
import TableTheme from "../../shared/general/TableTheme";

import { getBiddingVendor, conclusionBidding } from "../../../../actions/bidding";
import dayjs from 'dayjs';
import utils from '../../../../utils';

const StyledRoot = styled("div")({
    "& .part-header": {
        marginBottom: 24,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .column-name": {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        color:"#000000",
        "& .MuiAvatar-root": {
          marginRight: 10,
          width: 40,
          height: 40,
        },
      },
});

const StyledAvatar = styled(Avatar)({
  "& img":{
    backgroundColor:"#ffffff",
    height:"inherit",
    width:"inherit",
    objectFit:"contain"
  }
});

const ButtonRed = styled(ButtonBlue)({
  width: "105px",
  backgroundColor: "#d32f2f",
  color: "white",
  "&:hover": {
    backgroundColor: "#c62828",
  },
});

const DescriptionComponent = ({ params }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box style={{ display: 'flex', alignItems: "center" }}>
      <Typography align="left" noWrap style={{ width: "460px", overflow: "hidden" }}>
        {params.description || "-"}
      </Typography>
      <IconButton aria-label="view" onClick={() => setOpen(true)}>
        <Visibility />
      </IconButton>
      <DialogDescription open={open} onClose={() => setOpen(false)} description={params.description} />
    </Box>
  );
};

const CompanyBidding = (props) => {
    const dispatch = useDispatch();
    const { result: BiddingVendors } = useSelector((state) => state.biddingVendors);
    const [bidding, setBidding] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [vendorPassed, setVendorPassed] = useState(null);
    const columns = [
      {
        field: "name",
        width: 300,
        headerName: (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <i
              className="fa-solid fa-building"
              style={{
                color: "#007afe",
                fontSize: "1.1rem",
                marginRight: "10px",
              }}
            />
            <Typography>ชื่อคู่ธุรกิจ</Typography>
          </Box>
        ),
        format: (params) => (
          <Tooltip title="ดูประวัติ" placement="top-start">
            <Box
              className="column-name"
              component={Link} to={`/vendor/${params.vendorNameShort}`}
            >
              <StyledAvatar
                src={params.vendorImageURL}
                alt={params.vendorNameShort}
              >
                <img src={"/assets/logo_default.png"} alt={params.vendorNameShort}/>
              </StyledAvatar>
              <Typography>{params.vendorName}</Typography>
            </Box>
          </Tooltip>
        ),
      },
      ...BiddingVendors ? BiddingVendors.data.bidType === 1 ? [
      {
        field: "chargeSalaryOffer",
        width: 144,
        headerName: (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i
              className="fa-solid fa-user"
              style={{
                color: "#007afe",
                fontSize: "1.1rem",
                marginRight: "10px",
              }}
            />
            <Typography>ชาร์จค่าแรง</Typography>
          </Box>
        ),
        format: (params) => (
          <Box>
            <Typography align="center">{`${params.chargeSalaryOffer || "-"} %`}</Typography>
          </Box>
        ),
      },
      {
        field: "chargeOtOffer",
        width: 144,
        headerName: (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i
              className="fa-solid fa-clock"
              style={{
                color: "#007afe",
                fontSize: "1.1rem",
                marginRight: "10px",
              }}
            />
            <Typography>ชาร์จค่า OT</Typography>
          </Box>
        ),
        format: (params) => (
          <Box>
            <Typography align="center">{`${params.chargeOtOffer || "-"} %`}</Typography>
          </Box>
        ),
      },
      {
        field: "chargeHireOffer",
        width: 144,
        headerName: (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i
              className="fa-sharp fa-solid fa-people-group"
              style={{
                color: "#007afe",
                fontSize: "1.1rem",
                marginRight: "10px",
              }}
            />
            <Typography>ชาร์จค่าเหมา</Typography>
          </Box>
        ),
        format: (params) => (
          <Box>
            <Typography align="center">
              {`${params.chargeHireOffer ? (params.chargeHireOfferType === 1 ? params.chargeHireOffer : utils.numberWithCommas(params.chargeHireOffer) ) : "-"} ${params.chargeHireOfferType === 1 ? "%" : "บาท"}`}
            </Typography>
          </Box>
        ),
      },
      {
        field: "chargeCompensationOffer",
        width: 144,
        headerName: (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i
              className="fa-solid fa-sack-dollar"
              style={{
                color: "#007afe",
                fontSize: "1.1rem",
                marginRight: "10px",
              }}
            />
            <Typography>ชาร์จค่าชดเชย</Typography>
          </Box>
        ),
        format: (params) => (
          <Box>
            <Typography align="center">
              {`${params.chargeCompensationOffer || "-"} %`}
            </Typography>
          </Box>
        ),
      },
      ] : [
        {
          field: "budgetOffer",
          width: 180,
          headerName: (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <i
                className="fa-solid fa-user"
                style={{
                  color: "#007afe",
                  fontSize: "1.1rem",
                  marginRight: "10px",
                }}
              />
              <Typography>งบประมาณรายเดือน</Typography>
            </Box>
          ),
          format: (params) => (
            <Box>
              <Typography align="center">{`${utils.numberWithCommas(params.budgetOffer) || 0} บาท`}</Typography>
            </Box>
          ),
        },
      ] : [],
      {
        field: "freeManpower",
        width: 150,
        headerName: (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i
              className="fa-solid fa-users"
              style={{
                color: "#007afe",
                fontSize: "1.1rem",
                marginRight: "10px",
              }}
            />
            <Typography>กำลังพลที่พร้อม</Typography>
          </Box>
        ),
        format: (params) => (
          <Box>
            <Typography align="center">
              {`${(params.freeManpowers !== undefined && params.freeManpowers !== null) ? params.freeManpowers : 0} คน`}
            </Typography>
          </Box>
        ),
      },
      {
        field: "rating",
        width: "120px",
        headerName: (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <i className="fa-solid fa-star" style={{ color: "#faaf00", fontSize:"1.1rem", marginRight:"10px" }} />
            <Typography>คะแนน</Typography>
          </Box>
        ),
        format: (params) => (
          <Box style={{ display:'flex', justifyContent:"flex-start" }}>
            <i className="fa-solid fa-star" style={{ color: "#faaf00", fontSize:"1.1rem", marginRight:"10px" }} />
            <Typography>{params.rating || 0}</Typography>
          </Box>
        ),
      },
      {
        field: "description",
        width: "500px",
        headerName: (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <i
              className="fa-solid fa-memo"
              style={{
                color: "#007afe",
                fontSize: "1.1rem",
                marginRight: "10px",
              }}
            />
            <Typography>รายละเอียด</Typography>
          </Box>
        ),
        format: (params) => (
          <DescriptionComponent params={params}/>
        ),
      },
      {
        field: "attach",
        width: 32,
        headerName: "",
        format: (params) => (
          <Box>
            {params.attachFileURL &&
              <a href={params.attachFileURL} target="_blank">
                <i className="fa-solid fa-file-pdf" style={{ fontSize:"1.5rem", color:"#ff0000" }}/>
              </a>
            }
          </Box>
        )
      },
      {
        field: "button",
        width: 110,
        headerName: "",
        format: (params) => (
          <Box>
            {!vendorPassed && 
                <ButtonBlue 
                  variant="contained"
                  onClick={() => setVendorPassed(params)}
                  style={{ width:"105px" }}
                >เลือกคู่ธุรกิจ</ButtonBlue>
            }
            {vendorPassed && vendorPassed.id === params.id &&
                <ButtonRed
                  variant="contained"
                  onClick={() => setVendorPassed(null)}
                >
                  ยกเลิก
                </ButtonRed>
            }
            {vendorPassed && vendorPassed.id !== params.id &&
                <ButtonRed
                  variant="contained"
                  disabled
                  style={{ widht:"105px" }}
                >
                  เลือกคู่ธุรกิจ
                </ButtonRed>
            }
          </Box>
        ),
      },
    ];
    
    useEffect(() => {
        if(props.match.params.idBidding){
          dispatch(getBiddingVendor(props.match.params.idBidding));
        }
    },[props.match.params.idBidding]);

    useEffect(() => {
      if (BiddingVendors && BiddingVendors.message === "success") {
        // if(!dayjs().isAfter(dayjs(BiddingVendors.data.biddingEndAt))){
        //   props.history.push("/company/myjobs");
        // } else {
        //   setBidding(BiddingVendors.data);
        // }
          setBidding(BiddingVendors.data);
      }
    },[BiddingVendors]);

    const handleCloseDialog = () => {
      setOpenConfirm(false);
    };

    const handleSubmitConclusion = async () => {
      let data = {
        idBiddings: props.match.params.idBidding,
        idBiddingVendors: vendorPassed ? vendorPassed.idBiddingVendors : null
      };
      const result = await dispatch(conclusionBidding(data));
      if(result && result.message === "success"){
        dispatch(getBiddingVendor(props.match.params.idBidding));
        handleCloseDialog();
        setVendorPassed(null);
      }
    };

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <div className="part-header">
                    <Typography variant="h4">
                        {`คู่ธุรกิจที่ประมูล / ${(bidding && bidding.biddingName) || "-"}`}
                    </Typography>
                    <div className="item-total">
                        <Typography variant="h4">
                            {bidding && bidding.listVendors.length || 0}
                        </Typography>
                        <Typography color="text.secondary">{`จำนวนคู่ธุรกิจที่ประมูล`}</Typography>
                    </div>
                </div>

                <TableTheme 
                    columns={columns}
                    rows={bidding ? bidding.listVendors : []}
                    textEmpty="ไม่พบผู้สมัคร"
                    minHeight={200}
                />
                
                <Box
                    style={{ marginTop:"50px", display:"flex", justifyContent:"flex-end", marginBottom:"10px" }}
                >
                    <ButtonBlue
                        variant="contained"
                        onClick={() => setOpenConfirm(true)}
                    >สรุปการประมูล</ButtonBlue>
                </Box>
                <DialogConfirmConclusion 
                  open={openConfirm}
                  onClose={handleCloseDialog}
                  vendor={vendorPassed}
                  handleSubmit={handleSubmitConclusion}
                />
            </Container>
        </StyledRoot>
    );
};

export default CompanyBidding;