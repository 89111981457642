import VendorBranchService from "../services/vendorBranch.service";

export const updateBranchByBranchId = (idVendorBranch, formData) => async () => {
  try {
    const res = await VendorBranchService.updateBranchByBranchId(idVendorBranch, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteBranchByBranchId = (idVendorBranch) => async () => {
  try {
    const res = await VendorBranchService.deleteBranchByBranchId(idVendorBranch);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
}