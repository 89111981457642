import React, { useEffect, useState } from "react";

import Chart from 'react-apexcharts'

const GraphManpowerIncrease = (props) => {

  const {monthlyManpowerList} = props;

  const [series, setSeries] = useState([
    {
      name: 'กำลังพลรายเดือน',
      type: 'line',
      // data: [
      //   ['Jan 2022 GMT+7', 50],
      //   ['Feb 2022 GMT+7', 40]
      // ]
      // data: [36, 26, 7, 5, 4, 28, 0, 6, 7, 10, 20, 4]
      data: [0, 0, 0, 0, 0]
    }
  ])

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
    },
    stroke: {
      width: 4,
      curve: 'smooth',
      // colors: ["#008ffb", "#d50000"],
    },
    legend: {
      markers: {
        // fillColors: ["#008ffb", "#d50000"],
      }
    },
    title: {
      text: 'กำลังพลรายเดือน'
    },
    dataLabels: {
      enabled: false,
    },
    // labels: ['Jan 2022', 'Feb 2022', 'Mar 2022', 'Apr 2022', 'May 2022', 'Jun 2022', 'Jul 2022', 'Aug 2022', 'Sep 2022', 'Oct 2022', 'Nov 2022', 'Dec 2022'],
    labels: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.','ต.ค.','พ.ย.','ธ.ค.'],
    xaxis: {
      // type: 'datetime',
      // min: new Date('Jan 2022').getTime(),
      labels: {
        // format: 'MMM yyyy'
        // datetimeFormatter: {
        //   year: 'yyyy',
        //   month: 'MMM \'yy',
        //   day: 'dd MMM',
        //   hour: 'HH:mm'
        // }
      }
    },
    // tooltip: {
    //   x: {
    //     format: "MMM yyyy"
    //   }
    // }
    yaxis: [
      {
        labels: {
          formatter: value => {
            return value.toFixed(0);
          }
        }
      }
    ]
    // yaxis: [
    //   {
    //     title: {
    //       text: 'กำลังพลที่ส่งไป',
    //     },
    //   }, 
    //   {
    //     opposite: true,
    //     title: {
    //       text: 'กำลังพลที่รับงานสำเร็จ'
    //     }
    // }]
  })

  useEffect(() => {
    const cleansingData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    monthlyManpowerList.map((m) => {
      cleansingData[m.month - 1] = m.count
    })

    setSeries([
      {
        name: 'กำลังพล',
        type: 'line',
        data: cleansingData
      }
    ])

  }, [monthlyManpowerList])

  return(
    <Chart options={options} series={series} type="line" height={350}/>
  )
}

export default GraphManpowerIncrease;