import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Autocomplete, Box, CircularProgress, Container, Grid, IconButton, MenuItem, Paper, styled, Typography, useMediaQuery } from "@mui/material";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDropzone } from "react-dropzone";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addManpower } from "../../../../actions/manpower";
import { Link } from "react-router-dom";
import { getOptionYears } from "../../../../utils";
import { useHistory } from "react-router-dom";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getJobGroups } from "../../../../actions/job";

const degrees = [
  {
    idDegrees: 1,
    degreeName: "ประถมศึกษา",
  },
  {
    idDegrees: 2,
    degreeName: "มัธยมศึกษา",
  },
  {
    idDegrees: 3,
    degreeName: "ปวช./ปวส.",
  },
  {
    idDegrees: 4,
    degreeName: "ปริญญาตรี",
  },
  {
    idDegrees: 5,
    degreeName: "ปริญญาโท",
  },
  {
    idDegrees: 6,
    degreeName: "ปริญญาเอก",
  },
]

const StyledRoot = styled("div")({
  "& .header-container": {
    padding: "24px 0",
  },
  "& .part-expertise": {
    "& .MuiPaper-root": {
      height: "100%",
      minHeight: 96,
      borderRadius: 16,
      position: "relative",
      padding: 16,
      boxSizing: "border-box",
      userSelect: "none",
      "& .wrap-name": {
        width: 160,
        color: "#919eab",
        "& .MuiTypography-root": {
          fontSize: 20,
        },
      },
      "& .wrap-icon": {
        position: "absolute",
        fontSize: 35,
        color: "#919eab",
        right: 16,
        bottom: 8,
      },
    },
    "& .isSelected": {
      borderColor: "#1976d2",
      "& .wrap-name": {
        color: "#1976d2",
      },
      "& .wrap-icon": {
        color: "#1976d2",
      }
    },
  },
  "& .left-panel": {
    "& .wrap-container": {
      paddingTop: 16,
    },
    "& .MuiTypography-root": {
      wordBreak: "break-word",
    }
  },
  "& .dropzone": {
    marginBottom: 24,
    border: `2px dashed #1976d2`,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    height: 100,
    outline: "none",
    padding: 20,
    gap: 10,
  },
  "& .success-wraper": {
    paddingTop: 55,
    textAlign: "center",
    "& .button-wrap": {
      display: "inline-block",
      marginTop: ".6em",
    }
  }
})

const AddManpowerFormPage = () => {

  const [activeForm, setActiveForm] = useState(0);
  const matches = useMediaQuery('(min-width:900px)');
  const { result: jobGroups } = useSelector(state => state.jobGroups)

  const [isFetching, setIsFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getJobGroups());
  }, [dispatch])

  const formAction = {
    prevForm: () => {if(activeForm === 0) return; setActiveForm(activeForm-1);},
    nextForm: () => {setActiveForm(activeForm+1);},
    submitForm: async () => {
      setIsFetching(true);

      const formData = {
        ...formOneUseForm.getValues(),
        ...formTwoUseForm.getValues(),
        ...formThreeUseForm.getValues(),
      }

      const work = formData.work.map(w => {
        return {
          startYear: w.startYear,
          endYear: w.endYear,
          companyName: w.companyName,
          positionName: w.positionName,
          idJobGroup: w.jobGroup.idJobGroup
        }
      })

      const cleansingData = {
        ...formData,
        educationDegree: degrees.find(d => d.idDegrees === parseInt(formData.educationDegree)).degreeName,
        education: formData.education.map(e => {return {...e, degree: degrees.find(d => d.idDegrees === parseInt(e.degree)).degreeName}}),
        work: work,
      };

      let timeoutDisplay;
      const response = await dispatch(addManpower(cleansingData))

      const handleCloseDisplay = () => {
        clearInterval(timeoutDisplay);
        history.push("/manpower");
      };

      if(response.status === 200){
        setIsSuccess(true);
        timeoutDisplay = setTimeout(handleCloseDisplay, 5000);
      }

      setIsFetching(false);
    }
  }

  const formOneUseForm = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      phoneNumber: "",
      email: "",
      dateOfBirth: null,
    }
  })

  const formTwoUseForm = useForm({
    defaultValues: {
      idJobGroup: "",
      workExperience: "",
      educationDegree: "",
      currentSalary: "",
      expectedSalary: "",
    }
  })

  const formThreeUseForm = useForm({
    defaultValues: {
      education: [
        {
          startYear: "",
          endYear: "",
          universityName: "",
          degree: "",
          faculty: "",
          major: "",
          gpa: "",
        }
      ],
      work: [
        {
          startYear: "",
          endYear: "",
          companyName: "",
          positionName: "",
          jobGroup: null,
        }
      ],
    }
  })

  const educationUseFieldArray = useFieldArray({
    control: formThreeUseForm.control,
    name: "education",
  })

  const workUseFieldArray = useFieldArray({
    control: formThreeUseForm.control,
    name: "work"
  })

  return(
    <StyledRoot className="page">
      {!isSuccess && <Container maxWidth="xl">
        <div className="header-container">
          <Typography variant="h4">เพิ่มกำลังพล</Typography>
        </div>
        <Grid container spacing={2}>
          {matches && <Grid item xs={0} md={4}>
            <LeftPanel useForm={{formOneUseForm: formOneUseForm, formTwoUseForm: formTwoUseForm}}/>
          </Grid>}
          <Grid item xs={12} md={8}>
            {activeForm === 0 && (
              <Fragment>
                <UploadFile useForm={{formOneUseForm: formOneUseForm, formTwoUseForm: formTwoUseForm, formThreeUseForm: formThreeUseForm}}/>
                <FormOne useForm={formOneUseForm} formAction={formAction}/>
              </Fragment>
            )}
            {activeForm === 1 && <FormTwo useForm={formTwoUseForm} formAction={formAction} isFetching={isFetching} jobGroups={jobGroups}/>}
            {activeForm === 2 && <FormThree useForm={formThreeUseForm} useFieldArray={{workUseFieldArray: workUseFieldArray, educationUseFieldArray: educationUseFieldArray}} formAction={formAction} isFetching={isFetching} jobGroups={jobGroups}/>}
          </Grid>
        </Grid>
        {/* <UploadFile useForm={{formOneUseForm: formOneUseForm, formTwoUseForm: formTwoUseForm}}/> */}
      </Container>}

      {isSuccess && 
        <Container maxWidth="lg">
          <div className="success-wraper">
            <img 
              style={{ width:300, marginBottom:"20px" }}
              src={`${process.env.PUBLIC_URL}/assets/background/complete.jpg`}
              // alt="https://www.freepik.com/free-vector/completed-concept-illustration_10802151.htm"  
            />
            <Typography variant="h4" textAlign="center">เพิ่มกำลังพลสำเร็จ</Typography>
            <div className="button-wrap">
              <ButtonBlue variant="outlined" component={Link} to="/manpower">กำลังพล</ButtonBlue>
            </div>
          </div>
        </Container>
      }
    </StyledRoot>
  )
}

const LeftPanel = ({useForm}) => {

  const { watch: formOneWatch } = useForm.formOneUseForm;
  const { watch: formTwoWatch } = useForm.formTwoUseForm;

  const firstname = formOneWatch('firstname');
  const lastname = formOneWatch('lastname');
  const phoneNumber = formOneWatch('phoneNumber');
  const email = formOneWatch('email');

  const idJobGroup = String(formTwoWatch('idJobGroup'));
  const workExperience = String(formTwoWatch('workExperience'));
  const educationDegree = String(formTwoWatch('educationDegree'));
  const currentSalary = formTwoWatch('currentSalary');
  const expectedSalary = formTwoWatch('expectedSalary');

  return(
    <div className="left-panel">
      <Typography fontSize={20}>{`กำลังพลที่จะถูกเพิ่ม..`}</Typography>

      {(firstname.length + lastname.length + phoneNumber.length + email.length) > 0 && (
        <div className="wrap-container">
          {firstname.length > 0 && <Typography fontSize={18}>{`${firstname} ${lastname}`}</Typography>}
          {phoneNumber.length > 0 && <Typography fontSize={18}>{`${phoneNumber}`}</Typography>}
          {email.length > 0 && <Typography fontSize={18}>{`${email}`}</Typography>}
        </div>
      )}

      {/* {idExpertise.length > 0 && (
        <div className="wrap-container">
          <Typography fontSize={20}>ความเชี่ยวชาญ</Typography>
          <Typography fontSize={18}>{expertises.find(e => e.idExpertise === +idExpertise).expertiseName}</Typography>
        </div>
      )} */}

      {(workExperience.length + educationDegree.length) > 0 && (
        <div className="wrap-container">
          {workExperience.length > 0 && <Typography fontSize={18}>{`ประสบการณ์ทำงาน ${workExperience} ปี`}</Typography>}
          {educationDegree.length > 0 && <Typography fontSize={18}>{`ระดับการศึกษา ${educationDegree}`}</Typography>}
          {/* {educationDegree.length > 0 && <Typography fontSize={18}>{`ระดับการศึกษา ${degrees.find(d => d.idDegrees === +educationDegree).degreeName}`}</Typography>} */}
        </div>
      )}

      {(currentSalary.length + expectedSalary.length) > 0 && (
        <div className="wrap-container">
          {currentSalary.length > 0 && <Typography fontSize={18}>{`เงินเดือนปัจจุบัน ${currentSalary}`}</Typography>}
          {expectedSalary.length > 0 && <Typography fontSize={18}>{`เงินเดือนที่คาดหวัง ${expectedSalary}`}</Typography>}
        </div>
      )}
    </div>
  )
}

const FormOne = ({useForm, formAction}) => {

  const {control, handleSubmit, formState: { errors }, getValues} = useForm;

  const onSubmit = (formData) => {
    formAction.nextForm();
  }

  const renderAge = (totalMonth) => {
    const year = Math.floor(totalMonth / 12);
    const month = (totalMonth % 12)
    return `${year} ปี ${month} เดือน`
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Controller
            name="firstname"
            control={control}
            rules={{
              required: {value: true, message: "กรุณากรอกชื่อ"}
            }}
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="ชื่อ"
                fullWidth
                helperText={errors && errors.firstname && errors.firstname.message}
                error={errors && errors.firstname ? true: false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="lastname"
            control={control}
            rules={{
              required: {value: true, message: "กรุณากรอกนามสกุล"}
            }}
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="นามสกุล"
                fullWidth
                helperText={errors && errors.lastname && errors.lastname.message}
                error={errors && errors.lastname ? true: false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: {value: true, message: "กรุณากรอกเบอร์โทร"},
              validate: (value) => (!isNaN(value) && value.length === 10) || "กรุณากรอกเบอร์โทร 10 หลัก"
            }}
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="เบอร์โทร"
                fullWidth
                helperText={errors && errors.phoneNumber && errors.phoneNumber.message}
                error={errors && errors.phoneNumber ? true: false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: {value: true, message: "กรุณากรอกอีเมล"},
              pattern: {
                value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z.]{1,}[A-z]$/,
                message: "กรุณากรอกอีเมลให้ถูกต้อง เช่น 'antjob@example.com'"
              }
            }}
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="อีเมล"
                fullWidth
                helperText={errors && errors.email && errors.email.message}
                error={errors && errors.email ? true: false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              name="dateOfBirth"
              rules={{
                required: {
                  value: true,
                  message: "กรุณากรอกวันเกิด"
                }
              }}
              render={({field, formState}) => (
                <DesktopDatePicker
                  {...field}
                  views={["year", "month", "day"]}
                  inputFormat="DD MMMM YYYY"
                  disableFuture
                  disableMaskedInput
                  openTo="year"
                  onChange={(value)=>{
                    field.onChange(value)
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      label="วัน เดือน ปีเกิด"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "วัน เดือน ปีเกิด",
                        readOnly: true
                      }}
                      error={formState.errors && formState.errors[field.name]? true: false}
                      helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <Controller 
              control={control}
              name="dateOfBirth"
              render={()=>(
                <Fragment>
                  {getValues("dateOfBirth") && (
                    <Box display="flex">
                      <Typography color="text.secondary" fontSize={20} paddingRight="8px">อายุ</Typography>
                      <Typography variant="h6">{renderAge(dayjs(new Date()).diff(getValues("dateOfBirth"), 'month'))}</Typography>
                    </Box>
                  )}
                </Fragment>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <ButtonBlue variant="contained" type="submit">ถัดไป</ButtonBlue>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const FormTwo = ({useForm, formAction, jobGroups}) => {

  const {control, handleSubmit, formState: { errors }, getValues, setValue} = useForm;

  // const onClickExpertise = (idExpertise) => {
  //   const currentExpertise = getValues("idExpertise");
  //   if (idExpertise !== currentExpertise) {
  //     setValue("idExpertise", idExpertise);
  //     return;
  //   }
  // };

  const onSubmit = (formData) => {
    // formAction.submitForm();
    formAction.nextForm();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="idJobGroup"
            rules={{
              required: {value: true, message: "กรุณาเลือกความเชี่ยวชาญ"},
            }}
            render={({field})=>(
              <Fragment>
                <Typography gutterBottom>ความเชี่ยวชาญ</Typography>
                {errors && errors.idJobGroup && errors.idJobGroup.message && <Typography fontSize={12} gutterBottom color="error" style={{marginLeft: "14px"}}>{errors.idJobGroup.message}</Typography>}
                <Grid className="part-expertise" container spacing={2}>
                  {jobGroups && jobGroups.map((item, index)=>(
                    <Grid key={index} item xs={6} md={4} lg={3}>
                      <Paper className={getValues("idJobGroup") === item.idJobGroup? "isSelected": ""} variant="outlined" onClick={()=>{field.onChange(item.idJobGroup)}}>
                        <div className="wrap-name">
                          <Typography variant="subtitle">{item.jobGroupName}</Typography>
                        </div>
                        {/* <div className="wrap-icon">{item.icon}</div> */}
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Fragment>
            )}
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="workExperience"
            rules={{
              required: {value: true, message: "กรุณาเลือกประสบการณ์ทำงาน"},
            }}
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="ประสบการณ์ทำงาน"
                helperText={errors && errors.workExperience && errors.workExperience.message}
                error={errors && errors.workExperience ? true: false}
                select
              >
                <MenuItem value="0">น้อยกว่า 1 ปี</MenuItem>
                <MenuItem value="1">1 ปี</MenuItem>
                <MenuItem value="2">2 ปี</MenuItem>
                <MenuItem value="3">3 ปี</MenuItem>
                <MenuItem value="4">4 ปี</MenuItem>
                <MenuItem value="5">5 ปี</MenuItem>
                <MenuItem value="6">6 ปี</MenuItem>
                <MenuItem value="7">7 ปี</MenuItem>
                <MenuItem value="8">8 ปี</MenuItem>
                <MenuItem value="9">9 ปี</MenuItem>
                <MenuItem value="10">10 ปี</MenuItem>
                <MenuItem value="11">11 ปี</MenuItem>
                <MenuItem value="12">12 ปี</MenuItem>
                <MenuItem value="13">13 ปี</MenuItem>
                <MenuItem value="14">14 ปี</MenuItem>
                <MenuItem value="15">15 ปี</MenuItem>
                <MenuItem value="16">16 ปี</MenuItem>
                <MenuItem value="17">17 ปี</MenuItem>
                <MenuItem value="18">18 ปี</MenuItem>
                <MenuItem value="19">19 ปี</MenuItem>
                <MenuItem value="20">20 ปีขึ้นไป</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="educationDegree"
            rules={{
              required: {value: true, message: "กรุณาเลือกระดับการศึกษา"},
            }}
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="ระดับการศึกษา"
                helperText={errors && errors.educationDegree && errors.educationDegree.message}
                error={errors && errors.educationDegree ? true: false}
                select
              >
                {degrees.map(d => (
                  <MenuItem key={`deegree_${d.idDegrees}`} value={`${d.degreeName}`}>{d.degreeName}</MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="currentSalary"
            rules={{
              // required: {value: true, message: "กรุณากรอกเงินเดือนปัจจุบัน"},
            }}
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="เงินเดือนปัจจุบัน (ไม่จำเป็น)"
                helperText={errors && errors.currentSalary && errors.currentSalary.message}
                error={errors && errors.currentSalary ? true: false}
              />  
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="expectedSalary"
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="เงินเดือนที่คาดหวัง (ไม่จำเป็น)"
                helperText={errors && errors.expectedSalary && errors.expectedSalary.message}
                error={errors && errors.expectedSalary ? true: false}
              />  
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <ButtonBlue variant="contained" onClick={()=>{formAction.prevForm()}}>ก่อนหน้า</ButtonBlue>
            <ButtonBlue variant="contained" type="submit">ถัดไป</ButtonBlue>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const FormThree = ({useForm, useFieldArray, formAction, isFetching, jobGroups}) => {
  const {control, handleSubmit, formState: { errors }, getValues, setValue} = useForm;

  const { educationUseFieldArray, workUseFieldArray } = useFieldArray;

  const onSubmit = (formData) => {
    formAction.submitForm();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Typography variant="h5">ประวัติการศึกษา</Typography>
          <ButtonBlue
            variant="outlined"
            startIcon={<i className="fa-light fa-plus"></i>}
            onClick={()=>{educationUseFieldArray.append({
              startYear: "",
              endYear: "",
              universityName: "",
              degree: "",
              faculty: "",
              major: "",
              gpa: "",
            })}}
          >
              เพิ่ม
          </ButtonBlue>
        </Grid>

        {educationUseFieldArray.fields.length <= 0 && (
          <Grid item xs={12}>
            <Typography textAlign="center" color="text.secondary">ไม่มีประวัติการศึกษา</Typography>
          </Grid>
        )}

        {educationUseFieldArray.fields.map((education, index) => (
          <Grid key={education.id} item xs={12}>
            <div style={{paddingRight: 64, position: "relative"}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name={`education.${index}.startYear`}
                        control={control}
                        // defaultValue={education.startYear || ""}
                        rules={{
                          required: {value: true, message: "กรุณาเลือกปีที่เริ่มการศึกษา"},
                        }}
                        render={({field})=>(
                          <TextFieldTheme
                            {...field}
                            label="เริ่มการศึกษา"
                            select
                            helperText={errors && errors.education && errors.education[index] && errors.education[index].startYear && errors.education[index].startYear.message}
                            error={errors && errors.education && errors.education[index] && errors.education[index].startYear? true: false}
                          >
                            {getOptionYears().map(year => (
                              <MenuItem key={`year_${year.value}`} value={`${year.value}`}>{year.label}</MenuItem>
                            ))}
                          </TextFieldTheme>

                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`education.${index}.endYear`}
                        control={control}
                        // defaultValue={education.endYear || ""}
                        rules={{
                          required: {value: true, message: "กรุณาเลือกปีที่จบการศึกษา"},
                        }}
                        render={({field})=>(
                          <TextFieldTheme
                            {...field}
                            label="จบการศึกษา"
                            select
                            helperText={errors && errors.education && errors.education[index] && errors.education[index].endYear && errors.education[index].endYear.message}
                            error={errors && errors.education && errors.education[index] && errors.education[index].endYear? true: false}
                          >
                            {/* <MenuItem value={`0`}>ปัจจุบัน</MenuItem> */}
                            {getOptionYears().map(year => (
                              <MenuItem key={`year_${year.value}`} value={`${year.value}`}>{year.label}</MenuItem>
                            ))}
                          </TextFieldTheme>

                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name={`education.${index}.universityName`}
                    control={control}
                    // defaultValue={education.universityName || ""}
                    rules={{
                      required: {value: true, message: "กรุณากรอกชื่อสถาบัน"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="ชื่อสถาบัน"
                        helperText={errors && errors.education && errors.education[index] && errors.education[index].universityName && errors.education[index].universityName.message}
                        error={errors && errors.education && errors.education[index] && errors.education[index].universityName? true: false}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name={`education.${index}.degree`}
                    control={control}
                    // defaultValue={education.degree || ""}
                    rules={{
                      required: {value: true, message: "กรุณาเลือกระดับการศึกษา"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="ระดับการศึกษา"
                        select
                        helperText={errors && errors.education && errors.education[index] && errors.education[index].degree && errors.education[index].degree.message}
                        error={errors && errors.education && errors.education[index] && errors.education[index].degree? true: false}
                      >
                        {degrees.map(d => (
                          <MenuItem key={`deegree_${d.idDegrees}`} value={`${d.idDegrees}`}>{d.degreeName}</MenuItem>
                        ))}
                      </TextFieldTheme>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name={`education.${index}.faculty`}
                    control={control}
                    // defaultValue={education.major || ""}
                    rules={{
                      // required: {value: true, message: "กรุณากรอกคณะ"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="คณะ"
                        helperText={errors && errors.education && errors.education[index] && errors.education[index].faculty && errors.education[index].faculty.message}
                        error={errors && errors.education && errors.education[index] && errors.education[index].faculty? true: false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name={`education.${index}.major`}
                    control={control}
                    // defaultValue={education.major || ""}
                    rules={{
                      // required: {value: true, message: "กรุณากรอกสาขาวิชา"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="สาขาวิชา"
                        helperText={errors && errors.education && errors.education[index] && errors.education[index].major && errors.education[index].major.message}
                        error={errors && errors.education && errors.education[index] && errors.education[index].major? true: false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name={`education.${index}.gpa`}
                    control={control}
                    // defaultValue={education.gpa || ""}
                    rules={{
                      // required: {value: true, message: "กรุณากรอกเกรดเฉลี่ย"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="GPA"
                        helperText={errors && errors.education && errors.education[index] && errors.education[index].gpa && errors.education[index].gpa.message}
                        error={errors && errors.education && errors.education[index] && errors.education[index].gpa? true: false}
                      />
                    )}
                  />
                </Grid>

              </Grid>

              <IconButton
                onClick={()=>{educationUseFieldArray.remove(index)}}
                style={{position: "absolute", right: 0, top: 16}}
              >
                <i className="fa-regular fa-trash"></i>
              </IconButton>

            </div>
          </Grid>
        ))}


        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Typography variant="h5">ประสบการณ์ทำงาน</Typography>
          <ButtonBlue
            variant="outlined"
            startIcon={<i className="fa-light fa-plus"></i>}
            onClick={()=>{workUseFieldArray.append({
              startYear: "",
              endYear: "",
              companyName: "",
              positionName: "",
              jobGroup: null,
            })}}
          >
            เพิ่ม
          </ButtonBlue>
        </Grid>

        {workUseFieldArray.fields.length <= 0 && (
          <Grid item xs={12}>
            <Typography textAlign="center" color="text.secondary">ไม่มีประสบการณ์ทำงาน</Typography>
          </Grid>
        )}

        {workUseFieldArray.fields.map((work, index) => (
          <Grid key={work.id} item xs={12}>
            <div style={{paddingRight: 64, position: "relative"}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name={`work.${index}.startYear`}
                        control={control}
                        // defaultValue={education.endYear || ""}
                        rules={{
                          required: {value: true, message: "กรุณาเลือกปีที่เริ่มงาน"},
                        }}
                        render={({field})=>(
                          <TextFieldTheme
                            {...field}
                            label="เริ่มงาน"
                            select
                            helperText={errors && errors.work && errors.work[index] && errors.work[index].startYear && errors.work[index].startYear.message}
                            error={errors && errors.work && errors.work[index] && errors.work[index].startYear? true: false}
                          >
                            {getOptionYears().map(year => (
                              <MenuItem key={`year_${year.value}`} value={`${year.value}`}>{year.label}</MenuItem>
                            ))}
                          </TextFieldTheme>

                        )}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controller
                        name={`work.${index}.endYear`}
                        control={control}
                        // defaultValue={education.endYear || ""}
                        rules={{
                          required: {value: true, message: "กรุณาเลือกปีที่ออกจากงาน"},
                        }}
                        render={({field})=>(
                          <TextFieldTheme
                            {...field}
                            label="ออกจากงาน"
                            select
                            helperText={errors && errors.work && errors.work[index] && errors.work[index].endYear && errors.work[index].endYear.message}
                            error={errors && errors.work && errors.work[index] && errors.work[index].endYear? true: false}
                          >
                            <MenuItem value={`0`}>ปัจจุบัน</MenuItem>
                            {getOptionYears().map(year => (
                              <MenuItem key={`year_${year.value}`} value={`${year.value}`}>{year.label}</MenuItem>
                            ))}
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                  </Grid> 
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name={`work.${index}.companyName`}
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณากรอกชื่อบริษัท"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="ชื่อบริษัท"
                        helperText={errors && errors.work && errors.work[index] && errors.work[index].companyName && errors.work[index].companyName.message}
                        error={errors && errors.work && errors.work[index] && errors.work[index].companyName? true: false}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name={`work.${index}.positionName`}
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณากรอกตำแหน่งงาน"},
                    }}
                    render={({field}) => (
                      <TextFieldTheme
                        {...field}
                        label="ตำแหน่งงาน"
                        helperText={errors && errors.work && errors.work[index] && errors.work[index].positionName && errors.work[index].positionName.message}
                        error={errors && errors.work && errors.work[index] && errors.work[index].positionName? true: false}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name={`work.${index}.jobGroup`}
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณากรอกกลุ่มงาน"},
                    }}
                    render={({field, formState}) => (
                      // <TextFieldTheme
                      //   {...field}
                      //   label="กลุ่มงาน"
                      //   helperText={errors && errors.work && errors.work[index] && errors.work[index].idJobGroup && errors.work[index].idJobGroup.message}
                      //   error={errors && errors.work && errors.work[index] && errors.work[index].idJobGroup? true: false}
                      // />
                      <Autocomplete
                        sx={{
                          "& .MuiInputBase-root": {
                            paddingLeft: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            "& input": {
                              padding: "13.5px!important",
                            }
                          }
                        }}
                        disableClearable
                        options={[...jobGroups].sort((a, b) => a.jobGroupName.localeCompare(b.jobGroupName))}
                        getOptionLabel={(option) => option.jobGroupName}
                        isOptionEqualToValue={(option, value) => (option.idJobGroup === value.idJobGroup)}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.idJobGroup}>
                            {option.jobGroupName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            label="กลุ่มงาน"
                            error={formState.errors && formState.errors.work && formState.errors.work[index] && formState.errors.work[index].jobGroup? true: false}
                            helperText={formState.errors && formState.errors.work && formState.errors.work[index] && formState.errors.work[index].jobGroup? formState.errors.work[index].jobGroup.message: null}
                          />
                        )}
                        noOptionsText="ไม่พบคำที่ค้นหา"
                        value={field.value}
                        onChange={(_, data) => {
                          field.onChange(data);
                        }}
                      />
                    )}
                  />
                </Grid>

              </Grid>

              <IconButton
                onClick={()=>{workUseFieldArray.remove(index)}}
                style={{position: "absolute", right: 0, top: 16}}
              >
                <i className="fa-regular fa-trash"></i>
              </IconButton>
            </div>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <ButtonBlue variant="contained" onClick={()=>{formAction.prevForm()}}>ก่อนหน้า</ButtonBlue>
            <ButtonBlue variant="contained" type="submit" disabled={isFetching}>ยืนยัน</ButtonBlue>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const UploadFile = ({useForm}) => {

  const { setValue: formOneSetValue } = useForm.formOneUseForm;
  const { setValue: formTwoSetValue } = useForm.formTwoUseForm;
  const { setValue: formThreeSetValue, reset: formThreeReset } = useForm.formThreeUseForm;

  const url = "http://localhost:8081/resume/extraction";
  const [loading, setLoading] = useState(false);

  
  const onDrop = useCallback((acceptedFiles) => {
    setLoading(true);
    acceptedFiles.forEach(async (file) => {
      const formData = new FormData();
      formData.append("file", file);
      await axios
        .post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          // setData(res.data);
          formOneSetValue("firstname", res.data.fname);
          formOneSetValue("lastname", res.data.lname);
          formOneSetValue("phoneNumber", res.data.phone);
          formOneSetValue("email", res.data.email);

          let maxDegree = 0
          let maxWorkExp = res.data.sum_year_exp === 0 ? 1: res.data.sum_year_exp || ""

          const educations = res.data.education.map((e) => {

            let degree = 0;

            
            if(e.education_level && e.education_level.includes("Doctoral")){
              degree = 6
            }else if(e.education_level && e.education_level.includes("Master")){
              degree = 5
            } else if(e.education_level && e.education_level.includes("Bachelor")){
              degree = 4
            } else if(e.education_level && e.education_level.includes("High")){
              degree = 2
            }

            if(degree > maxDegree){
              maxDegree = degree
            }

            return { 
              startYear: e["start_year"] || "",
              endYear: e["end_year"] || "",
              universityName: e["institution_name"] || "",
              degree: degree || "",
              faculty: e["faculty"] || "",
              gpa: e["gpa"] || "",
              major: e["program_name"] || "",
            }
          })
          // console.log(res.data);
          // formThreeSetValue("education", educations)
          const works = res.data.experience.map((e) => {
            return {
              startYear: e["start_year"] || "",
              endYear: e["end_year"] || "",
              companyName: e["company_name"] || "",
              positionName: e["position"] || "",
            }
          })
          // console.log(res.data);
          // formThreeSetValue("education", educations)
          formThreeReset({
            education: educations,
            work: works,
          })

          formTwoSetValue("educationDegree", (maxDegree || ""))
          formTwoSetValue("workExperience", maxWorkExp)


          // console.log(educations)

        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const dropArea = () => {
    if (!loading) {
      if (isDragActive) {
        return (
          <>
            <Typography>วางไฟล์ได้ที่นี่...</Typography>
          </>
        );
      } else {
        return (
          <>
            <UploadFileOutlinedIcon sx={{ fontSize: 50, color: "#1976d2" }} />
            <Typography>
              คุณสามารถลากและวางไฟล์เรซูเม่ได้ที่นี่, หรือคลิ๊กเพื่อเลือกไฟล์
            </Typography>
          </>
        );
      }
    } else {
      return (
        <>
          <CircularProgress />
          <Typography>กำลังประมวลผล...</Typography>
        </>
      );
    }
  };

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      {dropArea()}
    </div>
  );
}

export default AddManpowerFormPage;