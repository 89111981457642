import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Autocomplete, createFilterOptions, Checkbox, Chip, Container, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, MenuItem, Radio, RadioGroup, styled, Switch, Typography, CircularProgress, IconButton, Box } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import TextFieldNumber from "../../shared/general/TextFieldNumber";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useDispatch, useSelector } from "react-redux";
import { addJob, getJobForm, getJobEdit, updateJob, addJobAttachFile, deleteJobAttachFile } from "../../../../actions/job";
import { getAllExpertises } from "../../../../actions/expertise";
import { Link, useHistory, useLocation } from "react-router-dom";
import Dropzone, { useDropzone } from "react-dropzone";
import UploadFileOutlined from "@mui/icons-material/UploadFileOutlined";
import { getBiddingMinGuaranteeContract } from "../../../../actions/bidding";
import NumberFormat from "react-number-format";
import { getAntHREmployees } from "../../../../actions/anthr";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";

const StyledRoot = styled("div")({
  "& .header-text": {
    marginBottom: 24,
  },
  "& .confirm-button": {
    padding: 10,
  },
  "& .success-container": {
    display: "flex",
    justifyContent: "center",
    padding: "48px 0",
  },
  "& .dropzone": {
    // marginBottom: 24,
    border: `2px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: 10,
    background: "white",
    minHeight: 100,
    outline: "none",
    padding: 20,
    "& .MuiTypography-root, & svg": {
      color: "#919eab"
    },
    "& .file-show": {
      display: "flex",
      flexWrap: "wrap",
      gap: 10,
    },
    "& .empty-file": {
      minHeight: "inherit",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }
  },
  "& .input-with-select": {
    "& .MuiInputBase-root": {
      paddingRight: 0,
    },
    "& .select-input": {
      width: 110,
      "& .MuiSelect-select": {
        color: "#919eab",
      },
      "& fieldset": {
        border: "none",
        borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      "& .MuiOutlinedInput-input": {
        textAlign: "right",
        paddingLeft: "4px!important",
      }
    }
  }
})

const StyledForm = styled("div")({
  padding: 24,
  paddingTop: 16,
  "& .is-false": {
    color: "#9e9e9e",
    backgroundColor: "#e4e4e4",
  },
  "& .autocomplete": {
    "& .MuiInputBase-root": {
      paddingTop: "13.5px",
      paddingBottom: "13.5px",
      paddingLeft: "14px",
    },
    "& input": {
      padding: "0px !important",
    }
  },
  "& .number-input": {
    "& input": {
      textAlign: "center",
    }
  },
})

const StyledChip = styled(Chip)({
  color: "#b72136",
  backgroundColor: "#ff484229",
  fontWeight: 700,
  borderRadius: 6,
});

const StandardContract = styled(FormControlLabel)({
  alignItems: "center",
  "& .MuiCheckbox-root": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& .MuiTypography-body1": {
    fontSize: 16,
  },
});

const filter = createFilterOptions();

const CompanyRequestFormPage = () => {

  const location = useLocation();
  const history = useHistory();
  const { isFetching: isJobFetching, result: JobDetail } = useSelector(state => state.jobEdit);

  const [isFetching, setIsFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [idJob, setIdJob] = useState(null);

  const [isReplace, setIsReplace] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  // const [defaultJob, setDefaultJob] = useState()

  const { control, handleSubmit, getValues, setValue, formState: { errors, dirtyFields }, watch, reset, clearErrors } = useForm({
    defaultValues: {
      isUrgent: false,
      positionName: "",
      idJobGroup: null,
      departmentName: "",
      idBranch: null,
      workLocationDetail: "",
      jobDescription: "",
      expertises: [],
      workExperience: "",
      educationDegree: "",
      employeeType: "",
      closeRegisterDate: null,
      salaryType: "monthly",
      minSalary: "",
      maxSalary: "",
      quota: "",
      vendorSelectedType: "all",
      vendorCustomize: [],
      isStandardContract: false,
      chargeSalary: "",
      chargeOt: "",
      chargeHire: "",
      chargeHireType: "1",
      chargeCompensation: "",
      attachFile: [],
      jobOpeningType: 1,
      bidding: null,
      jobSkillList: [],
    }
  })

  const jobSkillListFieldArray = useFieldArray({
    control: control,
    name: "jobSkillList",
  })

  const initialJobSkillData = {
    skillName: "",
  }

  const salaryTypeWatch = watch("salaryType");
  const vendorSelectedTypeWatch = watch("vendorSelectedType");
  const isUrgentWatch = watch("isUrgent");
  const jobOpeningTypeWatch = watch("jobOpeningType");

  const dispatch = useDispatch();
  const { result: userProfileStore } = useSelector((state) => state.userProfile);
  const { result: jobFormStore } = useSelector((state) => state.jobForm);
  const { result: allExpertises } = useSelector((state) => state.expertises);
  const { isFetching: biddingMinGuaranteeContractFetching, result: biddingMinGuaranteeContractStore } = useSelector(state => state.biddingMinGuaranteeContract);
  const [companyVendor, setCompanyVendor] = useState([]);
  const [biddingMinGuaranteeContract, setBiddingMinGuaranteeContract] = useState([]);
  const { result: anthrEmployeeStore } = useSelector((state) => state.anthrEmployees);
  const [employees, setEmployees] = useState([]);
  const [useStandardContract, setUseStandardContract] = useState(false);
  
  let timeoutDisplay;

  const handleCloseDisplay = (idJobNew) => {
    clearInterval(timeoutDisplay);
    if(idJobNew){
      history.push(`/company/request/${idJobNew}`);
    } else {
      history.push(`/company/request/${idJob}`);
    }
  };

  const handleCountDownTimeSet = (idJobNew) => {
    timeoutDisplay = setTimeout(() => handleCloseDisplay(idJobNew), 3000);
  };

  const handleChangeIsReplace = (_, value) => {
    if(value === "true"){
      dispatch(getAntHREmployees());
      setIsReplace(true)
    } else {
      setIsReplace(false)
    }
  }

  useEffect(() => {
    if(anthrEmployeeStore !== null){
      setEmployees([...anthrEmployeeStore])
    }
  }, [anthrEmployeeStore])

  const handleChangeReplaceEmployees = (_, data) => {
    setValue("positionName", {positionName: data.positionName})
    setValue("idJobGroup", data.idJobGroup)
    setValue("departmentName", {departmentName: data.departmentName})
    setValue("maxSalary", data.salary)
    setValue("chargeSalary", data.chargeSalary === null? "": data.chargeSalary)
    setValue("chargeOt", data.chargeOt === null? "": data.chargeOt)
    setValue("chargeHire", data.chargeHire === null? "": data.chargeHire)
    setValue("chargeCompensation", data.chargeCompensation === null? "": data.chargeCompensation)

    if(data.idBranch === null){
      setValue("idBranch", {idBranch: 0, branchName: jobFormStore.companyName})
    }else {
      setValue("idBranch", {idBranch: data.idBranch, branchName: jobFormStore.branches.find(b => b.idBranch === data.idBranch).branchName})
    }
  }

  const onDropAttachFile = async (acceptedFiles) => {

    if(acceptedFiles.length + getValues("attachFile").length <= 1){

      if(isEdit){
        const formData = new FormData();
        formData.append("attachFile", acceptedFiles[0])
        const response = await dispatch(addJobAttachFile(location.state.idJob, formData))
        if(response.status === 200){
          setValue("attachFile", [...getValues("attachFile"), ...acceptedFiles], {
            shouldValidate: true,
          })
        }
        return
      }

      setValue("attachFile", [...getValues("attachFile"), ...acceptedFiles], {
        shouldValidate: true,
      })
    }
  }

  const onDeleteAttachFile = async (index) => {

    if(isEdit){
      const response = await dispatch(deleteJobAttachFile(location.state.idJob))
      if(response.status === 200){
        setValue("attachFile", [], {
          shouldValidate: true,
        })
      }
      return
    }

    let arr = getValues("attachFile");
    arr.splice(index, 1);
    setValue("attachFile", arr)
  }

  const onSubmit = async (formData) => {

    setIsFetching(true);

    if(!isEdit){
      let cleansingData = {
        isUrgent: formData.isUrgent,
        idJobGroup: formData.idJobGroup.idJobGroup,
        idBranch: formData.idBranch.idBranch,
        workLocationDetail: formData.workLocationDetail,
        jobDescription: formData.jobDescription,
        workExperienceMin: formData.workExperience.split(",")[0],
        workExperienceMax: formData.workExperience.split(",")[1],
        educationDegree: formData.educationDegree,
        employeeType: formData.employeeType,
        closeRegisterDate: formData.closeRegisterDate,
        salaryType: formData.salaryType,
        minSalary: formData.minSalary || null,
        maxSalary: formData.maxSalary || null,
        quota: formData.quota,
        vendorSelectedType: formData.vendorSelectedType,
        vendorCustomize: formData.vendorCustomize.map(v => { return {idVendor: v.idVendor}}),
        jobOpeningType: formData.jobOpeningType,
        idBidding: formData.bidding? formData.bidding.idBiddings: null,
        jobSkillList: formData.jobSkillList,
      }

      if(parseInt(cleansingData.idBranch) === 0){
        cleansingData.idBranch = null;
      }

      if(parseInt(cleansingData.jobOpeningType) === 2){
        cleansingData.vendorSelectedType = null;
      }
  
      if(cleansingData.vendorSelectedType === "all"){
        cleansingData.isStandardContract = formData.isStandardContract
        cleansingData.chargeSalary = formData.chargeSalary === ""? null: formData.chargeSalary
        cleansingData.chargeOt = formData.chargeOt === ""? null: formData.chargeOt
        cleansingData.chargeHire = formData.chargeHire === ""? null: formData.chargeHire
        cleansingData.chargeHireType = (formData.chargeSalary || formData.chargeOt) ? null : formData.chargeHireType
        cleansingData.chargeCompensation = formData.chargeCompensation === ""? null: formData.chargeCompensation
      }else{
        cleansingData.isStandardContract = null
        cleansingData.chargeSalary = null
        cleansingData.chargeOt = null
        cleansingData.chargeHire = null
        cleansingData.chargeHireType = null
        cleansingData.chargeCompensation = null
      }
  
      const checkPosition = jobFormStore.positions.find(position => position.positionName === formData.positionName.positionName.trim())
      if(checkPosition){
        cleansingData.idPosition = checkPosition.idPosition
      }else{
        cleansingData.idPosition = 0
        cleansingData.positionName = formData.positionName.positionName
      }
  
      const checkDepartment = jobFormStore.departments.find(department => department.departmentName === formData.departmentName.departmentName.trim())
      if(checkDepartment){
        cleansingData.idDepartment = checkDepartment.idDepartment
      }else{
        cleansingData.idDepartment = 0
        cleansingData.departmentName = formData.departmentName.departmentName
      }

      // console.log("cleansingData : ",cleansingData);

      const newFormData = new FormData();

      Object.keys(cleansingData).forEach(key => {
        newFormData.append(key, JSON.stringify(cleansingData[key]))
      });

      newFormData.append("attachFile", formData.attachFile[0])

      // for (var pair of newFormData.entries()) {
      //   console.log(pair[0]+ ', ' + pair[1]); 
      // }
  
      const response = await dispatch(addJob(newFormData))

      
      if(response.status === 200){
        setIsSuccess(true)
        setIdJob(response.data.idJob)
        handleCountDownTimeSet(response.data.idJob);
      }
      // setIsFetching(false);

    } else {

      let editedData = {};

      Object.keys(dirtyFields).map(key => {editedData[key] = formData[key] || null})
      // console.log(dirtyFields)

      if(dirtyFields.positionName){
        const checkPosition = jobFormStore.positions.find(position => position.positionName === formData.positionName.positionName.trim())
        if(checkPosition){
          editedData.idPosition = checkPosition.idPosition
          editedData.positionName = undefined;
        }else{
          // console.log("new position")
          editedData.idPosition = 0
          editedData.positionName = formData.positionName.positionName
          // console.log(editedData)
        }
      }
  
      if(dirtyFields.departmentName){
        const checkDepartment = jobFormStore.departments.find(department => department.departmentName === formData.departmentName.departmentName.trim())
        if(checkDepartment){
          editedData.idDepartment = checkDepartment.idDepartment
          editedData.departmentName = undefined;
        }else{
          editedData.idDepartment = 0
          editedData.departmentName = formData.departmentName.departmentName
        }
      }

      if(dirtyFields.idJobGroup){
        editedData.idJobGroup = formData.idJobGroup.idJobGroup
      }

      if(dirtyFields.idBranch){
        editedData.idBranch = formData.idBranch.idBranch
      }

      if(dirtyFields.jobSkillList){
        editedData.jobSkillList = formData.jobSkillList
      }

      if(dirtyFields.quota !== undefined && formData.quota === ""){
        editedData.quota = "0"
      }

      if(dirtyFields.vendorCustomize){
        editedData.vendorCustomize = formData.vendorCustomize.map(v => { return {idVendor: v.idVendor}})
      }

      if(dirtyFields.workExperience){
        editedData.workExperienceMin = formData.workExperience.split(",")[0];
        editedData.workExperienceMax = formData.workExperience.split(",")[1];
        editedData.workExperience = undefined;
      }

      if(dirtyFields.isStandardContract){
        if(formData.isStandardContract){
          editedData.chargeHireType = null
        } else {
          editedData.chargeHireType = formData.chargeHireType === "" ? null : formData.chargeHireType
        }
      }

      if(dirtyFields.vendorSelectedType){
        if(editedData.vendorSelectedType === "all"){
          editedData.isStandardContract = formData.isStandardContract
          editedData.chargeSalary = formData.chargeSalary === ""? null: formData.chargeSalary
          editedData.chargeOt = formData.chargeOt === ""? null: formData.chargeOt
          editedData.chargeHire = formData.chargeHire === ""? null: formData.chargeHire
          editedData.chargeHireType = formData.chargeHireType === "" ? null : formData.chargeHireType
          editedData.chargeCompensation = formData.chargeCompensation === ""? null: formData.chargeCompensation
        }else{
          editedData.isStandardContract = null
          editedData.chargeSalary = null
          editedData.chargeOt = null
          editedData.chargeHire = null
          editedData.chargeHireType = null
          editedData.chargeCompensation = null
        }
      }

      const response = await dispatch(updateJob(location.state.idJob, editedData))
  
      if(response.status === 200){
        setIsSuccess(true);
        handleCountDownTimeSet();
        // history.goBack();
      }

      setIsFetching(false);
    }

  }

  useEffect(() => {
    dispatch(getJobForm());
    dispatch(getAllExpertises());
    dispatch(getBiddingMinGuaranteeContract());
  }, [])

  useEffect(() => {
    if(jobFormStore !== null){
      setCompanyVendor([...jobFormStore.companyVendor])
    }
  }, [jobFormStore])

  useEffect(() => {
    if(biddingMinGuaranteeContractStore !== null){
      setBiddingMinGuaranteeContract([...biddingMinGuaranteeContractStore])
    }
  }, [biddingMinGuaranteeContractStore])

  useEffect(() => {
    if(location.state && location.state.idJob){
      setIsEdit(true)
      setIdJob(location.state.idJob);
      dispatch(getJobEdit(location.state.idJob));
    }
  }, [])

  useEffect(() => {
    if(isEdit && JobDetail !== null && !biddingMinGuaranteeContractFetching){
      const branch = {};
      let bidding = {};

      if(!JobDetail.idBranch){
        branch.idBranch = 0;
        branch.branchName = JobDetail.companyName;
      } else {
        branch.idBranch = JobDetail.idBranch;
        branch.branchName = JobDetail.branchName;
      }

      if(JobDetail.idBidding){
        bidding = biddingMinGuaranteeContract.find(b => b.idBiddings === JobDetail.idBidding)
      } else {
        bidding = null;
      }

      reset({
        positionName: {positionName: JobDetail.positionName},
        idJobGroup: {idJobGroup: JobDetail.idJobGroup, jobGroupName: JobDetail.jobGroupName},
        departmentName: {departmentName: JobDetail.departmentName},
        idBranch: branch,
        isUrgent: Boolean(JobDetail.isUrgent),
        workLocationDetail: JobDetail.workLocationDetail,
        jobDescription: JobDetail.jobDescription,
        expertises: JobDetail.expertises || [],
        employeeType: JobDetail.employeeType,
        closeRegisterDate: new Date(JobDetail.closeRegisterDate),
        salaryType: JobDetail.salaryType,
        minSalary: String(JobDetail.minSalary),
        maxSalary: String(JobDetail.maxSalary),
        workExperience: String(`${JobDetail.workExperienceMin},${JobDetail.workExperienceMax}`),
        educationDegree: String(JobDetail.educationDegree),
        quota: JobDetail.quota === 0? "": String(JobDetail.quota),
        vendorSelectedType: JobDetail.vendorSelectedType,
        vendorCustomize: JobDetail.vendorCustomize || [],
        isStandardContract: JobDetail.isStandardContract ? Boolean(JobDetail.isStandardContract) : false,
        chargeSalary: (JobDetail.chargeSalary === null || JobDetail.chargeSalary === "" || JobDetail.chargeSalary === undefined)? "": String(JobDetail.chargeSalary),
        chargeOt: (JobDetail.chargeOt === null || JobDetail.chargeOt === "" || JobDetail.chargeOt === undefined)? "": String(JobDetail.chargeOt),
        chargeHire: (JobDetail.chargeHire === null || JobDetail.chargeHire === "" || JobDetail.chargeHire === undefined)? "": String(JobDetail.chargeHire),
        chargeHireType: (JobDetail.chargeHireType === null || JobDetail.chargeHireType === "" || JobDetail.chargeHireType === undefined)? "1": String(JobDetail.chargeHireType),
        chargeCompensation: (JobDetail.chargeCompensation === null || JobDetail.chargeCompensation === "" || JobDetail.chargeCompensation === undefined)? "": String(JobDetail.chargeCompensation),
        jobOpeningType: JobDetail.jobOpeningType,
        bidding: bidding,
        attachFile: JobDetail.attachFileName? [{name: JobDetail.attachFileName}]: [],
        jobSkillList: JobDetail.jobSkills
      })
    }
  }, [JobDetail, biddingMinGuaranteeContract])

  return(
    <StyledRoot className="page">
      {!isSuccess && (
        <Fragment>
          {(!isEdit || (isEdit && JobDetail)) && <Container maxWidth="lg">
            {!isEdit && <Typography className="header-text" variant="h4">เปิดรับสมัครงาน</Typography>}
            {isEdit && <Typography className="header-text" variant="h4">แก้ไขรับสมัครงาน / {JobDetail && JobDetail.positionName}</Typography>}
            <CardStyle>
              <StyledForm>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name="isUrgent"
                        render={({field})=>(
                          <Fragment>
                            <Switch {...field} checked={field.value}/>
                            <StyledChip className={isUrgentWatch? ``: `is-false`} label="งานด่วน" size="small"/>
                          </Fragment>
                        )}
                      />
                    </Grid>
                    
                    <Grid item xs={6}>
                      <RadioGroup
                        value={isReplace}
                        onChange={handleChangeIsReplace} 
                        row
                        disabled={isEdit}
                      >
                        <FormControlLabel value="false" control={<Radio />} label="งานใหม่"/>
                        <FormControlLabel value="true" control={<Radio />} label={<span>แทนพนักงานเดิม (เฉพาะ <a href="https://www.anthr.co" target="_blank" style={{textDecoration: "none", color: "#1976d2"}}>AntHR</a>)</span>}/>
                      </RadioGroup>

                      {isReplace && (
                        <Autocomplete
                          className="autocomplete"
                          disableClearable
                          options={employees}
                          getOptionLabel={(option) => option.employeeName}
                          isOptionEqualToValue={(option, value) => option.idEmp === value.idEmp}
                          noOptionsText="ไม่มีตัวเลือก"
                          // renderOption={(props, option, { selected }) => (
                          //   <li {...props} key={option.idBiddings}>
                          //     <div>
                          //       <div>
                          //         <Typography>{`${option.biddingName} (id: ${option.idBiddings})`}</Typography>
                          //         <Typography>{option.vendorName}</Typography>
                          //         <Typography fontSize={14}>{option.bidType === 1? "ประเภทค่าชาร์จ": option.bidType === 2? "ประเภทงบประมาณ": ""}</Typography>
                          //       </div>
                          //       <ButtonBlue component={Link} target="_blank" to={`/bidding/${option.idBiddings}`} onClick={(e)=>{e.stopPropagation()}}>ดูข้อมูล</ButtonBlue>
                          //     </div>
                          //   </li>
                          // )}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              // label="สัญญาการันตีขั้นต่ำ"
                            />
                          )}
                          onChange={handleChangeReplaceEmployees}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="positionName"
                        rules={{
                          required: {value: true, message: "กรุณากรอกตำแหน่งงาน"},
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            className="autocomplete"
                            freeSolo
                            disableClearable
                            options={(jobFormStore && jobFormStore.positions) || []}
                            value={value}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                      
                              const { inputValue } = params;
                              const isExisting = options.some((option) => inputValue === option.positionName);
                              if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  positionName: `เพิ่ม "${inputValue}"`,
                                });
                              }
                      
                              return filtered;
                            }}
                            getOptionLabel={(option) => {
                              if (typeof option === 'string') {
                                return option;
                              }
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              return option.positionName;
                            }}
                            isOptionEqualToValue={(option, value) => option.positionName === value.positionName}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                {option.positionName}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                label="ตำแหน่งงาน"
                                helperText={errors && errors.positionName && errors.positionName.message}
                                error={errors && errors.positionName ? true: false}
                              />
                            )}
                            onChange={(_, data) => {
                              if (typeof data === 'string') {
                                onChange({
                                  positionName: data,
                                });
                              } else if (data && data.inputValue) {
                                onChange({
                                  positionName: data.inputValue,
                                });
                              } else {
                                onChange(data);
                              }

                              return data
                            }}
                            
                            onBlur={(event) => {
                              if(event.target.value.length === 0){
                                onChange(null);
                              }
                              else{
                                onChange({
                                  positionName: event.target.value,
                                });
                              }
                              return event.target.value
                            }}
                          />
                        )}
                      />                  
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="idJobGroup"
                        rules={{
                          required: {value: true, message: "กรุณากรอกกลุ่มงาน"},
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            className="autocomplete"
                            disableClearable
                            value={value}
                            options={(jobFormStore && jobFormStore.jobGroups) || []}
                            getOptionLabel={(option) => option.jobGroupName || ""}
                            isOptionEqualToValue={(option, value) => option.idJobGroup === value.idJobGroup}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} key={option.idJobGroup}>
                                {option.jobGroupName}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                label="กลุ่มงาน"
                                helperText={errors && errors.idJobGroup && errors.idJobGroup.message}
                                error={errors && errors.idJobGroup ? true: false}
                              />
                            )}
                            onChange={(_, data) => {
                              onChange(data);
                              return data
                            }}
                          />
                        )}
                      />                  
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="departmentName"
                        rules={{
                          required: {value: true, message: "กรุณากรอกหน่วยงาน"},
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            className="autocomplete"
                            freeSolo
                            disableClearable
                            value={value}
                            options={(jobFormStore && jobFormStore.departments) || []}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                      
                              const { inputValue } = params;
                              const isExisting = options.some((option) => inputValue === option.departmentName);
                              if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  departmentName: `เพิ่ม "${inputValue}"`,
                                });
                              }
                      
                              return filtered;
                            }}
                            getOptionLabel={(option) => {
                              if (typeof option === 'string') {
                                return option;
                              }
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              return option.departmentName || "";
                            }}
                            isOptionEqualToValue={(option, value) => option.departmentName === value.departmentName}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                {option.departmentName}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                label="หน่วยงาน"
                                helperText={errors && errors.departmentName && errors.departmentName.message}
                                error={errors && errors.departmentName ? true: false}
                              />
                            )}
                            onChange={(_, data) => {
                              if (typeof data === 'string') {
                                onChange({
                                  departmentName: data,
                                });
                              } else if (data && data.inputValue) {
                                onChange({
                                  departmentName: data.inputValue,
                                });
                              } else {
                                onChange(data);
                              }

                              return data
                            }}
                            
                            onBlur={(event) => {
                              if(event.target.value.length === 0){
                                onChange(null);
                              }
                              else{
                                onChange({
                                  departmentName: event.target.value,
                                });
                              }
                              return event.target.value
                            }}
                          />
                        )}
                      />                  
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="idBranch"
                        rules={{
                          required: {value: true, message: "กรุณากรอกสาขาที่ทำงาน"},
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            className="autocomplete"
                            disableClearable
                            value={value}
                            options={(jobFormStore && [
                              {idBranch: 0, branchName: jobFormStore.companyName},
                              ...jobFormStore.branches
                            ]) || []}
                            getOptionLabel={(option) => option.branchName || ""}
                            isOptionEqualToValue={(option, value) => option.idBranch === value.idBranch}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                {option.branchName}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                label="สาขาที่ทำงาน"
                                helperText={errors && errors.idBranch && errors.idBranch.message}
                                error={errors && errors.idBranch ? true: false}
                              />
                            )}
                            onChange={(_, data) => {
                              onChange(data);
                              return data
                            }}
                          />
                        )}
                      />                  
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="workLocationDetail"
                        rules={{
                          required: {value: true, message: "กรุณากรอกรายละเอียดสถานที่ทำงาน"},
                        }}
                        render={({field})=>(
                          <TextFieldTheme
                            {...field}
                            label="รายละเอียดสถานที่ทำงาน"
                            placeholder="เช่น ตึก อาคาร ชั้น"
                            helperText={errors && errors.workLocationDetail && errors.workLocationDetail.message}
                            error={errors && errors.workLocationDetail ? true: false}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="jobDescription"
                        rules={{
                          required: {value: true, message: "กรุณากรอกรายละเอียดของงาน"},
                        }}
                        render={({field})=>(
                          <TextFieldTheme
                            {...field}
                            label="รายละเอียดของงาน"
                            multiline
                            rows={4}
                            helperText={errors && errors.jobDescription && errors.jobDescription.message}
                            error={errors && errors.jobDescription ? true: false}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <Typography flexGrow={1}>ทักษะที่เกี่ยวกับงาน</Typography>
                        <IconButton onClick={()=>{jobSkillListFieldArray.fields.length < 3 && jobSkillListFieldArray.append(initialJobSkillData)}} disabled={jobSkillListFieldArray.fields.length >= 3}>
                          <i className="fa-regular fa-circle-plus"></i>
                        </IconButton>
                      </Box>
                    </Grid>
                    {jobSkillListFieldArray.fields.length <= 0 && (
                      <Grid item xs={12}>
                        <Typography textAlign="center" color="text.third">ไม่มีทักษะที่เกี่ยวข้อง</Typography>
                      </Grid>
                    )}
                    {jobSkillListFieldArray.fields.map((jobSkill, index) => (
                      <Grid key={jobSkill.id} item xs={12}>
                        <Box display="flex" alignItems="flex-start">
                          <Controller
                            control={control}
                            name={`jobSkillList.${index}.skillName`}
                            rules={{
                              required: {
                                value: true,
                                message: "กรุณากรอกทักษะ"
                              }
                            }}
                            render={({field, formState}) => (
                              <TextFieldTheme
                                {...field}
                                placeholder="ทักษะ"
                                error={formState.errors && formState.errors.jobSkillList && formState.errors.jobSkillList[index] && formState.errors.jobSkillList[index].skillName? true: false}
                                helperText={formState.errors && formState.errors.jobSkillList && formState.errors.jobSkillList[index] && formState.errors.jobSkillList[index].skillName? formState.errors.jobSkillList[index].skillName.message: null}
                              />
                            )}
                          />
                          <IconButton onClick={()=>{jobSkillListFieldArray.remove(index)}}>
                            <i className="fa-regular fa-trash"></i>
                          </IconButton>
                        </Box>
                      </Grid>
                    ))}
                    {/* <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="expertises"
                        rules={{
                          required: {value: true, message: "กรุณากรอกความเชี่ยวชาญเกี่ยวกับงานที่ต้องการ"},
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            className="autocomplete"
                            multiple
                            options={allExpertises || []}
                            value={value}
                            getOptionLabel={(option) => option.expertiseName}
                            isOptionEqualToValue={(option, value) => option.expertiseName === value.expertiseName}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.expertiseName}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextFieldTheme
                                {...params}
                                label="ความเชี่ยวชาญเกี่ยวกับงานที่ต้องการ"
                                placeholder="ความเชี่ยวชาญ"
                                helperText={errors && errors.expertises && errors.expertises.message}
                                error={errors && errors.expertises ? true: false}
                              />
                            )}
                            onChange={(_, data) => {
                              onChange(data);
                              return data
                            }}
                          />
                        )}
                      />                  
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="workExperience"
                        rules={{
                          required: {value: true, message: "กรุณาเลือกประสบการณ์ทำงาน"},
                        }}
                        render={({field})=>(
                          <TextFieldTheme
                            {...field}
                            label="ประสบการณ์ทำงาน"
                            helperText={errors && errors.workExperience && errors.workExperience.message}
                            error={errors && errors.workExperience ? true: false}
                            select
                          >
                            <MenuItem value="0,0">ไม่จำกัด</MenuItem>
                            <MenuItem value="0,3">0-3 ปี</MenuItem>
                            <MenuItem value="3,5">3-5 ปี</MenuItem>
                            <MenuItem value="5,7">5-7 ปี</MenuItem>
                            <MenuItem value="7,9">7-9 ปี</MenuItem>
                            <MenuItem value="9,0">9 ปีขึ้นไป</MenuItem>
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="educationDegree"
                        rules={{
                          required: {value: true, message: "กรุณาเลือกระดับการศึกษาขั้นต่ำ"},
                        }}
                        render={({field})=>(
                          <TextFieldTheme
                            {...field}
                            label="ระดับการศึกษาขั้นต่ำ"
                            helperText={errors && errors.educationDegree && errors.educationDegree.message}
                            error={errors && errors.educationDegree ? true: false}
                            select
                          >
                            <MenuItem value="0">ไม่จำกัด</MenuItem>
                            <MenuItem value="1">ประถมศึกษา</MenuItem>
                            <MenuItem value="2">มัธยมศึกษา</MenuItem>
                            <MenuItem value="3">ปวช./ปวส.</MenuItem>
                            <MenuItem value="4">ปริญญาตรี</MenuItem>
                            <MenuItem value="5">ปริญญาโท</MenuItem>
                            <MenuItem value="6">ปริญญาเอก</MenuItem>
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="employeeType"
                        rules={{
                          required: {value: true, message: "กรุณาเลือกประเภทพนักงาน"},
                        }}
                        render={({field})=>(
                          <TextFieldTheme
                            {...field}
                            label="ประเภทพนักงาน"
                            helperText={errors && errors.employeeType && errors.employeeType.message}
                            error={errors && errors.employeeType ? true: false}
                            select
                          >
                            <MenuItem value="FULLTIME">ประจำ</MenuItem>
                            <MenuItem value="CONTRACT">ชั่วคราว</MenuItem>
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="closeRegisterDate"
                        rules={{
                          required: {value: true, message: "กรุณากรอกวันที่ปิดรับสมัคร"},
                          validate: {
                            dateValidation : (value) => !isNaN(value) || "กรุณากรอกวันที่ให้ถูกต้อง",
                            disblePast: (value) => ((new Date(value)) >= new Date(new Date().setHours(0,0,0,0))) || "ไม่สามารถเลือกวันที่ผ่านมาแล้วได้"
                          }
                        }}
                        render={({field})=>(
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              {...field}
                              label="ปิดรับสมัครวันที่"
                              inputFormat="DD/MM/YYYY"
                              disablePast
                              renderInput={(params) => (
                                <TextFieldTheme
                                  {...params}
                                  helperText={errors && errors.closeRegisterDate && errors.closeRegisterDate.message}
                                  error={errors && errors.closeRegisterDate ? true: false}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="salaryType"
                        // rules={{
                        //   required: {value: true, message: "กรุณาเลือกการจ่ายค่าจ้าง"},
                        // }}
                        render={({field})=>(
                          <TextFieldTheme
                            {...field}
                            label="การจ่ายค่าจ้าง"
                            helperText={errors && errors.salaryType && errors.salaryType.message}
                            error={errors && errors.salaryType ? true: false}
                            select
                          >
                            <MenuItem value="daily">รายวัน</MenuItem>
                            <MenuItem value="monthly">รายเดือน</MenuItem>
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="salary"
                        // rules={{
                        //   required: {value: true, message: "กรุณาเลือกกรอกค่าจ้าง"},
                        // }}
                        render={({field}) => (
                          <TextFieldTheme
                            {...field}
                            label="ค่าจ้าง"
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                              endAdornment: <InputAdornment position="end">{salaryTypeWatch === "daily"? "ต่อวัน": "ต่อเดือน"}</InputAdornment> ,
                            }}
                            inputProps={{
                              allowNegative: false,
                              thousandSeparator:",",
                              suffix:" บาท",
                              onValueChange:(values) => {
                                const {formattedValue, value, floatValue} = values;
                                field.onChange(value)
                              }
                            }}
                            helperText={errors && errors.salary && errors.salary.message}
                            error={errors && errors.salary ? true: false}
                            onChange={()=>{}}
                          />
                        )}
                      />
                    </Grid> */}
                    
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name={`quota`}
                        rules={{
                          // required: {value: true, message: "กรุณากรอกจำนวน"},
                          // validate: {
                          //   moreThanZero: (value) => value >= 0 || "กรุณากรอกจำนวนให้ถูกต้อง และต้องมีค่ามากกว่า 0",
                          // }
                        }}
                        render={({field}) => (
                          <TextFieldTheme
                            {...field}
                            className="number-input"
                            inputProps={{
                              allowNegative: false,
                              isAllowed:(values, sourceInfo) => {
                                const { value } = values;
                                return value < 1000;
                              },
                              thousandSeparator:","
                            }}
                            label="จำนวน"
                            InputProps={{
                              startAdornment: <IconButton onClick={()=>{if(Number(field.value) >= 1) setValue(`quota`, Number(field.value) - 1, { shouldValidate: true })}}><i className="fa-regular fa-circle-minus"></i></IconButton>,
                              endAdornment: <IconButton onClick={()=>{if(Number(field.value) < 999) setValue(`quota`, Number(field.value) + 1, { shouldValidate: true })}}><i className="fa-regular fa-circle-plus"></i></IconButton>,
                              inputComponent: NumberFormatTheme
                            }}
                            placeholder="จำนวนที่เปิดรับสมัคร"
                            helperText={errors && errors.quota && errors.quota.message}
                            error={errors && errors.quota ? true: false}
                          />
                        )}
                      />
                      {/* <TextFieldNumber name="quota" control={control} getValues={getValues} setValue={setValue} placeholder="จำนวนที่เปิดรับสมัคร"/> */}
                      <Typography variant="caption">(กรณีไม่กรอกจำนวนเท่ากับเปิดหลายอัตรา)</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="minSalary"
                        // rules={{
                        //   required: {value: true, message: "กรุณาเลือกกรอกค่าจ้าง"},
                        // }}
                        render={({field}) => (
                          <TextFieldTheme
                            {...field}
                            label="ค่าจ้างขั้นต่ำ"
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                              endAdornment: <InputAdornment position="end">{salaryTypeWatch === "daily"? "ต่อวัน": "ต่อเดือน"}</InputAdornment> ,
                            }}
                            inputProps={{
                              allowNegative: false,
                              thousandSeparator:",",
                              suffix:" บาท",
                              onValueChange:(values) => {
                                const {formattedValue, value, floatValue} = values;
                                field.onChange(value)
                              }
                            }}
                            helperText={errors && errors.salary && errors.salary.message}
                            error={errors && errors.salary ? true: false}
                            onChange={()=>{}}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="maxSalary"
                        // rules={{
                        //   required: {value: true, message: "กรุณาเลือกกรอกค่าจ้าง"},
                        // }}
                        render={({field}) => (
                          <TextFieldTheme
                            {...field}
                            label="ค่าจ้างสูงสุด"
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                              endAdornment: <InputAdornment position="end">{salaryTypeWatch === "daily"? "ต่อวัน": "ต่อเดือน"}</InputAdornment> ,
                            }}
                            inputProps={{
                              allowNegative: false,
                              thousandSeparator:",",
                              suffix:" บาท",
                              onValueChange:(values) => {
                                const {formattedValue, value, floatValue} = values;
                                field.onChange(value)
                              }
                            }}
                            helperText={errors && errors.salary && errors.salary.message}
                            error={errors && errors.salary ? true: false}
                            onChange={()=>{}}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name="jobOpeningType"
                        rules={{
                          required: {value: true, message: "กรุณาเลือกประเภทการเปิดงาน"},
                        }}
                        render={({field})=>(
                          <TextFieldTheme
                            {...field}
                            label="ประเภทการเปิดรับสมัคร"
                            select
                            helperText={errors && errors.jobOpeningType && errors.jobOpeningType.message}
                            error={errors && errors.jobOpeningType ? true: false}
                            disabled={isEdit}
                            onChange={(e)=>{
                              setValue("bidding", null)
                              field.onChange(e.target.value);
                            }}
                          >
                            <MenuItem value="1">ปกติ</MenuItem>
                            <MenuItem value="2">สัญญาการันตีขั้นต่ำ</MenuItem>
                          </TextFieldTheme>
                        )}
                      />
                    </Grid>

                    {parseInt(jobOpeningTypeWatch) === 2 && (
                      <Grid item xs={12} sm={6}>
                        <Controller
                          control={control}
                          name="bidding"
                          rules={{
                            required: {value: true, message: "กรุณาเลือกสัญญา"},
                          }}
                          render={({field})=>(
                            <Autocomplete
                              className="autocomplete"
                              disableClearable
                              options={biddingMinGuaranteeContract}
                              getOptionLabel={(option) => option.biddingName}
                              isOptionEqualToValue={(option, value) => option.idBiddings === value.idBiddings}
                              value={field.value}
                              disabled={isEdit}
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.idBiddings}>
                                  <div>
                                    <div>
                                      <Typography>{`${option.biddingName} (id: ${option.idBiddings})`}</Typography>
                                      <Typography>{option.vendorName}</Typography>
                                      <Typography fontSize={14}>{option.bidType === 1? "ประเภทค่าชาร์จ": option.bidType === 2? "ประเภทงบประมาณ": ""}</Typography>
                                    </div>
                                    <ButtonBlue component={Link} target="_blank" to={`/bidding/${option.idBiddings}`} onClick={(e)=>{e.stopPropagation()}}>ดูข้อมูล</ButtonBlue>
                                  </div>
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextFieldTheme
                                  {...params}
                                  label="สัญญาการันตีขั้นต่ำ"
                                  helperText={errors && errors.bidding && errors.bidding.message}
                                  error={errors && errors.bidding ? true: false}
                                />
                              )}
                              onChange={(_, data) => {
                                field.onChange(data);
                              }}
                            />
                          )}
                        />
                      </Grid> 
                    )}

                    {parseInt(jobOpeningTypeWatch) === 1 && <Fragment>

                      <Grid item xs={12}>
                        <FormControl>
                          <FormLabel>Vendor</FormLabel>
                          <Controller
                            control={control}
                            name="vendorSelectedType"
                            render={({field})=>(
                              <RadioGroup
                                {...field}
                                onChange={(event)=>{
                                  setValue("vendorCustomize", [], { shouldDirty: true})
                                  setValue("chargeSalary", "", { shouldDirty: true})
                                  setValue("chargeOt", "", { shouldDirty: true})
                                  setValue("chargeHire", "", { shouldDirty: true})
                                  setValue("chargeHireType", "1", { shouldDirty: true})
                                  setValue("chargeCompensation", "", { shouldDirty: true})
                                  setValue("isStandardContract", false, { shouldDirty: true})
                                  // console.log(getValues())
                                  field.onChange(event.target.value)
                                }}
                                row
                              >
                                <FormControlLabel value="all" control={<Radio />} label="ทั้งหมด"/>
                                <FormControlLabel value="contract" control={<Radio />} label="เฉพาะที่ทำสัญญา"/>
                                <FormControlLabel value="custom" control={<Radio />} label="กำหนดเอง"/>
                              </RadioGroup>
                            )}
                          />
                        </FormControl>
                      </Grid>

                      {vendorSelectedTypeWatch === "custom" && (
                        <Grid item xs={12}>
                          <Controller
                            control={control}
                            name="vendorCustomize"
                            rules={{
                              required: {value: true, message: "กรุณากรอก Vendor ที่ต้องการ"},
                            }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                className="autocomplete"
                                multiple
                                options={companyVendor}
                                value={value}
                                getOptionLabel={(option) => option.vendorName}
                                isOptionEqualToValue={(option, value) => option.idVendor === value.idVendor}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.vendorName}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextFieldTheme
                                    {...params}
                                    label="Vendor แบบกำหนดเอง"
                                    placeholder="Vendor"
                                    helperText={errors && errors.vendorCustomize && errors.vendorCustomize.message}
                                    error={errors && errors.vendorCustomize ? true: false}
                                  />
                                )}
                                onChange={(_, data) => {
                                  onChange(data);
                                  return data
                                }}
                              />
                            )}
                          />   
                        </Grid>
                      )}

                      {vendorSelectedTypeWatch === "all" && (
                        <Fragment>
                          <Grid item xs={12}>
                            <Box style={{ display:"flex", alignItems:"center" }}>
                              <Typography style={{ marginRight: 16 }}>ค่าชาร์จโดยประมาณสำหรับ Vendor ที่ไม่ได้ทำสัญญา</Typography>
                              <Controller
                                control={control}
                                name="isStandardContract"
                                render={({field})=>(
                                  <Fragment>
                                    <Switch 
                                      checked={Boolean(field.value)}
                                      onChange={(e) => {
                                        setValue("isStandardContract",e.target.checked, { shouldDirty: true});
                                        if(e.target.checked){
                                          setValue("chargeSalary","25", { shouldDirty: true});
                                          setValue("chargeOt","10", { shouldDirty: true});
                                          setValue("chargeHire","", { shouldDirty: true});
                                          setValue("chargeHireType","1",{shouldDirty: true});
                                          setValue("chargeCompensation","", { shouldDirty: true});
                                        }
                                      }}
                                    />
                                    <Typography style={{ marginRight: 8 }}>สัญญามาตราฐาน</Typography>
                                    <Box 
                                      style={{ fontSize: 20, cursor:"pointer", color:"#ff0000" }}
                                      component={Link}
                                      to="/contract/AntJob_ต้นฉบับ_B2B_สัญญาจ้างเหมาบริการมาตรฐาน.pdf"
                                      target="_blank" 
                                      download 
                                    >
                                      <i className="fa-solid fa-file-pdf" />
                                    </Box>
                                  </Fragment>
                                )}
                              />
                            </Box>
                          </Grid>
                          {console.log("watch('isStandardContract') : ",Boolean(watch('isStandardContract')))}
                          <Grid item xs={12} sm={6}>
                            <Controller
                              control={control}
                              name="chargeSalary"
                              rules={{
                                // required: {value: true, message: "กรุณากรอกชาร์จค่าแรงสูงสุด"},
                                validate: {
                                  isNumber: (v) => !isNaN(v) || "กรุณาตัวเลขให้ถูกต้อง" 
                                }
                              }}
                              render={({field})=>(
                                <TextFieldTheme
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                  }}
                                  disabled={!!watch(`chargeHire`) || !!watch("isStandardContract")}
                                  label="ชาร์จค่าแรง (ไม่บังคับ)"
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }}
                                  helperText={errors && errors.chargeSalary && errors.chargeSalary.message}
                                  error={errors && errors.chargeSalary ? true: false}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Controller
                              control={control}
                              name="chargeOt"
                              rules={{
                                // required: {value: true, message: "กรุณากรอกชาร์จค่าล่วงเวลาสูงสุด"},
                                validate: {
                                  isNumber: (v) => !isNaN(v) || "กรุณาตัวเลขให้ถูกต้อง" 
                                }
                              }}
                              render={({field})=>(
                                <TextFieldTheme
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                  }}
                                  disabled={!!watch(`chargeHire`) || !!watch("isStandardContract")}
                                  label="ชาร์จค่าล่วงเวลา (ไม่บังคับ)"
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }}
                                  helperText={errors && errors.chargeOt && errors.chargeOt.message}
                                  error={errors && errors.chargeOt ? true: false}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Controller
                              control={control}
                              name="chargeHire"
                              rules={{
                                validate: {
                                  isNumber: (v) => !isNaN(v) || "กรุณาตัวเลขให้ถูกต้อง"
                                }
                              }}
                              render={({field}) => (
                                <TextFieldTheme
                                  {...field}
                                  className="input-with-select"
                                  disabled={!!watch(`chargeSalary`) || !!watch("chargeOt") || !!watch("isStandardContract")}
                                  InputProps={{
                                    inputComponent: NumberFormatTheme,
                                    endAdornment:  (
                                      <Controller
                                        control={control}
                                        name="chargeHireType"
                                        render={({field}) => (
                                          <TextFieldTheme
                                            {...field}
                                            className="select-input"
                                            disabled={!!watch(`chargeSalary`) || !!watch("chargeOt") || !!watch("isStandardContract")}
                                            select
                                            onChange={(e)=>{
                                              setValue("chargeHire", "")
                                              clearErrors("chargeHire")
                                              field.onChange(e.target.value)
                                            }}
                                          >
                                            <MenuItem value="1">%</MenuItem>
                                            <MenuItem value="2">บาท</MenuItem>
                                          </TextFieldTheme>
                                        )}
                                      />
                                    )
                                  }}
                                  inputProps={{
                                    allowNegative: false,
                                    value: field.value,
                                    thousandSeparator: ",",
                                    onValueChange: (values) => {
                                      const { value } = values;
                                      field.onChange(value)
                                    },
                                  }}
                                  label="ชาร์จค่าเหมาจ่าย"
                                  autoComplete="chargeHire"
                                  helperText={errors && errors.chargeHire && errors.chargeHire.message}
                                  error={errors && errors.chargeHire ? true: false}
                                  onChange={(e)=>{
                                    clearErrors("atLeastOne");
                                  }}
                                />

                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Controller
                              control={control}
                              name="chargeCompensation"
                              rules={{
                                // required: {value: true, message: "กรุณากรอกชาร์จค่าชดเชยสูงสุด"},
                                validate: {
                                  isNumber: (v) => !isNaN(v) || "กรุณาตัวเลขให้ถูกต้อง" 
                                }
                              }}
                              render={({field})=>(
                                <TextFieldTheme
                                  {...field}
                                  label="ชาร์จค่าชดเชย (ไม่บังคับ)"
                                  disabled={watch("isStandardContract")}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }}
                                  helperText={errors && errors.chargeCompensation && errors.chargeCompensation.message}
                                  error={errors && errors.chargeCompensation ? true: false}
                                />
                              )}
                            />
                          </Grid>
                        </Fragment>
                      )}
                    </Fragment>}

                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="attachFile"
                        rules={{
                          // required: { value: true, message: 'กรุณาอัพโหลดรายละเอียดสัญญา' },
                        }}
                        render={({field, fieldState}) => (
                          <Dropzone
                            onDrop={onDropAttachFile}
                            accept="application/pdf"
                            maxFiles={1}
                            multiple
                            maxSize={10485760}
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              open,
                              isDragActive,
                              acceptedFiles,
                            }) => (
                              <div>
                                <Typography>เอกสารแนบเงื่อนไขการจ้าง</Typography>
                                <div {...getRootProps({ className: "dropzone" })}>
                                  <input {...getInputProps()}/>

                                  {getValues("attachFile").length > 0? (
                                    <div className="file-show">
                                      {getValues("attachFile").map((f, index) => <Chip key={`attachFile_${index}`} label={f.name} onClick={(e)=>{e.stopPropagation()}} onDelete={()=>{onDeleteAttachFile(index)}}/>)}
                                    </div>
                                  ) : (
                                    <div className="empty-file">
                                      <Typography>คลิก หรือ ลากไฟล์วางไว้ที่นี่</Typography>
                                      <Typography fontSize={12}>*อัพโหลดได้เฉพาะ .pdf เท่านั้น สูงสุด 1 ไฟล์ ไม่เกิน 10MB</Typography>
                                    </div>
                                  )}
                                </div>

                                {fieldState.error && (
                                  <Typography fontSize={12} color="error">{fieldState.error.message}</Typography>
                                )}
                              </div>
                            )}
                          </Dropzone>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} container justifyContent={isEdit? "space-between": "flex-end"}>
                      {isEdit && <ButtonBlue className="confirm-button" variant="outlined" onClick={()=>{history.goBack()}}>ยกเลิก</ButtonBlue>}
                      <ButtonBlue className="confirm-button" variant="contained" type="submit" disabled={(isEdit && (isFetching || Object.keys(dirtyFields).length === 0))}>ยืนยัน</ButtonBlue>
                    </Grid>
                  </Grid>
                </form>
              </StyledForm>
            </CardStyle>
          </Container>}
        </Fragment>
      )}

      {isSuccess && (
        <div className="success-container">
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ width: 300, marginBottom: "20px" }}
                src={`${process.env.PUBLIC_URL}/assets/background/complete.jpg`}
              // alt="https://www.freepik.com/free-vector/completed-concept-illustration_10802151.htm"  
              />
            </div>
            <Typography variant="h3">{!isEdit ? "รับสมัครงานสำเร็จ" : "แก้ไขการรับสมัครงานสำเร็จ"}</Typography>
          </div>
        </div>
      )}
    </StyledRoot>
  )
}
export default CompanyRequestFormPage;