import React from 'react';

import {
    Box,
    Dialog,
    DialogContent,
    Typography
} from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";

const DialogDescription = (props) => {
    const { open, onClose, description } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogContent style={{ width:"450px" }}>
                <Typography variant='h4'>
                    <i
                        className="fa-solid fa-memo"
                        style={{
                            color: "#007afe",
                            fontSize: "1.5rem",
                            marginRight: "10px",
                        }}
                    />
                    รายละเอียด
                </Typography>
                <Box style={{ padding:"0 20px", margin:"20px 0" }}>
                    <Typography style={{ textIndent:"50px" }}>{description}</Typography>
                </Box>
                <Box style={{ display:"flex", justifyContent:"flex-end" }}>
                    <ButtonBlue onClick={onClose}>ปิด</ButtonBlue>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export  default DialogDescription;