import React, { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getUserProfile } from "../../../actions/user";

import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  Box,
  Card,
  CardContent,
  Container,
  Button,
  Paper,
} from "@mui/material";

import Bidding from "./bidding";
import Request from "./request";
import Category from "./category";
import Company from "./company";

import Vendor from "./forCompany/vendor";
import ContractVendor from "./forCompany/contractVendor";


import request from "../assets/data/request";
// import jobGroup from "../assets/data/jobGroup";
import company from "../assets/data/company";
import locations from "../assets/data/location";

import ButtonBlue from "../shared/general/ButtonBlue";

import Cover from "./assets/cover.png";
import Locations from "./location";

import { getAllJobRequestAllVendor } from "../../../actions/job";
import { getVendorHomepage } from "../../../actions/vendor";
import { getHomepageForGuest, getHomepageForCompany, getHomepageForVendor } from "../../../actions/homepage";
import TopVendor from "./topVendor";
import TopCompany from "./topCompany";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#f1f4f9",
  paddingBottom: 36,
});

const StyledCover = styled("div")({
  height: "350px",
  backgroundImage: `url(${Cover})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  ["@media only screen and (max-width: 600px)"]: {
    height: "156px",
  },
});

const StyledPaper = styled(Paper)({
  padding: "40px 0px",
  width: "100%",
  borderRadius: 20,
  border: "none",
  "& .wrap": {
    // fontSize: 28,
    padding: "0 16px",
    textAlign: "center",
    "& .MuiButton-root": {
      marginTop: 16,
    },
    "& .MuiTypography-h6": {
      fontSize: 28,
    }
  },
});

function Home(props) {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: AllJobs } = useSelector(state => state.jobs);
  const { result: vendorHomepage } = useSelector(state => state.vendorHomepage);

  const { result: homepageGuestStore, isFetching: homepageGuestFetching } = useSelector(state => state.homepageGuest);
  const { result: homepageCompanyStore, isFetching: homepageCompanyFetching } = useSelector(state => state.homepageCompany);
  const { result: homepageVendorStore, isFetching: homepageVendorFetching } = useSelector(state => state.homepageVendor);

  const [homepageCompany, setHomepageCompany] = useState(null);

  useEffect(() => {
    if((userProfile && userProfile.rolesName === "ROLE_ADMIN")){
      dispatch(getHomepageForCompany());
    }

    if((userProfile && userProfile.rolesName === "ROLE_VENDOR")){
      dispatch(getHomepageForVendor());
    }
  }, [userProfile])

  useEffect(() => {
    if(!isLoggedIn){
      dispatch(getHomepageForGuest());
    }
  }, [])

  useEffect(() => {
    if(homepageCompanyStore !== null){
      setHomepageCompany({...homepageCompanyStore})
    }
  }, [homepageCompanyStore])

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Grid container spacing={1} alignItems="center" style={{ marginBottom: 48 }}>
          <Grid item xs={12} sm={3}>
            <StyledPaper variant="outlined">
              {!isLoggedIn && (
                <div className="wrap">
                  <Typography variant="h6" align="center" gutterBottom>
                    คุณคือฝั่งบริษัท ?
                  </Typography>
                  <ButtonBlue variant="outlined" component={Link} to="/company/signup">เข้าร่วมเป็นบริษัท</ButtonBlue>
                </div>
              )}
              
              {userProfile && userProfile.rolesName === "ROLE_ADMIN" && (
                <div className="wrap">
                  <Typography variant="h6" align="center" gutterBottom>
                    ค้นหาผู้ที่เหมาะสมมาร่วมงานกับคุณ
                  </Typography>
                  <ButtonBlue variant="outlined" component={Link} to="/company/myjobs/jobForm">รับสมัครงาน</ButtonBlue>
                </div>
              )}
            </StyledPaper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledCover></StyledCover>
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledPaper variant="outlined">

              {!isLoggedIn && (
                <div className="wrap">
                  <Typography variant="h6" align="center" gutterBottom>
                    คุณคือฝั่งคู่ธุรกิจ ?
                  </Typography>
                  <ButtonBlue variant="outlined" component={Link} to="/vendor/signup">เข้าร่วมเป็นคู่ธุรกิจ</ButtonBlue>
                </div>
              )}

              {userProfile && userProfile.rolesName === "ROLE_ADMIN" && (
                <div className="wrap">
                  <Typography variant="h6" align="center" gutterBottom>
                    มีการนัดสัมภาษณ์ที่ใกล้จะถึง
                  </Typography>
                  <ButtonBlue variant="outlined" component={Link} to="/company/interview">ตารางนัดสัมภาษณ์</ButtonBlue>
                </div>
              )}
            </StyledPaper>
          </Grid>
        </Grid>

        {/* vendors for guest */}
        {/* {(!isLoggedIn) && (
          <Fragment>
            <Vendor vendors={(homepageGuestStore && [...homepageGuestStore.vendors].splice(0, 3)) || []}/>
            <ContractVendor />
          </Fragment>
        )} */}

        {/* vendors for company */}
        {/* {(userProfile && userProfile.isCompany) && (
          <Fragment>
            {console.log(homepageCompany)}
            <Vendor vendors={(homepageCompany && [...homepageCompany.vendors].splice(0, 3)) || []}/>
            <ContractVendor />
          </Fragment>
        )} */}

        {/* job for vendor */}
        {(userProfile && userProfile.idVendor) && (
          <Fragment>
            <Bidding request={(homepageVendorStore && [...homepageVendorStore.biddings]) || []} isFetching={homepageVendorFetching} />
            <Request request={(homepageVendorStore && [...homepageVendorStore.jobs].splice(0, 3)) || []} isFetching={homepageVendorFetching} />
          </Fragment>
        )}

        {/* job for company */}
        {(userProfile && userProfile.idCompany) && (
          <Fragment>
            <Bidding request={(homepageCompanyStore && [...homepageCompanyStore.myBiddings]) || []} isFetching={homepageCompanyFetching} isCompany/>
            <Request request={(homepageCompanyStore && [...homepageCompanyStore.myJobs].splice(0, 3)) || []} isFetching={homepageCompanyFetching} isCompany/>
          </Fragment>
        )}

        {/* job for guest */}
        {(!isLoggedIn) && (
          <Fragment>
            <Bidding request={(homepageGuestStore && [...homepageGuestStore.biddings]) || []} isFetching={homepageGuestFetching} />
            <Request request={(homepageGuestStore && [...homepageGuestStore.jobs].splice(0, 3)) || []} isFetching={homepageGuestFetching} />
          </Fragment>
        )}

        
        <Locations locations={locations} isLoggedIn={isLoggedIn}/>
          

        {/* category for guest */}
        {(!isLoggedIn) && (
          <Category jobGroups={homepageGuestStore && [...homepageGuestStore.jobGroups] || []} isFetching={homepageGuestFetching}/>
        )}

        {/* category for vendor */}
        {(userProfile && userProfile.idVendor) && (
          <Category jobGroups={homepageVendorStore && [...homepageVendorStore.jobGroups] || []} />
        )}

        {/* top company for guest */}
        {(!isLoggedIn) && (
          <TopCompany company={(homepageGuestStore && homepageGuestStore.topCompanies) || []} count={(homepageGuestStore && homepageGuestStore.count && homepageGuestStore.count.company)} isFetching={homepageGuestFetching}/>
        )}

        {/* top company for vendor */}
        {(userProfile && userProfile.idVendor) && (
          <TopCompany company={(homepageVendorStore && homepageVendorStore.topCompanies) || []} count={(homepageVendorStore && homepageVendorStore.count && homepageVendorStore.count.company)}/>
        )}

        {/*top vendor for guest */}
        {(!isLoggedIn) && (
          <TopVendor vendor={(homepageGuestStore && homepageGuestStore.topVendors) || []} count={(homepageGuestStore && homepageGuestStore.count && homepageGuestStore.count.vendor)} isFetching={homepageGuestFetching}/>
        )}

        {/*top vendor for company */}
        {(userProfile && userProfile.idCompany) && (
          <TopVendor vendor={(homepageCompany && homepageCompany.topVendors) || []} count={(homepageCompany && homepageCompany.count && homepageCompany.count.vendor)}/>
        )}

      </Container>
    </StyledRoot>
  );
}

export default Home;
