import {
    COMPANYS_FETCHING,
    COMPANYS_FAILED,
    COMPANYS_SUCCESS
} from "../actions/types";

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case COMPANYS_FETCHING:
            return { ...state, result: null, isFetching: true, isError: false };
        case COMPANYS_FAILED:
            return { ...state, result: null, isFetching: false, isError: true };
        case COMPANYS_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false };
        default:
            return state;
    }
};