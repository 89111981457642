import React from "react";
import { Box, styled, Typography } from "@mui/material";
import Dropzone from "react-dropzone";

const StyledRoot = styled(Box)({
  display: "flex",
  gap: 16,
  flexWrap: "wrap",
  "& .item": {
    width: 120,
    height: 120,
    borderRadius: 8,
    position: "relative",
    border: "1px solid #919eab",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    "& .part-icon": {
      padding: 8,
      display: "flex",
      justifyContent: "center",
      color: "#919eab",
      "& i": {
        fontSize: 48,
      }
    },
    "& .part-filename": {
      minHeight: 0,
      padding: 8,
      flexGrow: 1,
      fontSize: 12,
      overflow: "hidden",
      "& .text": {
        fontSize: "inherit",
        wordBreak: "break-word",
        height: "inherit",
        lineHeight: 2,
      },
    },
    "& .delete-icon": {
      position: "absolute",
      top: 0,
      left: "100%",
      color: "#919eab",
      fontSize: 24,
      transform: "translate(-50%, -50%)"
    }
  },
  "& .dropzone": {
    color: "#919eab",
    fontSize: 24,
    "& i": {
      width: 24,
      display: "flex",
      justifyContent: "center",
      color: "inherit",
      fontSize: "inherit",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }
  }
})


const DropAttachFile = (props) => {

  const { accept, maxFiles, maxSize, files, onChange, currentFiles, onDeleteCurrentFiles } = props;

  const onDrop = (file) => {
    if(files.length >= maxFiles){
      return
    }
    onChange([...files, ...file])
  }

  const onDeleteCurrentFileHandler =  (index) => {
    const newFiles = currentFiles.filter((f, i) => i !== index)
    onDeleteCurrentFiles(newFiles, currentFiles[index].name)
  }

  const onDeleteFile = (index) => {
    const newFiles = files.filter((f, i) => i !== index)
    onChange(newFiles)
  }

  return (
    <StyledRoot>
      <Dropzone
        accept={accept}
        maxFiles={maxFiles}
        maxSize={maxSize * 1048576} //convert mb => b
        onDrop={onDrop}
        multiple
        disabled={(currentFiles.length + files.length) >= maxFiles}
      >
        {({
          getRootProps,
          getInputProps,
        }) => (
          <Box {...getRootProps({ className: "item dropzone" })}>
            <input {...getInputProps()}/>
            <i className="fa-solid fa-plus"></i>
          </Box>
        )}
      </Dropzone>
      {currentFiles.map((f, index) => (
        <Box className="item" key={`current_file_${index}`}>
          <i className="fa-solid fa-circle-xmark delete-icon" onClick={()=>{onDeleteCurrentFileHandler(index)}}></i>
          <Box className="part-icon">
            <i className="fa-regular fa-file-pdf"></i>
          </Box>
          <Box className="part-filename">
            <Typography className="text">{f.name}</Typography>
          </Box>
        </Box>

      ))}
      {files.map((f, index) => (
        <Box className="item" key={`file_${index}`}>
          <i className="fa-solid fa-circle-xmark delete-icon" onClick={()=>{onDeleteFile(index)}}></i>
          <Box className="part-icon">
            <i className="fa-regular fa-file-pdf"></i>
          </Box>
          <Box className="part-filename">
            <Typography className="text">{f.name}</Typography>
          </Box>
        </Box>

      ))}
    </StyledRoot>
  )
}

export default DropAttachFile;