import { httpClient } from "./httpClient";

const signupCompany = (formData) => {
  return httpClient.post("signup/company", formData);
};

const getSignupCompanyForm = () => {
  return httpClient.get("signup/companyForm");
};

const signupVendor = (formData) => {
  return httpClient.post("signup/vendor", formData);
};

const verifyEmail = (query) => {
  return httpClient.get("signup/verify", {params: query});
};

const createPasword = (formData) => {
  return httpClient.post("signup/createPassword", formData);
};

export default {
  signupCompany,
  getSignupCompanyForm,
  signupVendor,
  verifyEmail,
  createPasword
};