import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box,
    Container,
    Grid,
    styled,
    Typography
} from '@mui/material';

import MyCompanyCard from './myCompanyCard';

import { getMyCompanys } from '../../../../actions/company';

const RootStyled = styled(Box)({
    "& .header-text": {
        marginBottom: 24,
    },
});

const VendorMyCompany = () => {
    const dispatch = useDispatch();
    const { result: MyCompanys } = useSelector(state => state.vendorMyCompanys);  

    useEffect(() => {
        dispatch(getMyCompanys());
    },[dispatch]);

    return (
        <RootStyled className="page">
            <Container maxWidth="lg">
                <Typography className="header-text" variant="h4">
                    บริษัทคู่สัญญาของฉัน
                </Typography>
                <Grid container spacing={2}>
                    {(MyCompanys && MyCompanys.length > 0) ?
                        MyCompanys.map((company) => (
                            <Grid key={company.idCompany} item xs={12} md={4}>
                                <MyCompanyCard company={company} />
                            </Grid>
                        ))
                        :
                        <Grid container item xs={12} justifyContent="center" alignItems="center" style={{ height:200 }}>
                            <Grid item>
                                <Typography variant="h5">ไม่พบบริษัทคู่สัญญาของฉัน</Typography>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Container>
        </RootStyled>
    );
};

export default VendorMyCompany;