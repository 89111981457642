import React from "react";
import { Box, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import utils from "../../../../../utils";

const StyledRoot = styled(Box)({

})

const Left = (props) => {

  const { useFormOne, useFormTwo, useFormThree } = props;
  const {result: jobGroups} = useSelector(state => state.jobGroups)

  const title = useFormOne.watch("title")
  const firstname = useFormOne.watch("firstname")
  const lastname = useFormOne.watch("lastname")
  const phoneNumber = useFormOne.watch("phoneNumber")
  const email = useFormOne.watch("email")
  const dateOfBirth = useFormOne.watch("dateOfBirth")
  const personalId = useFormOne.watch("personalId")


  const idJobGroup = useFormTwo.watch("idJobGroup")
  const workExperience = useFormTwo.watch("workExperience")
  const workExperienceM = useFormTwo.watch("workExperienceM")
  const educationDegree = useFormTwo.watch("educationDegree")
  const currentSalary = useFormTwo.watch("currentSalary")
  const expectedSalary = useFormTwo.watch("expectedSalary")

  const renderAge = (totalMonth) => {
    const year = Math.floor(totalMonth / 12);
    const month = (totalMonth % 12)
    return `${year} ปี ${month} เดือน`
  }

  // const renderWorkExp = (year) => {
  //   switch (Number(year)) {
  //     case 0: return "น้อยกว่า 1 ปี";
  //     case 20: return "มากกว่า 20 ปี";
  //     default: return `${year} ปี`
  //   }
  // }

  return (
    <StyledRoot>
      <Typography fontSize={20} paddingBottom="24px">{`กำลังพลที่จะถูกเพิ่ม..`}</Typography>

      {(firstname || lastname) && <Typography variant="h5" paddingBottom="16px">{title} {firstname} {lastname}</Typography>}

      {phoneNumber && <Typography variant="h5" paddingBottom="16px" fontSize={16}><span>เบอร์โทร :</span> {phoneNumber}</Typography>}
      {email && <Typography variant="h5" paddingBottom="16px" fontSize={16}><span>อีเมล :</span> {email}</Typography>}
      {dateOfBirth && <Typography variant="h5" paddingBottom="16px" fontSize={16}><span>อายุ :</span> {renderAge(dayjs(new Date()).diff(dateOfBirth, 'month'))}</Typography>}
      {personalId && <Typography variant="h5" paddingBottom="16px" fontSize={16}><span>เลขบัตรประชาชน :</span> {personalId}</Typography>}
      {idJobGroup && <Typography variant="h5" paddingBottom="16px" fontSize={16}><span>ความเชี่ยวชาญ :</span> {jobGroups.find(j => j.idJobGroup === Number(idJobGroup)).jobGroupName}</Typography>}
      {(workExperience || workExperienceM) && <Typography variant="h5" paddingBottom="16px" fontSize={16}><span>ประสบการณ์ทำงาน :</span> {workExperience && `${workExperience} ปี`} {workExperienceM && `${workExperienceM} เดือน`}</Typography>}
      {educationDegree && <Typography variant="h5" paddingBottom="16px" fontSize={16}><span>ระดับการศึกษาสูงสุด :</span> {educationDegree}</Typography>}
      {currentSalary && <Typography variant="h5" paddingBottom="16px" fontSize={16}><span>เงินเดือนปัจจุบัน :</span> {utils.numberWithCommas(currentSalary)} บาท</Typography>}
      {expectedSalary && <Typography variant="h5" paddingBottom="16px" fontSize={16}><span>เงินเดือนที่คาดหวัง :</span> {utils.numberWithCommas(expectedSalary)} บาท</Typography>}


    </StyledRoot>
  )
}

export default Left