import React from 'react';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';

import {
    Avatar,
    Box,
    CardContent,
    styled,
    Typography
} from "@mui/material";

import CardStyle from '../../shared/general/Card';

const StyledCardContent = styled(CardContent)({
    height: "calc(100% - 48px)",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .part-company": {
        display: "flex",
        marginBottom: 10,
        marginTop: 10
    },
    "& .part-pic": {
        display: "flex",
        marginBottom: 8,
        "& .MuiAvatar-root": {
            width: 75,
            height: 75,
            borderRadius: 8,
            backgroundColor: "white",
            marginRight: 20,
            "& img": {
                width: "inherit",
                height: "inherit",
                objectFit: "contain",
            },
        }
    },
    "& .part-three": {
        display: "flex",
        alignItems: "center",
        marginBottom: 10,
    },
    "& .part-four": {
      "& span":{
        marginLeft:10
      },
    }
});

const CardRequest = (props) => {
    const { value } = props;

    return (
        <CardStyle style={{ height: "100%", minHight: "fit-content" }}>
            <StyledCardContent>
                <Box>
                    <Box className="part-pic">
                        <Avatar variant="rounded" src={value && value.idJobGroup ? `${process.env.REACT_APP_API_URL}image/jobGroup/${value.idJobGroup}.png` : "/assets/logo_default.png"} />
                    </Box>
                    <Box>
                        <Typography
                            component={NavLink}
                            to={`/company/request/${value.idJob}`}
                            style={{ color: "#000000", textDecoration: "none", width: "100%", fontWeight: 500, fontSize: 24, lineHeight: 1.4, wordBreak: "break-word" }}
                        >
                            {value && value.positionName || "-"}
                        </Typography>
                        <Typography variant="body1">{value && value.departmentName || "-"}</Typography>
                    </Box>
                </Box>
                <Box style={{ marginTop:"10px" }}>
                    <div className="part-company">
                        <i
                            className="fa-solid fa-building"
                            style={{ color: "#637381", marginRight: 8 }}
                        />
                        <Typography variant="body2" style={{ color: "#637381" }}>
                            {value && value.companyName || "-"}
                        </Typography>
                    </div>
                    <div className={`part-three`}>
                        <i
                            className="fal fa-map-marker-alt"
                            style={{ color: "#637381", marginRight: 8 }}
                        ></i>
                        <Typography variant="body2" style={{ color: "#637381" }}>
                            {`${value.district || "-"}, ${value.province || "-"}`}
                        </Typography>
                    </div>
                    <div className={`part-four`}>
                        <div style={{ display: "flex", marginBottom: 10, justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <i className="fa-solid fa-calendar-pen" style={{ color: "#637381", marginRight: 8 }} />
                                <Typography variant="body2" color="text.secondary">วันที่เปิดสมัคร:</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" color="text.secondary">{value.postDate && dayjs(value.postDate).format("DD-MMM-YYYY") || "ไม่ระบุวันประกาศ"}</Typography>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <i className="fa-solid fa-calendar-xmark" style={{ color: "#637381", marginRight: 8 }} />
                                <Typography variant="body2" color="text.secondary">
                                    วันที่ปิดรับสมัคร:
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body2" color="text.secondary">{value.closeRegisterDate && dayjs(value.closeRegisterDate).format("DD-MMM-YYYY") || "ไม่ระบุวันปิดรับ"}</Typography>
                            </div>
                        </div>
                    </div>
                </Box>
            </StyledCardContent>
        </CardStyle>
    );
};

export default CardRequest;