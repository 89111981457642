import React, { Fragment, useState } from 'react';

import {
    Avatar,
    Box,
    Dialog,
    DialogContent,
    Grid,
    styled,
    Typography,
    useMediaQuery
} from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";

const DialogStyled = styled(Dialog)({
  "& .MuiPaper-root":{
    borderRadius:"16px"
  }
});

const BoxContentVendor = styled(Box)({
    display:"flex",
    margin:"20px 0 10px",
    alignItems:"center",
    flexDirection:"column",
    "& .MuiAvatar-root": {
      width: 100,
      height: 100,
      marginRight:10,
      marginBottom:"10px",
    },
    "& .manpowers":{
        fontSize: "16px",
        marginTop:"5px",
        marginBottom:"5px"
    }
});

const BoxSpaceBetween = styled(Box)({
    display:"flex", 
    justifyContent:"space-between",
    marginTop:"5px",
});

const DialogConfirmConclusion = (props) => {
    const { open, onClose, handleSubmit, vendor } = props;
    const [openDetail, setOpenDetail] = useState(false);
    const mobile = useMediaQuery('(max-width:450px)');

    return (
      <DialogStyled open={open} onClose={onClose}>
        <DialogContent style={{ width: mobile ? "300px" : "500px" }}>
          <Typography variant={mobile ? "h5" : "h4"}>ยืนยันสรุปการประมูล</Typography>
          <Box style={{ margin: "15px 0" }}>
            {vendor ? (
              <Fragment>
                <Typography>คู่ธุรกิจที่ชนะการประมูล</Typography>
                <BoxContentVendor>
                  <Avatar 
                    src={vendor.vendorImage ? `${process.env.REACT_APP_API_URL}image/vendor/${vendor.vendorImage}` : "/assets/logo_default.png"}
                  />
                  <Box>
                    <Typography
                      className="companyName"
                      align={"center"}
                      variant="h5"
                    >
                      <i
                        className="fa-solid fa-building"
                        style={{
                          color: "#007afe",
                          fontSize: "1.1rem",
                          marginRight: "10px",
                        }}
                      />
                      {vendor.vendorName}
                    </Typography>
                    <Typography
                      className="manpowers"
                      align={"center"}
                    >
                      <i
                        className="fa-solid fa-users"
                        style={{
                          color: "#007afe",
                          fontSize: "1.1rem",
                          marginRight: "10px",
                        }}
                      />
                      {`กำลังพลที่พร้อม ${(vendor.freeManpowers !== undefined && vendor.freeManpowers !== null) ? vendor.freeManpowers : 0} คน`}
                    </Typography>
                    <Box style={{ display:"flex", justifyContent:"center" }}>
                      <Grid container style={{ width: mobile ? "250px" : "400px", margin:"15px 0 20px" }}>
                        <Grid item xs={12} sm={6}>
                          <BoxSpaceBetween style={{ paddingRight: mobile ? "0" : "20px" }}>
                            <Typography>
                              <i
                                className="fa-solid fa-user"
                                style={{
                                  color: "#007afe",
                                  fontSize: "1.1rem",
                                  marginRight: "5px",
                                }}
                              />
                              ชาร์จค่าแรง :
                            </Typography>
                            <Typography>{`${vendor.chargeSalaryOffer || "-"} %`}</Typography>
                          </BoxSpaceBetween>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <BoxSpaceBetween style={{ paddingRight: mobile ? "0" : "20px" }}>
                            <Typography>
                              <i
                                className="fa-solid fa-clock"
                                style={{
                                  color: "#007afe",
                                  fontSize: "1.1rem",
                                  marginRight: "5px",
                                }}
                              />
                              ชาร์จค่า OT :
                            </Typography>
                            <Typography>{`${vendor.chargeOtOffer || "-"} %`}</Typography>
                          </BoxSpaceBetween>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <BoxSpaceBetween style={{ paddingRight: mobile ? "0" : "20px" }}>
                            <Typography>
                              <i
                                className="fa-sharp fa-solid fa-people-group"
                                style={{
                                  color: "#007afe",
                                  fontSize: "1.1rem",
                                  marginRight: "5px",
                                }}
                              />
                              ชาร์จค่าเหมา :
                            </Typography>
                            <Typography>{`${vendor.chargeHireOffer || "-"} %`}</Typography>
                          </BoxSpaceBetween>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <BoxSpaceBetween style={{ paddingRight: mobile ? "0" : "20px" }}>
                            <Typography>
                              <i
                                className="fa-solid fa-sack-dollar"
                                style={{
                                  color: "#007afe",
                                  fontSize: "1.1rem",
                                  marginRight: "5px",
                                }}
                              />
                              ชาร์จค่าชดเชย :
                            </Typography>
                            <Typography>{`${vendor.chargeCompensationOffer || "-"} %`}</Typography>
                          </BoxSpaceBetween>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box style={{ margin: "10px 0" }}>
                      {!openDetail && vendor.description &&
                        <Box style={{ width:"100%", display:"flex", justifyContent: "center" }}>
                            <ButtonBlue
                                onClick={() => setOpenDetail(true)}
                            >ดูรายละเอียด</ButtonBlue>
                        </Box>
                      }
                      {openDetail && (
                        <Fragment>
                          <Typography variant="h6">รายละเอียด :</Typography>
                          <Box>
                            <Typography style={{ textIndent: "30px" }}>
                              {vendor.description}
                            </Typography>
                          </Box>
                        </Fragment>
                      )}
                    </Box>
                  </Box>
                </BoxContentVendor>
              </Fragment>
            ) : (
              <Box>
                <Typography align="center" style={{ fontSize: "20px" }}>
                  ไม่มีคู่ธุรกิจชนะการประมูล
                </Typography>
              </Box>
            )}
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <ButtonBlue onClick={() => {
                onClose();
                setOpenDetail();
            }}>ยกเลิก</ButtonBlue>
            <ButtonBlue
              variant="contained"
              onClick={handleSubmit}
              style={{ marginLeft: "10px" }}
            >
              ยืนยัน
            </ButtonBlue>
          </Box>
        </DialogContent>
      </DialogStyled>
    );
};

export default DialogConfirmConclusion;