import React from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import SlickLocations from "./slick-locations";
import ButtonBlue from "../shared/general/ButtonBlue";
import { Link } from "react-router-dom";


const StyledRoot = styled("div")({
  // paddingBottom: 120,
  "& .category-head": {
    paddingBottom: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .all-button": {
      "& i": {
        display: "flex",
      }
    }
  },
  "& .wrap-card": {
    position: "relative",
  }
});


const Locations = (props) => {
  const { locations, isLoggedIn } = props;
  const { result : userProfile } = useSelector(state => state.userProfile);

  return (
    <StyledRoot>
      <div className="category-head">
        <Typography variant="h5" gutterBottom>
          จังหวัด
        </Typography>
        <ButtonBlue 
          className="all-button" 
          component={Link} 
          to={isLoggedIn ? 
            (userProfile && userProfile.isCompany) ? 
              "/vendor-search" 
              : 
              "/province" 
            : 
            "/province"
          }
          >ทั้งหมด<i className="fi fi-br-angle-small-right"></i></ButtonBlue>
      </div>
      <div className="wrap-card">
        <SlickLocations locations={locations} isLoggedIn={isLoggedIn} />
      </div>
    </StyledRoot>
  );
};

export default Locations;
