import { httpClient } from "./httpClient";

const getVendorNotification = () => {
    return httpClient.get("vendor/notification");
};

const updateVendorNotification = (data) => {
    return httpClient.post("vendor/notification/reading", data);
};

export default {
    getVendorNotification,
    updateVendorNotification
};