import React from "react";
import { IconButton, Paper, styled, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const StyledRoot = styled("div")({
  "& .MuiPaper-root": {
    padding: "4px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: 8,
    "& input": {
      padding: 0,
      textAlign: "center",
      fontSize: 20,
    },
    "& fieldset": {
      border: "none",
    }
  }
})

const TextFieldNumber = ({
  control, 
  name, 
  getValues, 
  setValue, 
  placeholder
}) => {

  const onClickMinus = () => {
    if(getValues(name) === ""){
      return;
    }

    setValue(name, String(parseInt(getValues(name).replaceAll(",",""))-1), { shouldDirty: true});

    if(getValues(name) <= 0 || isNaN(getValues(name))){
      setValue(name, "", { shouldDirty: true});
    }
  }

  const onClickPlus = () => {
    if(getValues(name) === "" || isNaN(getValues(name))){
      setValue(name, "1", { shouldDirty: true});
    }
    else{
      setValue(name, String(parseInt(getValues(name).replaceAll(",",""))+1), { shouldDirty: true});
    }
  }

  const onBlurHandler = () => {
    if(getValues(name) <= "0" || isNaN(getValues(name))){
      setValue(name, "", { shouldDirty: true});
    }
  }
  
  return(
    <StyledRoot>
      <Controller
        control={control}
        name={name}
        render={({field})=>(
          <Paper variant="outlined">
            <IconButton onClick={onClickMinus}><i className="fa-regular fa-circle-minus"></i></IconButton>
            <TextField
              {...field}
              placeholder={placeholder}
              onBlur={onBlurHandler}
            />
            <IconButton onClick={onClickPlus}><i className="fa-regular fa-circle-plus"></i></IconButton>
          </Paper>
        )}
      />
    </StyledRoot>
  )
}

export default TextFieldNumber;