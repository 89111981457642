import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogContent, Grid, styled, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDispatch } from "react-redux";
import { getBiddingById, submitBid } from "../../../../actions/bidding";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import Utils from "../../../../utils";
import Dropzone from "react-dropzone";
import DropAttachFile from "../../shared/general/DropAttachFile";


const StyledRoot = styled("div")({

})

const NumberCustom = React.forwardRef((props, ref) => {
  return <NumberFormat {...props} getInputRef={ref}/>
})

const BiddingOfferBudget = (props) => {
  const { open, onClose, idBidding, values, budgetMax } = props

  const [isFetching, setIsFetching] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [deletingFiles, setDeletingFiles] = useState([]);

  const dispatch = useDispatch();

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      budgetOffer: values && values.budgetOffer || "",
      description: values && values.description || "",
      attachFiles: [],
    }
  });

  const onDeleteCurrentFiles = (newCurrentFiles, fileDeleteName) => {
    setCurrentFiles(newCurrentFiles);
    setDeletingFiles([...deletingFiles, fileDeleteName])
  }

  const onSubmit = async (formData) => {

    setIsFetching(true)

    const cleansingData = new FormData();

    cleansingData.append("budgetOffer", formData.budgetOffer)
    cleansingData.append("description", formData.description)
    cleansingData.append("bidType", 2)
    
    formData.attachFiles.map((f) => {
      cleansingData.append("attachFiles", f)
    })

    deletingFiles.map(filename => {
      cleansingData.append("deleteFiles", filename)
    })
    
    const response = await dispatch(submitBid(idBidding, cleansingData))
    
    setIsFetching(false)

    if(response.status === 200){
      dispatch(getBiddingById(idBidding));
      onClose();
    }
  }

  useEffect(() => {
    if(values.attachFile){
      setCurrentFiles([
        {
          name: values.attachFile,
          url: values.attachFileURL,
        }
      ])
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style:{
          borderRadius: 20,
        }
      }}
    >
      <StyledRoot>
        <DialogContent>
          <Typography variant="h6" pb="24px">เสนองบประมาณรายเดือนที่ต้องการ</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="budgetOffer"
                  rules={{
                    required: {value: true, message: "กรุณากรอกงบประมาณรายเดือน"},
                    validate: {
                      lessThan: (value) => {
                        if(budgetMax === "" || budgetMax === null){
                          return true
                        } else {
                          return (value <= budgetMax) || `งบประมาณต้องไม่เกิน ${Utils.numberWithCommas(budgetMax)} บาท`
                        }
                      }
                    }
                  }}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      InputProps={{
                        inputComponent: NumberCustom,
                      }}
                      inputProps={{
                        suffix: " บาท",
                        value: field.value,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value)
                        },
                      }}
                      label="งบประมาณรายเดือน"
                      helperText={errors && errors.budgetOffer && errors.budgetOffer.message}
                      error={errors && errors.budgetOffer ? true: false}
                      autoComplete="budgetOffer"
                      onChange={()=>{}}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="description"
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      label="ข้อความถึงบริษัท"
                      multiline
                      rows={3}
                      helperText={errors && errors.description && errors.description.message}
                      error={errors && errors.description ? true: false}
                      // onChange={(e)=>{
                      //   console.log(field)
                      //   field.onChange(e.target.value)
                      // }}
                    />
                  )}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Box style={{paddingBottom: 16}}>
                  <Typography>ไฟล์แนบ (.pdf สูงสุด 1 ไฟล์ ไม่เกิน 2mb)</Typography>
                </Box>
                <Controller
                  control={control}
                  name="attachFiles"
                  render={({field}) => (
                    <DropAttachFile
                      accept="application/pdf"
                      maxFiles={1}
                      maxSize={2}
                      files={field.value}
                      onChange={field.onChange}
                      currentFiles={currentFiles}
                      onDeleteCurrentFiles={onDeleteCurrentFiles}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <ButtonBlue variant="contained" type="submit">ประมูล</ButtonBlue>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </StyledRoot>
    </Dialog>
  )
}

export default BiddingOfferBudget;