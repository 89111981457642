import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
    Avatar,
    Box,
    Chip,
    styled,
    Tooltip,
    Typography
} from '@mui/material';

import ButtonBlue from '../../shared/general/ButtonBlue';
import CardStyle from '../../shared/general/Card';
import DialogCancelled from '../dialogCancelled';
import TableTheme from '../../shared/general/TableTheme';

import { updateCancelSendingCandidate } from "../../../../actions/candidate";
import { getAllFreeUsersByIdVendor } from "../../../../actions/user";

const StyledContent = styled("div")({
    padding: 36,
    paddingTop: 24,
    "& .column-name": {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        color:"#000000",
        "& .MuiAvatar-root": {
          marginRight: 8,
          width: 40,
          height: 40,
        },
    },
});

const WaitingProcessChip = styled(Chip)({
    width: "130px",
    color: "#ff9800",
    fontWeight: 500,
    backgroundColor: "#fff0ce"
});

const WaitingResultChip = styled(Chip)({
    width: "130px",
    color: "#623f00",
    fontWeight: 500,
    backgroundColor: "#ffa500"
});

const PassResultChip = styled(Chip)({
    width: "130px",
    color: "#0b5f10",
    fontWeight: 500,
    backgroundColor: "#76d576"
});

const FailResultChip = styled(Chip)({
    width: "130px",
    color: "#b81a1a",
    fontWeight: 500,
    backgroundColor: "#eb7d7d"
});

const CancelledResultChip = styled(Chip)({
    width: "130px",
    fontWeight: 500
});

const CardCandidateSent = (props) => {
    let { listCandidatesSent, onClickSubmitCancelled, isBidding } = props;
    const dispatch = useDispatch();

    const [openDialog, setOpenDialog] = useState({ status: false, candidate: null});

    const displayTime = (time) => {
        let splitText = time.split(":");
        return dayjs().hour(parseInt(splitText[0])).minute(parseInt(splitText[1])).format("HH.mm");
    };

    const handleClickCancelled = async (candidate) => {
        setOpenDialog({ status: true, candidate: candidate });
    };

    const handleSubmitCancelled = async (params) => {
        const res = await dispatch(updateCancelSendingCandidate({ id: params.idCandidate, idManpower: params.idManpower }));
        setOpenDialog({ status: false, candidate: null });
        if (res.message === "success") {
            onClickSubmitCancelled();
            dispatch(getAllFreeUsersByIdVendor());
        }
    };

    const columnTable = [
    {
        field: "avatar&name",
        width:"300px",
        headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
            <i className="fa-solid fa-user-group" style={{ color: "#007afe", fontSize: "1.1rem", marginRight: "10px" }} />
            <Typography>ชื่อ-สกุล</Typography>
        </Box>
        ),
        format: (params) => (
        <Tooltip title="ดูประวัติ" placement="top-start">
            <Box className="column-name" component={Link} to={`/manpower/profile/${params.idManpower}`}>
            <Avatar src={`${process.env.REACT_APP_API_URL}image/profile/${parseInt(Math.random() * 20) + 1}.jpg`} />
            <Box>
                <Typography>{`${params.name} ${params.lastname}`}</Typography>
                <Typography style={{ color:"#919eab" }}>{`${params.telephoneMobile}`}</Typography>
            </Box>
            </Box>
        </Tooltip>
        )
    },
    {
        field: "status",
        width: "130px",
        headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
            <i className="fa-solid fa-user" style={{ color: "#007afe", fontSize: "1.1rem", marginRight: "10px" }} />
            <Typography>สถานะ</Typography>
        </Box>
        ),
        format: (params) => (
        <Box className="column-name">
            {params.idStatus && params.idStatus === 1 ?
            <PassResultChip label="ผ่านสัมภาษณ์" />
            :
            params.idStatus === 2 ?
                <FailResultChip label="ไม่ผ่านสัมภาษณ์" />
                :
                params.idStatus === 3 ?
                <Tooltip 
                    title={
                    <Box style={{ display: "flex", padding: "5px 10px" }}>
                        <ButtonBlue
                        variant="contained"
                        onClick={() => handleClickCancelled(params)}
                        >ยกเลิกการส่ง</ButtonBlue>
                    </Box>
                    } 
                    placement="top"
                >
                    <WaitingProcessChip label="รอดำเนินการ" />
                </Tooltip>
                :
                params.idStatus === 4 ?
                    <WaitingResultChip label="รอสัมภาษณ์" />
                    :
                    params.idStatus === 8 ?
                    <CancelledResultChip label="ยกเลิก" />
                    :
                    <Typography> ไม่มีสถานะ </Typography>
            }
        </Box>
        )
    },
    ...isBidding ? [{
        field: "positionName",
        width: "240px",
        headerName: (
            <Box style={{ display: "flex", alignItems: "center" }}>
                <i 
                    className="fa-solid fa-briefcase" 
                    style={{ color: "#007afe", fontSize: "1.1rem", marginRight: "10px", marginTop: "2px" }} 
                />
                <Typography>ตำแหน่งงาน</Typography>
            </Box>
        ),
        format: (params) => (
            <Box className="column-name">
                <Typography>{params.positionName}</Typography>
            </Box>
        )
    }] : [],
    {
        field: "time",
        width: 150,
        headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
            <i className="fa-solid fa-clock" style={{ color: "#007afe", fontSize: "1.1rem", marginRight: "10px", marginTop: "2px" }} />
            <Typography>เวลาสัมภาษณ์</Typography>
        </Box>
        ),
        format: (params) => (
        <Box className="column-name">
            <Typography>{params.appointmentStartTime ? displayTime(params.appointmentStartTime) : "-"}</Typography>
        </Box>
        )
    },
    {
        field: "place",
        width: 400,
        headerName: (
        <Box style={{ display: "flex", alignItems: "center" }}>
            <i className="fa-solid fa-location-dot" style={{ color: "#007afe", fontSize: "1.1rem", marginRight: "10px" }} />
            <Typography>สถานที่สัมภาษณ์</Typography>
        </Box>
        ),
        format: (params) => (
        <Box className="column-name">
            <Typography>{params.appointmentPlace || "-"}</Typography>
        </Box>
        )
    },
    ];

    return (
        <Fragment>
          <CardStyle>
            <StyledContent>
              <Typography variant="h5">กำลังพลที่ส่ง</Typography>
              <Box style={{ margin: "20px 0" }}>
                <TableTheme
                  columns={columnTable}
                  rows={listCandidatesSent}
                  textEmpty="ไม่พบผู้สมัคร"
                  minHeight={200}
                />
              </Box>
            </StyledContent>
          </CardStyle>
          {openDialog.status &&
            <DialogCancelled
              open={openDialog.status}
              onClose={() => setOpenDialog({ status: false, candidate: null })}
              candidate={openDialog.candidate}
              handleSubmit={handleSubmitCancelled}
            />
          }
        </Fragment>
    );
};

export default CardCandidateSent;