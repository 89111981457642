import {
  USERPROFILE_FETCHING,
  USERPROFILE_FAILED,
  USERPROFILE_SUCCESS,
  USERS_FETCHING,
  USERS_FAILED,
  USERS_SUCCESS,
  USERS_VENDOR_FREE_FETCHING,
  USERS_VENDOR_FREE_FAILED,
  USERS_VENDOR_FREE_SUCCESS,
  USERPROFILE_VENDOR_MANPOWER_FETCHING,
  USERPROFILE_VENDOR_MANPOWER_FAILED,
  USERPROFILE_VENDOR_MANPOWER_SUCCESS
} from "./types";

import UserService from "../services/user.service";

export const getUserProfile = (id) => async (dispatch) => {
  try {
    const res = await UserService.getUserProfile(id);
    if (res) {
      // console.log(res)
      dispatch({
        type: USERPROFILE_SUCCESS,
        payload: res.data,
      });

      return res
    }
  } catch (err) {
    dispatch({
      type: USERPROFILE_FAILED,
    });
    console.log(err.response);
    return err.response
  }
};

export const getAllUsers = () => async (dispatch) => {
  try {
    const res = await UserService.getAllUsers();
    if (res) {
      dispatch({
        type: USERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USERS_FAILED,
    });
    console.log(err);
  }
};

export const getAllFreeUsersByIdVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: USERS_VENDOR_FREE_FETCHING,
    });
    const res = await UserService.getAllFreeUsersByIdVendor();
    if (res) {
      dispatch({
        type: USERS_VENDOR_FREE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: USERS_VENDOR_FREE_FAILED,
    });
  }
};

export const getAllUsersByIdVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: USERS_FETCHING,
    });
    const res = await UserService.getAllUsersByIdVendor();
    if (res) {
      dispatch({
        type: USERS_SUCCESS,
        payload: res.data,
      });
      return res
    }
  } catch (error) {
    dispatch({
      type: USERS_FAILED,
    });
    return error.response
  }
};

export const getUserProfileVendorManpower = (idManpower) => async (dispatch) => {
  try {
    dispatch({
      type: USERPROFILE_VENDOR_MANPOWER_FETCHING,
    });
    const res = await UserService.getUserProfileVendorManpower(idManpower);
    if(res){
      dispatch({
        type: USERPROFILE_VENDOR_MANPOWER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: USERPROFILE_VENDOR_MANPOWER_FAILED,
    });
  }
};

export const updateStatusManpower = (data) => async () => {
  try {
    const res = await UserService.updateStatusManpower(data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};