import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Chip, Container, Divider, Grid, IconButton, Rating, styled, Typography } from "@mui/material";
import CardStyle from "../../shared/general/Card";

import {
  Favorite, 
  FavoriteBorder
} from '@mui/icons-material';

import ButtonBlue from "../../shared/general/ButtonBlue";

import branchStore from "../../assets/data/branch";

import DrawerBranch from "../drawerBranch";
import { getVendorByNameShort } from "../../../../actions/vendor";
import { addFavoriteVendor, deleteFavoriteVendor } from "../../../../actions/favoriteVendor";
import { getContractCompany } from "../../../../actions/contract";
import ContractCompany from "./contract";
import DialogAddToMyVendor from "./dialogAddToMyVendor";
import { addMyVendor } from "../../../../actions/adminCompany";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#f1f4f9",
  paddingBottom: 36,
  "& .vendor-expertise":{
    marginBottom:"12px"
  }
})

const StyledContent = styled("div")({
  padding: 36,
  paddingTop: 24,
  "& .part-one": {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 8,
    "& .MuiAvatar-root": {
      width: 160,
      height: 160,
      fontSize: 42,
      marginRight: 36,
      backgroundColor: "white",
      color: "inherit",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
      "& i": {
        fontSize: 120,
        color: "inherit",
      }
    },
    "& .detail": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      position:"relative",
      "& .start": {
        "& .vendor-name": {
          marginBottom: 12,
        },
        "& .company-industry": {
          marginBottom: 12,
        },
        "& div": {
          marginRight: 32,
          ["@media only screen and (max-width: 600px)"]:{
            marginRight: 0
          },
        },
      },
    },
  },
  "& .MuiDivider-root": {
    margin: "16px 0px",
    borderWidth: "0px 0px thin",
    borderColor: "rgba(145, 158, 171, 0.24)",
    borderStyle: "dashed",
  },
  "& .part-two": {
    marginBottom: 16,
    "& .wrap-item": {
      "& .label": {
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 4,
        "& span": {
          marginLeft: 16,
        },
      },
      "& .value": {
        fontSize: 18,
        marginLeft: 32,
      },
      "& .fal": {
        marginRight: 8,
      },
    },
  },
  "& .topic": {
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 8 
  },
  "& .MuiTabs-root": {
    marginBottom: 16,
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },
  "& .part-three": {
    "& .branch-count": {
      marginLeft: 32,
    }
  }
})

const StyledBranchContent = styled("div")({
  // padding: 16,
  paddingLeft: 16,
  // borderRadius: 20,
  // border: "1px solid #ececec",
  "& .branch-name": {
    fontSize: 18,
  },
  "& i": {
    marginRight: 8,
  },
  "& .contact-container": {
    display: "flex",
    flexFlow: "row wrap",
    "& .contact-wrap": {
      marginRight: 16,
    }
  }
})

const VendorInformationPage = (props) => {

  const { match } = props;

  const [isOpenDrawerBranch, setIsOpenDrawerBranch] = useState(false);
  const [isOpenAddToMyVendor, setIsOpenAddToMyVendor] = useState(false);

  const [branch, setBranch] = useState([]);

  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { result: userMe } = useSelector((state) => state.userProfile);
  const { result: vendorStore } = useSelector((state) => state.vendorInformation)
  const { result: contractCompany } = useSelector((state) => state.contractCompany)

  const [vendor, setVendor] = useState(null);

  useEffect(() => {
    setBranch(branchStore.filter(b => b.idCompany === 1));
    dispatch(getVendorByNameShort(match.params.vendorNameShort)).then(res => {
      if(res.status === 200 && isLoggedIn && userMe && userMe.idCompany){
        dispatch(getContractCompany({idVendor: res.data.idVendor}))
      }
    });
  }, [])

  useEffect(() => {
    setVendor(null)
    if(vendorStore !== null){
      setVendor(vendorStore);
    }
  }, [vendorStore])

  const onClickFavorite = async () => {
    if(vendor){
      let result;
      if(!Boolean(vendor.isFavorite)){
        result = await dispatch(addFavoriteVendor(vendor.idVendor));
      } else {
        result = await dispatch(deleteFavoriteVendor(vendor.idVendor));
      }
      if(result){
        let temp = vendor;
        temp.isFavorite = !Boolean(vendor.isFavorite) ? 1 : 0;
        setVendor({...temp});
      }
    }
  };

  const onConfirmAddToMyVendor = () => {
    dispatch(addMyVendor({idVendor: vendor.idVendor})).then(res => {
      if(res.status === 200){
        dispatch(getVendorByNameShort(match.params.vendorNameShort)).then(res => {
          if(res.status === 200 && isLoggedIn && userMe && userMe.idCompany){
            dispatch(getContractCompany({idVendor: res.data.idVendor}))
          }
        });
      }
    })
  }

  return(
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {vendor && (
          <Fragment>
            <Typography variant="h4" style={{ padding: "24px 0" }}>
              ข้อมูล Vendor
            </Typography>
            <CardStyle>
              <StyledContent>
                <div className="part-one">
                  {/* <Avatar variant="rounded" src={vendor.vendorImage? `${process.env.REACT_APP_API_URL}image/vendor/${vendor.vendorImage}`: ""}> */}
                  <Avatar variant="rounded" src={vendor.vendorImageURL}>
                    <img src="/assets/logo_default.png" />
                  </Avatar>
                  <div className={`detail`}>
                    <div className={`start`}>
                      <div className="vendor-name">
                        <Typography variant="h4">{vendor.vendorName}</Typography>
                      </div>
                      <div className="vendor-expertise">
                        <Typography variant="caption" color="text.secondary">ขนาดองค์กร</Typography>
                        <Typography variant="h5">{`ขนาดกลาง`}</Typography>
                      </div>
                      <div className="vendor-expertise">
                        <Typography variant="caption" color="text.secondary">ขนาด Manpower</Typography>
                        <Typography variant="h5">{`${vendor.manpowerSize || 0} คน`}</Typography>
                      </div>
                      <div className="vendor-expertise" style={{ display:"flex", flexDirection:"column" }}>
                        <Typography variant="caption" color="text.secondary">คะแนน</Typography>
                        <Box style={{ display:"flex", alignItems:"center" }}>
                          <Rating 
                            value={vendor.rating}
                            style={{ fontSize:"32px" }}
                            readOnly
                          />
                          <Typography style={{ marginLeft:"8px" }}>{`(${vendor.rating})`}</Typography>
                        </Box>
                      </div>
                    </div>
                    {isLoggedIn && 
                      <IconButton 
                        style={{ position: "absolute", right: 0, color:"#FF0833" }}
                        onClick={onClickFavorite}
                      >
                        {!!vendor.isFavorite ? <Favorite /> : <FavoriteBorder />}
                      </IconButton>
                    }
                  </div>
                </div>
                <Divider
                  style={{
                    margin: "16px 0px",
                    borderWidth: "0px 0px thin",
                    borderColor: "rgba(145, 158, 171, 0.24)",
                    borderStyle: "dashed",
                  }}
                />
                <div className="part-two">
                  <div className="wrap-item">
                    <Typography className="label" color="text.secondary">
                      <i className="far fa-map-marker-alt"></i>
                      <span style={{ fontSize:"14px" }}>ที่อยู่</span>
                    </Typography>
                    <Typography className="value" style={{ fontSize:"1.25rem" }}>{vendor.address || "-"}</Typography>
                  </div>
                </div>

                <div className="part-three">
                  <Grid container justifyContent="space-between" alignItems="center">
                    <div>
                      <Typography className="label" color="text.secondary">
                        <i className="fa-regular fa-building"></i>
                        <span style={{marginLeft: 14, fontSize:"14px"}}>สาขาของบริษัท</span>
                      </Typography>
                      <Typography className="branch-count" style={{ fontSize:"1.25rem" }}>{`${vendor.branches.length} สาขา`}</Typography>
                    </div>
                    <ButtonBlue startIcon={<i className="fa-regular fa-magnifying-glass"></i>} variant="outlined" onClick={()=>{setIsOpenDrawerBranch(true)}}>ดูสาขาทั้งหมด</ButtonBlue>
                  </Grid>
                  {/* <Grid container spacing={2}>
                    {branch
                    .length > 1? (
                      <>
                      </>
                    ): (
                      <>
                        {branch
                          .map((b, index) => (
                            <Grid key={index} item xs={12}>
                              <StyledBranchContent>
                                <Typography className="branch-name" variant="h7">{b.branchName}</Typography>
                                <Typography color="text.secondary">
                                  <i className="far fa-map-marker-alt"></i>
                                  <span>{`${b.address}`}</span>
                                </Typography>
                                <div className="contact-container">
                                  <div className="contact-wrap">
                                    <Typography>
                                      <i className="fa-regular fa-user-headset"></i>
                                      <span>{`${b.mainContactName}`}</span>
                                    </Typography>
                                  </div>
                                  <div className="contact-wrap">
                                    <i className="fa-regular fa-phone"></i>
                                    <span>{`${b.mainContactPhone}`}</span>
                                  </div>
                                  <div className="contact-wrap">
                                    <i className="fa-regular fa-envelope"></i>
                                    <span>{`${b.mainContactEmail}`}</span>
                                  </div>
                                </div>
                              </StyledBranchContent>
                            </Grid>
                          ))
                        }
                      </>
                    )}
                  </Grid> */}
                </div>
              </StyledContent>
            </CardStyle>

            <div style={{ marginBottom: 24 }}></div>

            <CardStyle>
              <StyledContent>
                <Typography className="topic" style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}>
                  ข้อมูลติดต่อ
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="caption" color="text.secondary">ชื่อผู้ติดต่อ</Typography>
                    <Typography style={{ fontSize:"1.25rem" }}>{vendor.mainContactName || "-"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="caption" color="text.secondary">เบอร์โทรศัพท์</Typography>
                    <Typography style={{ fontSize:"1.25rem" }}>{vendor.mainContactPhone || "-"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="caption" color="text.secondary">อีเมล</Typography>
                    <Typography style={{ fontSize:"1.25rem" }}>{vendor.mainContactEmail || "-"}</Typography>
                  </Grid>
                </Grid>
              </StyledContent>
            </CardStyle>

            <div style={{ marginBottom: 24 }}></div>

            <CardStyle>
              <StyledContent>
                <Typography className="topic" style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}>
                  เกี่ยวกับ Vendor
                </Typography>
                <Typography style={{ fontSize:"1.25rem" }}>
                  {vendor.vendorAbout || "-"}
                </Typography>
              </StyledContent>
            </CardStyle>

            <div style={{ marginBottom: 24 }}></div>

            <CardStyle>
              <StyledContent>
                <Typography className="topic" style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}>
                  ความเชี่ยวชาญ
                </Typography>
                {vendor.expertises.length === 0 && (
                  <Typography>-</Typography>
                )}
                {vendor.expertises.length > 0 && <div style={{display: "flex", gap: 16}}>
                  {vendor.expertises.map(ex => (
                    <Chip key={`expertise_${ex.idExpertise}`} label={ex.expertiseName}/>
                  ))}
                </div>}
              </StyledContent>
            </CardStyle>

            {isLoggedIn && contractCompany && contractCompany.isContract && (
              <Box marginTop="24px">
                <CardStyle>
                  <StyledContent>
                    <ContractCompany contractCompany={contractCompany} idVendor={vendor.idVendor}/>
                  </StyledContent>
                </CardStyle>
              </Box>
            )}

            {isLoggedIn && contractCompany && !contractCompany.isContract && (
              <Fragment>
                <Box marginTop="24px" display="flex" justifyContent="flex-end">
                  <ButtonBlue variant="contained" onClick={()=>{setIsOpenAddToMyVendor(true)}}>ขึ้นทะเบียน</ButtonBlue>
                </Box>
                {isOpenAddToMyVendor && (
                  <DialogAddToMyVendor
                    open={isOpenAddToMyVendor}
                    onClose={()=>{setIsOpenAddToMyVendor(false)}}
                    onConfirm={onConfirmAddToMyVendor}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Container>

      {isOpenDrawerBranch && (
        <DrawerBranch
          open={isOpenDrawerBranch}
          onClose={()=>{setIsOpenDrawerBranch(false)}}
          branches={vendor.branches}
        />
      )}
    </StyledRoot>
  )
}

export default VendorInformationPage;