import { httpClient } from "./httpClient";

const getCompanyByNameShort = (companyNameShort) => {
  return httpClient.get(`companies/${companyNameShort}`);
};

const getCompanyManage = () => {
  return httpClient.get(`companies/manage`);
};

const addCompanyLogo = (formData) => {
  return httpClient.post(`companies/manage/logo`, formData);
};

const deleteCompanyLogo = () => {
  return httpClient.delete(`companies/manage/logo`);
};

// const addCompanySignature = (formData) => {
//   return httpClient.post(`companies/manage/signature`, formData);
// };

const addCompanyAuthorizedSignatureOne = (formData) => {
  return httpClient.post(`companies/manage/authorizedSignatureOne`, formData);
};

const deleteCompanyAuthorizedSignatureOne = () => {
  return httpClient.delete(`companies/manage/authorizedSignatureOne`);
};

const addCompanyAuthorizedSignatureTwo = (formData) => {
  return httpClient.post(`companies/manage/authorizedSignatureTwo`, formData);
};

const deleteCompanyAuthorizedSignatureTwo = () => {
  return httpClient.delete(`companies/manage/authorizedSignatureTwo`);
};

const addCompanyWitnessSignature = (formData) => {
  return httpClient.post(`companies/manage/witnessSignature`, formData);
};

const deleteCompanyWitnessSignature = () => {
  return httpClient.delete(`companies/manage/witnessSignature`);
};

const updateCompanyManage = (formData) => {
  return httpClient.patch(`companies/manage`, formData);
};

const updateCompanyManageBankDetail = (formData) => {
  return httpClient.patch(`companies/manage/bankdetail`, formData);
};

const addCompanyManageBranch = (formData) => {
  return httpClient.post(`companies/manage/branch`, formData);
};

const getAllCompanys = (query) => {
  return httpClient.get(`companys`,{params: query});
};

const getMyCompanys = () => {
  return httpClient.get(`my-company`);
};

const generatorTokenChildsCompany = () => {
  return httpClient.get(`company/generator-token-childs-company`);
};

const decodeTokenChildsCompany = (token) => {
  return httpClient.post(`company/decode-token-childs-company`,token);
};

const setParentCompany = (data) => {
  return httpClient.put(`company/setParentCompany`,data);
};

export default {
  getCompanyByNameShort,
  getCompanyManage,
  addCompanyLogo,
  deleteCompanyLogo,
  addCompanyAuthorizedSignatureOne,
  deleteCompanyAuthorizedSignatureOne,
  addCompanyAuthorizedSignatureTwo,
  deleteCompanyAuthorizedSignatureTwo,
  addCompanyWitnessSignature,
  deleteCompanyWitnessSignature,
  updateCompanyManageBankDetail,
  updateCompanyManage,
  addCompanyManageBranch,
  getAllCompanys,
  getMyCompanys,
  generatorTokenChildsCompany,
  decodeTokenChildsCompany,
  setParentCompany
};