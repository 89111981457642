import React, { useEffect, useState } from "react";
import { Autocomplete, Container, Grid, InputAdornment, Pagination, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import CardCompany from "../../shared/general/CardCompany";

import { getAllWorkingLocations } from "../../../../actions/address";
import { getAllCompanys } from "../../../../actions/company";
import { getAllBusinessGroup } from '../../../../actions/business';

const StyledRoot = styled("div")({
  "& .part-filter": {
    marginBottom: 48,
    padding: 24,
    backgroundColor: "#f9fafb",
    borderRadius: 20,
    "& .MuiAutocomplete-root": {
      "& input": {
        padding: 0,
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiInputAdornment-root": {
        "& i": {
          fontSize: 24,
          color: "#919EAB",
        }
      },
      "& .MuiButtonBase-root": {
        "& i": {
          fontSize: 14,
          color: "#919EAB",
        }
      }
    }
  },
  "& .part-pagination": {
    paddingTop: 24,
    display: "flex",
    justifyContent: "flex-end",
  }
})

const CompanySearchPage = () => {

  const dispatch = useDispatch();

  const { control, getValues } = useForm({
    defaultValues: {
      province: null,
      industryType: null,
    }
  })

  const { result: AllWorkingLocations } = useSelector((state) => state.workingLocation);
  const { result: AllCompanys } = useSelector((state) => state.companys);
  const { result: AllBusinessGroups } = useSelector((state) => state.businessGroups);

  const [companys, setCompanys] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [page, setPage] = useState(1);

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  }

  const onSubmitHandler = () => {
    let formData = getValues();
    let query = {
      province: (formData.province && formData.province.workingLocationNameTH) || null,
      businessGroup: (formData.businessGroup && formData.businessGroup.idBusinessGroup) || null
    };
    dispatch(getAllCompanys(query));
  }

  useEffect(() => {
    dispatch(getAllWorkingLocations());
    dispatch(getAllCompanys());
    dispatch(getAllBusinessGroup());
  }, [])

  useEffect(() => {
    if(AllCompanys !== null){
      setCompanys([...AllCompanys]);
    }
  }, [AllCompanys])

  return(
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="part-filter">
          <form>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="province"
                  render={({ field: { onChange, value }}) => (
                    <Autocomplete
                      options={AllWorkingLocations || []}
                      popupIcon={<i className="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) => option.workingLocationNameTH}
                      isOptionEqualToValue={(option, value) => option.workingLocationNameTH === value.workingLocationNameTH}
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.workingLocationNameTH}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder="จังหวัด"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="fa-light fa-location-dot"></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, data) => {
                        onChange(data);
                        onSubmitHandler();
                        return data
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>
              
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="businessGroup"
                  render={({ field: { onChange, value }}) => (
                    <Autocomplete
                      options={AllBusinessGroups || []}
                      popupIcon={<i className="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) => option.businessGroupName}
                      isOptionEqualToValue={(option, value) => option.businessGroupName === value.businessGroupName}
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.businessGroupName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder="ประเภทอุตสาหกรรม"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="fa-light fa-briefcase"></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      onChange={(_, data) => {
                        onChange(data);
                        onSubmitHandler();
                        return data
                      }}
                      value={value}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </div>

        <div>
          <Grid container columnSpacing={4} rowSpacing={8}>
            {companys.length > 0 ?
              companys
              .slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage)
              .map((company, index) => (
                <Grid key={`company_${index}`} item xs={4}>
                  <CardCompany company={company} />
                </Grid>
              ))
              :
              <Grid item xs={12}>
                <Typography align="center" variant="h5">ไม่พบบริษัท</Typography>
              </Grid>
            }
          </Grid>
        </div>

        {companys.length > 0 && <div className="part-pagination">
          <Pagination
            count={Math.ceil(companys.length / rowsPerPage) || 1}
            shape="rounded"
            onChange={onChangePage}
            page={page}
          />
        </div>}

      </Container>
    </StyledRoot>
  )
}

export default CompanySearchPage;