import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, styled } from "@mui/material";

import LeftPanel from "./leftPanel";
import RightPanel from "./rightPanel";

import { getVendorManage } from "../../../../actions/vendor";
import { getAllBank } from "../../../../actions/bank";
import { getJobGroups } from "../../../../actions/job";

const StyledRoot = styled("div")({
  "& .header-text": {
    fontSize: 20,
  },
  "& .edit-button": {
    borderRadius: 19,
    "& i": {
      fontSize: 12,
      marginRight: 8,
    }
  },
});

const VendorManagePage = (props) => {

  const { match } = props;

  const dispatch = useDispatch();

  const { result: vendorStore } = useSelector((state) => state.vendorManage)
  const { result: bankStore } = useSelector((state) => state.bank)

  const [vendor, setVendor] = useState(null);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    dispatch(getVendorManage())
    dispatch(getAllBank())
    dispatch(getJobGroups())
  }, [match.params.vendorNameShort])

  useEffect(() => {
    setVendor(null);
    if(vendorStore != null){
      setVendor({...vendorStore});
    }
  }, [vendorStore])

  useEffect(() => {
    if(bankStore != null){
      setBanks([...bankStore])
    }
  }, [bankStore])

  return(
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {vendor && (
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <LeftPanel vendor={vendor}/>
              </Grid>
              <Grid item xs={12} sm={8}>
                <RightPanel
                  vendor={vendor}
                  branches={vendor.branches}
                  banks={banks}
                  bankDetail={vendor.bankDetail}/>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Container>
    </StyledRoot>
  )
}

export default VendorManagePage;