import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Avatar, Card, Container, styled, Typography } from "@mui/material";

import JobIcon from "../../../assets/icons/job_500px.png"
import BiddingIcon from "../../../assets/icons/bidding_500px.png"
import VendorIcon from "../../../assets/icons/vendor_500px.png"

import locations from "../../../assets/data/location";
import { Link, Redirect } from "react-router-dom";
import { NotFoundPage } from "../../../error";

const StyledRoot = styled("div")({
  "& a": {
    textDecoration: "none"
  },
  "& .part-header": {
    paddingBottom: 24,
    "& .text": {
      fontSize: 24,
    }
  },
  "& .part-menu": {
    display: "grid",
    gap: 24,
    gridTemplateColumns: "repeat(3, 1fr)",
    "& .card": {
      padding: 16,
      boxShadow: "none",
      borderRadius: 16,
      border: "1px solid #919eab3d",
      cursor: "pointer",
      "& .logo": {
        display: "flex",
        justifyContent: "center",
        "& .MuiAvatar-root": {
          width: "100%",
          height: "auto",
          maxWidth: 200,
        },
      },
      "& .name": {
        paddingTop: 16,
        textAlign: "center",
        fontSize: 22,
        color: "#212b36",
        wordBreak: "break-word"
      }
    },
    "@media only screen and (max-width: 900px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media only screen and (max-width: 500px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  }
})

const ProvinceMenu = (props) => {

  const { match } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isFetching, setIsFetching] = useState(true);
  const [province, setProvince] = useState(null);

  useEffect(() => {
    const province = locations.find(p => p.name === match.params.provinceName)
    if(province){
      setProvince(province)
    } else {

    }
    setIsFetching(false)
  }, [])

  return (
    <StyledRoot className="page">
      {(!isFetching && province) && <Container maxWidth="lg">
        <div className="part-header">
          <Typography className="text">จังหวัด: {province.name}</Typography>
        </div>
        <div className="part-menu">
          {(!userProfile || userProfile.idVendor) &&
            <Fragment>
              <Link to={`/jobs?province=${province.name}`}>
                <Card className="card">
                  <div className="logo">
                    <Avatar src={JobIcon} variant="square"/>
                  </div>
                  <Typography className="name">งานที่เปิด</Typography>
                </Card>
              </Link>
              <Link to={`/biddings?province=${province.name}`}>
                <Card className="card">
                  <div className="logo">
                    <Avatar src={BiddingIcon} variant="square"/>
                  </div>
                  <Typography className="name">ประมูลสัญญา</Typography>
                </Card>
              </Link>
            </Fragment>
          }
          {!userProfile && 
            <Link to={`/vendor-search?province=${province.name}`}>
              <Card className="card">
                <div className="logo">
                  <Avatar src={VendorIcon} variant="square" />
                </div>
                <Typography className="name">คู่ธุรกิจ</Typography>
              </Card>
            </Link>
          }
        </div>
      </Container>}

      {(!isFetching && !province) && <Container maxWidth="lg">
        <NotFoundPage />
      </Container>}
    </StyledRoot>
  )
}

export default ProvinceMenu;