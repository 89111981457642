import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import clsx from "clsx";

const StyledTable = styled(TableContainer)(({ minheight }) => ({
  minHeight: minheight === undefined ? 600 : minheight,
  maxHeight: 600,
  "& .MuiTableRow-root": {
    "& .MuiTableCell-root": {
      backgroundColor: "#ffffff",
      borderBottom: "none",
      zIndex: 2,
      "&:first-of-type": {
        position: "sticky",
        left: 0,
        zIndex: 4,
        boxShadow: "rgb(238 238 238) 1px 20px 20px",
      }
    }
  },
  "& .MuiTableHead-root": {
    borderRadius: 20,
    "& .MuiTableCell-root": {
      color: "rgb(99, 115, 129)",
      backgroundColor: "rgb(244, 246, 248)",
      "&:first-of-type": {
        zIndex: 5,
        boxShadow: "rgb(238 238 238) 8px -10px 20px",
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      },
      "&:last-of-type": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    }
  },
}))

const TableTheme = (props) => {

  const { columns, rows, textEmpty, minHeight } = props;

  return (
    <StyledTable minheight={minHeight}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.field}
                align={column.align}
                style={{minWidth: column.width, maxWidth: column.width, width: column.width}}
              >
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows && rows.length > 0 ?
            rows.map((row, rowIndex) => (
              <TableRow key={`row-${rowIndex}`}>
                {columns
                .map((column, columnIndex) => (
                  <TableCell 
                    key={`column-${rowIndex}-${columnIndex}`} 
                    align={column.align}
                    className={column.classname ? column.classname(row) : null}
                  >
                    {
                      column.format(row, rowIndex)
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))
            :
            <TableRow style={{ height: minHeight ? minHeight : "540px" }}>
              <TableCell align="center">{textEmpty}</TableCell>
              <TableCell align="center" colSpan={columns.length - 1}>
                {textEmpty}
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </StyledTable>
  )
};

export default TableTheme;
