import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Avatar, Box, Chip, Container, Dialog, DialogContent, Divider, Grid, styled, Typography } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import { getBiddingById } from "../../../../actions/bidding";
import dayjs from "dayjs";
import Utils from "../../../../utils";

import BiddingOfferCharge from "./biddingOfferCharge";
import BiddingOfferBudget from "./biddingOfferBudget";
import CardCandidateSent from "../shared/cardCandidateSent";
import CardPassedCandidate from "../shared/cardPassedCandidate";
import CardSendManpower from "../shared/CardSendManpower";

import { getAllFreeUsersByIdVendor } from "../../../../actions/user";
import { 
  getCandidatesAlreadySentToBidding,
  getPassedCandidatesInBidding, 
  sendManpowersToCandidateBidding
} from "../../../../actions/candidate";

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

const StyledRoot = styled("div")(({ theme }) => ({
  "& .bidding-time-remain-container": {
    padding: "48px 0",
    "& .title": {
      paddingBottom: 24,
      fontSize: 24,
      textAlign: 'center',
    },
    "& .remain": {
      fontSize: 48,
      textAlign: 'center',
      "& span": {
        fontSize: 36
      }
    },
    "& .warning": {
      color: "#d50000"
    }
  },
  "& .bidding-left-container": {
    padding: 24,
    "& .bidding-header": {
      display: "flex",
      justifyContent: "space-between",
      "& .bidding-name": {
        fontSize: 24,
        wordBreak: "break-word"
      }, 
      "& .total": {
        whiteSpace: "nowrap",
        fontSize: 24,
        fontWeight: 500,
      }
    },
    "& .company": {
      paddingTop: 24,
      display: "flex",
      alignItems: "center",
      "& .MuiAvatar-root": {
        marginRight: 16,
        width: 56,
        height: 56,
        backgroundColor: "white",
        "& img": {
          width: "inherit",
          height: "inherit",
          objectFit: "contain"
        }
      },
      "& .name": {
        fontSize: 22,
        wordBreak: "break-word"
      }
    },
    "& .info": {
      paddingTop: 48,
      "& .item": {
        textAlign: "center",
        "& .title": {
          paddingBottom: 8,
          "& i": {
            marginRight: 8
          }
        }
      }
    },
    "& .positions": {
      paddingTop: 48,
      "& .title": {
        paddingBottom: 16,
        fontSize: 20,
        fontWeight: 500,
      },
      "& ul": {
        paddingLeft: 28,
        margin: 0,
        "& li": {
          paddingBottom: 8,
        }
      }
    },
    "& .job-groups": {
      paddingTop: 32,
      "& .title": {
        paddingBottom: 16,
        fontSize: 20,
        fontWeight: 500,
      },
      "& .wrap": {
        display: "flex",
        gap: 8,
        flexWrap: "wrap",
        "& .MuiChip-root": {
          fontWeight: 700,
        }
      }
    },
    "& .job-description": {
      paddingTop: 48,
      wordBreak: "break-word",
      "& .title": {
        paddingBottom: 16,
        fontSize: 20,
        fontWeight: 500,
      },
      "& .description": {
        wordBreak: "break-word"
      }
    },
    "& .location-description": {
      paddingTop: 48,
      wordBreak: "break-word",
      "& .title": {
        paddingBottom: 16,
        fontSize: 20,
        fontWeight: 500,
      },
      "& .description": {
        wordBreak: "break-word"
      }
    },
    "& .contract-description": {
      paddingTop: 48,
      "& .title": {
        paddingBottom: 16,
        fontSize: 20,
        fontWeight: 500,
      },
    },
    "& .payment-description": {
      paddingTop: 48,
      "& .title": {
        paddingBottom: 16,
        fontSize: 20,
        fontWeight: 500,
      },
    },
    "& .bidding-charge": {
      paddingTop: 48,
      "& .title": {
        // paddingBottom: 16,
        fontSize: 20,
        fontWeight: 500,
      },
      "& .charge": {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        "& .charge-title": {
          "& i": {
            marginRight: 8
          }
        },
        "& .value": {
          fontSize: 20,
          paddingLeft:"24px"
        }
      },
    },
    "& .bidding-budget": {
      paddingTop: 48,
      "& .title": {
        paddingBottom: 16,
        fontSize: 20,
        fontWeight: 500,
      },
      "& .value": {
        fontSize: 20,
      }
    },
    "& .date": {
      padding: 24,
      textAlign: "center",
      "& .title": {
        paddingBottom: 8,
        fontSize: 20,
        "& i": {
          marginRight: 8
        }
      }
    }
  },
  "& .bidding-right-container": {
    padding: 24,
    "& .charge": {
      textAlign: "center",
      "& .title": {
        "& i": {
          marginRight: 8
        }
      },
      "& .value": {
        fontSize: 20,
      }
    },
    "& .bid-button": {
      paddingTop: 16,
      paddingBottom: 16,
      fontSize: 20,
      lineHeight: 1,
    }
  },
  "& .grid-right": {
    position: "sticky",
    top: 100,
  },
  "& .link-text":{
    color:"rgb(0, 122, 253)",
    cursor:"pointer",
    marginLeft:"5px"
  }
}))

const DialogStyled = styled(Dialog)({
  "& .MuiPaper-root":{
      borderRadius: 16,
      padding: 16
  }
});

const BoxStatus = styled(Box)({
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  "& .icon":{
      fontSize: 112
  },
  "& .text":{
      marginTop: 24
  },
  "& .error":{
      color:"#e93d3d"
  },
  "& .CloseButton":{
    width: 80,
    marginTop: 16,
    padding: 8,
    border:"1px solid rgba(224, 224, 224, 1)",
    borderRadius: 8,
    cursor:"pointer",
    "& .textButton":{
      color: `#707070`,
    }
  }
});

const showEmployeeType = (value) => {
  switch(parseInt(value)){
    case 1: return "รายวัน";
    case 2: return "รายเดือน";
  }
}

const showPaymentType = (value) => {
  switch(parseInt(value)){
    case 1: return "15วันต่อครั้ง";
    case 2: return "เดือนละครั้ง";
  }
}

const showCharge = (value) => {
  if(value === null){
    return "ไม่ระบุ"
  } else {
    return `${value} %`
  }
}

const BiddingInfomationPage = (props) => {
  
  const { match } = props;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { result: biddingInformationStore, isFetching, isError } = useSelector(state => state.biddingInformation);
  const { result: UserProfile } = useSelector(state => state.userProfile);
  const { result: AllUsersVendorFree } = useSelector(state => state.userVendorFree);
  const { result: AllPassedCandidates } = useSelector(state => state.passedCandidatesCompany);
  const { result: AllCandidatesAlreadySent } = useSelector(state => state.candidateAlreadySent);

  const [biddingInformation, setBiddingInformation] = useState(null);
  const [isOpenDialogStartBidding, setIsOpenDialogStartBidding] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = React.useState(false);

  useEffect(() => {
    dispatch(getBiddingById(match.params.idBidding));
    dispatch(getCandidatesAlreadySentToBidding(match.params.idBidding));
  }, [match.params.idBidding])

  useEffect(() => {
    if(UserProfile && UserProfile.idVendor && match.params.idBidding) {
      dispatch(getAllFreeUsersByIdVendor());
    }
  },[UserProfile]);

  useEffect(() => {
    if (UserProfile && UserProfile.idCompany && 
      (biddingInformationStore && biddingInformationStore.idCompany === UserProfile.idCompany) && 
      match.params.idBidding
    ){
      dispatch(getPassedCandidatesInBidding(biddingInformationStore.idBiddings));
    }
  },[UserProfile, biddingInformationStore, match.params.idBidding]);

  useEffect(() => {
    if(biddingInformationStore !== null && !isFetching){
      setBiddingInformation(biddingInformationStore)
    }
  }, [biddingInformationStore]);

  let timeoutDisplay;

  const handleOnSubmit = async (right, ResetRight) => {
    if(dayjs().isSameOrBefore(biddingInformation.manpowerSendingDueDate)){
      let listManpowers = [];
      right.listManpowers.map((manpower) => {
        let temp = {
          idManpower : manpower.idManpower,
          idBiddingPositions : manpower.position.idBiddingPositions
        }
        listManpowers.push(temp);
      })
      let data = {
        listManpowers: listManpowers,
        idBiddings: match.params.idBidding
      }
      const res = await dispatch(sendManpowersToCandidateBidding(data));
      if (res.message === "success") {
        dispatch(getAllFreeUsersByIdVendor());
        ResetRight();
        setIsSuccess(true);
        timeoutDisplay = setTimeout(handleCloseDisplay, 5000);
      }
    } else {
      setIsFail(true);
    }
  } 

  const handleCloseDisplay = () => {
    clearInterval(timeoutDisplay);
    setIsSuccess(false);
  };

  const showBidButton = (bidType, startBiddingTime, endBiddingTime) => {
    if (UserProfile) {
      if(UserProfile.idCompany){
        if(dayjs().isBefore(dayjs(startBiddingTime))){
          return (
            <Box style={{ display:"flex", flexDirection:"column", alignItems:"center" }}>
              <i className="fa-solid fa-clock"  style={{ fontSize:"56px", margin:"10px", color:"#d88c00" }} />
              <Typography variant="h5"style={{ marginBottom:"10px", color:"#d88c00" }} align="center">ยังไม่เริ่มการประมูล</Typography>
            </Box>
          );
        } else if(dayjs().isBefore(dayjs(endBiddingTime))){
          return (
            <Box style={{ display:"flex", flexDirection:"column", alignItems:"center" }}>
              <i className="fa-duotone fa-gavel"  style={{ fontSize:"56px", margin:"10px", color:"#2979ff" }} />
              <Typography variant="h5"style={{ marginBottom:"10px", color:"#2979ff" }} align="center">อยู่ระหว่างการประมูล</Typography>
            </Box>
          );
        } else {
          return (
            <Fragment>
              <ButtonBlue 
                className="bid-button" 
                variant="contained" 
                fullWidth 
                onClick={() => { history.push(`/company-bidding/${match.params.idBidding}`); }}
              >คู่ธุรกิจที่ประมูล</ButtonBlue>
            </Fragment>
          );
          
        }
        
      } else {
        switch (parseInt(bidType)) {
          case 1: return <ButtonBlue className="bid-button" variant="contained" fullWidth onClick={() => { setIsOpenDialogStartBidding(true) }}>เสนอค่าชาร์จ</ButtonBlue>;
          case 2: return <ButtonBlue className="bid-button" variant="contained" fullWidth onClick={() => { setIsOpenDialogStartBidding(true) }}>เสนองบประมาณ</ButtonBlue>;
        }
      }
    } else {
      return (
        <Fragment>
          <Typography variant="h6"style={{ marginBottom:"10px" }}>เข้าสู่ระบบเพื่อร่วมประมูลงานนี้</Typography>
          <ButtonBlue className="bid-button" variant="contained" fullWidth onClick={() => { history.push("/login"); }}>เข้าสู่ระบบ</ButtonBlue>
          <Typography style={{ marginTop:"15px", color:"rgb(99, 115, 129)", fontSize:"0.875rem" }}>
            ยังไม่ได้เข้าร่วม<span className="link-text" onClick={() => history.push("/vendor/signup")}>เข้าร่วมเป็นธุรกิจ</span>
          </Typography>
        </Fragment>
      );
    }
  }

  const handleDispatchCandidateAlreadySent = () => {
    dispatch(getCandidatesAlreadySentToBidding(match.params.idBidding));
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {!isSuccess && 
          <Fragment>
            {biddingInformation && !isError && <Fragment>
              {(!dayjs().isBefore(dayjs(biddingInformation.biddingStartAt)) && biddingInformation.isConfirmedVendor === 0) &&
                <TimerCountdown time={biddingInformation.biddingEndAt}/>
              }
              <Grid container spacing={2}>

                <Grid item xs={12} md={biddingInformation.isConfirmedVendor === 0 ? 8 : 12}>
                  <CardStyle>
                    <div className="bidding-left-container">
                      
                      {(UserProfile && UserProfile.idCompany && biddingInformation.isConfirmedVendor === 1 && biddingInformation.isSuccess === 0) &&
                        <Box style={{ width:"100%" }}>
                          <Typography variant="h4"style={{ margin:"30px 0 40px" }} >
                            {`คู่ธุรกิจที่สัญญา : `}<span style={{ color:"rgb(0, 122, 253)" }}>{biddingInformation.vendorName || "-"}</span>
                          </Typography>
                          <Divider style={{ margin:"24px 0" }} />
                        </Box>
                      }

                      <div className="bidding-header">
                        <Typography className="bidding-name">{biddingInformation.biddingName || "ไม่มีชื่อ"}</Typography>
                        <Typography className="total">{`รับ ${biddingInformation.totalQuota} คน`}</Typography>
                      </div>
                      
                      <div className="company">
                        {/* <Avatar variant="square" src={`${process.env.REACT_APP_API_URL}image/company/${biddingInformation.companyImage}`}> */}
                        <Avatar variant="square" src={biddingInformation.companyImageURL}>
                          <img src="/assets/logo_default.png" />
                        </Avatar>
                        <Typography className="name">{biddingInformation.companyName}</Typography>
                      </div>

                      <div className="info">
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <div className="item">
                              <Typography className="title" color="text.secondary"><i className="fa-regular fa-user"></i>ประเภทพนักงาน</Typography>
                              <Typography>{showEmployeeType(biddingInformation.employeeType)}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <div className="item">
                              <Typography className="title" color="text.secondary"><i className="fa-regular fa-money-bill-wave"></i>ประเภทการจ่ายเงิน</Typography>
                              <Typography>{showPaymentType(biddingInformation.paymentType)}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <div className="item">
                              <Typography className="title" color="text.secondary"><i className="fa-regular fa-location-dot"></i>สถานที่</Typography>
                              <Typography>{`${biddingInformation.district}, ${biddingInformation.province}`}</Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </div>

                      <div className="positions">
                        <Typography className="title">ตำแหน่งงาน</Typography>
                        <ul>
                          {biddingInformation.positions.map((position, index) => (
                            <li key={`position_${index}`}>
                              <Typography>{`${position.positionName} ${position.quota} คน`}</Typography>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="job-groups">
                        <Typography className="title">กลุ่มงาน</Typography>
                        <div className="wrap">
                          {biddingInformation.jobGroups.map((jobGroup, index) => (
                            <Chip key={`jobGroup_${index}`} label={jobGroup.jobGroupName}/>
                          ))}
                        </div>
                      </div>
                      <div className="job-description">
                        <Typography className="title">รายละเอียดงาน</Typography>
                        <Typography className="description">{biddingInformation.jobDescription || "ไม่ระบุ"}</Typography>
                      </div>

                      <div className="location-description">
                        <Typography className="title">สถานที่ทำงาน</Typography>
                        <Typography className="description">{biddingInformation.location || "ไม่ระบุ"}</Typography>
                      </div>

                      <div className="contract-description">
                        <Typography className="title">รายละเอียดสัญญาจ้าง / เงื่อนไข</Typography>
                        {biddingInformation.contractTermsFileName ? 
                          <Chip label={biddingInformation.contractTermsFileName} component={"a"} href={biddingInformation.contractTermsFileURL} onDelete={()=>{}} deleteIcon={<i className="fa-solid fa-circle-down"></i>}/>
                          :
                          <Typography>-</Typography>
                        }
                      </div>
                      <div className="payment-description">
                        <Typography className="title">เงื่อนไขการจ่ายเงิน</Typography>
                        {biddingInformation.paymentTermsFileName ? 
                          <Chip label={biddingInformation.paymentTermsFileName} component={"a"} href={biddingInformation.paymentTermsFileURL} onDelete={()=>{}} deleteIcon={<i className="fa-solid fa-circle-down"></i>}/>
                          :
                          <Typography>-</Typography>
                        }
                      </div>

                      {parseInt(biddingInformation.bidType) === 1 &&
                        <Grid container spacing={2} className="bidding-charge">
                          <Grid item xs={12}>
                            <Typography className="title">ค่าชาร์จสูงสุด</Typography>
                          </Grid>

                          <Grid item xs={12} sm={3} className="charge">
                            <Typography className="charge-title" color="text.secondary"><i className="fa-regular fa-badge-percent"></i>ชาร์จค่าแรง</Typography>
                            <Typography className="value">{showCharge(biddingInformation.chargeSalaryMax)}</Typography>
                          </Grid>

                          <Grid item xs={12} sm={3} className="charge">
                            <Typography className="charge-title" color="text.secondary"><i className="fa-regular fa-badge-percent"></i>ชาร์จค่าOT</Typography>
                            <Typography className="value">{showCharge(biddingInformation.chargeOtMax)}</Typography>
                          </Grid>

                          <Grid item xs={12} sm={3} className="charge">
                            <Typography className="charge-title" color="text.secondary"><i className="fa-regular fa-badge-percent"></i>ชาร์จค่าเหมา</Typography>
                            <Typography className="value">{showCharge(biddingInformation.chargeHireMax)}</Typography>
                          </Grid>

                          <Grid item xs={12} sm={3} className="charge">
                            <Typography className="charge-title" color="text.secondary"><i className="fa-regular fa-badge-percent"></i>ชาร์จค่าชดเชย</Typography>
                            <Typography className="value">{showCharge(biddingInformation.chargeCompensationMax)}</Typography>
                          </Grid>
                        </Grid>
                      }

                      {parseInt(biddingInformation.bidType) === 2 &&
                        <div className="bidding-budget">
                          <Typography className="title">งบประมาณรายเดือนสูงสุด</Typography>
                          <Typography className="value">{(biddingInformation.budgetMax && `${Utils.numberWithCommas(biddingInformation.budgetMax)} บาท`) || "ไม่ระบุ"}</Typography>
                        </div>
                      }

                      <div style={{paddingTop: 24}}>
                        <Divider />
                      </div>

                      <div className="date">
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={biddingInformation.manpowerSendingType === 2 ? 4 : 6}>
                            <Typography className="title" color="text.secondary"><i className="fa-regular fa-calendar-check"></i>เริ่มการประมูล</Typography>
                            <Typography>{dayjs(biddingInformation.biddingStartAt).format("DD MMMM YYYY")}</Typography>
                            <Typography>{`${dayjs(biddingInformation.biddingStartAt).format("HH:mm")} น.`}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={biddingInformation.manpowerSendingType === 2 ? 4 : 6}>
                            <Typography className="title" color="text.secondary"><i className="fa-regular fa-calendar-xmark"></i>สิ้นสุดการประมูล</Typography>
                            <Typography>{dayjs(biddingInformation.biddingEndAt).format("DD MMMM YYYY")}</Typography>
                            <Typography>{`${dayjs(biddingInformation.biddingEndAt).format("HH:mm")} น.`}</Typography>
                          </Grid>
                          {biddingInformation.manpowerSendingType === 2 &&
                            <Grid item xs={12} sm={4}>
                              <Typography className="title" color="text.secondary"><i className="fa-solid fa-users-slash"></i>สิ้นสุดการส่งคน</Typography>
                              <Typography>{dayjs(biddingInformation.manpowerSendingDueDate).format("DD MMMM YYYY")}</Typography>
                            </Grid>
                          }
                        </Grid>
                      </div>
                    </div>
                  </CardStyle>
                </Grid>

                {biddingInformation.isConfirmedVendor === 0 && 
                  <Grid item xs={12} md={4}>
                    <div className="grid-right">
                      <CardStyle>
                        <div className="bidding-right-container">
                          
                          <Grid container spacing={2}>
                            {biddingInformation.isBid && (
                              <Fragment>
                                <Grid item xs={12}>
                                  <Typography>การเสนอของฉัน</Typography>
                                </Grid>
                                {parseInt(biddingInformation.bidType) === 1 && (
                                  <Fragment>
                                    <Grid item xs={6}>
                                      <div className="charge">
                                        <Typography className="title" color="text.secondary"><i className="fa-regular fa-badge-percent"></i>ชาร์จค่าแรง</Typography>
                                        <Typography className="value">{(biddingInformation.myBid.chargeSalaryOffer && Number(biddingInformation.myBid.chargeSalaryOffer) > 0)? `${biddingInformation.myBid.chargeSalaryOffer} %`: "-"}</Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div className="charge">
                                        <Typography className="title" color="text.secondary"><i className="fa-regular fa-badge-percent"></i>ชาร์จค่าOT</Typography>
                                        <Typography className="value">{(biddingInformation.myBid.chargeOtOffer && Number(biddingInformation.myBid.chargeOtOffer) > 0)? `${biddingInformation.myBid.chargeOtOffer} %`: "-"}</Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div className="charge">
                                        <Typography className="title" color="text.secondary"><i className="fa-regular fa-badge-percent"></i>ชาร์จค่าเหมา</Typography>
                                        <Typography className="value">{(biddingInformation.myBid.chargeHireOffer && Number(biddingInformation.myBid.chargeHireOffer) > 0)? `${`${Utils.numberWithCommas(biddingInformation.myBid.chargeHireOffer)} ${biddingInformation.myBid.chargeHireOfferType === 1? "%": "บาท"}`}`: "-"}</Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <div className="charge">
                                        <Typography className="title" color="text.secondary"><i className="fa-regular fa-badge-percent"></i>ชาร์จค่าชดเชย</Typography>
                                        <Typography className="value">{(biddingInformation.myBid.chargeCompensationOffer && Number(biddingInformation.myBid.chargeCompensationOffer) > 0)? `${biddingInformation.myBid.chargeCompensationOffer} %`: "-"}</Typography>
                                      </div>
                                    </Grid>
                                  </Fragment>
                                )}

                                {parseInt(biddingInformation.bidType) === 2 && (
                                  <Grid item xs={12}>
                                    <Typography variant="h5" textAlign="center">{`${Utils.numberWithCommas(biddingInformation.myBid.budgetOffer)} บาท`}</Typography>
                                  </Grid>
                                )}
                              </Fragment>
                            )}

                            {(!biddingInformation.isBid) && (
                              <Grid item xs={12}>
                                {showBidButton(biddingInformation.bidType, biddingInformation.biddingStartAt, biddingInformation.biddingEndAt)}
                              </Grid>
                            )}

                            {(biddingInformation.isBid && dayjs().isBefore(dayjs(biddingInformation.biddingEndAt)))&& (
                              <Fragment>
                                <Grid item xs={12}>
                                  <ButtonBlue className="bid-button" variant="outlined" fullWidth onClick={()=>{setIsOpenDialogStartBidding(true)}}>แก้ไขการเสนอ</ButtonBlue>
                                </Grid>
                              </Fragment>
                            )}

                            <Grid item xs={12}>
                              <Divider />
                            </Grid>

                            <Grid item xs={12}>
                              <Typography textAlign="center">จำนวนคู่ธุรกิจที่ประมูล</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography textAlign="center" fontSize={28} lineHeight={1} style={{ color: "#1976d2", textDecoration: "none" }}>
                                {(UserProfile && UserProfile.idRoles === 3 && UserProfile.idCompany === biddingInformation.idCompany)? 
                                  <Link to={`/company-bidding/${biddingInformation.idBiddings}`} style={{color: "inherit", textDecoration: "inherit"}}>{biddingInformation.bidCount}</Link>
                                  : biddingInformation.bidCount
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </CardStyle>
                    </div>
                  </Grid>
                }
                {(UserProfile && UserProfile.idVendor && (biddingInformation.isConfirmedVendor === 1) && (biddingInformation.idVendor === UserProfile.idVendor)) &&
                  <Box style={{ width:"100%", padding:"20px 0 0 16px" }}>
                    <CardCandidateSent 
                      listCandidatesSent={AllCandidatesAlreadySent}
                      isBidding={true}
                      onClickSubmitCancelled={handleDispatchCandidateAlreadySent}
                    />
                  </Box>
                }
                {(UserProfile && UserProfile.idVendor && (biddingInformation.isConfirmedVendor === 1) && (biddingInformation.idVendor === UserProfile.idVendor)) &&
                (dayjs().isSameOrBefore(biddingInformation.manpowerSendingDueDate)) && 
                  <Box style={{ width:"100%", padding:"0 0 20px 16px" }}>
                    <CardSendManpower  
                      type="bidding"
                      id={biddingInformation.idBiddings}
                      listManpowers={AllUsersVendorFree}
                      listPositions={biddingInformation.positions}
                      listManpowersFromManpowersPage={location.state && location.state.listSelected || []}
                      handleOnSubmit={handleOnSubmit}
                    />
                  </Box>
                }
                {(UserProfile && UserProfile.idCompany && biddingInformation.isConfirmedVendor === 1 && biddingInformation.manpowerSendingType === 2) &&
                  <Box style={{ width:"100%", paddingLeft:"16px", margin:"24px 0" }}>
                    <CardPassedCandidate 
                      AllPassedCandidates={AllPassedCandidates || []}
                      buttonPath={`/company-candidate/bidding/${biddingInformation.idBiddings}`} 
                      isBidding={true}
                    />
                  </Box>
                }
              </Grid>

              {parseInt(biddingInformation.bidType) === 1 && isOpenDialogStartBidding && (
                <BiddingOfferCharge
                  open={isOpenDialogStartBidding}
                  onClose={()=>{setIsOpenDialogStartBidding(false)}}
                  idBidding={match.params.idBidding}
                  values={biddingInformation.myBid}
                  chargeMax={{
                    chargeSalaryMax: biddingInformation.chargeSalaryMax,
                    chargeOtMax: biddingInformation.chargeOtMax,
                    chargeHireMax: biddingInformation.chargeHireMax,
                    chargeCompensationMax: biddingInformation.chargeCompensationMax,
                  }}/>
                )}
              {parseInt(biddingInformation.bidType) === 2 && isOpenDialogStartBidding && (
                <BiddingOfferBudget
                  open={isOpenDialogStartBidding}
                  onClose={()=>{setIsOpenDialogStartBidding(false)}}
                  idBidding={match.params.idBidding}
                  values={biddingInformation.myBid}
                  budgetMax={biddingInformation.budgetMax}
                />
              )}

            </Fragment>}

            {!biddingInformation && isError && <Fragment>
              <Typography padding="48px 0" textAlign="center" fontSize={32}>ไม่พบข้อมูลการประมูล</Typography>
              <Avatar src="/assets/background/error404.jpg" style={{width: 600, height: 600, margin: "0 auto"}}/>
            </Fragment>}
          </Fragment>
        }
        {isSuccess && 
          <div style={{ display:"flex", justifyContent:"center", height:"calc(100vh - 160px)" }}>
            <div style={{ display:"flex", flexDirection:"column", alignItems: "center", marginTop:"80px" }}>
              <img 
                style={{ width:300, marginBottom:"20px" }}
                src={`${process.env.PUBLIC_URL}/assets/background/complete.jpg`}
                // alt="https://www.freepik.com/free-vector/completed-concept-illustration_10802151.htm"  
              />
              <Typography variant="h4" style={{ marginBottom:"40px" }}>ส่งคนสำเร็จ</Typography>
              <ButtonBlue 
                variant="outlined" 
                style={{ borderRadius:"25px", width:"100px" }} 
                onClick={handleCloseDisplay}
              >
                <Typography variant="h6">ปิด</Typography>
              </ButtonBlue>                          
            </div>
          </div>
        }
        {isFail &&
          <DialogStyled
            open={isFail}
            onClose={() => setIsFail(false)}
          >
            <DialogContent>
              <BoxStatus>
                <i className="fa-regular fa-circle-xmark icon error"></i>
                <Typography
                  variant='h5'
                  align="center"
                  className="text error"
                >
                  ส่งกำลังพลไม่สำเร็จ
                </Typography>
                <Box className="CloseButton" onClick={() => setIsFail(false)}>
                  <Typography align="center" className="textButton">ปิด</Typography>
                </Box>
              </BoxStatus>
            </DialogContent>
          </DialogStyled>
        }
      </Container>
    </StyledRoot>
  )
}

const TimerCountdown = (props) => {

  const { time } = props;

  const [remainTime, setRemainTime] = useState(new Date(time).getTime() - (new Date().getTime()));

  const [remainDay, setRemainDay] = useState(Math.floor(remainTime / (1000 * 60 * 60 * 24)))
  const [remainHour, setRemainHour] = useState(Math.floor(remainTime / (1000 * 60 * 60)) % 24)
  const [remainMinute, setRemainMinute] = useState(Math.floor(remainTime / (1000 * 60)) % 60)
  const [remainSecond, setRemainSecond] = useState(Math.floor(remainTime / (1000)) % 60)

  useEffect(() => {

    let interval = setInterval(() => {
      let remainTimeTemp = new Date(time).getTime() - (new Date().getTime());
      setRemainTime(remainTimeTemp)
      setRemainDay(Math.floor(remainTimeTemp / (1000 * 60 * 60 * 24)))
      setRemainHour(Math.floor(remainTimeTemp / (1000 * 60 * 60)) % 24)
      setRemainMinute(Math.floor(remainTimeTemp / (1000 * 60)) % 60)
      setRemainSecond(Math.floor(remainTimeTemp / (1000)) % 60)
      if(remainTimeTemp <= 0){
        clearInterval(interval);
        setRemainTime(0);
      }
    }, 1000)

    return () => {
      clearInterval(interval);
    }

  }, [])

  const showRemain = () => {

    if(remainDay === 0 && remainHour === 0 && remainMinute === 0) {
      return <Typography className="remain warning">{remainSecond} <span>วินาที</span></Typography>
    } else if(remainDay === 0 && remainHour === 0) {
      return <Typography className="remain warning">{remainMinute} <span>นาที</span> {remainSecond} <span>วินาที</span></Typography>
    } else if(remainDay === 0){
      return <Typography className="remain warning">{remainHour} <span>ชั่วโมง</span> {remainMinute} <span>นาที</span> {remainSecond} <span>วินาที</span></Typography> 
    } else if (remainTime <= 0) {
      return <Typography className="remain">ปิดการประมูลแล้ว</Typography>
    } else {
      return <Typography className="remain">{remainDay} <span>วัน</span> {remainHour} <span>ชั่วโมง</span> {remainMinute} <span>นาที</span></Typography>
    }
  }

  return (
    <div className="bidding-time-remain-container">
      {remainTime > 0 && <Typography className="title">เหลือเวลาอีก</Typography>}
      {showRemain()}
    </div>
  )
}

export default BiddingInfomationPage