import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    Avatar,
    Box,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    Fab,
    styled,
    Typography
} from '@mui/material';

import { decodeTokenChildsCompany, setParentCompany } from '../../../../actions/company';

const DialogStyled = styled(Dialog)({
    "& .MuiPaper-root":{
        borderRadius: 16,
        padding: 16
    }
});

const Content = styled(Box)({
    width: 350,
    "& .Info":{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        margin:"24px 0",
        "& .avatar":{
            width: 104,
            height: 104
        },
        "& .spaceTop":{
            marginTop:8
        },
        "& .BusinessGroupName":{
            fontWeight: 500
        }
    },
    "& .Navbutton":{
        width:300,
        display:"flex",
        justifyContent:"space-between"
    },
    "& .ConfirmButton":{
        backgroundColor:"#4b924f",
        color:"#ffffff",
        "&:hover":{
            backgroundColor: "#2e7d32"
        }
    },
    "& .CancelButton":{
        backgroundColor:"#e93d3d" ,
        color:"#ffffff",
        "&:hover":{
            backgroundColor: "#d32f2f"
        }
    }
});

const BoxStatus = styled(Box)({
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    "& .icon":{
        fontSize: 112
    },
    "& .text":{
        marginTop: 24
    },
    "& .success":{
        color: "#4b924f"
    },
    "& .error":{
        color:"#e93d3d"
    }
});

const CompanyConfirmChildCompany = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { result: UserProfile } = useSelector(state => state.userProfile);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    let timeoutDisplay;

    useEffect(async () => {
        const token = new URLSearchParams(history.location.search).get("token");
        if(token && UserProfile && UserProfile.idCompany){
            const res = await dispatch(decodeTokenChildsCompany({ token : token }));
            if ((res.data && res.data.idCompany === UserProfile.idCompany) || res.status === 401) {
                setIsError(true);
                timeoutDisplay = setTimeout(handleCloseDisplayError, 5000);
            } else {
                setCompanyInfo(res.data);
            }
        }
    },[history.location.search,UserProfile]);

    const HandleConfirm = async () => {
        const res = await dispatch(setParentCompany({ idParentCompany : companyInfo.idCompany }));
        if(res.status === 200){
            setIsSuccess(true);
            timeoutDisplay = setTimeout(handleCloseDisplay, 5000);
        }
    };

    const handleCloseDisplay = () => {
        clearInterval(timeoutDisplay);
        history.push('/company/manage');
    };

    const handleCloseDisplayError = () => {
        clearInterval(timeoutDisplay);
        history.push('/');
    };

    return (
        <Container maxWidth="lg">
            <DialogStyled
                open={true}
            >
                <DialogContent>
                    <Content>
                        {(!companyInfo && !isError) &&
                            <Box style={{ height:350, display:"flex", justifyContent:"center", alignItems:"center" }}>
                                <CircularProgress />
                            </Box>
                        }
                        {(!isSuccess && companyInfo && !isError) && 
                            <Fragment>
                                <Typography variant='h5'>ยืนยันการเป็นบริษัทลูก</Typography>
                                <Box>
                                    <Typography>ยืนยันการเป็นบริษัทลูกบริษัท เอกชน จำกัด</Typography>
                                    <Box className="Info">
                                        <Avatar className='avatar' src={companyInfo.companyImage} />
                                        <Typography variant="h6" className="spaceTop">{companyInfo.companyName}</Typography>
                                        <Typography className="spaceTop">กลุ่มธรุกิจ</Typography>
                                        <Typography className="BusinessGroupName">{companyInfo.businessGroupName}</Typography>
                                    </Box>
                                    <Box style={{ width:"100%", display:"flex", justifyContent:"center" }}>
                                        <Box className="Navbutton">
                                            <Fab className="CancelButton" component={Link} to="/">
                                                <i className="fa-solid fa-xmark-large"></i>
                                            </Fab>
                                            <Fab className="ConfirmButton" onClick={HandleConfirm}>
                                                <i className="fa-solid fa-check"></i>
                                            </Fab>
                                        </Box>
                                    </Box>
                                </Box>
                            </Fragment>
                        }
                        {(isSuccess && !isError) &&
                            <BoxStatus>
                                <i className="fa-regular fa-circle-check icon success"></i>
                                <Typography 
                                    variant='h5' 
                                    align="center"
                                    className="text success"
                                >
                                    ยืนยันการเป็นบริษัทลูกสำเร็จ
                                </Typography>
                            </BoxStatus>
                        }
                        {isError &&
                            <BoxStatus>
                                <i className="fa-regular fa-circle-xmark icon error"></i>
                                <Typography 
                                    variant='h5' 
                                    align="center"
                                    className="text error"
                                >
                                    ไม่สามารถเป็นบริษัทลูกได้
                                </Typography>
                            </BoxStatus>
                        }
                    </Content>
                </DialogContent>
            </DialogStyled>
        </Container>
    );
};

export default CompanyConfirmChildCompany;