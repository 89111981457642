import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    Box,
    Grid,
    Pagination,
    Typography
} from '@mui/material';

import CardBidding from '../shared/CardBidding';

const Bidding = (props) => {
    const { listBiddings } = props;
    const { isFetching } = useSelector(state => state.historyBiddingsCompany);
    const [page, setPage] = useState(1);

    const handleChangePage = (event, newValue) => {
        setPage(newValue);
    };

    return (
        <Fragment>
            <Box style={{ paddingTop: "20px" }}>
                <Grid
                    container
                    spacing={3}
                >
                    {!isFetching && listBiddings && listBiddings.length > 0 && 
                        listBiddings.map((bidding, index) => {
                            if (index >= (6 * (page - 1)) && index <= ((6 * page) - 1)) {
                                return (
                                    <Grid item xs={12} sm={6} md={4} key={bidding.idBiddings}>
                                        <CardBidding bidding={bidding} isHistory={true} />
                                    </Grid>
                                );
                            }
                        })
                    }
                    {isFetching &&
                        <Grid item xs={12} style={{ height: "347px" }}>
                            <Box style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography variant="h5" textAlign="center">กำลังโหลดข้อมูล...</Typography>
                            </Box>
                        </Grid>
                    }
                    {!isFetching && listBiddings && listBiddings.length === 0 &&
                        <Grid item xs={12} style={{ height: "347px" }}>
                            <Box style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography variant="h5" textAlign="center">ไม่พบงานที่เปิดประมูล</Typography>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
            {(listBiddings.length > 0) &&
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        marginRight: page === parseInt(((listBiddings.length - 1) / 6) + 1) ? "38px" : '0'
                    }}
                >
                    <Pagination
                        count={parseInt(((listBiddings.length - 1) / 6) + 1)}
                        page={page}
                        onChange={handleChangePage}
                        hidePrevButton={page === 1}
                        hideNextButton={page === parseInt(((listBiddings.length - 1) / 6) + 1)}
                    />
                </Box>
            }
        </Fragment>
    );
};

export default Bidding;