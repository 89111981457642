import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import {
    Box,
    Container,
    Grid,
    styled,
    Tab,
    Tabs,
    Typography
} from "@mui/material";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import Bidding from "./bidding";
import Request from "./request";
import TextFieldTheme from '../../shared/general/TextFieldTheme';

import { getHistoryJobsCompany } from "../../../../actions/job";
import { getHistoryBiddingsCompany } from "../../../../actions/bidding";

const StyledRoot = styled("div")({
    "& .header-text": {
        marginBottom: 24,
    },
});

const CompanyHistoryJob = () => {
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector(state => state.userProfile);
    const { isFetching: isFetchingJobs, result: AllJobs } = useSelector(state => state.historyJobsCompany);
    const { isFetching: isFetchingBiddings, result: AllBiddings } = useSelector(state => state.historyBiddingsCompany);
    const [search, setSearch] = useState("");
    const [year, setYear] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [listJobs, setListJobs] = useState([]);
    const [listBiddings, setListBiddings] = useState([]);

    useEffect(() => {
        if (userProfile && userProfile.idCompany) {
            if (tabValue === 0) {
                dispatch(getHistoryJobsCompany());
            } else if (tabValue === 1) {
                dispatch(getHistoryBiddingsCompany());
            }
        }
    },[userProfile, tabValue]);

    useEffect(() => {
        if (tabValue === 0 && !isFetchingJobs && AllJobs) {
            let tempLists = AllJobs.filter((job) => {
                if (
                    (job.companyName.toLowerCase().includes(search.toLowerCase()) || job.positionName.toLowerCase().includes(search.toLowerCase())) && 
                    (year === null || dayjs(year).isSame(dayjs(job.postDate),'year'))
                ) {
                    return true;
                } else {
                    return false;
                }
            });
            setListJobs([...tempLists]);
        } else if (tabValue === 1 && !isFetchingBiddings && AllBiddings) {
            let tempLists = AllBiddings.filter((bidding) => {
                if (
                    bidding.biddingName.toLowerCase().includes(search.toLowerCase()) && 
                    (year === null || dayjs(year).isSame(dayjs(bidding.biddingStartAt),'year'))
                ) {
                    return true;
                } else {
                    return false;
                }
            });
            setListBiddings([...tempLists]);
        }
    },[tabValue, search, year, AllJobs, AllBiddings]);

    return (
        <StyledRoot className='page'>
            <Container maxWidth="lg">
                <Typography className="header-text" variant="h4">
                    ประวัติรายการคำร้องของฉัน
                </Typography>
                <Grid
                    container
                    justifyContent="space-between"
                >
                    <Grid item>
                        <TextFieldTheme
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="ค้นหา..."
                        />
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label={tabValue === 0 ? "ปีที่ประกาศ" : "ปีที่เริ่มประมูล"}
                                inputFormat="YYYY"
                                views={["year"]}
                                value={year}
                                onChange={(e) => setYear(e)}
                                renderInput={(params) => <TextFieldTheme {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Tabs 
                    style={{ marginTop:"20px" }}
                    value={tabValue} 
                    onChange={(e,newValue) => setTabValue(newValue)}
                >
                    <Tab label="จ้างแรงงาน" />
                    <Tab label="จ้างทำของ" />
                </Tabs>
                {tabValue === 0 && 
                    <Request listJobs={listJobs}/>
                }
                {tabValue === 1 &&
                    <Bidding listBiddings={listBiddings} />
                }
            </Container>
        </StyledRoot>
    );
};

export default CompanyHistoryJob;