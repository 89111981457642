import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, CardContent, Chip, IconButton, styled, Typography } from "@mui/material";

import CardStyle from "./Card";

import {
  Star,
  Favorite, 
  FavoriteBorder
} from '@mui/icons-material';

import Utils from "../../../../utils"
import { Link } from "react-router-dom";

import { addFavoriteVendor, deleteFavoriteVendor } from "../../../../actions/favoriteVendor";

const StyledCardContent = styled(CardContent)({
  display:"flex",
  flexDirection:"column",
  position:"relative",
  padding: 24,
  "& a": {
    textDecoration: "none",
    color: "inherit",
  },
  "& .card-header": {
    paddingBottom: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiChip-root": {
      color: "#637381",
      fontSize: 16,
      borderRadius: 8,
      "& i": {
        marginRight: 8,
      }
    }
  },
  "& .card-logo": {
    paddingBottom: 16,
    "& .MuiAvatar-root": {
      margin: "0 auto",
      width: 180,
      height: 180,
      backgroundColor: "white",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
    },
    "& i": {
      margin: "0 auto",
      width: 180,
      height: 180,
      fontSize: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }
  },
  "& .card-vendor-name": {
    textAlign: "center",
    "& .MuiTypography-root": {
      fontSize: 22,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },

  }
})

const CardVendor = (props) => {
  const dispatch = useDispatch();
  const { vendor, favorite, 
    // onClickAdd, onClickDelete, 
    onClickHandler,
  index } = props;
  const { isLoggedIn } = useSelector((state) => state.auth);

  const onClickFavorite = async (idVendor) => {
    let result ;
    if(!favorite){
      result = await dispatch(addFavoriteVendor(idVendor));
    } else {
      result = await dispatch(deleteFavoriteVendor(idVendor));
    }
    if(result){
      onClickHandler(index, !favorite);
    }
  };

  return(
    <CardStyle>
      <StyledCardContent>
        {isLoggedIn && 
          <IconButton 
            style={{ position: "absolute", right: 24, color:"#FF0833" }}
            onClick={() => onClickFavorite(vendor.idVendor)}
          >
            {favorite ? <Favorite /> : <FavoriteBorder />}
          </IconButton>
        }
        <div className="card-header">
          <Chip label={<span><i className="fa-regular fa-users"></i>{`${Utils.numberWithCommas(vendor.manpowerSize || 0)}`}</span>}/>
        </div>
        <div className="card-logo">
          <Avatar
            variant="rounded"
            src={vendor.vendorImageURL}
            // src={vendor.vendorImage? `${process.env.REACT_APP_API_URL}image/vendor/${vendor.vendorImage}`: ""}
            component={Link}
            to={`/vendor/${vendor.vendorNameShort || ""}`}
          >
            <img src="/assets/logo_default.png" />
          </Avatar>
        </div>
        <div className="card-vendor-name">
          <Link to={`vendor/${vendor.vendorNameShort || ""}`}>
            <Typography variant="h6">
                {vendor.vendorName || "ไม่ระบุ"}
            </Typography>
          </Link>
        </div>
        <Box style={{ display:"flex", alignItems:"center", marginTop:"5px" }}>
          <Star style={{ marginRight:"10px", color:"#ff9800" }} />
          <Typography>
            {Utils.floatNumberWithCommas(vendor.rating)}
            <span style={{ color: "#9e9e9e", marginLeft: "5px" }}>
              {`( ${Utils.numberWithCommas(vendor.successCount)} )`}
            </span>
          </Typography>
        </Box>
      </StyledCardContent>
    </CardStyle>
  )
}

export default CardVendor;