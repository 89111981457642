import { httpClient } from "./httpClient";


const addAdminVendor = (formData) => {
    return httpClient.post(`admin/vendor/manage_admin`, formData);
};

const verifyTokenAddAdminVendor = (query) => {
    return httpClient.get(`admin/vendor/manage_admin/verifyToken`, {params: query});
};

const createPasswordAdminVendor = (formData) => {
    return httpClient.post(`admin/vendor/manage_admin/createPassword`, formData);
};

const deleteAdminVendor = (idAdminVendorAntJob) => {
    return httpClient.delete(`admin/vendor/manage_admin/${idAdminVendorAntJob}`);
};

export default {
  addAdminVendor,
  verifyTokenAddAdminVendor,
  createPasswordAdminVendor,
  deleteAdminVendor
};