import React from "react";
import { Avatar, Box, styled, Typography } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import { Link } from "react-router-dom";

const StyledRoot = styled(Box)({
  padding: 24,
  "& .avatar": {
    width: 80,
    height: 80,
    borderRadius: 8,
    backgroundColor: "white",
    "& img": {
      width: "inherit",
      height: "inherit",
      objectFit: "contain"
    }
  },
  "& .company-name": {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  }
})

const MyVendorCard = (props) => {

  const { vendor } = props;

  return (
    <Link to={`/vendor/${vendor.vendorNameShort}`} style={{textDecoration: "none"}}>
      <CardStyle>
        <StyledRoot>
          <Box display="flex" alignItems="center">
            <Avatar className="avatar" variant="rounded" src={vendor.vendorImageURL}>
              <img src="/assets/logo_default.png" />
            </Avatar>
            <Box flexGrow={1} paddingLeft="16px">
              <Typography className="company-name" variant="h6">{vendor.vendorName}</Typography>
              <Box display="flex" alignItems="center" sx={{"& i": {color: "#919eab"}}}>
                <i className="fa-solid fa-users"></i>
                <Typography paddingLeft="8px" color="text.secondary">{`${vendor.manpowerSize || 0}`}</Typography>
              </Box>
            </Box>
          </Box>
        </StyledRoot>
      </CardStyle>
    </Link>
  )
}

export default MyVendorCard