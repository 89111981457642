import React, { Fragment, useEffect, useState } from "react";
import { Avatar, Box, Button, Container, Divider, Grid, Modal, Rating, styled, Tab, Tabs, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardRequest from "../../shared/general/CardRequest";
import CardStyle from "../../shared/general/Card";

import company from "../../assets/data/company";
import request from "../../assets/data/request";
import branchStore from "../../assets/data/branch";
import ContractCompany from "./contract";
import DrawerBranch from "../drawerBranch";
import { useDispatch, useSelector } from "react-redux";

import { getCompanyByNameShort } from "../../../../actions/company";
import { getContractVendor } from "../../../../actions/contract";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#f1f4f9",
  paddingBottom: 36,
  // ["@media (min-width:1200px)"]:{
  //   paddingLeft: 24,
  //   paddingRight: 24
  // }
});

const StyledContent = styled("div")({
  padding: 36,
  paddingTop: 24,
  "& .part-one": {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 8,
    "& .MuiAvatar-root": {
      width: 160,
      height: 160,
      fontSize: 42,
      marginRight: 36,
      backgroundColor: "white",
      color: "inherit",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
      "& i": {
        fontSize: 120,
        color: "inherit",
      }
    },
    "& .detail": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "& .start": {
        "& .company-name": {
          marginBottom: 12,
        },
        "& .company-industry": {
          marginBottom: 12,
        },
        "& div": {
          marginRight: 32,
          ["@media only screen and (max-width: 600px)"]:{
            marginRight: 0
          },
        },
      },
    },
    "& .MuiTypography-caption": {
      fontSize: 14,
    },
  },
  "& .MuiDivider-root": {
    margin: "16px 0px",
    borderWidth: "0px 0px thin",
    borderColor: "rgba(145, 158, 171, 0.24)",
    borderStyle: "dashed",
  },
  "& .part-two": {
    marginBottom: 16,
    "& .wrap-item": {
      "& .label": {
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 4,
        "& span": {
          marginLeft: 16,
        },
      },
      "& .value": {
        fontSize: 18,
        marginLeft: 32,
      },
      "& .fal": {
        marginRight: 8,
      },
    },
  },
  "& .topic": {
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 8 
  },
  "& .MuiTabs-root": {
    marginBottom: 16,
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },
  "& .part-three": {
    "& .branch-count": {
      marginLeft: 32,
    }
  }
})

const StyledOpenJob = styled("div")({
  marginTop: 24,
  "& .openjob-header": {
    marginBottom: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-card": {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 12,
    ["@media only screen and (max-width: 1200px)"]:{
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    ["@media only screen and (max-width: 600px)"]:{
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  }
})

const StyledBranchContent = styled("div")({
  // padding: 16,
  paddingLeft: 16,
  // borderRadius: 20,
  // border: "1px solid #ececec",
  "& .branch-name": {
    fontSize: 18,
  },
  "& i": {
    marginRight: 8,
  },
  "& .contact-container": {
    display: "flex",
    flexFlow: "row wrap",
    "& .contact-wrap": {
      marginRight: 16,
    }
  }
})

const CompanyInformationPage = (props) => {

  const { match } = props;

  const [idCompany, setIdCompany] = useState(0);

  const [isOpenModalBranchCompany, setIsOpenModalBranchCompany] = useState(false);

  const [branch, setBranch] = useState([]);

  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { isFetching: isFetchingCompany, result: companyStore } = useSelector((state) => state.companyInformation);
  const { isFetching: isFetchingContactVendor, result: contractVendor } = useSelector((state) => state.contractVendor);
  const [company, setCompany] = useState(null);


  useEffect(() => {
    dispatch(getCompanyByNameShort(match.params.companyNameShort))
  }, [])

  useEffect(() => {
    setCompany(null)
    if(companyStore !== null){
      setCompany(companyStore);
      dispatch(getContractVendor({ idCompany: companyStore.idCompany }));
    }
  }, [companyStore])

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ padding: "24px 0" }}>
          ข้อมูลบริษัท
        </Typography>
        {isFetchingCompany && <Typography variant="h5" textAlign="center">กำลังโหลดข้อมูล...</Typography>}
        {!isFetchingCompany && !companyStore && <Typography variant="h5" textAlign="center">ไม่พบข้อมูลบริษัท</Typography>}
        {company && (
          <Fragment>
            <CardStyle>
              <StyledContent>
                <div className="part-one">
                  {/* <Avatar variant="rounded" src={company.companyImage? `${process.env.REACT_APP_API_URL}image/company/${company.companyImage}`: ""}> */}
                  <Avatar variant="rounded" src={company.companyImageURL}>
                    <img src="/assets/logo_default.png" />
                  </Avatar>
                  <div className={`detail`}>
                    <div className={`start`}>
                      <div className="company-name">
                        {/* <Typography variant="caption" color="text.secondary">บริษัท</Typography> */}
                        <Typography variant="h4">{company.companyName}</Typography>
                      </div>
                      <div className="company-industry">
                        <Typography variant="caption" color="text.secondary">กลุ่มธรุกิจ</Typography>
                        <Typography variant="h5">{company.businessGroupName || "-"}</Typography>
                      </div>
                      <div className="company-industry">
                        <Typography variant="caption" color="text.secondary">ขนาดองค์กร</Typography>
                        <Typography variant="h5">{`ขนาดใหญ่`}</Typography>
                      </div>
                      <div className="company-industry" style={{ display:"flex", flexDirection:"column" }}>
                        <Typography variant="caption" color="text.secondary">คะแนน</Typography>
                        <Box style={{ display:"flex", alignItems:"center" }}>
                          <Rating 
                            value={company.rating}
                            style={{ fontSize:"32px" }}
                            readOnly
                          />
                          <Typography style={{ marginLeft:"8px" }}>{`(${company.rating})`}</Typography>
                        </Box>
                      </div>
                    </div>
                    <div className={`end`}>
                      <div>
                        <Typography variant="caption" color="text.secondary">งานที่เปิดรับ</Typography>
                        <Typography variant="h4" align="right">
                          {company.jobs && company.jobs.length || "0"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider
                  style={{
                    margin: "16px 0px",
                    borderWidth: "0px 0px thin",
                    borderColor: "rgba(145, 158, 171, 0.24)",
                    borderStyle: "dashed",
                  }}
                />
                <div className="part-two">
                  <div className="wrap-item">
                    <Typography className="label" color="text.secondary">
                      <i className="far fa-map-marker-alt"></i>
                      <span style={{ fontSize:"14px" }}>ที่อยู่</span>
                    </Typography>
                    <Typography className="value" style={{ fontSize:"1.25rem" }}>{company.address || "-"}</Typography>
                  </div>
                </div>
                
                <div className="part-three">
                  <Grid container justifyContent="space-between" alignItems="center">
                    <div>
                      <Typography className="label" color="text.secondary">
                        <i className="fa-regular fa-building"></i>
                        <span style={{ marginLeft: 14, fontSize:"14px" }}>สาขาของบริษัท</span>
                      </Typography>
                      <Typography className="branch-count" style={{ fontSize:"1.25rem" }}>{`${company.branches && company.branches.length || 0} สาขา`}</Typography>
                    </div>
                    <ButtonBlue startIcon={<i className="fa-regular fa-magnifying-glass"></i>} variant="outlined" onClick={()=>{setIsOpenModalBranchCompany(true)}}>ดูสาขาทั้งหมด</ButtonBlue>
                  </Grid>
                  {/* <Grid container spacing={2}>
                    {company.branches
                    .length > 1? (
                      <>
                      </>
                    ): (
                      <>
                        {company.branches
                          .map((b, index) => (
                            <Grid key={index} item xs={12}>
                              <StyledBranchContent>
                                <Typography className="branch-name" variant="h7">{b.branchName}</Typography>
                                <Typography color="text.secondary">
                                  <i className="far fa-map-marker-alt"></i>
                                  <span>{`${b.address}`}</span>
                                </Typography>
                                <div className="contact-container">
                                  <div className="contact-wrap">
                                    <Typography>
                                      <i className="fa-regular fa-user-headset"></i>
                                      <span>{`${b.mainContactName}`}</span>
                                    </Typography>
                                  </div>
                                  <div className="contact-wrap">
                                    <i className="fa-regular fa-phone"></i>
                                    <span>{`${b.mainContactPhone}`}</span>
                                  </div>
                                  <div className="contact-wrap">
                                    <i className="fa-regular fa-envelope"></i>
                                    <span>{`${b.mainContactEmail}`}</span>
                                  </div>
                                </div>
                              </StyledBranchContent>
                            </Grid>
                          ))
                        }
                      </>
                    )}
                  </Grid> */}
                </div>
              </StyledContent>
            </CardStyle>

            <div style={{ marginBottom: 24 }}></div>

            <CardStyle>
              <StyledContent>
                <CompanyInfoTab company={company} />
              </StyledContent>
            </CardStyle>
            
            <div style={{ marginBottom: 24 }}></div>

            <CardStyle>
              <StyledContent>
                <Typography className="topic" style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}>
                  สิทธิประโยชน์
                </Typography>
                <Typography style={{ fontSize:"1.25rem" }}>
                  {company.companyBenefits || "-"}
                </Typography>
              </StyledContent>
            </CardStyle>
            {isLoggedIn && contractVendor && contractVendor.isContract && (
              <Box marginTop="24px">
                <CardStyle>
                  <StyledContent>
                    <ContractCompany 
                      id={companyStore.idCompany} 
                      contractCompany={contractVendor}
                    />
                  </StyledContent>
                </CardStyle>
              </Box>
            )}
            
            <StyledOpenJob>
              <div className="openjob-header">
                <Typography variant="h5">งานที่เปิดรับสมัคร</Typography>
                <div>
                  <ButtonBlue
                    component={Link}
                    to={`/jobs?companyName=${company.companyName}`}
                  >
                    ดูทั้งหมด{" "}
                    <i
                      style={{ lineHeight: 0, paddingLeft: 6 }}
                      className="fi fi-br-angle-small-right"
                    />
                  </ButtonBlue>
                </div>
              </div>
              
              <div className="wrap-card">
                {company.jobs && company.jobs
                  .map((value, index) => {
                    return <CardRequest key={index} value={value}></CardRequest>;
                  })
                }
              </div>
              {company.jobs && company.jobs.length === 0 && <Typography variant="h5" textAlign="center">ยังไม่มีงานที่เปิดรับ</Typography>}
            </StyledOpenJob>

            {isOpenModalBranchCompany && (
              <DrawerBranch
                open={isOpenModalBranchCompany}
                onClose={()=>{setIsOpenModalBranchCompany(false)}}
                branches={company.branches}
              />
            )}

          </Fragment>
        )}
      </Container>
    </StyledRoot>
  )
}

const CompanyInfoTab = (props) => {

  const { company } = props;

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return(
    <div>
      <Tabs value={tabValue} onChange={handleChange}>
        <Tab label="เกี่ยวกับบริษัท"/>
        <Tab label="ข้อมูลติดต่อ"/>
      </Tabs>

      {tabValue === 0 && (
        <Fragment>
          <Typography className="topic" style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}>
            เกี่ยวกับบริษัท
          </Typography>
          <Typography style={{ fontSize:"1.25rem" }}>
            {company.companyAbout || "-"}
          </Typography>
        </Fragment>
      )}

      {tabValue === 1 && (
        <Fragment>
          <Typography className="topic" style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}>
            ข้อมูลติดต่อ
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="text.secondary">ชื่อผู้ติดต่อ</Typography>
              <Typography style={{ fontSize:"1.25rem" }}>{company.mainContactName || "-"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="text.secondary">เบอร์โทรศัพท์</Typography>
              <Typography style={{ fontSize:"1.25rem" }}>{company.mainContactPhone || "-"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="text.secondary">อีเมล</Typography>
              <Typography style={{ fontSize:"1.25rem" }}>{company.mainContactEmail || "-"}</Typography>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </div>
  )
}

export default CompanyInformationPage;