import React, { useState } from "react";
import { Container, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { requestResetPassword } from "../../../../../../actions/auth";
import { useDispatch } from "react-redux";

const StyledRoot = styled("div")({
  "& .forget-container": {
    margin: "0 auto",
    maxWidth: 480,
    "& .forget-title": {
      marginBottom: 24,
      fontSize: 24
    },
    "& .forget-item": {
      marginBottom: 16,
    }
  },
})

const ForgetPasswordPage = () => {

  const dispatch = useDispatch();

  const useFormOne = useForm({
    defaultValues: {
      email: "",
    }
  })

  const useFormTwo = useForm({
    defaultValues: {
      verifyCode: "",
    }
  })

  const [pageForm, setPageForm] = useState(1);

  const onSubmitFormOneHandler = async (formData) => {
    // console.log(formData)

    const response = await dispatch(requestResetPassword(formData))

    if(response.status === 200){
      setPageForm(2)
    } else if(response.status === 404) {
      useFormOne.setError("email", {message: "ไม่มีอีเมลนี้อยู่ในระบบ"})
    }
  }

  const onSubmitFormTwoHandler = (formData) => {
    console.log(formData)
  }


  const renderForm = () => {
    switch(pageForm){
      case 1: return (
        <form onSubmit={useFormOne.handleSubmit(onSubmitFormOneHandler)}>
          <div className="forget-container">
            <Typography className="forget-title" fontSize={24} textAlign="center">ลืมรหัสผ่าน</Typography>

            <div className="forget-item">
              <Controller
                control={useFormOne.control}
                name="email"
                rules={{
                  required: {value: true, message: "กรุณากรอกอีเมล"},
                  pattern: {
                    value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z.]{1,}[A-z]$/,
                    message: "กรุณากรอกอีเมลให้ถูกต้อง เช่น 'antjob@example.com'"
                  }
                }}
                render={({field}) => (
                  <TextFieldTheme
                    {...field}
                    label="อีเมล"
                    autoComplete="email"
                    helperText={useFormOne.formState.errors && useFormOne.formState.errors.email && useFormOne.formState.errors.email.message}
                    error={useFormOne.formState.errors && useFormOne.formState.errors.email ? true: false}
                  />
                )}
              />
            </div>

            <div className="forget-item">
              <ButtonBlue variant="contained" type="submit" fullWidth>ดำเนินการต่อ</ButtonBlue>
            </div>

          </div>
        </form>
      );
      case 2: return (
        <Typography textAlign="center" fontSize={24}>ระบบได้ทำการส่งข้อความไปที่อีเมลของท่าน เพื่อรีเซ็ตรหัสผ่าน</Typography>
      )
    }
  }

  return(
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {renderForm()}
      </Container>
    </StyledRoot>
  )
}

export default ForgetPasswordPage;