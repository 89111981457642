import { httpClient } from "./httpClient";

const getUserProfile = (id) => {
  return httpClient.get("users/me");
};

const getAllUsers = () => {
  return httpClient.get("users");
};

const getAllFreeUsersByIdVendor = () => {
  return httpClient.get(`users/vendor/free`);
};

const getAllUsersByIdVendor = () => {
  return httpClient.get(`users/vendor`);
};

const getUserProfileVendorManpower = (idManpower) => {
  return httpClient.get(`manpowers/${idManpower}/vendorManpower/profile`);
};

const updateStatusManpower = (data) => {
  return httpClient.put(`manpower/updateStatus`,data);
};

export default {
  getUserProfile,
  getAllUsers,
  getAllFreeUsersByIdVendor,
  getAllUsersByIdVendor,
  getUserProfileVendorManpower,
  updateStatusManpower
};
