import React, { Fragment } from "react";
import { Box, CircularProgress, FormHelperText, Grid, IconButton, MenuItem, styled, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import Dropzone from "react-dropzone";
import AddIcon from "../../../assets/add.png"
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";

const StyledRoot = styled(Box)({
  "& .dropzone": {
    cursor: "pointer",
    flexShrink: 0,
    width: "100%",
    height: 144,
    boxSizing: "border-box",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .inner-dropzone": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& img": {
        width: 72,
        height: 72,
      }
    }
  },
  "& .resume-wrap": {
    textDecoration: "none",
    color: "inherit",
    position: "relative",
    cursor: "pointer",
    flexShrink: 0,
    padding: 8,
    width: "100%",
    height: 144,
    boxSizing: "border-box",
    border: "1px solid #D0D0D0",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    "& .icon-part": {
      flexGrow:1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& i": {
        fontSize: 56,
        color: "#D0D0D0"
      }
    },
    "& .filename": {
      flexShrink: 1,
      height: "48px",
      "& .MuiTypography-root": {
        fontSize: 14,
        display: "-webkit-box",
        overflow: "hidden",
        WebkitLineClamp: 2, 
        WebkitBoxOrient: "vertical",
        textAlign: "center",
        wordBreak: "break-all",
      }
    },
    "& .delete": {
      position: "absolute",
      top: 0,
      right: 0,
      padding: 8,
      "& i": {
        fontSize: 16,
      }
    }
  }
})

const FormOne = (props) => {

  const { onNextPage, useForm } = props; 

  const {control, handleSubmit, formState: { errors }, getValues} = useForm;

  const onSubmit = (formData) => {
    onNextPage();
  }

  const renderAge = (totalMonth) => {
    const year = Math.floor(totalMonth / 12);
    const month = (totalMonth % 12)
    return `${year} ปี ${month} เดือน`
  }

  return (
    <StyledRoot>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center">

          <Grid item xs={12}>
            <Controller
              control={control}
              name="resume"
              render={({field, formState}) => (
                <Fragment>
                  {!field.value && 
                  <Dropzone
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if(acceptedFiles.length > 0){
                        field.onChange(acceptedFiles[0])
                      }
                    }}
                    accept="application/pdf"
                    maxFiles={1}
                    multiple={false}
                    maxSize={10485760}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                    }) => (
                      <div {...getRootProps({ className: "dropzone" })}>

                        {/* {isFetching ? (
                          <Box>
                            <Box className="inner-dropzone">
                              <CircularProgress />
                            </Box>
                          </Box>
                        ):( */}
                          <Box>
                            <input {...getInputProps()}/>
                            <Box className="inner-dropzone">
                              <img alt="add" src={AddIcon} />
                              <Typography paddingTop="16px" fontSize={14} color="text.third">อัพโหลดเรซูเม่ได้ที่นี่</Typography>
                            </Box>
                          </Box>
                        {/* )} */}
                      </div>
                      )
                    }
                  </Dropzone>}

                  {field.value && <Box className="resume-wrap" component={"a"} href={URL.createObjectURL(field.value)} target="_blank">
                    <Box className="icon-part">
                      <i className="fa-solid fa-file"></i>
                    </Box>
                    <Box className="filename">
                      <Typography>{field.value.name}</Typography>
                    </Box>
                    <IconButton className="delete" onClick={(e)=>{e.preventDefault(); field.onChange(null)}}>
                      <i className="fa-solid fa-trash-can"></i>
                    </IconButton>
                  </Box>}

                  <FormHelperText error>*สามารถอัปโหลดไฟล์ได้เพียง 1 ไฟล์ โดยต้องเป็นไฟล์ .pdf เท่านั้น</FormHelperText>
                </Fragment>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display="flex" gap="8px">
              <Controller
                name="title"
                control={control}
                rules={{
                  required: {value: true, message: "กรุณากรอกคำนำหน้า"}
                }}
                render={({field})=>(
                  <TextFieldTheme
                    {...field}
                    label="คำนำหน้า"
                    // fullWidth
                    helperText={errors && errors.title && errors.title.message}
                    error={errors && errors.title ? true: false}
                    select
                    sx={{maxWidth: "120px"}}
                  >
                    <MenuItem value="นาย">นาย</MenuItem>
                    <MenuItem value="นาง">นาง</MenuItem>
                    <MenuItem value="นางสาว">นางสาว</MenuItem>
                  </TextFieldTheme>
                )}
              />
              <Controller
                name="firstname"
                control={control}
                rules={{
                  required: {value: true, message: "กรุณากรอกชื่อ"}
                }}
                render={({field})=>(
                  <TextFieldTheme
                    {...field}
                    label="ชื่อ"
                    fullWidth
                    helperText={errors && errors.firstname && errors.firstname.message}
                    error={errors && errors.firstname ? true: false}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="lastname"
              control={control}
              rules={{
                required: {value: true, message: "กรุณากรอกนามสกุล"}
              }}
              render={({field})=>(
                <TextFieldTheme
                  {...field}
                  label="นามสกุล"
                  fullWidth
                  helperText={errors && errors.lastname && errors.lastname.message}
                  error={errors && errors.lastname ? true: false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: {value: true, message: "กรุณากรอกเบอร์โทร"},
                validate: (value) => (!isNaN(value) && value.length === 10) || "กรุณากรอกเบอร์โทร 10 หลัก"
              }}
              render={({field})=>(
                <TextFieldTheme
                  {...field}
                  label="เบอร์โทร"
                  InputProps={{
                    inputComponent: NumberFormatTheme,
                  }}
                  inputProps={{
                    allowNegative: false,
                  }}
                  fullWidth
                  helperText={errors && errors.phoneNumber && errors.phoneNumber.message}
                  error={errors && errors.phoneNumber ? true: false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: {value: true, message: "กรุณากรอกอีเมล"},
                pattern: {
                  value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z.]{1,}[A-z]$/,
                  message: "กรุณากรอกอีเมลให้ถูกต้อง เช่น 'antjob@example.com'"
                }
              }}
              render={({field})=>(
                <TextFieldTheme
                  {...field}
                  label="อีเมล"
                  fullWidth
                  helperText={errors && errors.email && errors.email.message}
                  error={errors && errors.email ? true: false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                control={control}
                name="dateOfBirth"
                rules={{
                  required: {
                    value: true,
                    message: "กรุณากรอกวันเกิด"
                  }
                }}
                render={({field, formState}) => (
                  <DesktopDatePicker
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    disableFuture
                    disableMaskedInput
                    openTo="year"
                    onChange={(value)=>{
                      field.onChange(value)
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        label="วัน เดือน ปีเกิด"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "",
                          readOnly: true
                        }}
                        error={formState.errors && formState.errors[field.name]? true: false}
                        helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <Controller 
                control={control}
                name="dateOfBirth"
                render={()=>(
                  <Fragment>
                    {getValues("dateOfBirth") && (
                      <Box display="flex">
                        <Typography color="text.secondary" fontSize={20} paddingRight="8px">อายุ</Typography>
                        <Typography variant="h6">{renderAge(dayjs(new Date()).diff(getValues("dateOfBirth"), 'month'))}</Typography>
                      </Box>
                    )}
                  </Fragment>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="personalId"
              control={control}
              rules={{
                // required: {value: true, message: "กรุณากรอกเลขบัตรประชาชน"},
                minLength: {value: 13, message: "กรุณากรอกเลขบัตรประชาชนเป็นตัวเลข 13 หลัก"},
                maxLength: {value: 13, message: "กรุณากรอกเลขบัตรประชาชนเป็นตัวเลข 13 หลัก"}
              }}
              render={({field})=>(
                <TextFieldTheme
                  {...field}
                  label="เลขบัตรประชาชน"
                  InputProps={{
                    inputComponent: NumberFormatTheme,
                  }}
                  inputProps={{
                    allowNegative: false,
                    onValueChange:(values) => {
                      const {value} = values;
                      field.onChange(value)
                    }
                  }}
                  onChange={()=>{}}
                  fullWidth
                  helperText={errors && errors.personalId && errors.personalId.message}
                  error={errors && errors.personalId ? true: false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <ButtonBlue variant="contained" type="submit">ถัดไป</ButtonBlue>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </StyledRoot>
  )
}

export default FormOne;