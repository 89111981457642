import React from 'react';

import {
    Box,
    Dialog,
    DialogContent,
    Typography
} from "@mui/material";
import ButtonBlue from '../../../shared/general/ButtonBlue';

const DialogConfirmDelete = (props) => {
    const { open, onClose, isEducation, onSubmit } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogContent>
                <Typography variant="h4">ยืนยันการลบ</Typography>
                <Box 
                    style={{ 
                        width: "350px", 
                        height:"80px", 
                        display:"flex", 
                        flexDirection:"column", 
                        justifyContent:"space-between" 
                    }}
                >
                    <Typography variant="body1">
                        {`ต้องการลบ${isEducation ? "ประวัติการศึกษา" : "ประสบการณ์ทำงาน"}นี้หรือไม่`}
                    </Typography>
                    <Box style={{ width:"100%", display:"flex", justifyContent:"flex-end" }}>
                        <ButtonBlue 
                            variant="outlined"
                            style={{ marginRight:"10px" }}
                            onClick={onClose}
                        >ยกเลิก</ButtonBlue>
                        <ButtonBlue
                            variant="contained"
                            onClick={() => {
                                onSubmit();
                                onClose();
                            }}
                        >ยืนยัน</ButtonBlue>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DialogConfirmDelete;