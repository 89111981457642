import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Dialog, IconButton, styled, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addAdminCompany, deleteAdminCompany } from "../../../../actions/adminCompany";

const StyledRoot = styled(Box)({

})

const DialogDelete = (props) => {

  const { open, onClose, idAdminAntJob, email, setAdminList } = props;

  const [isFetching, setIsFetcing] = useState(false);

  const dispatch = useDispatch();

  const onConfirm = () => {
    setIsFetcing(true);
    dispatch(deleteAdminCompany(idAdminAntJob)).then(res => {
      if(res.status === 200){
        setAdminList(state => state.filter(s => s.idAdminAntJob !== res.data.idAdminAntJob));
        onClose();
      }
    })
  }

  useEffect(() => {
    setIsFetcing(false);
  },[open])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <Box
        padding="16px"
      >
        <Typography fontSize={20}>ลบ</Typography>
        <Box padding="24px 0">
          <Typography>คุณต้องการยกเลิกบัญชีนี้จากการเป็นแอดมิน</Typography>
          <Typography>{email}</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <ButtonBlue variant="outlined" onClick={onClose}>ยกเลิก</ButtonBlue>
          <ButtonBlue variant="contained" onClick={onConfirm} disabled={isFetching} startIcon={isFetching && <CircularProgress size={16}/>}>ลบ</ButtonBlue>
        </Box>
      </Box>
    </Dialog>
  )
}

const AdminSetting = (props) => {

  const { adminList, setAdminList } = props;

  const { result: userMe } = useSelector((state) => state.userProfile);

  // const [adminList, setAdminList] = useState(company.admin);
  const [isFetching, setIsFetching] = useState(false);

  const [dialogDeleteState, setDialogDeleteState] = useState({
    isOpen: false,
    idAdminAntJob: null,
    email: null
  })

  const {control, handleSubmit, setError} = useForm({
    defaultValues: {
      email: "",
    }
  })

  const dispatch = useDispatch();

  const onSubmit = (formData) => {

    if(adminList.length >= 3){
      setError("email", { type: "custom", message: "สามารถมีแอดมินได้ 3 บัญชีเท่านั้น"})
      return
    }

    if(adminList.find(a => a.email === formData.email)){
      setError("email", { type: "custom", message: "คุณได้เพิ่มแอดมินคนนี้แล้ว"})
      return
    }

    setIsFetching(true)

    dispatch(addAdminCompany(formData)).then(res => {
      if(res.status === 200){
        setAdminList([...adminList, {
          idAdminAntJob: res.data.idAdminAntJob,
          email: formData.email
        }])
      } else if(res.status === 400) {
        if(res.data.errorCode === "EMAIL_IS_EXISTS") {
          setError("email", { type: "custom", message: "อีเมลนี้มีผู้ใช้งานแล้ว"})
        }
      }
      setIsFetching(false);
    })
  }

  return (
    <StyledRoot>

      <Box>
        <Typography component={"div"} variant="h7" fontSize={20} paddingBottom="24px">ผู้ดูแล</Typography>
      </Box>

      {Boolean(userMe.isOwner) && <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" gap="8px" alignItems="flex-start">
          <Box flexGrow={1}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: {value: true, message: "กรุณากรอกอีเมล"},
                pattern: {
                  value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z.]{1,}[A-z]$/,
                  message: "กรุณากรอกอีเมลให้ถูกต้อง เช่น 'antjob@example.com'"
                }
              }}
              render={({field, formState}) => (
                <TextFieldTheme
                  {...field}
                  fullWidth
                  helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                  error={formState.errors && formState.errors[field.name]? true: false}
                  autoComplete=""
                />
              )}
            />
          </Box>
          <ButtonBlue type="submit" variant="outlined" startIcon={<i className="fa-solid fa-plus" />} disabled={isFetching}>เพิ่มผู้ดูแล</ButtonBlue>
        </Box>
      </form>}

      {!Boolean(userMe.isOwner) && <Typography>เฉพาะบัญชีผู้สร้างเท่านั้นที่สามารถเพิ่ม-ลบผู้ดูแลได้</Typography>}

      <Box>
        <Typography fontSize={12} color="text.third">*สามารถเป็นแอดมินได้สูงสุด 3 คนเท่านั้น</Typography>
      </Box>

      {adminList.map((admin, index) => (
        <Box key={index} paddingTop="16px" display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography fontSize={16} fontWeight={500}>{admin.email} {admin.isOwner? <span>(ผู้สร้าง)</span>: null} {!admin.idUsers? <span>(รอการตอบรับ...)</span>: null}</Typography>
            <Typography fontSize={14}>{admin.name}</Typography>
          </Box>
          {Boolean(userMe.isOwner) && !Boolean(admin.isOwner) && <Box>
            <IconButton onClick={()=>{setDialogDeleteState({isOpen: true, idAdminAntJob: admin.idAdminAntJob, email: admin.email})}}>
              <i className="fa-solid fa-trash-can" style={{margin: 0, fontSize: 14}} />
            </IconButton>
          </Box>}
        </Box>
      ))}

      <DialogDelete
        open={dialogDeleteState.isOpen}
        onClose={() => {
          setDialogDeleteState({
            isOpen: false,
            idAdminAntJob: null,
            email: null,
          })
        }}
        idAdminAntJob={dialogDeleteState.idAdminAntJob}
        email={dialogDeleteState.email}
        setAdminList={setAdminList}
      />

    </StyledRoot>
  )
}

export default AdminSetting;