import React from "react";
import { styled, Typography } from "@mui/material";
import ButtonBlue from "../shared/general/ButtonBlue";
import { Link } from "react-router-dom";

const StyledRoot = styled("div")({
  display: "flex",
  // minHeight: "calc(100vh - 100px)",
  "& .part-text": {
    // margin: "auto",
    paddingTop: 55,
    paddingBottom: 55,
    textAlign: "center",
    flexGrow: 1,
    "& .success": {
      fontSize: 48,
      paddingBottom: 24,
    },
    "& .home-button": {
      marginTop: 24,
    }
  }
})

const SignupSuccess = () => {
  return(
    <StyledRoot>
      <div className="part-text">
        <Typography className="success">ยินดีด้วยคุณได้เป็น Partner <br />กับ AntJob แล้ว</Typography>
        <Typography color="text.secondary" fontSize={20}>โปรดตรวจสอบอีเมลของท่านเพื่อยืนยันตัวตน</Typography>
        <ButtonBlue className="home-button" variant="outlined" component={Link} to="/login">เข้าสู่ระบบ</ButtonBlue>
      </div>
    </StyledRoot>
  )
}

export default SignupSuccess;