import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link, withRouter, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Fade from '@mui/material/Fade';
import { useDispatch, useSelector } from "react-redux";

import TranslateIcon from "@mui/icons-material/Translate";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import FavoriteIcon from '@mui/icons-material/Favorite';

import Logo from "../../../components/pages/assets/ANT job.png";
import ButtonBlue from "../../pages/shared/general/ButtonBlue";

import { logout } from "../../../actions/auth";
import { getUserProfile } from "../../../actions/user";
import { Box } from "@mui/system";
import { Paper } from "@mui/material";

const StyledAppBar = styled(AppBar)(({}) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  "& .MuiAvatar-root": {
    // padding: 4,
    "& img": {
      objectFit: "contain",
      pointerEvents: "none",
    }
  },
  "& .MuiToolbar-regular": {
    padding: 0,
    color: "#212b36",
    backgroundColor: "#ffffffcc",
    transition:
      "height 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    backdropFilter: "blur(6px)",
    ["@media (min-width: 900px)"]: {
      height: 76,
    },

    "& .MuiContainer-root": {
      display: "flex",
      alignItems: "center",
      "& .headerAction": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .partner": {
          ["@media only screen and (max-width: 600px)"]: {
            display: "none",
          },
        },
        "& .contact-us": {
          ["@media only screen and (max-width: 600px)"]: {
            display: "none",
          },
        },
        "& .divider": {
          margin: "0 16px", height: 24,
          ["@media only screen and (max-width: 600px)"]: {
            display: "none",
          },
        },
      },
    },
  },
}));

const StyledIconButtonTranslate = styled(IconButton)(({}) => ({
  border: "1px solid #00000030",
  borderRadius: 8,
  marginLeft: 8,
  "&:hover": {
    transform: "scale(1.09) translateZ(0px)",
  },
  ["@media only screen and (max-width: 600px)"]: {
    display: "none",
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const BoxSubMenu = styled(Paper)({
  width:240, 
  height:"fit-content", 
  position:"absolute", 
  top:0, 
  right:"100%",
  borderRadius:16,
  padding:"16px 0",
  marginRight:8
});

const SubMenu = ({ path, icon, label, submenu }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const handleMouseEnter = () => {
    setOpenSubMenu(true);
  };

  const handleMouseLeave = () => {
    setOpenSubMenu(false);
  };

  return (
    <Box
      style={{ display:"flex", position:"relative" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <MenuItem
        component={Link} 
        to={path}
        style={{
          width:"100%",
          color:"initial",
          textDecoration:"none"
        }}
      >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        {label}
      </MenuItem>
      <Fade in={openSubMenu}>
        <BoxSubMenu>
          {submenu}
        </BoxSubMenu>
      </Fade>
    </Box>
  );
};

const Header = (props) => {
  const { open, matchesBig, isLoggedIn } = props;
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    dispatch(logout());
    handleMenuClose();
  };

  // useEffect(()=>{
  //   if(currentUser !== null){
  //     dispatch(getUserProfile(currentUser.id))
  //   }
  // }, [currentUser])

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          top: "56px !important",
          right: "190px !important",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: "20px",
          width: "250px",
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {/* {console.log("userProfile", userProfile)}
      {console.log("currentUser", currentUser)} */}
      <Box style={{ padding: "0 20px", margin: "12px 0" }}>
        <Typography
          variant="h6"
          style={{
            fontSize: 16,
            // whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {userProfile && (userProfile.companyName || userProfile.vendorName)}
        </Typography>
        <Typography color="text.secondary" sx={{whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}}>{userProfile && userProfile.username}</Typography>
      </Box>
      <Divider />
      <MenuItem component={Link} to="/">
        <ListItemIcon>
          <HomeOutlinedIcon fontSize="small" />
        </ListItemIcon>
        หน้าแรก
      </MenuItem>
      {userProfile && (userProfile.rolesName === "ROLE_ADMIN") && (
        <div>
          <MenuItem component={NavLink} to={`/company/manage`}>
            <ListItemIcon>
              <PersonOutlineOutlinedIcon fontSize="small" />
            </ListItemIcon>
            โปรไฟล์
          </MenuItem>
          <MenuItem component={NavLink} to={`/company/favorites`}>
            <ListItemIcon>
              <FavoriteIcon fontSize="small" />
            </ListItemIcon>
            คู่ธุรกิจโปรด
          </MenuItem>
          <SubMenu 
            path="/company/myjobs" 
            icon={<i className="fa-regular fa-briefcase-blank"></i>} 
            label="งานของฉัน"
            submenu={
              <Box>
                <MenuItem component={NavLink} to={`/company/myjobs/jobForm`}>
                  <ListItemIcon>
                    <i className="fa-solid fa-file-plus" />
                  </ListItemIcon>
                  เปิดงานใหม่
                </MenuItem>
                <MenuItem component={NavLink} to={`/company/myjobs/biddingForm`}>
                  <ListItemIcon>
                    <i className="fa-regular fa-file-signature" />
                  </ListItemIcon>
                  เปิดสัญญาใหม่
                </MenuItem>
              </Box>
            }
          />
          <MenuItem component={Link} to="/company-history-job">
            <ListItemIcon>
              <i className="fa-regular fa-clock"></i>
            </ListItemIcon>
            ประวัติงานของฉัน
          </MenuItem>
          <MenuItem component={Link} to="/company-mycontract">
            <ListItemIcon>
              <i className="fa-regular fa-file-contract"></i>
            </ListItemIcon>
            สัญญาของฉัน
          </MenuItem>
          <MenuItem component={Link} to="/company/myvendors">
            <ListItemIcon>
              <i className="fa-regular fa-handshake"></i>
            </ListItemIcon>
            คู่ธุรกิจของฉัน
          </MenuItem>
          <MenuItem component={Link} to="/company/interview">
            <ListItemIcon>
              <i className="fa-regular fa-users"></i>
            </ListItemIcon>
            นัดหมายสัมภาษณ์
          </MenuItem>
        </div>
      )}

      {userProfile && (userProfile.rolesName === "ROLE_VENDOR") && (
        <div>
          <MenuItem component={Link} to={`/vendor/manage`}>
            <ListItemIcon>
              <PersonOutlineOutlinedIcon fontSize="small" />
            </ListItemIcon>
            โปรไฟล์
          </MenuItem>
          <MenuItem component={Link} to="/vendor-dashboard">
            <ListItemIcon>
              {/* <PersonOutlineOutlinedIcon fontSize="small" /> */}
              <i className="fa-regular fa-chart-simple"></i>
            </ListItemIcon>
            แดชบอร์ด
          </MenuItem>
          <MenuItem component={Link} to="/manpower">
            <ListItemIcon>
              {/* <PersonOutlineOutlinedIcon fontSize="small" /> */}
              <i className="fa-regular fa-users"></i>
            </ListItemIcon>
            กำลังพล
          </MenuItem>
          <MenuItem component={Link} to="/vendor-mycompanys">
            <ListItemIcon>
              <i className="fa-solid fa-apartment"></i>
            </ListItemIcon>
            บริษัทคู่สัญญาของฉัน
          </MenuItem>
          <MenuItem component={Link} to="/vendor-mybidding">
            <ListItemIcon>
              <i className="fa-regular fa-gavel"></i>
            </ListItemIcon>
            การประมูลของฉัน
          </MenuItem>
          <MenuItem component={Link} to="/vendor-mycontract">
            <ListItemIcon>
              <i className="fa-regular fa-file-contract"></i>
            </ListItemIcon>
            สัญญาของฉัน
          </MenuItem>
          <MenuItem component={Link} to="/vendor-interview">
            <ListItemIcon>
              {/* <PersonOutlineOutlinedIcon fontSize="small" /> */}
              <i className="fa-regular fa-handshake"></i>
            </ListItemIcon>
            ผู้ถูกเรียกสัมภาษณ์
          </MenuItem>
          <MenuItem component={Link} to="/vendor-matching">
            <ListItemIcon>
              <i className="fa-light fa-business-time"></i>
            </ListItemIcon>
            ประวัติการMatching
          </MenuItem>
          <MenuItem component={Link} to="/vendor-history-sending">
            <ListItemIcon>
              <i className="fa-regular fa-clock"></i>
            </ListItemIcon>
            ประวัติการส่งคน
          </MenuItem>
        </div>
      )}
      <MenuItem component={Link} to="/account/setting">
        <ListItemIcon>
          <i className="fa-solid fa-gear"></i>
        </ListItemIcon>
        ตั้งค่าบัญชี
      </MenuItem>
      <MenuItem component={Link} to="/login" onClick={logOut}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        ออกจากระบบ
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <ElevationScroll {...props}>
        <StyledAppBar>
          <Toolbar>
            <Container maxWidth="lg">
              <NavLink to="/">
                <img src={Logo} alt="logo" width={75} />
              </NavLink>
              <div style={{ flexGrow: 1 }}></div>
              <div className={`headerAction`}>
                {!isLoggedIn && <div>
                  <ButtonBlue
                    variant={"text"}
                    component={NavLink}
                    to="/company/signup"
                    className="partner"
                  >
                    เข้าร่วมเป็นบริษัท
                  </ButtonBlue>
                </div>}
                {!isLoggedIn && <div>
                  <ButtonBlue
                    variant={"text"}
                    component={NavLink}
                    to="/vendor/signup"
                    className="partner"
                    style={{marginLeft: 16}}
                  >
                    เข้าร่วมเป็นคู่ธุรกิจ
                  </ButtonBlue>
                </div>}
                {/* {!isLoggedIn && <div>
                  <ButtonBlue
                    variant={"text"}
                    component={NavLink}
                    to="/register"
                    className="partner"
                  >
                    partner
                  </ButtonBlue>
                </div>} */}
                <Divider
                  className="divider"
                  orientation="vertical"
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isLoggedIn ? (
                    <div>
                      <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        size="large"
                        style={{ marginRight: 8, padding: 4}}
                      >
                        <Avatar
                          alt={userProfile && userProfile.email}
                          // src={`${process.env.REACT_APP_API_URL}image/profile/${currentUser.image}`}
                          src={userProfile && userProfile.avatar}
                        />
                      </IconButton>
                    </div>
                  ) : (
                    <Fragment>
                      <ButtonBlue
                        variant={"outlined"}
                        className="contact-us"
                        component={NavLink}
                        to="/contact-us"
                      >
                        ติดต่อเรา
                      </ButtonBlue>
                      <ButtonBlue
                        variant={"contained"}
                        style={{ marginLeft: 8 }}
                        component={NavLink}
                        to="/login"
                      >
                        เข้าสู่ระบบ
                      </ButtonBlue>
                    </Fragment>
                  )}

                  <div>
                    <StyledIconButtonTranslate aria-label="translate">
                      <TranslateIcon fontSize="small" />
                    </StyledIconButtonTranslate>
                  </div>
                </div>
              </div>
            </Container>
          </Toolbar>
        </StyledAppBar>
      </ElevationScroll>
      {renderMenu}
    </div>
  );
};

export default withRouter(Header);
