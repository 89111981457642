import React, { useState } from "react";
import { Alert, CircularProgress, Snackbar, styled, Typography } from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../../../actions/auth";
import { Link } from "react-router-dom";

const StyledRoot = styled("div")({
  "& .item": {
    marginBottom: 8,
  },
  "& .MuiTextField-root": {
    maxWidth: 480,
  },
  "& .link a": {
    fontSize: 14,
    textDecoration: "none",
    color: "#1976d2"
  }
})

const ChangePassword = () => {

  const dispatch = useDispatch();

  const [snackbarState, setSnackbarState] = useState({
    isOpen: false,
    severity: "success",
    message: "",
  })

  const { control, handleSubmit, formState: { isSubmitting, errors }, getValues, reset } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    }
  })

  const handleCloseSnackbar = (_, reason) => {
    if(reason === "clickaway"){
      return
    }
    setSnackbarState({
      ...snackbarState,
      isOpen: false,
    });
  };

  const onSubmitHandler = async (formData) => {

    const response = await dispatch(changePassword(formData))

    if(response.status === 200){
      setSnackbarState({
        isOpen: true,
        severity: "success",
        message: "เปลี่ยนรหัสผ่านสำเร็จ"
      });
      reset()
    } else if(response.status === 403) {
      setSnackbarState({
        isOpen: true,
        severity: "error",
        message: "รหัสผ่านปัจจุบันไม่ถูกต้อง"
      });
    } else {
      setSnackbarState({
        isOpen: true,
        severity: "warning",
        message: "เกิดข้อผิดพลาดของระบบ"
      });
    }

  }

  return (
    <StyledRoot>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        {/* <Typography className="item">เปลี่ยนรหัสผ่าน</Typography> */}

        <div>
          <Controller
            control={control}
            name="currentPassword"
            rules={{
              required: {value: true, message: "กรุณากรอกรหัสผ่านปัจจุบัน"}
            }}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                className="item"
                label="รหัสผ่านปัจจุบัน"
                autoComplete="current-password"
                type="password"
                helperText={errors && errors.currentPassword && errors.currentPassword.message}
                error={errors && errors.currentPassword ? true: false}
              />
            )}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="newPassword"
            rules={{
              required: {value: true, message: "กรุณากรอกรหัสผ่านใหม่"},
              pattern: {
                value: /^[A-Za-z0-9_]+$/,
                message: "กรุณากรอกรหัสผ่าน เป็นตัวภาษาอังกฤษ ตัวเลข หรือเครื่องหมาย_เท่านั้น"
              },
              minLength: {value: 8, message: "กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวอักษร"},
            }}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                className="item"
                label="รหัสผ่านใหม่"
                autoComplete="new-password"
                type="password"
                helperText={errors && errors.newPassword && errors.newPassword.message}
                error={errors && errors.newPassword ? true: false}
              />
            )}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="newPasswordConfirm"
            rules={{
              required: {value: true, message: "กรุณากรอกยืนยันรหัสผ่านใหม่"},
              validate: (value) => (value === getValues("newPassword")) || "กรุณากรอกรหัสผ่านให้ตรงกัน",
            }}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                className="item"
                label="ยืนยันรหัสผ่านใหม่"
                autoComplete="new-password"
                type="password"
                helperText={errors && errors.newPasswordConfirm && errors.newPasswordConfirm.message}
                error={errors && errors.newPasswordConfirm ? true: false}
              />
            )}
          />
        </div>
        <ButtonBlue className="item" variant="contained" type="submit" disabled={isSubmitting}>
          เปลี่ยนรหัสผ่าน
        </ButtonBlue>
        <div className="item link">
          <Typography component={Link} to="/account/setting/forget_password">ลืมรหัสผ่าน ?</Typography>
        </div>
      </form>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarState.isOpen}
        onClose={handleCloseSnackbar}
        onClick={handleCloseSnackbar}
      >
        <Alert severity={snackbarState.severity} >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </StyledRoot>
  )
}

export default ChangePassword;