import {
    VENDOR_NOTIFICATION_FETCHING,
    VENDOR_NOTIFICATION_FAILED,
    VENDOR_NOTIFICATION_SUCCESS
} from "./types";
import VendorNotificationService from "../services/vendorNotification.service";

export const getVendorNotification = () => async (dispatch) =>{
    try {
        dispatch({
            type: VENDOR_NOTIFICATION_FETCHING
        })
        const res = await VendorNotificationService.getVendorNotification();
        if(res){
            dispatch({
                type: VENDOR_NOTIFICATION_SUCCESS,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch({
            type: VENDOR_NOTIFICATION_FAILED
        })
    }
};

export const updateVendorNotification = (data) => async () => {
    try {
        const res = await VendorNotificationService.updateVendorNotification(data);
        if(res){
            return res.data;
        }
    } catch (error) {
        const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
        return "Error";
    }
};