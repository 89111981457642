import { Box, Grid, MenuItem, Paper, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { Fragment } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const FormTwo = (props) => {

  const { onNextPage, onPrevPage, useForm, degrees } = props;

  const {control, handleSubmit, formState: { errors }, getValues, setValue} = useForm;

  const {result: jobGroups} = useSelector(state => state.jobGroups)

  // const onClickExpertise = (idExpertise) => {
  //   const currentExpertise = getValues("idExpertise");
  //   if (idExpertise !== currentExpertise) {
  //     setValue("idExpertise", idExpertise);
  //     return;
  //   }
  // };

  const onSubmit = (formData) => {
    // formAction.submitForm();
    onNextPage();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          {/* <Box> */}
            <Typography variant="h5" fontSize={20}>ความเชี่ยวชาญ</Typography>
            {errors && errors.idJobGroup && errors.idJobGroup.message && <Typography fontSize={12} color="error" style={{marginLeft: "14px"}}>{errors.idJobGroup.message}</Typography>}
          {/* </Box> */}
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="idJobGroup"
            // rules={{
            //   required: {value: true, message: "กรุณาเลือกความเชี่ยวชาญ"},
            // }}
            render={({field})=>(
              <Grid className="part-expertise" container spacing={2}>
                {jobGroups && jobGroups.map((item, index)=>(
                  <Grid key={index} item xs={6} md={4} lg={3}>
                    <Paper className={getValues("idJobGroup") === item.idJobGroup? "isSelected": ""} variant="outlined" onClick={()=>{field.onChange(item.idJobGroup)}}>
                      <div className="wrap-name">
                        <Typography variant="subtitle">{item.jobGroupName}</Typography>
                      </div>
                      {/* <div className="wrap-icon">{item.icon}</div> */}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
          />
        </Grid>
        
        <Grid item xs={6} sm={3}>
          <Controller
            control={control}
            name="workExperienceY"
            // rules={{
            //   required: {value: true, message: "กรุณาเลือกประสบการณ์ทำงาน"},
            // }}
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="ประสบการณ์ทำงาน(ปี)"
                helperText={errors && errors.workExperience && errors.workExperience.message}
                error={errors && errors.workExperience ? true: false}
                select
                SelectProps={{
                  displayEmpty: true
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="">ไม่มี</MenuItem>
                <MenuItem value="1">1 ปี</MenuItem>
                <MenuItem value="2">2 ปี</MenuItem>
                <MenuItem value="3">3 ปี</MenuItem>
                <MenuItem value="4">4 ปี</MenuItem>
                <MenuItem value="5">5 ปี</MenuItem>
                <MenuItem value="6">6 ปี</MenuItem>
                <MenuItem value="7">7 ปี</MenuItem>
                <MenuItem value="8">8 ปี</MenuItem>
                <MenuItem value="9">9 ปี</MenuItem>
                <MenuItem value="10">10 ปี</MenuItem>
                <MenuItem value="11">11 ปี</MenuItem>
                <MenuItem value="12">12 ปี</MenuItem>
                <MenuItem value="13">13 ปี</MenuItem>
                <MenuItem value="14">14 ปี</MenuItem>
                <MenuItem value="15">15 ปี</MenuItem>
                <MenuItem value="16">16 ปี</MenuItem>
                <MenuItem value="17">17 ปี</MenuItem>
                <MenuItem value="18">18 ปี</MenuItem>
                <MenuItem value="19">19 ปี</MenuItem>
                <MenuItem value="20">20 ปี</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Controller
            control={control}
            name="workExperienceM"
            // rules={{
            //   required: {value: true, message: "กรุณาเลือกประสบการณ์ทำงาน"},
            // }}
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="ประสบการณ์ทำงาน(เดือน)"
                helperText={errors && errors.workExperienceM && errors.workExperienceM.message}
                error={errors && errors.workExperienceM ? true: false}
                select
                SelectProps={{
                  displayEmpty: true
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="">ไม่มี</MenuItem>
                <MenuItem value="1">1 เดือน</MenuItem>
                <MenuItem value="2">2 เดือน</MenuItem>
                <MenuItem value="3">3 เดือน</MenuItem>
                <MenuItem value="4">4 เดือน</MenuItem>
                <MenuItem value="5">5 เดือน</MenuItem>
                <MenuItem value="6">6 เดือน</MenuItem>
                <MenuItem value="7">7 เดือน</MenuItem>
                <MenuItem value="8">8 เดือน</MenuItem>
                <MenuItem value="9">9 เดือน</MenuItem>
                <MenuItem value="10">10 เดือน</MenuItem>
                <MenuItem value="11">11 เดือน</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="educationDegree"
            rules={{
              required: {value: true, message: "กรุณาเลือกระดับการศึกษาสูงสุด"},
            }}
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="ระดับการศึกษาสูงสุด"
                helperText={errors && errors.educationDegree && errors.educationDegree.message}
                error={errors && errors.educationDegree ? true: false}
                select
              >
                {degrees.map(d => (
                  <MenuItem key={`deegree_${d.idDegrees}`} value={`${d.degreeName}`}>{d.degreeName}</MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="currentSalary"
            rules={{
              // required: {value: true, message: "กรุณากรอกเงินเดือนปัจจุบัน"},
            }}
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="เงินเดือนปัจจุบัน (ไม่จำเป็น)"
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  allowNegative: false,
                  thousandSeparator:",",
                  suffix:" บาท",
                  onValueChange:(values) => {
                    const {value} = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
                helperText={errors && errors.currentSalary && errors.currentSalary.message}
                error={errors && errors.currentSalary ? true: false}
              />  
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="expectedSalary"
            render={({field})=>(
              <TextFieldTheme
                {...field}
                label="เงินเดือนที่คาดหวัง (ไม่จำเป็น)"
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  allowNegative: false,
                  thousandSeparator:",",
                  suffix:" บาท",
                  onValueChange:(values) => {
                    const {value} = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
                helperText={errors && errors.expectedSalary && errors.expectedSalary.message}
                error={errors && errors.expectedSalary ? true: false}
              />  
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <ButtonBlue variant="contained" onClick={onPrevPage}>ก่อนหน้า</ButtonBlue>
            <ButtonBlue variant="contained" type="submit">ถัดไป</ButtonBlue>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default FormTwo;