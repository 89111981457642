import React, { Fragment, useEffect, useState } from "react";
import { Grid, styled, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardVendor from "../../shared/general/CardVendor";
import { Link } from "react-router-dom";
import CardVendorSkeleton from "../../shared/general/CardVendorSkeleton";

// import vendorStore from "../../assets/data/vendor";

const StyledRoot = styled("div")({
  "&.vendors": {
    paddingBottom: 64,
    "& .part-header": {
      paddingBottom: 16,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .all-button": {
        "& i": {
          display: "flex",
        }
      }
    },
  }
})

const Vendor = (props) => {

  const { vendors } = props;

  // const [vendors, setVendors] = useState([]);

  // useEffect(() => {
  //   setVendors(vendorStore.slice(0, 3));
  // }, [])

  return(
    <StyledRoot className="vendors">
      <div className="part-header">
        <Typography variant="h4">คู่ธุรกิจ</Typography>
        <ButtonBlue className="all-button" component={Link} to="/vendor-search">ทั้งหมด<i className="fi fi-br-angle-small-right"></i></ButtonBlue>
      </div>
      <Grid container spacing={2}>

        {vendors.length > 0? 
          vendors.map((vendor, index) => (
            <Grid key={`vendor_${index}`} item xs={4}>
              <CardVendor vendor={vendor}/>
            </Grid>
          ))
          :
          <Fragment>
            <Grid item xs={4}>
              <CardVendorSkeleton />
            </Grid>
            <Grid item xs={4}>
              <CardVendorSkeleton />
            </Grid>
            <Grid item xs={4}>
              <CardVendorSkeleton />
            </Grid>
          </Fragment>
        }
      </Grid>
    </StyledRoot>
  )
}

export default Vendor;