import React from "react";

import { 
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    styled,
    Typography
} from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";

const StyledDialog = styled(Dialog)({
    "& .MuiPaper-root":{
        minWidth: 300,
        maxWidth: 550,
        borderRadius: 16,
        padding: "10px"
    },
    "& h2":{
        fontSize:"1.8rem"
    },
    "& p":{
        fontSize:"1.1rem"
    }
});

const DialogConfirmCloseJob = ({ open, onClose, job, handleSubmit }) => {
    return (
        <StyledDialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>ปิดรับสมัคร</DialogTitle>
            <DialogContent>
                <Typography>{`ต้องการปิดรับสมัคร ' ${job.positionName} ${job.employeeTypeName} '`}</Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBlue variant="outlined" onClick={onClose}>ยกเลิก</ButtonBlue>
                <ButtonBlue variant="contained" onClick={handleSubmit}>ยืนยัน</ButtonBlue>
            </DialogActions>
        </StyledDialog>
    );
};

export default DialogConfirmCloseJob;