import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, styled, Typography, Zoom } from "@mui/material";

import { getVendorNotification, updateVendorNotification } from "../../../../actions/vendorNotification";

const StyledButton = styled(Button)({
    width:"150px",
    borderRadius:"25px",
});

const NotificationSendSuccess = () => {
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: vendorNotification } = useSelector((state) => state.vendorNotification);
    const [open, setOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if(userProfile && userProfile.idVendor){
            dispatch(getVendorNotification());
        }
    },[userProfile]);

    useEffect(() => {
        if(vendorNotification && vendorNotification.listNotifications && vendorNotification.listNotifications.length > 0){
            setOpen(true);
        }
    },[vendorNotification]);

    const handleClose = async () => {
        const res = await dispatch(updateVendorNotification({ 
            listVendorNotification: vendorNotification.listNotifications,
            listCandidate: vendorNotification.listCandidates
        }));
        if(res.message === "success"){
            dispatch(getVendorNotification());
        }
        setOpen(false);
    };  

    return (
        <Fragment>
            <Zoom 
                in={open} 
                style={{ position: "fixed", zIndex: 3 }}
                timeout={{ enter: 2000, exit: 300 }}
            >
                <Box 
                    style={{ 
                        height: "100vh", 
                        width: "100vw", 
                        display:"flex", 
                        justifyContent:"center", 
                        alignItems:"center",
                        backgroundColor:"rgba(255, 255, 255, 0.8)",
                        position:"fixed",
                        zIndex:6,
                        backdropFilter:"blur(5px)"
                    }}
                >
                    <Box style={{ height:"400px", display:"flex", justifyContent:"center", alignItems:"center", position:"relative" }}>
                        <img src={`${process.env.PUBLIC_URL}/assets/background/textCongraturation (1).png`}  />
                        <Box style={{ display:"flex", position:"absolute", bottom: 0,  }}>
                            <StyledButton
                                style={{ 
                                    backgroundColor:"#1976d2", 
                                    boxShadow:"0 2px 10px #000000",
                                    color:"#FFFFFF",
                                    marginRight:"20px"
                                }}
                                onClick={() => {
                                    history.push("/manpower",{ id: 'Candidate'});
                                    setOpen(false);
                                }}
                            >
                                <Typography variant="h6">ดูรายละเอียด</Typography>
                            </StyledButton>
                            <StyledButton
                                style={{
                                    backgroundColor:"#FFFFFF",
                                    boxShadow:"0 2px 10px #000000",
                                    color: "#1976d2"
                                }}
                                onClick={handleClose}
                            >
                                <Typography variant="h6">ปิด</Typography>
                            </StyledButton>
                        </Box>
                    </Box>
                </Box>
            </Zoom>
        </Fragment>
    );
};

export default NotificationSendSuccess;