import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Box, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ButtonBlue from '../../../shared/general/ButtonBlue';
import DrawerCustom from '../../../shared/general/Drawer';
import TextFieldTheme from '../../../shared/general/TextFieldTheme';
import { useDispatch } from 'react-redux';
import { updateManpower, getManpowerById } from '../../../../../actions/manpower';
import NumberFormatTheme from '../../../shared/general/NumberFormatTheme';

const DrawerPersonalInfo = (props) => {
  const { open, onClose, data, idManpower  } = props;
  const { handleSubmit, control, formState: { errors, dirtyFields } } = useForm({
    defaultValues: {
      personalId: data.personalId || "",
      dateOfBirth: data.dateOfBirth || null,
      telephoneMobile: data.telephoneMobile || "",
      email: data.email || "",
    }
  });

  const dispatch = useDispatch();

  const HandlerSubmit = (submitData) => {
    const formData = {};

    for (const key in dirtyFields) {
      formData[key] = submitData[key];
    }
    dispatch(updateManpower(idManpower, formData)).then(res => {
      dispatch(getManpowerById(idManpower));
    })
    onClose();
  }

  return (
    <DrawerCustom
      open={open}
      title={"แก้ไขเบอร์โทร, อีเมลและวันเกิด"}
      anchor="right"
    >
      <Box style={{ width: 400, padding: 16 }}>
        <form onSubmit={handleSubmit(HandlerSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="personalId"
                control={control}
                rules={{
                  // required: {value: true, message: "กรุณากรอกเลขบัตรประชาชน"},
                  minLength: {value: 13, message: "กรุณากรอกเลขบัตรประชาชนเป็นตัวเลข 13 หลัก"},
                  maxLength: {value: 13, message: "กรุณากรอกเลขบัตรประชาชนเป็นตัวเลข 13 หลัก"}
                }}
                render={({field})=>(
                  <TextFieldTheme
                    {...field}
                    label="เลขบัตรประชาชน"
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      allowNegative: false,
                      onValueChange:(values) => {
                        const {value} = values;
                        field.onChange(value)
                      }
                    }}
                    onChange={()=>{}}
                    fullWidth
                    helperText={errors && errors.personalId && errors.personalId.message}
                    error={errors && errors.personalId ? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="dateOfBirth"
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณากรอกวันเกิด"
                    }
                  }}
                  render={({field, formState}) => (
                    <DesktopDatePicker
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="DD MMMM YYYY"
                      disableFuture
                      disableMaskedInput
                      openTo="year"
                      onChange={(value)=>{
                        field.onChange(value)
                      }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          label="วัน เดือน ปีเกิด"
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "วัน เดือน ปีเกิด",
                            readOnly: true
                          }}
                          error={formState.errors && formState.errors[field.name]? true: false}
                          helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="telephoneMobile"
                control={control}
                rules={{
                  required: { value: true, message: "กรุณากรอกเบอร์โทร" },
                  validate: (value) => (!isNaN(value) && value.length >= 9) || "กรุณากรอกเบอร์โทร 10 หลัก"
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label="เบอร์โทร"
                    fullWidth
                    helperText={errors && errors.telephoneMobile && errors.telephoneMobile.message}
                    error={errors && errors.telephoneMobile ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: { value: true, message: "กรุณากรอกอีเมล" },
                  pattern: {
                    value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z.]{1,}[A-z]$/,
                    message: "กรุณากรอกอีเมลให้ถูกต้อง เช่น 'antjob@example.com'"
                  }
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label="อีเมล"
                    fullWidth
                    helperText={errors && errors.email && errors.email.message}
                    error={errors && errors.email ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <ButtonBlue
                  variant="outlined"
                  onClick={onClose}
                >ยกเลิก</ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={Object.keys(dirtyFields).length <= 0}
                >ยืนยัน</ButtonBlue>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </DrawerCustom>
  );
};

export default DrawerPersonalInfo;