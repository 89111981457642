import {
  CONTRACT_COMPANY_FETCHING,
  CONTRACT_COMPANY_FAILED,
  CONTRACT_COMPANY_SUCCESS,
  CONTRACT_VENDOR_FETCHING,
  CONTRACT_VENDOR_FAILED,
  CONTRACT_VENDOR_SUCCESS
} from "./types";
import ContractService from "../services/contract.service";

export const getContractCompany = (query) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_COMPANY_FETCHING
    });
    const res = await ContractService.getContractCompany(query);
    if (res) {
      dispatch({
        type: CONTRACT_COMPANY_SUCCESS,
        payload: res.data
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: CONTRACT_COMPANY_FAILED
    });
    return error.response;
  }
};

export const addContractCompany = (formData) => async () => {
  try {
    const res = await ContractService.addContractCompany(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteContractCompany = (idContractCompany) => async () => {
  try {
    const res = await ContractService.deleteContractCompany(idContractCompany);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getContractVendor = (query) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_VENDOR_FETCHING
    });
    const res = await ContractService.getContractVendor(query);
    if (res) {
      dispatch({
        type: CONTRACT_VENDOR_SUCCESS,
        payload: res.data
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: CONTRACT_VENDOR_FAILED
    });
    return error.response;
  }
};

export const addContractVendor = (formData) => async () => {
  try {
    const res = await ContractService.addContractVendor(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteContractVendor = (idContractVendor) => async () => {
  try {
    const res = await ContractService.deleteContractVendor(idContractVendor);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};