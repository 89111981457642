import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    Box,
    Grid,
    Pagination,
    styled,
    Typography
} from "@mui/material";

import CardBidding from '../shared/CardBidding';

const StyledRoot = styled(Box)({
    marginBottom:"20px",
    "& .Topic":{
        display:"flex",
        alignItems:"center",
        marginLeft:"10px",
        marginBottom:"20px",
        "& .icon":{
            fontSize:"28px"
        },
        "& .TopicText":{
            fontSize:"24px",
            marginLeft:"20px"
        }
    }
});

const BiddingContract = (props) => {
    const { type } = props;
    const { isFetching, result: AllContracts } = useSelector(state => state.mycontractBiddings);
    const [page, setPage] = useState(1);
    const [listContracts, setListContracts] = useState([]);

    useEffect(() => {
        if (AllContracts) {
            if(
                type === "LimitTime" && AllContracts.listBiddingsLimitTime && 
                AllContracts.listBiddingsLimitTime.length > 0
            ) {
                setListContracts([...AllContracts.listBiddingsLimitTime]);
            } else if (
                type === "Guarantee" && AllContracts.listBiddingsGuarantee && 
                AllContracts.listBiddingsGuarantee.length > 0
            ) {
                setListContracts([...AllContracts.listBiddingsGuarantee]);
            } else {
                setListContracts([]);
            }
        } else {
            setListContracts([]);
        }
    },[AllContracts]);

    const handleChangePage = (_,newValue) => {
        setPage(newValue);
    }

    return (
        <StyledRoot>
            <Box className="Topic">
                {type === "LimitTime" ?
                    <i className="fa-regular fa-clock icon" />
                    :
                    <i className="fa-regular fa-gavel icon" />
                }
                <Typography className="TopicText">{type === "LimitTime" ? "สัญญาจำกัดเวลา" : "สัญญาการันตีขั้นต่ำ"}</Typography>
            </Box>
            <Box style={{ minHeight: (listContracts > 3) ?  "694px" : "347px" }}>
                <Grid container spacing={3} style={{ height:"100%" }}>
                    {!isFetching && listContracts.length > 0 && listContracts.map((bidding, index) => {
                        if(index >= (6*(page - 1)) && index <= ((6*page)-1)){
                            return (
                                <Grid item xs={12} sm={6} md={4} key={bidding.idBiddings+""+index}>
                                    <CardBidding bidding={bidding} isContract={true} />
                                </Grid>
                            );
                        }
                    })}
                    {isFetching && 
                        <Grid item xs={12} style={{ height:"347px" }}>
                            <Box style={{ height:"100%", display:"flex", justifyContent:"center", alignItems:"center" }}>
                                <Typography variant="h5" textAlign="center">กำลังโหลดข้อมูล...</Typography>
                            </Box>
                        </Grid>
                    }
                    {!isFetching && listContracts.length === 0 && 
                        <Grid item xs={12} style={{ height:"347px" }}>
                            <Box style={{ height:"100%", display:"flex", justifyContent:"center", alignItems:"center" }}>
                                <Typography variant="h5" textAlign="center">
                                    {`ไม่พบสัญญา${type === "LimitTime" ? "จำกัดเวลา" : "การันตีขั้นต่ำ"}`}
                                </Typography>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
            {listContracts.length > 0 &&
                <Box 
                    style={{ 
                        display:"flex", 
                        justifyContent:"flex-end", 
                        marginTop:"20px",
                        marginRight: page === parseInt(((listContracts.length-1)/6)+1) ? "38px" : '0'
                    }}
                >
                    <Pagination 
                        count={parseInt(((listContracts.length-1)/6)+1)}
                        page={page}
                        onChange={handleChangePage}
                        hidePrevButton={page === 1}
                        hideNextButton={page === parseInt(((listContracts.length-1)/6)+1)}
                    />
                </Box>
            }
        </StyledRoot>
    );
};

export default BiddingContract;