import {
    WORKINGLOCATIONS_FETCHING,
    WORKINGLOCATIONS_FAILED,
    WORKINGLOCATIONS_SUCCESS
} from "./types";
import AddressService from "../services/address.service";

export const getAllWorkingLocations = () => async (dispatch) => {
    try {
        dispatch({
            type: WORKINGLOCATIONS_FETCHING
        });
        const res = await AddressService.getAllWorkingLocations();
        if(res){
            dispatch({
                type: WORKINGLOCATIONS_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: WORKINGLOCATIONS_FAILED
        });
    }
};