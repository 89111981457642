import {
	WORKINGLOCATIONS_FETCHING,
	WORKINGLOCATIONS_FAILED,
	WORKINGLOCATIONS_SUCCESS
} from "../actions/types";

const initialState = {
	result: null,
	isFetching: false,
	isError: false,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case WORKINGLOCATIONS_FETCHING:
			return { ...state, result: null, isFetching: true, isError: false };
		case WORKINGLOCATIONS_FAILED:
			return { ...state, result: null, isFetching: false, isError: true };
		case WORKINGLOCATIONS_SUCCESS:
			return { ...state, result: payload, isFetching: false, isError: false };
		default:
			return state;
	}
};