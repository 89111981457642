import { combineReducers } from "redux";
import auth from "./auth";
import userProfile from "./user-profile";
import users from "./user";

import vendorHomepage from "./vendor-homepage";
import vendorInformation from "./vendor-information";
import companyInformation from "./company-information";
import vendorSearch from "./vendor-search";
import companyManage from "./company-manage";

import jobs from "./jobs";
import jobForm from "./job-form";
import jobDetail from "./jobDetail";
import jobGroups from "./job-groups";

import userVendorFree from "./user-vendor-free";
import userProfileVendorManpower from "./user-profile-vendor-manpower";

import newCandidate from "./new-candidate";
import interviewedCandidate from "./interviewedCandidate";
import scheduleCandidates from "./schedule-candidates";

import bank from "./bank";

import vendorManage from "./vendor-manage";
import workingLocation from "./workingLocation";
import companys from "./companys";
import industries from "./industries";
import expertises from "./expertises";
import homepageCompany from "./homepage-company";
import homepageGuest from "./homepage-guest";
import homepageVendor from "./homepage-vendor";
import businessGroups from "./business-groups";

import signupCompanyForm from "./signup-company-form";
import favoriteVendors from "./favorite-vendors";
import vendorNotification from "./vendorNotification";

import jobEdit from "./jobEdit";
import candidateAlreadySent from "./candidate-already-sent";

import dashboardVendor from "./dashboard-vendor";
import biddingsMyJobs from "./biddings-myjobs";
import biddingVendors from "./bidding-vendors";
import mybiddingsVendor from "./mybiddings-vendor";
import historyBiddingsVendor from "./history-biddings-vendor";
import historyJobsCompany from "./history-jobs-company";
import passedCandidatesCompany from "./passed-candidates-company";
import historyBiddingsCompany from "./history-biddings-company";
import biddingInformation from "./bidding-information";
import biddingMinGuaranteeContract from "./bidding-min-guarantee-contract";
import mycontractBiddings from "./mycontract-biddings";
import anthrEmployees from "./anthr-employees";
import biddings from "./biddings";
import companyMyVendors from "./company-myvendors";
import manpowerProfile from "./manpower-profile";
import historyCandidatesJobsMatching from "./history-candidates-jobs-matching";
import vendorMyCompanys from "./vendor-mycompanys";
import historyManpowersSending from './history-manpowers-sending';
import contractCompany from "./contract-company";
import manpowerCertificate from "./manpower-certificate";
import contractVendor from "./contract-vendor";

import infoContract from "./dev-info-contract";

const appReducer = combineReducers({
  auth,
  users,
  userProfile,
  jobs,
  jobDetail,
  userVendorFree,
  vendorHomepage,
  userProfileVendorManpower,
  vendorInformation,
  companyInformation,
  vendorSearch,
  companyManage,
  bank,
  newCandidate,
  interviewedCandidate,
  scheduleCandidates,
  vendorManage,
  workingLocation,
  companys,
  industries,
  homepageGuest,
  homepageCompany,
  homepageVendor,
  expertises,
  jobForm,
  signupCompanyForm,
  businessGroups,
  jobGroups,
  favoriteVendors,
  vendorNotification,
  jobEdit,
  candidateAlreadySent,
  dashboardVendor,
  biddingsMyJobs,
  biddingVendors,
  mybiddingsVendor,
  historyBiddingsVendor,
  historyJobsCompany,
  passedCandidatesCompany,
  historyBiddingsCompany,
  biddingInformation,
  biddingMinGuaranteeContract,
  mycontractBiddings,
  anthrEmployees,
  biddings,
  companyMyVendors,
  manpowerProfile,
  historyCandidatesJobsMatching,
  vendorMyCompanys,
  historyManpowersSending,
  contractCompany,
  manpowerCertificate,
  infoContract,
  contractVendor
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
