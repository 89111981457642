import React from 'react';

import {
    Box,
    Dialog,
    DialogContent,
    Typography
} from '@mui/material';

import ButtonBlue from '../shared/general/ButtonBlue';

const DialogCancelled = (props) => {
    const { open, onClose, handleSubmit, candidate } = props;

    return (
        <Dialog
            open={open}
        >
            <DialogContent style={{ width:350 }}>
                <Typography variant="h5">ยืนยันการยกเลิก</Typography>
                <Box style={{ padding:"10px", marginBottom:"10px" }}>
                    <Typography variant="body1">{`ยืนยันยกเลิกการส่ง ${candidate.name} ${candidate.lastname}`}</Typography>
                </Box>
                <Box style={{ width:"100%", display:"flex", justifyContent:"flex-end" }}>
                    <ButtonBlue variant="outlined" onClick={onClose} style={{ marginRight:"10px" }}>ยกเลิก</ButtonBlue>
                    <ButtonBlue variant="contained" onClick={() => handleSubmit(candidate)}>ยืนยัน</ButtonBlue>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DialogCancelled;