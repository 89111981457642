import React, { useState } from "react";
import { Divider, Grid, styled, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerHeadOfficeAddress from "./drawerHeadOfficeAddress";
import DrawerBranchAddress from "./drawerBranchAddress";

import ModalConfirmDeleteBranch from "./modalConfirmDeleteBranch";
import { useDispatch } from "react-redux";
import { deleteBranchByBranchId } from "../../../../actions/vendorBranch";
import { getVendorManage } from "../../../../actions/vendor";

const StyledRoot = styled("div")({
  "& i": {
    marginRight: 8,
  },
  "& .header-text": {
    fontSize: 20,
  },
  "& .edit-button": {
    borderRadius: 19,
    "& i": {
      fontSize: 12,
    }
  },
  "& .MuiDivider-root": {
    borderWidth: "0px 0px thin",
    borderColor: "#919eab52",
    borderStyle: "dashed",
  },
  "& .delete-button": {
    color: "#d32f2f",
    borderColor: "#d32f2f",
    borderRadius: 19,
    "& i": {
      fontSize: 12,
    },
    "&:hover": {
      borderColor: "#d32f2f",
      borderRadius: 19,
    }
  }
})

const AddressPanel = (props) => {

  const { branches, vendor } = props;

  const [isOpenDrawerHeadOfficeAddress, setIsOpenDrawerHeadOfficeAddress] = useState(false);
  const [isOpenDrawerBranchAddressAdd, setIsOpenDrawerBranchAddressAdd] = useState(false);

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Typography className="header-text" variant="h7">สำนักงานใหญ่</Typography>
          <ButtonBlue className="edit-button" variant="outlined" size="small" onClick={()=>{setIsOpenDrawerHeadOfficeAddress(true)}}><i className="fa-regular fa-pen"></i>แก้ไข</ButtonBlue>
        </Grid>

        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="caption" color="text.secondary">
              ที่อยู่
            </Typography>
            <Typography>{vendor && vendor.address || "-"}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption" color="text.secondary">
              อำเภอ
            </Typography>
            <Typography>{vendor && vendor.district || "-"}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption" color="text.secondary">
              จังหวัด
            </Typography>
            <Typography>{vendor && vendor.province || "-"}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption" color="text.secondary">
              รหัสไปรษณีย์
            </Typography>
            <Typography>{vendor && vendor.areaCode || "-"}</Typography>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Typography variant="caption" color="text.secondary">
              ประเทศ
            </Typography>
            <Typography>{vendor && vendor.country || "-"}</Typography>
          </Grid> */}
        </Grid>
        
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Typography className="header-text" variant="h7">สาขาอื่นๆ ของบริษัท</Typography>
          <ButtonBlue variant="outlined" onClick={()=>{setIsOpenDrawerBranchAddressAdd(true)}}><i className="fa-regular fa-plus"></i>เพิ่มสาขา</ButtonBlue>
        </Grid>


        {branches.map((branch, index) => (
          <Grid key={`branch_${index}`} item xs={12}>
            <BranchContainer branch={branch} vendor={vendor}/>
          </Grid>
        ))}
      </Grid>

      {isOpenDrawerHeadOfficeAddress && (
        <DrawerHeadOfficeAddress
          open={isOpenDrawerHeadOfficeAddress}
          onClose={()=>{setIsOpenDrawerHeadOfficeAddress(false)}}
          vendor={vendor}
        />
      )}

      {isOpenDrawerBranchAddressAdd && (
        <DrawerBranchAddress
          open={isOpenDrawerBranchAddressAdd}
          onClose={()=>{setIsOpenDrawerBranchAddressAdd(false)}}
          vendor={vendor}
        />
      )}
    </StyledRoot>
  );
};

const BranchContainer = (props) => {

  const { branch, vendor } = props;

  const dispatch = useDispatch();

  const [isOpenDrawerBranch, setIsOpenDrawerBranch] = useState(false);

  const [isOpenModalBranchDelete, setIsOpenModalBranchDelete] = useState(false);

  const onDeleteBranch = async (branch) => {
    //ฟังก์ชันลบสาขา
    // setBranches(branches.filter((item, index) => deleteIndex !== index));
    const response = await dispatch(deleteBranchByBranchId(branch.idVendorBranch));
    if (response.status === 200){
      dispatch(getVendorManage());
      setIsOpenModalBranchDelete(false)
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} container justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Grid item>
          <Typography variant="caption" color="text.secondary">
            ชื่อสาขา
          </Typography>
          <Typography>{branch.branchName || "-"}</Typography>
        </Grid>
        <Grid item>
          <ButtonBlue className="edit-button" variant="outlined" size="small" onClick={()=>{setIsOpenDrawerBranch(true)}}><i className="fa-regular fa-pen"></i>แก้ไข</ButtonBlue>
          <ButtonBlue className="delete-button" variant="outlined" size="small" onClick={()=>{setIsOpenModalBranchDelete(true)}} style={{marginLeft: 8}}><i className="fa-regular fa-trash-can"></i>ลบ</ButtonBlue>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" color="text.secondary">
          ที่อยู่
        </Typography>
        {/* <Typography>39/30 ถนนเพชรเกษม</Typography> */}
        <Typography>{branch.address || "-"}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption" color="text.secondary">
          อำเภอ
        </Typography>
        {/* <Typography>บางแค</Typography> */}
        <Typography>{branch.district || "-"}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption" color="text.secondary">
          จังหวัด
        </Typography>
        {/* <Typography>กรุงเทพมหานคร</Typography> */}
        <Typography>{branch.province || "-"}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption" color="text.secondary">
          รหัสไปรษณีย์
        </Typography>
        {/* <Typography>10160</Typography> */}
        <Typography>{branch.areaCode || "-"}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption" color="text.secondary">
          ประเทศ
        </Typography>
        {/* <Typography>ประเทศไทย</Typography> */}
        <Typography>{branch.country || "-"}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption" color="text.secondary">
          ผู้ติดต่อ
        </Typography>
        {/* <Typography>หนองมน คนบ้านเดียวกัน</Typography> */}
        <Typography>{branch.mainContactName || "-"}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption" color="text.secondary">
          เบอร์โทรศัพท์
        </Typography>
        {/* <Typography>0999999999</Typography> */}
        <Typography>{branch.mainContactPhone || "-"}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption" color="text.secondary">
          อีเมล
        </Typography>
        {/* <Typography>nongmon@gmail.com</Typography> */}
        <Typography>{branch.mainContactEmail || "-"}</Typography>
      </Grid>

      {isOpenDrawerBranch && (
        <DrawerBranchAddress
          open={isOpenDrawerBranch}
          onClose={()=>{setIsOpenDrawerBranch(false)}}
          isEdit
          branch={branch}
          vendor={vendor}
        />
      )}

      {isOpenModalBranchDelete && (
        <ModalConfirmDeleteBranch
          open={isOpenModalBranchDelete}
          onClose={()=>{setIsOpenModalBranchDelete(false)}}
          onConfirm={()=>{onDeleteBranch(branch)}}
        />
      )}
    </Grid>
  )
}


export default AddressPanel;
