import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import { 
    Avatar,
    Box, 
    Button, 
    Card,
    CardHeader,
    Checkbox,
    Divider,
    Grid, 
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    styled, 
    Tooltip, 
    Typography 
} from "@mui/material";

import { 
    Group,
    Search 
} from "@mui/icons-material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from '../../shared/general/Card';
import DialogConfirmSendingBidding from "./dialogConfirmSendingBidding"
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const StyledContent = styled("div")({
  padding: 36,
  paddingTop: 24,
  "& .part-one": {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 8,
    ["@media only screen and (max-width: 600px)"]: {
      flexDirection: "column",
    },
    "& .detail": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "& .start": {
        "& .position": {
          marginBottom: 12,
        },
        "& div": {
          marginRight: 32,
          ["@media only screen and (max-width: 600px)"]: {
            marginRight: 0,
          },
        },
      },
      "& .end": {
        textAlign: "end",
      },
      ["@media only screen and (max-width: 600px)"]: {
        flexDirection: "column",
        marginTop: 16,
      },
    },
    "& .MuiAvatar-root": {
      width: 120,
      height: 120,
      fontSize: 42,
      marginRight: 36,
      borderRadius: 12,
      backgroundColor: "white",
      color: "inherit",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
    },
    "& .MuiTypography-caption": {
      fontSize: 14,
    },
  },
  "& .part-two": {
    display: "flex",
    justifyContent: "end",
  },
  "& .wrap-item": {
    "& .label": {
      fontWeight: 400,
      fontSize: 16,
      marginBottom: 4,
      "& span": {
        marginLeft: 16,
      },
    },
    "& .value": {
      fontSize: 18,
      marginLeft: 32,
    },
    "& .fal": {
      marginRight: 8,
    },
  },
  "&.selected-candidate": {
    minHeight: 200,
    "& .part-one": {
      marginBottom: 16,
    },
    "& .wrap-selected-candidate-list": {
      display: "grid",
      columnGap: 16,
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    "& .candidate-id": {
      "& .MuiTypography-h6": {
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    "& .date-appointment": {
      marginTop: 8,
      display: "flex",
      flexDirection: "column",
    },
  },
  "&.candidate": {
    minHeight: 200,
    "& .part-one": {
      marginBottom: 16,
      display: "flex",
      justifyContent: "space-between",
    },
    "& .wrap-candidate-list": {
      display: "grid",
      columnGap: 16,
      gridTemplateColumns: "repeat(4, 1fr)",
      "& .candidate-id": {
        "& .MuiTypography-h6": {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
  },
  "& .MuiInputBase-root": {
    borderRadius: "16px !important",
  },
  "& .part-company": {
    padding: "32px 0",
    "& .MuiAvatar-root": {
      width: 56,
      height: 56,
      backgroundColor: "white",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
    },
    "& .MuiTypography-root": {
      fontSize: 20,
    },
  },
  "& .attach-file-container": {
    marginLeft: 32,
    display: "flex",
    gap: 16,
    "& i": {
      marginLeft: 8,
      color: "#919eab",
    },
  },
});

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}
  
function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const CardSendManpower = (props) => {
  const { type, id, listManpowers, listPositions, listManpowersFromManpowersPage, handleOnSubmit } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    if(listManpowers){
        const temp = {};
  
        listManpowers.map(m => {temp[m.idManpower] = m});
        listManpowersFromManpowersPage.map(m => {temp[m.idManpower] = m});
  
        const array = Object.keys(temp).map(m => {return temp[m]})
  
        setLeft([...array]);
    }
  },[listManpowers]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const ResetRight = () => {
    setRight([]);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const CustomList = ({ title, items }) => {
    const [search, setSearch] = useState("");

    return (
      <Box style={{ width: "350px", minHeight: "100%", height: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <TextFieldTheme
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="ค้นหา"
            style={{
              borderRadius: "16px",
              width: title === "เลือกกำลังพล" ? "calc(100% - 50px)" : "100%",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          {title === "เลือกกำลังพล" && (
            <Tooltip title="ไปที่กำลังพล" placement="top">
              <Box style={{ marginLeft: "10px" }}>
                <ButtonBlue
                  variant="contained"
                  style={{
                    width: "fit-content",
                    minWidth: 0,
                    padding: 12,
                    borderRadius: "16px",
                  }}
                  component={Link}
                  to={{
                    pathname: "/manpower",
                    state: {
                      type: type,
                      id: id
                    },
                  }}
                >
                  <Group />
                </ButtonBlue>
              </Box>
            </Tooltip>
          )}
        </div>
        <Box style={{ height: "calc(100% - 70px)" }}>
          <Card
            variant="outlined"
            style={{ width: 350, height: "100%", borderRadius: 20 }}
          >
            <CardHeader
              sx={{ px: 2, py: 1 }}
              avatar={
                <Checkbox
                  onClick={handleToggleAll(items)}
                  checked={
                    numberOfChecked(items) === items.length &&
                    items.length !== 0
                  }
                  indeterminate={
                    numberOfChecked(items) !== items.length &&
                    numberOfChecked(items) !== 0
                  }
                  disabled={items.length === 0}
                  inputProps={{
                    "aria-label": "all items selected",
                  }}
                />
              }
              title={title}
              subheader={`เลือก ${numberOfChecked(items)}/${items.length} คน`}
            />
            <Divider />
            <List
              sx={{
                width: 350,
                minHeight: "fit-content",
                height: "100%",
                maxHeight: 600,
                bgcolor: "background.paper",
                overflow: "auto",
              }}
              dense
              component="div"
              role="list"
            >
              {items && items.length > 0 ? (
                items
                  .filter((value) => {
                    return (
                      value.name.includes(search) ||
                      value.lastname.includes(search)
                    );
                  })
                  .map((value, index) => {
                    const labelId = `transfer-list-all-item-${value.idManpower}-label`;
                    return (
                      <ListItem
                        key={value.idManpower}
                        role="listitem"
                        button
                        onClick={handleToggle(value)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={checked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemAvatar>
                          <Avatar
                            alt={`Avatar ${index + 1}`}
                            src={`${
                              process.env.REACT_APP_API_URL
                            }image/profile/${
                              parseInt(Math.random() * 20) + 1
                            }.jpg`}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          id={labelId}
                          primary={`${value.name} ${value.lastname}`}
                          secondary={value.telephoneMobile}
                        />
                      </ListItem>
                    );
                  })
              ) : (
                <ListItem>
                  <ListItemText
                    primary={`ไม่พบรายชื่อพนักงานที่พร้อม`}
                    primaryTypographyProps={{
                      align: "center",
                    }}
                  />
                </ListItem>
              )}
              <ListItem />
            </List>
          </Card>
        </Box>
      </Box>
    );
  };

  return (
    <CardStyle style={{ marginTop:"20px" }}>
      <StyledContent className={`selected-candidate`}>
        <div className={`part-one`}>
          <Typography variant="h5">เลือกกำลังพล</Typography>
        </div>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <CustomList title="เลือกกำลังพล" items={left} />
          </Grid>
          <Grid item>
            <Box
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{ my: 0.5 }}
                style={{ width: "55px" }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                style={{ width: "55px" }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Box>
          </Grid>
          <Grid item>
            <CustomList title="คนที่จะส่ง" items={right} />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: 36,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ButtonBlue
            variant="contained"
            startIcon={<i className="fal fa-paper-plane"></i>}
            disabled={right.length === 0}
            onClick={() => {
              if (type === 'job') {
                handleOnSubmit(right, ResetRight);
              } else {
                setOpenDialog(true);
              }
            }}
          >
            ส่งกำลังพลที่เลือก
          </ButtonBlue>
        </div>
        {openDialog && 
          <DialogConfirmSendingBidding 
            open={openDialog}
            onClose={handleCloseDialog}
            listSelected={right}
            listPositions={listPositions}
            handleOnSubmit={(data) => {
              handleOnSubmit(data, () => {
                ResetRight();
                setOpenDialog(false);
              })
            }}
          />
        }
      </StyledContent>
    </CardStyle>
  );
};

export default CardSendManpower;
