import {
    FAVORITE_VENDORS_FETCHING,
    FAVORITE_VENDORS_FAILED,
    FAVORITE_VENDORS_SUCCESS
} from "./types";
import FavoriteService from "../services/favoriteVendor.service";

export const getAllFavoriteVendors = () => async (dispatch) => {
    try {
        dispatch({
            type: FAVORITE_VENDORS_FETCHING
        });
        const res = await FavoriteService.getAllFavoriteVendors();
        if(res) {
            dispatch({
                type: FAVORITE_VENDORS_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: FAVORITE_VENDORS_FAILED
        });
    }
};

export const addFavoriteVendor = (idVendor) => async () => {
    try {
        const res = await FavoriteService.addFavoriteVendor(idVendor);
        if (res) {
            return res.data;
        }
    } catch (error) {
        const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
        return error;
    }
};

export const deleteFavoriteVendor = (idVendor) => async () => {
    try {
        const res = await FavoriteService.deleteFavoriteVendor(idVendor);
        if (res) {
            return res.data;
        }
    } catch (error) {
        const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
        return error;
    }
};