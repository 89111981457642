import React, { useEffect, useState } from 'react';
import {
    useDispatch, useSelector
} from "react-redux";
import { useHistory } from 'react-router-dom';

import {
    Box,
    Container,
    Grid,
    styled,
    Typography
} from "@mui/material";

import ButtonBlue from '../../shared/general/ButtonBlue';
import CardBidding from "../shared/CardBidding";
import TextFieldTheme from '../../shared/general/TextFieldTheme';

import { getMyBiddingsByVendor } from '../../../../actions/bidding';

const StyledRoot = styled("div")({
    "& .header-text": {
        marginBottom: 24,
    },
});

const VendorBidding = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isFetching: BiddingFetching, result: AllBiddings } = useSelector((state) => state.mybiddingsVendor);
    const [search, setSearch] = useState("");
    const [listBiddings, setListBiddings] = useState([]);

    useEffect(() => {
        dispatch(getMyBiddingsByVendor());
    },[]);

    useEffect(() => {
        if (AllBiddings) {
            let tempList = AllBiddings.filter((bidding) => {
                if (bidding.biddingName.includes(search) || bidding.companyName.includes(search)) {
                    return true;
                } else {
                    return false;   
                }
            });
            setListBiddings([...tempList]);
        }
    },[AllBiddings, search]);

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Typography className="header-text" variant="h4">
                    การประมูลของฉัน
                </Typography>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <TextFieldTheme
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="ค้นหา..."
                        />
                    </Grid>
                    <Grid item>
                        <ButtonBlue
                            variant="contained"
                            style={{ padding:"10px 20px" }}
                            onClick={() => history.push("/vendor-history-biddings")}
                        >
                            <i 
                                className="fa-solid fa-clock" 
                                style={{ marginRight:"10px", fontSize:"20px" }} 
                            />
                            <Typography style={{ fontSize:"16px" }}>ประวัติการประมูล</Typography>
                        </ButtonBlue>
                    </Grid>
                </Grid>
                <Box style={{ margin:"40px 0 20px" }}>
                    <Grid container spacing={3}>
                        {!BiddingFetching && listBiddings.length > 0 && listBiddings.map((bidding) => (
                            <Grid item xs={12} sm={6} md={4} key={bidding.idBiddings}>
                                <CardBidding bidding={bidding} />
                            </Grid>
                        ))}
                        {BiddingFetching && <Grid item xs={12}><Typography variant="h5" textAlign="center">กำลังโหลดข้อมูล...</Typography></Grid>}
                        {!BiddingFetching && listBiddings.length === 0 && <Grid item xs={12}><Typography variant="h5" textAlign="center">ไม่พบการประมูล</Typography></Grid>}
                    </Grid>
                </Box>
            </Container>
        </StyledRoot>
    );
};

export default VendorBidding;