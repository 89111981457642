import { httpClient } from "./httpClient";

const getContractCompany = (query) => {
  return httpClient.get(`/contracts/company`, {params: query});
};

const addContractCompany = (formData) => {
  return httpClient.post(`/contracts/company`, formData);
};

const deleteContractCompany = (idContractCompany) => {
  return httpClient.delete(`/contracts/company/${idContractCompany}`);
};

const getContractVendor = (query) => {
  return httpClient.get(`/contracts/vendor`, {params: query});
};

const addContractVendor = (formData) => {
  return httpClient.post(`/contracts/vendor`, formData);
};

const deleteContractVendor = (idContractVendor) => {
  return httpClient.delete(`/contracts/vendor/${idContractVendor}`);
};

export default {
  getContractCompany,
  addContractCompany,
  deleteContractCompany,
  getContractVendor,
  addContractVendor,
  deleteContractVendor
};