import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import {
    Box,
    Container,
    Grid,
    Pagination,
    styled,
    Typography
} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import CardBidding from "../shared/CardBidding";
import TextFieldTheme from '../../shared/general/TextFieldTheme';

import { getHistoryBiddingsVendor } from '../../../../actions/bidding';

const StyledRoot = styled("div")({
    "& .header-text": {
        marginBottom: 24,
    },
});

const GridLoadingAndNotFound = styled(Grid)({
    height:"400px", 
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center"
});

const BiddingsComponent = ({ type }) => {
    const { isFetching, result: AllBiddings } = useSelector(state => state.historyBiddingsVendor);
    const [search, setSearch] = useState("");
    const [yearBidding, setYearBidding] = useState(null);
    const [listBiddings, setListBiddings] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (AllBiddings) {
            let tempList;
            if (type === "success") {
                tempList = AllBiddings.listBiddingsSuccess.filter(bidding => {
                    if (bidding.biddingName.includes(search) || bidding.companyName.includes(search)) {
                        if (yearBidding) {
                            if (dayjs(yearBidding).isSame(dayjs(bidding.biddingAt),'year')) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    } else {
                        return false;   
                    }
                })
            } else {
                tempList = AllBiddings.listBiddingsFailure.filter(bidding => {
                    if (bidding.biddingName.includes(search) || bidding.companyName.includes(search)) {
                        if (yearBidding) {
                            if (dayjs(yearBidding).isSame(dayjs(bidding.biddingAt),'year')) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    } else {
                        return false;   
                    }
                })
            }
            setListBiddings([...tempList]);
        }
    },[AllBiddings, search, yearBidding]);

    const handleChangePage = (event, newValue) => {
        setPage(newValue);
    };

    return (
        <Fragment>
            <Typography variant="h4" style={{ marginBottom:"20px", marginTop: type === "success" ? "0" : "20px" }}>
                {`ประวัติการประมูล${type === "success" ? "สำเร็จ" : "ไม่สำเร็จ"}`}
            </Typography>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <TextFieldTheme
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="ค้นหา..."
                    />
                </Grid>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="ปีที่ประมูล"
                            inputFormat="YYYY"
                            views={["year"]}
                            value={yearBidding}
                            onChange={(e) => setYearBidding(e)}
                            renderInput={(params) => <TextFieldTheme {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Box style={{ margin:"20px 0", minHeight: listBiddings.length > 3 ?  "908px" : "455px" }}>
                <Grid container spacing={3}>
                    {!isFetching && listBiddings.length > 0 && listBiddings.map((bidding, index) => {
                        if (index >= (6*(page - 1)) && index <= ((6*page)-1)) {
                            return (
                                <Grid item xs={12} sm={6} md={4} key={bidding.idBiddings}>
                                    <CardBidding bidding={bidding} isHistory={true} />
                                </Grid>
                            )
                        }
                    })}
                    {isFetching &&
                        <GridLoadingAndNotFound item xs={12}>
                            <Typography variant="h5" textAlign="center">กำลังโหลดข้อมูล...</Typography>
                        </GridLoadingAndNotFound>
                    }
                    {!isFetching && listBiddings.length === 0 &&
                        <GridLoadingAndNotFound item xs={12}>
                            <Typography variant="h5" textAlign="center">
                                {`ไม่พบประวัติการประมูล${type === "success" ? "สำเร็จ" : "ไม่สำเร็จ"}`}
                            </Typography>
                        </GridLoadingAndNotFound>
                    }
                </Grid>
            </Box>
            {(parseInt(listBiddings.length/6) > 0) &&
                <Box 
                    style={{ 
                        display:"flex", 
                        justifyContent:"flex-end", 
                        marginRight: page === parseInt(((listBiddings.length-1)/6)+1) ? "38px" : '0'
                    }}
                >
                    <Pagination 
                        count={parseInt(((listBiddings.length-1)/6)+1)}
                        page={page}
                        onChange={handleChangePage}
                        hidePrevButton={page === 1}
                        hideNextButton={page === parseInt(((listBiddings.length-1)/6)+1)}
                    />
                </Box>
            }
        </Fragment>
    );
};

const VendorHistoryBidding = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHistoryBiddingsVendor());
    },[]);

    return (
        <StyledRoot className='page'>
            <Container maxWidth="lg">
                <BiddingsComponent type="success" />
                <BiddingsComponent type="failure" />
            </Container>
        </StyledRoot>
    );
};

export default VendorHistoryBidding;