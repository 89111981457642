import { Accordion, AccordionDetails, AccordionSummary, Box, Container, styled, Typography } from "@mui/material";
import React from "react";

import { ExpandMore } from '@mui/icons-material';

import CardStyle from "../../../shared/general/Card";
import ChangePassword from "./changePassword";
import ChangeEmail from "./changeEmail";

const StyledRoot = styled("div")({
  "& .card-container": {
    padding: 36,
  }
});

const StyledAccordion = styled(Accordion)({
  // backgroundColor:"red",
  borderRadius: 8
});

const AccountSettingPage = () => {
  return (
    <StyledRoot className="page">
      <Container className="lg">
        <CardStyle>
          <div className="card-container">
            <Typography variant="h5">
              <span style={{paddingRight: 8}}><i className="fa-solid fa-gear" ></i></span>
              ตั้งค่าบัญชี
            </Typography>
            <Box style={{ marginTop: 16 }}>
              <StyledAccordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                >
                  <Typography>เปลี่ยนอีเมล</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box style={{ padding:"0 8px" }}>
                    <ChangeEmail />
                  </Box>
                </AccordionDetails>
              </StyledAccordion>
              <StyledAccordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                >
                  <Typography>เปลี่ยนรหัสผ่าน</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box style={{ padding:"0 8px" }}>
                    <ChangePassword />
                  </Box>
                </AccordionDetails>
              </StyledAccordion>
            </Box>
          </div>
        </CardStyle>
      </Container>
    </StyledRoot>
  )
}

export default AccountSettingPage;