import {
  DASHBOARD_VENDOR_FETCHING,
  DASHBOARD_VENDOR_FAILED,
  DASHBOARD_VENDOR_SUCCESS,
} from "./types";
import DashboardService from "../services/dashboard.service";

export const getDashboardVendor = () => async (dispatch) => {
  try {
      dispatch({
          type: DASHBOARD_VENDOR_FETCHING
      });
      const res = await DashboardService.getDashboardVendor();
      if (res) {
          dispatch({
              type: DASHBOARD_VENDOR_SUCCESS,
              payload: res.data
          });
      }
  } catch (error) {
      dispatch({
          type: DASHBOARD_VENDOR_FAILED
      });
  }
};