import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMyVendors } from "../../../../actions/adminCompany";
import MyVendorCard from "./myVendorCard";
import { getCompanyManage } from "../../../../actions/company";

import ContractServiceBtn from "./generateFiles/contractService";
import CertificateEmp from "./generateFiles/certificateEmp";
import CertificateSalary from "./generateFiles/certificateSalary";

const StyledRoot = styled(Box)({});

const CompanyMyVendorsPage = () => {
  const dispatch = useDispatch();

  const { result: vendors } = useSelector((state) => state.companyMyVendors);

  useEffect(() => {
    dispatch(getMyVendors());
  }, [dispatch]);

  return (
    <Box className="page">
      <StyledRoot>
        <Container maxWidth="lg">
          <Box display="flex" paddingBottom="48px">
            <Typography flexGrow={1} variant="h5">
              คู่ธุรกิจของฉัน
            </Typography>
            {vendors && vendors.data && vendors.data && (
              <Typography variant="h5">{`${vendors.data.length} คู่ธุรกิจ`}</Typography>
            )}
          </Box>
          {/* <Box sx={{ display: "flex" }}>
            <ContractServiceBtn />
            <CertificateEmp />
            <CertificateSalary />
          </Box> */}
          <Grid container spacing={2}>
            {vendors &&
              vendors.data &&
              vendors.data.map((vendor, index) => (
                <Grid key={index} item xs={12} md={4}>
                  <MyVendorCard vendor={vendor} />
                </Grid>
              ))}
          </Grid>
        </Container>
      </StyledRoot>
    </Box>
  );
};

export default CompanyMyVendorsPage;
