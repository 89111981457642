import { httpClient } from "./httpClient";

const getAllVendor = (query) => {
  return httpClient.get("vendors", {params: query});
};

const getVendorByNameShort = (vendorNameShort) => {
  return httpClient.get(`vendors/${vendorNameShort}`);
};

const getVendorManage = () => {
  return httpClient.get(`vendors/manage`);
};

const updateVendorManage = (formData) => {
  return httpClient.patch(`vendors/manage`, formData);
};

const updateVendorManageBankDetail = (formData) => {
  return httpClient.patch(`vendors/manage/bankdetail`, formData);
};

const updateVendorManageJobGroup = (formData) => {
  return httpClient.put(`vendors/manage/jobGroup`, formData)
};

const addVendorManageBranch = (formData) => {
  return httpClient.post(`vendors/manage/branch`, formData);
};

const addVendorLogo = (formData) => {
  return httpClient.post(`vendors/manage/logo`, formData);
};

const deleteVendorLogo = () => {
  return httpClient.delete(`vendors/manage/logo`);
};

const addVendorAuthorizedSignatureOne = (formData) => {
  return httpClient.post(`vendors/manage/authorizedSignatureOne`, formData);
};

const deleteVendorAuthorizedSignatureOne = () => {
  return httpClient.delete(`vendors/manage/authorizedSignatureOne`);
};

const addVendorAuthorizedSignatureTwo = (formData) => {
  return httpClient.post(`vendors/manage/authorizedSignatureTwo`, formData);
};

const deleteVendorAuthorizedSignatureTwo = () => {
  return httpClient.delete(`vendors/manage/authorizedSignatureTwo`);
};

const addVendorWitnessSignature = (formData) => {
  return httpClient.post(`vendors/manage/witnessSignature`, formData);
};

const deleteVendorWitnessSignature = () => {
  return httpClient.delete(`vendors/manage/witnessSignature`);
};

export default {
  getAllVendor,
  getVendorByNameShort,
  getVendorManage,
  updateVendorManage,
  updateVendorManageBankDetail,
  updateVendorManageJobGroup,
  addVendorManageBranch,
  addVendorLogo,
  deleteVendorLogo,
  addVendorAuthorizedSignatureOne,
  deleteVendorAuthorizedSignatureOne,
  addVendorAuthorizedSignatureTwo,
  deleteVendorAuthorizedSignatureTwo,
  addVendorWitnessSignature,
  deleteVendorWitnessSignature,
};