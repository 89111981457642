import React from "react";
import { Avatar, styled, Typography } from "@mui/material";

const StyledRoot = styled("div")({

})

const StyledTable = styled("table")({
  width: "100%",
  tableLayout: "fixed",
  "& th": {
    "& .MuiTypography-root": {
      fontWeight: 500
    }
  },
  "& td, & th": {
    padding: "16px 0",
    borderBottom: "solid 1px #ececec"
  },
  "& .col-fullname": {
    width: "70%",
    textAlign: "left",
    "& .fullname-container": {
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-root": {
        flexGrow: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        paddingLeft: 8,
      }
    },
  },
  "& .col-expertise": {
    width: "30%",
    textAlign: "right",
    "& .MuiTypography-root": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    }
  },
})

const ManpowerDasboard = (props) => {

  const { value } = props;

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <StyledRoot>
      <StyledTable>
        <thead>
          <tr>
            <th className="col-fullname">
              <Typography>ชื่อ-สกุล</Typography>
            </th>
            <th className="col-expertise">
              <Typography>ความถนัด</Typography>
            </th>
          </tr>
        </thead>
        <tbody>

          {value.map((m, i) => (
            <tr key={`manpower_${i}`}>
              <td className="col-fullname">
                <div className="fullname-container">
                  <Avatar
                    {...stringAvatar(m.manpowerName)}
                  />
                  <Typography>{m.manpowerName}</Typography>
                </div>
              </td>
              <td className="col-expertise">
                <Typography>{m.expertiseName}</Typography>
              </td>
            </tr>
          ))}
          {/* <tr>
            <td className="col-fullname">
              <div className="fullname-container">
                <Avatar
                  {...stringAvatar('หนองหาญ กวาดลานวัด')}
                />
                <Typography>{`หนองหาญ กวาดลานวัด`}</Typography>
              </div>
            </td>
            <td className="col-expertise">
              <Typography>{`งานบริการ`}</Typography>
            </td>
          </tr>
          <tr>
            <td className="col-fullname">
              <div className="fullname-container">
                <Avatar
                  {...stringAvatar('หนองไหล พบหมอด่วน')}
                />
                <Typography>{`หนองไหล พบหมอด่วน`}</Typography>
              </div>
            </td>
            <td className="col-expertise">
              <Typography>{`งานบริการ`}</Typography>
            </td>
          </tr> */}
        </tbody>
      </StyledTable>
    </StyledRoot>
  )
}

export default ManpowerDasboard;