import {
  VENDOR_HOMEPAGE_FETCHING,
  VENDOR_HOMEPAGE_FAILED,
  VENDOR_HOMEPAGE_SUCCESS,
  VENDOR_INFORMATION_FETCHING,
  VENDOR_INFORMATION_FAILED,
  VENDOR_INFORMATION_SUCCESS,
  VENDOR_SEARCH_FETCHING,
  VENDOR_SEARCH_FAILED,
  VENDOR_SEARCH_SUCCESS,
  VENDOR_MANAGE_FETCHING,
  VENDOR_MANAGE_FAILED,
  VENDOR_MANAGE_SUCCESS,
} from "./types";
import VendorService from "../services/vendor.service";

export const getVendorHomepage = () => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_HOMEPAGE_FETCHING
    });
    const res = await VendorService.getAllVendor({ limit: 3 });
    if (res) {
      dispatch({
        type: VENDOR_HOMEPAGE_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: VENDOR_HOMEPAGE_FAILED
    });
  }
};

export const getVendorByNameShort = (vendorNameShort) => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_INFORMATION_FETCHING
    });
    const res = await VendorService.getVendorByNameShort(vendorNameShort);
    if (res) {
      dispatch({
        type: VENDOR_INFORMATION_SUCCESS,
        payload: res.data
      });
      return res
    }
  } catch (error) {
    dispatch({
      type: VENDOR_INFORMATION_FAILED
    });
    return error.response;
  }
};

export const getVendorSearch = (query) => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_SEARCH_FETCHING
    });
    const res = await VendorService.getAllVendor(query);
    if (res) {
      dispatch({
        type: VENDOR_SEARCH_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: VENDOR_SEARCH_FAILED
    });
  }
};

export const getVendorManage = () => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_MANAGE_FETCHING
    });
    const res = await VendorService.getVendorManage();
    if (res) {
      dispatch({
        type: VENDOR_MANAGE_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: VENDOR_MANAGE_FAILED
    });
  }
};

export const updateVendorManage = (formData) => async () => {
  try {
    const res = await VendorService.updateVendorManage(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateVendorManageBankDetail = (formData) => async () => {
  try {
    const res = await VendorService.updateVendorManageBankDetail(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateVendorManageJobGroup = (formData) => async () => {
  try {
    const res = await VendorService.updateVendorManageJobGroup(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    // const message =
    // (error.response && error.response.data && error.response.data.name) ||
    // error.name ||
    // error.toString();
    return "Error";
  }
};

export const addVendorManageBranch = (formData) => async () => {
  try {
    const res = await VendorService.addVendorManageBranch(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addVendorLogo = (formData) => async () => {
  try {
    const res = await VendorService.addVendorLogo(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteVendorLogo = () => async () => {
  try {
    const res = await VendorService.deleteVendorLogo();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addVendorAuthorizedSignatureOne = (formData) => async () => {
  try {
    const res = await VendorService.addVendorAuthorizedSignatureOne(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteVendorAuthorizedSignatureOne = () => async () => {
  try {
    const res = await VendorService.deleteVendorAuthorizedSignatureOne();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addVendorAuthorizedSignatureTwo = (formData) => async () => {
  try {
    const res = await VendorService.addVendorAuthorizedSignatureTwo(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteVendorAuthorizedSignatureTwo = () => async () => {
  try {
    const res = await VendorService.deleteVendorAuthorizedSignatureTwo();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addVendorWitnessSignature = (formData) => async () => {
  try {
    const res = await VendorService.addVendorWitnessSignature(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteVendorWitnessSignature = () => async () => {
  try {
    const res = await VendorService.deleteVendorWitnessSignature();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};