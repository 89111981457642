import React, { lazy, Suspense } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Container from "@mui/material/Container";

import Login from "./components/pages/login/LoginPage";
import Landing from "./components/pages/landing";
import Register from "./components/pages/register";
import { UnauthorizedPage, NotFoundPage } from "./components/pages/error";

import ContactUsPage from "./components/pages/contactus";
import CompanySignUp from "./components/pages/signup/company";
import CompanyInformationPage from "./components/pages/information/company/company";
import VendorSignUp from "./components/pages/signup/vendor";
import VendorInformationPage from "./components/pages/information/vendor/vendor";
import CreatePassword from "./components/pages/signup/createPassword";
import ForgetPasswordPage from "./components/pages/user/account/setting/forget_password";
import ResetPasswordPage from "./components/pages/user/account/setting/reset_password";
import FindProvincePage from "./components/pages/user/province";
import ProvinceMenuPage from "./components/pages/user/province/menu";
import BiddingSearchPage from "./components/pages/user/bidding-search";
import SetPasswordAdminCompany from "./components/pages/user/account/setting/set_password_admin_company";
import SetPasswordAdminVendor from "./components/pages/user/account/setting/set_password_admin_vendor";


//User
import ProfilePage from "./components/pages/user/profile";
import MePage from "./components/pages/user/me";
import ViewProfile from "./components/pages/user/profile/ViewProfile";
import Notification from "./components/pages/NotificationPage";
import Homepage from "./components/pages/homePage";
import VendorSuggestionPage from "./components/pages/vendor-suggestion";
import RequestInformationPage from "./components/pages/information/request";
import BiddingInformationPage from "./components/pages/information/bidding/bidding";
import JobSearch from "./components/pages/user/job-search";
import AccountSettingPage from "./components/pages/user/account/setting";
import WaitingUpdateNewEmail from "./components/pages/user/account/setting/waiting_update_newEmail";

//Company
import CompanySearchPage from "./components/pages/user/company-search";
import CompanyManagePage from "./components/pages/user/company-manage";
import CompanyMyJobPage from "./components/pages/user/company-myjob";
import CompanyJobFormPage from "./components/pages/user/company-myjob/jobForm";
import CompanyBiddingFormPage from "./components/pages/user/company-myjob/biddingForm";
import CompanyCandidatePage from "./components/pages/user/company-candidate";
import CompanyInterviewPage from "./components/pages/user/company-interview";
import CompanyFavorite from "./components/pages/user/company-favorite";
import CompanyBidding from "./components/pages/user/company-bidding";
import CompanyHistoryJob from "./components/pages/user/company-history-job";
import CompanyMyContract from "./components/pages/user/company-mycontract";
import CompanyMyVendorsPage from "./components/pages/company/myvendors";
import CompanyConfirmChildCompany from './components/pages/company/company-confirmchildcompany';

//Vendor
import VendorSearchPage from "./components/pages/user/vendor-search";
import VendorManagePage from "./components/pages/user/vendor-manage";
import VendorManpowerPage from "./components/pages/user/vendor-manpower";
import VendorManpowerFormPage from "./components/pages/user/vendor-manpower/form";
import VendorManpowerProfilePage from "./components/pages/user/vendor-manpower/profile";
import VendorDashboardPage from "./components/pages/user/vendor-dashboard";
import VendorInterviewPage from "./components/pages/user/vendor-interview/index";
import VendorBiddingPage from "./components/pages/user/vendor-mybidding";
import VendorHistoryBidding from "./components/pages/user/vendor-history-bidding";
import VendorMyContract from "./components/pages/user/vendor-mycontract";
import VendorMatchingPage from "./components/pages/user/vendor-matching";
import VendorMyCompany from "./components/pages/user/vendor-mycompany";
import VendorHistorySending from "./components/pages/user/vendor-history-sending";

// Admin
import {
  AdminDashboardCost,
  AdminDashboardOt,
  AdminDashboardWorkingTime
} from "./components/pages/admin";

import AddManpowerFormPage from "./components/pages/user/vendor-manpower/addManpower";
import RegisterManpower from "./components/pages/user/vendor-manpower/registerManpower";

const useStyles = makeStyles((theme) => ({
  content: {
    // flexGrow: 1,
    // display: "flex",
    // justifyContent: "center",
    // padding: 0,
  },
}));

const Routers = () => {
  const classes = useStyles();
  const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const RedirectLogin = () => (
    <Route>
      <Redirect to="/login" />
    </Route>
  );

  const AdminRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const CompanyRoute = ({ component: Component, ...rest }) => {
    if (isLoggedIn && userProfile && userProfile.idRoles === 3) {
      return (
        <Route {...rest} render={(props) => <Component {...props} />}/>
      );
    } else {
      return <Redirect to="/" />
    }
  };

  const AdminVendorRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_VENDOR") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const ManagerRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_MANAGER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const UserRoute = ({ component: Component, ...rest }) => {
    // if (currentUser) {
    //   return (
    //     <Route
    //       {...rest}
    //       render={(props) =>
    //         currentUser.roles.includes("ROLE_USER") === true ? (
    //           <Component {...props} />
    //         ) : (
    //           <Redirect to="/unauthorized" />
    //         )
    //       }
    //     />
    //   );
    // } else {
    //   return <RedirectLogin />;
    // }
    return (
      <Route
        {...rest}
        render={(props) =>
          userProfile ? (
            <Component {...props} />
          ) : (
            <Redirect to="/unauthorized" />
          )
        }
      />
    );
  };

  // const CompanyRoute = ({ component: Component, ...rest }) => {
  //     return (
  //       <Route
  //         {...rest}
  //         render={(props) =>
  //           userProfile && userProfile.rolesName === "ROLE_ADMIN" ? (
  //             <Component {...props} />
  //           ) : (
  //             <Redirect to="/unauthorized" />
  //           )
  //         }
  //       />
  //     );
  // };

  const VendorRoute = ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            userProfile && userProfile.rolesName === "ROLE_VENDOR" ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
  };

  const SignUpRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            userProfile && userProfile.idCompany !== null && userProfile.idCompany !== undefined && userProfile.idCompany > 0 ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const WarehouseRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_WAREHOUSE") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  return (
    <Container maxWidth={false} style={{ padding: 0, flexGrow: 1 }}>
      <Suspense fallback={<div>Loading..</div>}>
        <Switch>
          {/* <Route
            exact
            path={["/"]}
            component={() =>
              currentUser ? <Redirect to="/home" /> : <Redirect to="/login" />
            }
          /> */}
          <Route exact path={["/", ""]} component={Homepage} />
          <Route exact path="/verify" component={CreatePassword} />
          <Route exact path="/contact-us" component={ContactUsPage} />
          <Route exact path="/company/signup" component={CompanySignUp} />
          <Route exact path="/company-search" component={CompanySearchPage} />
          <Route exact path="/bidding/:idBidding" component={BiddingInformationPage} />
          <Route exact path="/account/setting/forget_password" component={ForgetPasswordPage} />
          <Route exact path="/account/setting/reset_password" component={ResetPasswordPage} />
          <Route exact path="/province" component={FindProvincePage} />
          <Route exact path="/province/:provinceName" component={ProvinceMenuPage} />
          <Route exact path="/account/setting/set_password_admin_company" component={SetPasswordAdminCompany} />
          <Route exact path="/account/setting/set_password_admin_vendor" component={SetPasswordAdminVendor} />
          <Route exact path="/manpower/register_manpower" component={RegisterManpower} />

          <Route exact path="/biddings" component={BiddingSearchPage} />

          <UserRoute exact path="/account/setting" component={AccountSettingPage} />
          <UserRoute exact path="/updating_new_email" component={WaitingUpdateNewEmail} />

          <CompanyRoute exact path="/company/myjobs" component={CompanyMyJobPage} />
          <CompanyRoute exact path="/company/myjobs/jobForm" component={CompanyJobFormPage} />
          <CompanyRoute exact path="/company/myjobs/biddingForm" component={CompanyBiddingFormPage} />
          <CompanyRoute exact path="/company/favorites" component={CompanyFavorite} />
          <CompanyRoute exact path="/company/interview" component={CompanyInterviewPage} />
          <CompanyRoute exact path="/company/manage" component={CompanyManagePage} />
          <CompanyRoute exact path="/company/myvendors" component={CompanyMyVendorsPage} />
          <CompanyRoute exact path="/company/confirm-child-company" component={CompanyConfirmChildCompany} />

          <VendorRoute exact path="/vendor/manage" component={VendorManagePage} />

          <Route exact path="/company/:companyNameShort" component={CompanyInformationPage} />
          <Route exact path="/company/request/:idRequest" component={RequestInformationPage} />
          <CompanyRoute exact path="/company-candidate/:type/:id" component={CompanyCandidatePage} />
          <CompanyRoute exact path="/company-bidding/:idBidding" component={CompanyBidding} />
          <CompanyRoute exact path="/company-history-job" component={CompanyHistoryJob} />
          <CompanyRoute exact path="/company-mycontract" component={CompanyMyContract} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/vendor-suggestion" component={VendorSuggestionPage} />
          <Route exact path="/vendor-search" component={VendorSearchPage} />
          <Route exact path="/vendor/signup" component={VendorSignUp} />
          <Route exact path="/vendor/:vendorNameShort" component={VendorInformationPage} />
          <VendorRoute exact path="/manpower" component={VendorManpowerPage} />
          <VendorRoute exact path="/manpower/form" component={AddManpowerFormPage} />
          <Route exact path="/manpower/profile/:idManpower" component={VendorManpowerProfilePage} />
          <VendorRoute exact path="/vendor-dashboard" component={VendorDashboardPage} />
          <VendorRoute exact path="/vendor-interview" component={VendorInterviewPage} />
          <VendorRoute exact path="/vendor-mybidding" component={VendorBiddingPage} />
          <VendorRoute exact path="/vendor-history-biddings" component={VendorHistoryBidding} />
          <VendorRoute exact path="/vendor-mycontract" component={VendorMyContract} />
          <VendorRoute exact path="/vendor-matching" component={VendorMatchingPage} />
          <VendorRoute exact path="/vendor-mycompanys" component={VendorMyCompany} />
          <VendorRoute exact path="/vendor-history-sending" component={VendorHistorySending} />
          <Route exact path="/jobs" component={JobSearch} />
          <Route exact path="/me" component={MePage} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/landing" component={Landing} />
          <Route exact path="/unauthorized" component={UnauthorizedPage} />
          <Route exact path="*" component={NotFoundPage} />

          <Container>
            {/* User Route */}
            <UserRoute exact path="/notification" component={Notification} />
            
            {/* Admin Route */}
            <AdminRoute
              exact
              path="/admin/dashboard/cost"
              component={AdminDashboardCost}
            />
            <AdminRoute
              exact
              path="/admin/dashboard/ot"
              component={AdminDashboardOt}
            />
            <AdminRoute
              exact
              path="/admin/dashboard/workingtime"
              component={AdminDashboardWorkingTime}
            />
          </Container>

        </Switch>
      </Suspense>
    </Container>
  );
};

export default Routers;
