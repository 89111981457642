import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Paper, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";

import ButtonBlue from "../../shared/general/ButtonBlue";
import { getVendorManage, updateVendorManageJobGroup } from "../../../../actions/vendor";

const StyledRoot = styled("div")(({ theme }) => ({
  "& .part-expertise": {
    marginTop:"8px",
    "& .MuiPaper-root": {
      height: 96,
      borderRadius: 16,
      position: "relative",
      padding: 16,
      boxSizing: "border-box",
      userSelect: "none",
      "& .wrap-name": {
        width: 272,
        maxWidth:"100%",
        color: "#919eab",
        [theme.breakpoints.down('md')]:{
          width:"100%"
        },
        "& .MuiTypography-root": {
          fontSize: 20,
        },
      },
      "& .wrap-icon": {
        position: "absolute",
        fontSize: 35,
        color: "#919eab",
        right: 16,
        bottom: 8,
      },
    },
    "& .iconExpand":{
      color: "#919eab",
    },
    "& .isSelected": {
      borderColor: "#1976d2",
      "& .wrapName": {
        color: "#1976d2",
      },
      "& .wrap-icon": {
        color: "#1976d2",
      }
    },
  },
}))

const BoxContentJobGroups = styled(Box)({
  width:"100%",
  padding:"8px 16px",
  display:"flex",
  flexWrap: "wrap"
}); 

const PaperJobGroup = (props) => {
  const { jobGroup, getValues, onClickJobGroup } = props;

  return (
    <Paper 
      variant="outlined" 
      style={{ minHeight:"64px", height:"fit-content" }}
      className={clsx(
        { isSelected : getValues(`jobGroups`).includes(jobGroup.idJobGroup) }
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClickJobGroup(jobGroup.idJobGroup);
      }}
    >
      <Box style={{ width:"100%", height:"64px", display:'flex', position:"relative" }}>
        <div 
          className="wrap-name" 
          style={{ 
            display: "-webkit-box", 
            overflow:"hidden", 
            textOverflow: "ellipsis", 
            whiteSpace: "normal",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical"
          }}
        >
          <Typography className="wrapName">{jobGroup.jobGroupName}</Typography>
        </div>
      </Box>
    </Paper>
  );
};

const ExpertisePanel = () => {

  const dispatch = useDispatch();

  const { result: vendorStore } = useSelector((state) => state.vendorManage)
  const { result: jobGroupStore } = useSelector((state) => state.jobGroups)
  
  const [jobGroups, setJobGroups] = useState([]);

  const {control, handleSubmit, formState: { dirtyFields }, getValues, setValue} = useForm({
    defaultValues: {
      jobGroups: (vendorStore && vendorStore.jobGroups) || []
    }
  });

  const onClickJobGroup = (idJobGroup) => {
    let currentJobGroup = getValues('jobGroups');
    if (currentJobGroup.includes(idJobGroup)) {
      currentJobGroup = currentJobGroup.filter(e => e !== idJobGroup);
    } else {
      if(currentJobGroup.length < 5){
        currentJobGroup.push(idJobGroup);
        currentJobGroup.sort((a,b) => a - b)
      }
    }
    setValue(`jobGroups`, currentJobGroup, { shouldDirty: true })
  };

  const onSubmit = async (formData) => {

    const response = await dispatch(updateVendorManageJobGroup(formData))

    if(response.status === 200){
      dispatch(getVendorManage())
    }
  }

  useEffect(() => {
    if(jobGroupStore !== null){
      setJobGroups([...jobGroupStore]);
    }
  },[jobGroupStore]);

  return(
    <StyledRoot>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
            <ButtonBlue 
              className="edit-button" 
              variant="outlined" 
              size="small" 
              disabled={dirtyFields && dirtyFields.jobGroups? false: true} 
              type="submit"
            >
              <i className="fa-regular fa-pen"></i>แก้ไข
            </ButtonBlue>
          </Grid>
          <BoxContentJobGroups>
            <Controller
              control={control}
              name="jobGroups"
              render={()=>(
                <Fragment>
                  <Grid item className="part-expertise" container spacing={2}>
                    {jobGroups.map((jobGroup) => (
                      <Grid key={`jobGroup-${jobGroup.idJobGroup}`} item xs={12} md={6}>
                        <PaperJobGroup
                          jobGroup={jobGroup}
                          getValues={getValues}
                          onClickJobGroup={onClickJobGroup}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Fragment>
              )}
            />
          </BoxContentJobGroups>
        </Grid>
      </form>
    </StyledRoot>
  )
}

export default ExpertisePanel;