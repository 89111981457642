import React, { useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { 
  Box,
  Button,
  Container,
  Dialog,
  DialogContent
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";

import CardStyle from "../shared/general/Card";
import ButtonBlue from "../shared/general/ButtonBlue";
import CardCandidateSent from "./shared/cardCandidateSent";

import Utils from "../../../utils";
import CardSendManpower from "./shared/CardSendManpower";
import CardPassedCandidate from "./shared/cardPassedCandidate";

import { getJobRequestByIdJobRequest } from "../../../actions/job";
import { getAllFreeUsersByIdVendor } from "../../../actions/user";
import { 
  addManpowersToCandidate, 
  getCandidatesAlreadySendToJob, 
  getPassedCandidatesInJob
} from "../../../actions/candidate";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#f1f4f9",
  paddingBottom: 36,
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color:"#000000",
    "& .MuiAvatar-root": {
      marginRight: 8,
      width: 40,
      height: 40,
    },
  },
});

const StyledChip = styled(Chip)({
  color: "#b72136",
  backgroundColor: "#ff928f",
  fontWeight: 700,
  borderRadius: 6,
});

const StyledContent = styled("div")({
  padding: 36,
  paddingTop: 24,
  "& .part-one": {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 8,
    ["@media only screen and (max-width: 600px)"]:{
      flexDirection: "column"
    },
    "& .detail": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "& .start": {
        "& .position": {
          marginBottom: 12,
        },
        "& div": {
          marginRight: 32,
          ["@media only screen and (max-width: 600px)"]:{
            marginRight: 0
          },
        },
      },
      "& .end": {
        textAlign: "end",
      },
      ["@media only screen and (max-width: 600px)"]:{
        flexDirection: "column",
        marginTop: 16
      },
    },
    "& .MuiAvatar-root": {
      width: 120,
      height: 120,
      fontSize: 42,
      marginRight: 36,
      borderRadius: 12,
      backgroundColor: "white",
      color: "inherit",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
    },
    "& .MuiTypography-caption": {
      fontSize: 14,
    },
  },
  "& .part-two": {
    display: "flex",
    justifyContent: "end",
  },
  "& .wrap-item": {
    "& .label": {
      fontWeight: 400,
      fontSize: 16,
      marginBottom: 4,
      "& span": {
        marginLeft: 16,
      }
    },
    "& .value": {
      fontSize: 18,
      marginLeft: 32,
    },
    "& .fal": {
      marginRight: 8,
    },
  },
  "&.selected-candidate": {
    minHeight: 200,
    "& .part-one": {
      marginBottom: 16,
    },
    "& .wrap-selected-candidate-list": {
      display: "grid",
      columnGap: 16,
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    "& .candidate-id": {
      "& .MuiTypography-h6": {
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    "& .date-appointment": {
      marginTop: 8,
      display: "flex",
      flexDirection: "column",
    },
  },
  "&.candidate": {
    minHeight: 200,
    "& .part-one": {
      marginBottom: 16,
      display: "flex",
      justifyContent: "space-between",
    },
    "& .wrap-candidate-list": {
      display: "grid",
      columnGap: 16,
      gridTemplateColumns: "repeat(4, 1fr)",
      "& .candidate-id": {
        "& .MuiTypography-h6": {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
  },
  "& .MuiInputBase-root":{
    borderRadius:"16px !important"
  },
  "& .part-company": {
    padding: "32px 0",
    "& .MuiAvatar-root": {
      width: 56,
      height: 56,
      backgroundColor: "white",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
    },
    "& .MuiTypography-root": {
      fontSize: 20,
    }
  },
  "& .attach-file-container": {
    marginLeft: 32,
    display: "flex",
    gap: 16,
    "& i": {
      marginLeft: 8,
      color: "#919eab"
    }
  }
});

const DialogStyled = styled(Dialog)({
  "& .MuiPaper-root":{
      borderRadius: 16,
      padding: 16
  }
});

const BoxStatus = styled(Box)({
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  "& .icon":{
      fontSize: 112
  },
  "& .text":{
      marginTop: 24
  },
  "& .error":{
      color:"#e93d3d"
  },
  "& .CloseButton":{
    width: 80,
    marginTop: 16,
    padding: 8,
    border:"1px solid rgba(224, 224, 224, 1)",
    borderRadius: 8,
    cursor:"pointer",
    "& .textButton":{
      color: `#707070`,
    }
  }
});

const renderWorkExp = (min, max) => {
  if(Number(min) === 0 && Number(max) === 0){
    return "ไม่จำกัด"
  } else if (Number(min) > 0 && Number(max) === 0){
    return `${min} ปีขึ้นไป`
  } else {
    return `${min} - ${max} ปี`
  }
}

const RequestInformationPage = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isFetching: isJobFetching, result: JobDetail } = useSelector(state => state.jobDetail);
  const { result: UserProfile } = useSelector(state => state.userProfile);
  const { result: AllUsersVendorFree } = useSelector(state => state.userVendorFree);
  const { result: AllCandidatesAlreadySent } = useSelector(state => state.candidateAlreadySent);
  const { result: AllPassedCandidates } = useSelector(state => state.passedCandidatesCompany);
  
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFail, setIsFail] = React.useState(false);

  useEffect(() => {
    let idRequest = props.match.params.idRequest;
    dispatch(getJobRequestByIdJobRequest(idRequest));
  }, [props.match.params.idRequest]);

  useEffect(() => {
    if(UserProfile && UserProfile.idVendor && props.match.params.idRequest){
      dispatch(getAllFreeUsersByIdVendor());
      dispatch(getCandidatesAlreadySendToJob(props.match.params.idRequest));
    }
  },[UserProfile, props.match.params.idRequest]);

  useEffect(() => {
    if (UserProfile && UserProfile.idCompany && (JobDetail && JobDetail.idCompany === UserProfile.idCompany) && props.match.params.idRequest){
      dispatch(getPassedCandidatesInJob(JobDetail.idJob));
    }
  },[UserProfile, JobDetail, props.match.params.idRequest]);

  let timeoutDisplay;

  const handleOnSubmit = async (right, ResetRight) => {
    if(dayjs().isBefore(JobDetail.closeRegisterDate)){
      const result = await dispatch(addManpowersToCandidate(right, parseInt(props.match.params.idRequest)));
      if(result){
        let idRequest = props.match.params.idRequest;
        dispatch(getAllFreeUsersByIdVendor());
        dispatch(getCandidatesAlreadySendToJob(idRequest));
        ResetRight();
        setIsSuccess(true);
        timeoutDisplay = setTimeout(handleCloseDisplay, 5000);
      }
    } else {
      setIsFail(true);
    }
  };

  const handleCloseDisplay = () => {
    clearInterval(timeoutDisplay);
    setIsSuccess(false);
  };

  const renderEmployeeType = (employeeType) => {
    switch(employeeType){
      case "FULLTIME": return "พนักงานประจำ";
      case "CONTRACT": return "พนักงานชั่วคราว";
      default: return "-"
    }
  }

  const handleDispatchCandidateAlreadySent = () => {
    dispatch(getCandidatesAlreadySendToJob(props.match.params.idRequest));
  };

  return (
    <StyledRoot className={`page`}>
      {!isSuccess && 
        <Container maxWidth="lg">
          <Typography variant="h4" style={{ padding: "24px 0" }}>
            ข้อมูลงาน
          </Typography>
          {!isJobFetching && JobDetail && 
            <Fragment>
              <CardStyle style={{ marginBottom: 24 }}>
                <StyledContent>
                  <Typography variant="caption" color="text.secondary" gutterBottom>
                    {`วันที่ประกาศรับสมัคร: ${JobDetail && JobDetail.postDate ? dayjs(JobDetail.postDate).format("DD-MMM-YYYY") : "ไม่ระบุวันประกาศ"}`}
                  </Typography>
                  <div className={`part-one`}>
                    <Avatar variant="rounded" src={JobDetail.companyImageURL }>
                      <img src={"/assets/logo_default.png"} />
                    </Avatar>
                    <Grid container spacing={2} style={{ width:"100%" }}>
                      <Grid item xs={12} md={10}>
                        <div className="position">
                          <Typography variant="caption" color="text.secondary">
                            ตำแหน่ง
                          </Typography>
                          <Typography variant="h5">
                            {JobDetail && JobDetail.positionName ? JobDetail.positionName : "-"}
                          </Typography>
                        </div>
                        <Grid container style={{ width:"100%" }}>
                          <Grid item xs={12} md={7}>
                            <Typography variant="caption" color="text.secondary">
                              หน่วยงาน
                            </Typography>
                            <Typography variant="h5">
                              {JobDetail && JobDetail.departmentName ? JobDetail.departmentName : "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <Typography variant="caption" color="text.secondary">
                              กลุ่มงาน
                            </Typography>
                            {JobDetail.jobGroupName? (
                              <Box>
                                <Chip 
                                  label={JobDetail.jobGroupName} 
                                  style={{ margin:"5px", fontWeight:700 }} 
                                />
                              </Box>
                            ) : (
                                <Typography variant="h5">-</Typography>
                              )
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <div style={{ display:"flex", flexDirection:"column", alignItems:"flex-end" }}>
                          {JobDetail && !!JobDetail.isUrgent ? (
                            <StyledChip label="Urgent" size="small" style={{ width:"fit-content" }}/>
                          ) : <></>}
                          <Typography
                            component={"div"}
                            variant="caption"
                            color="text.secondary"
                            align="right"
                            style={{ color:"#C97800" }}
                          >
                            เปิดรับสมัคร
                          </Typography>
                          <Typography variant="h4" align="right" style={{ color:"#C97800" }}>
                            {JobDetail && JobDetail.quota ? JobDetail.quota : <i className="fa-solid fa-infinity"></i>}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="part-company">
                    <Typography>{JobDetail.branchName ? JobDetail.branchName: JobDetail.companyName? JobDetail.companyName: "-"}</Typography>
                  </div>
                  
                  <div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={`wrap-item`}>
                          <Typography className="label" color="text.secondary">
                            <i className="fa-regular fa-user"></i>
                            <span>ประเภทพนักงาน</span>
                          </Typography>
                          <Typography className="value">
                            {JobDetail && JobDetail.employeeType ? 
                              renderEmployeeType(JobDetail.employeeType)
                            :
                              "-"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={`wrap-item`}>
                          <Typography className="label" color="text.secondary">
                            <i className="fa-regular fa-briefcase"></i>
                            <span>ประสบการณ์</span>
                          </Typography>
                          <Typography className="value">
                            {/* {JobDetail && JobDetail.workExperience ? JobDetail.workExperience <= 8
                              ? `${JobDetail.workExperience} ปี`
                              : `9 ปีขึ้นไป`
                              : "ไม่มีกำหนดประสบการณ์"
                            } */}
                            {JobDetail && renderWorkExp(JobDetail.workExperienceMin, JobDetail.workExperienceMax)}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={`wrap-item`}>
                          <Typography className="label" color="text.secondary">
                            <i className="fa-regular fa-graduation-cap"></i>
                            <span>ระดับการศึกษาขั้นต่ำ</span>
                          </Typography>
                          <Typography className="value">
                            {["ไม่จำกัด", "ประถมศึกษา", "มัธยมศึกษา", "ปวช./ปวส.","ปริญญาตรี","ปริญญาโท","ปริญญาเอก"][JobDetail.educationDegree || 0]}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={`wrap-item`}>
                          <Typography className="label" color="text.secondary">
                            <i className="far fa-map-marker-alt"></i>
                            <span>รายละเอียดสถานที่ทำงาน</span>
                          </Typography>
                          <Typography className="value">
                            {JobDetail && JobDetail.workLocationDetail ? JobDetail.workLocationDetail : "-"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={`wrap-item`}>
                          <Typography className="label" color="text.secondary">
                            <i className="far fa-hand-holding-usd"></i>
                            <span>ค่าจ้าง</span>
                          </Typography>
                          <Typography className="value">
                            {(JobDetail && (JobDetail.minSalary || JobDetail.maxSalary)) ?
                              (JobDetail.minSalary && JobDetail.maxSalary) ?
                                `${Utils.numberWithCommas(JobDetail.minSalary)}-${Utils.numberWithCommas(JobDetail.maxSalary)}  บาท` : 
                                  (JobDetail.minSalary) ?
                                    `ขั้นต่ำ ${Utils.numberWithCommas(JobDetail.minSalary)} บาท` :
                                    `สูงสุด ${Utils.numberWithCommas(JobDetail.maxSalary)} บาท`                            
                              : "ตามตกลง"
                            }
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={`wrap-item`}>
                          <Typography className="label" color="text.secondary">
                            <i className="far fa-hand-holding-usd"></i>
                            <span>ประเภทการจ่ายค่าจ้าง</span>
                          </Typography>
                          <Typography className="value">{`${JobDetail && JobDetail.salaryType === "monthly" ? "รายเดือน" : "รายวัน"}`}</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={`wrap-item`}>
                          <Typography className="label" color="text.secondary">
                            <i className="far fa-calendar-times"></i>
                            <span>ปิดรับสมัครวันที่</span>
                          </Typography>
                          <Typography className="value">{JobDetail && JobDetail.closeRegisterDate ? dayjs(JobDetail.closeRegisterDate).format("DD-MMM-YYYY") : "ไม่มีกำหนดปิดรับสมัคร"}</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className={`wrap-item`}>
                          <Typography className="label" color="text.secondary">
                            <i className="fa-solid fa-paperclip-vertical"></i>
                            <span>ไฟล์แนบ</span>
                          </Typography>
                        </div>
                        {JobDetail && JobDetail.attachFileName ? 
                          <Chip label={JobDetail.attachFileName} component={"a"} href={JobDetail.attachFileURL} onDelete={()=>{}} deleteIcon={<i className="fa-solid fa-circle-down"></i>}/>
                          :
                          <Typography>-</Typography>
                        }
                      </Grid>

                    </Grid>
                  </div>
                  <div style={{ marginTop: 40, marginBottom: 8 }}>
                    <Typography
                      style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
                    >
                      รายละเอียดงาน
                    </Typography>
                    <Typography style={{ whiteSpace:"pre-line" }}>
                      {JobDetail && JobDetail.jobDescription ? JobDetail.jobDescription : "-"}
                    </Typography>
                  </div>
                  <Box marginTop="40px" marginBottom="8px">
                    <Typography fontWeight={600} fontSize={18} marginBottom="8px">ทักษะเกี่ยวกับงาน</Typography>
                    {JobDetail.skills && JobDetail.skills.length > 0? (
                      <Box display="flex">
                        {JobDetail.skills.map((skill, index) => (
                          <Chip
                            kry={index}
                            label={skill.skillName} 
                            style={{ margin:"5px", fontWeight:700 }} 
                          />
                        ))}
                      </Box>
                    ) : (
                        <Typography>-</Typography>
                      )
                    }
                  </Box>
                  <div style={{ marginTop: 40, marginBottom: 8 }}>
                    <Typography
                      style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
                    >
                      สถานที่
                    </Typography>
                    <Typography className="value">
                      {`${JobDetail && JobDetail.district ? JobDetail.district : '-'}, ${JobDetail && JobDetail.province ? JobDetail.province : '-'}`}
                    </Typography>
                  </div>
                  {JobDetail.vendorSelectedType === "all" && (
                    <div style={{ marginTop: 40, marginBottom: 8 }}>
                      <Box style={{ display:'flex', alignItems:"flex-end", marginBottom: 8 }}>
                        <Typography
                          style={{ fontWeight: 600, fontSize: 18, marginRight: 8 }}
                        >
                          {`ค่าชาร์จโดยประมาณ`}
                        </Typography>
                        {Boolean(JobDetail.isStandardContract) && 
                          <Fragment>
                            {/* <Box 
                              style={{ cursor:"pointer", display:"flex", textDecoration:"none", color:"initial" }}
                              component={Link}
                              to="/contract/AntJob_ต้นฉบับ_B2B_สัญญาจ้างเหมาบริการมาตรฐาน.pdf"
                              target="_blank" 
                              download 
                            > */}
                              <Typography>{`(สัญญามาตราฐาน`}</Typography>
                              {/* <i className="fa-solid fa-file-pdf" style={{ fontSize: 20, color:"#ff0000", margin:"0 8px" }}/> */}
                              <Typography>{`)`}</Typography>
                            {/* </Box> */}
                          </Fragment>
                        }
                      </Box>
                      <Typography>- ชาร์จค่าแรง {JobDetail.chargeSalary === null? `ไม่ระบุ`: `${JobDetail.chargeSalary}%`}</Typography>
                      <Typography>- ชาร์จค่าล่วงเวลา {JobDetail.chargeOt === null? `ไม่ระบุ`: `${JobDetail.chargeOt}%`}</Typography>
                      <Typography>- ชาร์จค่าเหมาจ่าย {JobDetail.chargeHire === null? `ไม่ระบุ`: `${Utils.numberWithCommas(JobDetail.chargeHire)}${JobDetail.chargeHireType === 1 ? '%' : ' บาท'}`}</Typography>
                      <Typography>- ชาร์จค่าชดเชย {JobDetail.chargeCompensation === null? `ไม่ระบุ`: `${JobDetail.chargeCompensation}%`}</Typography>
                    </div>
                  )}
                  {(JobDetail.vendorSelectedType === "custom" || JobDetail.vendorSelectedType === "contract") && (
                    <div style={{ marginTop: 40, marginBottom: 8 }}>
                      <Typography
                        style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
                      >
                        ค่าชาร์จ
                      </Typography>
                      <Typography>- ตามที่ระบุในสัญญา</Typography>
                    </div>
                  )}
                </StyledContent>
              </CardStyle>
              {UserProfile && UserProfile.idVendor && props.match.params.idRequest &&
                <Fragment>
                  {AllCandidatesAlreadySent && AllCandidatesAlreadySent.length > 0 && 
                    <CardCandidateSent 
                      listCandidatesSent={AllCandidatesAlreadySent}
                      onClickSubmitCancelled={handleDispatchCandidateAlreadySent}
                    />
                  }
                  {(dayjs().isBefore(JobDetail.closeRegisterDate)) && 
                    <CardSendManpower 
                      type="job"
                      id={props.match.params.idRequest}
                      listManpowers={AllUsersVendorFree}
                      listManpowersFromManpowersPage={location.state && location.state.listSelected || []}
                      handleOnSubmit={handleOnSubmit}
                    />
                  }
                </Fragment>
              }
              {UserProfile && UserProfile.idCompany && (JobDetail && JobDetail.idCompany === UserProfile.idCompany) && props.match.params.idRequest &&
                <CardPassedCandidate AllPassedCandidates={AllPassedCandidates} />
              }
            </Fragment>
          }

          {isJobFetching && <Typography variant="h5" textAlign="center">กำลังโหลดข้อมูล...</Typography>}
          {!isJobFetching && !JobDetail && <Typography variant="h5" textAlign="center">ไม่พบข้อมูลรายละเอียดงาน</Typography>}

        </Container>
      }
      {isSuccess &&
        <Container maxWidth="lg">
          <div style={{ display:"flex", justifyContent:"center", height:"calc(100vh - 160px)" }}>
            <div style={{ display:"flex", flexDirection:"column", alignItems: "center", marginTop:"80px" }}>
              <img 
                style={{ width:300, marginBottom:"20px" }}
                src={`${process.env.PUBLIC_URL}/assets/background/complete.jpg`}
                // alt="https://www.freepik.com/free-vector/completed-concept-illustration_10802151.htm"  
              />
              <Typography variant="h4" style={{ marginBottom:"40px" }}>ส่งคนสำเร็จ</Typography>
              <ButtonBlue 
              variant="outlined" 
              style={{ borderRadius:"25px", width:"100px" }} 
              onClick={handleCloseDisplay}>
                <Typography variant="h6">ปิด</Typography>
              </ButtonBlue>
            </div>
          </div>
        </Container>
      }
      {isFail &&
        <DialogStyled
          open={isFail}
          onClose={() => setIsFail(false)}
        >
          <DialogContent>
            <BoxStatus>
              <i className="fa-regular fa-circle-xmark icon error"></i>
              <Typography 
                  variant='h5' 
                  align="center"
                  className="text error"
              >
                  ส่งกำลังพลไม่สำเร็จ
              </Typography>
              <Box className="CloseButton" onClick={() => setIsFail(false)}>
                <Typography align="center" className="textButton">ปิด</Typography>
              </Box>
            </BoxStatus>
          </DialogContent>
        </DialogStyled>
      }
    </StyledRoot>
  );
};

export default RequestInformationPage;
