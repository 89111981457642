import AdminVendor from "../services/adminVendor.service";

export const addAdminVendor = (formData) => async () => {
  try {
    const res = await AdminVendor.addAdminVendor(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const verifyTokenAddAdminVendor = (query) => async () => {
  try {
    const res = await AdminVendor.verifyTokenAddAdminVendor(query);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const createPasswordAdminVendor = (formData) => async () => {
  try {
    const res = await AdminVendor.createPasswordAdminVendor(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteAdminVendor = (idAdminVendorAntJob) => async () => {
  try {
    const res = await AdminVendor.deleteAdminVendor(idAdminVendorAntJob);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};