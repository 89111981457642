import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, styled } from "@mui/material";

import { getUserProfile } from "../../../../actions/user";
import LeftPanel from "./leftPanel";
import RightPanel from "./rightPanel";
import ProgressBarProfile from "../../shared/general/ProgressBarProfile";

import companyStore from "../../assets/data/company";
import bankDetailStore from "../../assets/data/bankDetail";
import { getCompanyManage } from "../../../../actions/company";
import { getAllBank } from "../../../../actions/bank";
import { getAllBusinessGroup } from "../../../../actions/business";


const StyledRoot = styled("div")({
  "& .part-progressbar": {
    paddingTop: 24,
    paddingBottom: 24,
  },
  "& .header-text": {
    fontSize: 20,
  },
  "& .edit-button": {
    borderRadius: 19,
    "& i": {
      fontSize: 12,
      marginRight: 8,
    }
  },
});

const CompanyManagePage = (props) => {

  const { match } = props;

  const dispatch = useDispatch();

  const { result: companyStore } = useSelector((state) => state.companyManage)
  const { result: bankStore } = useSelector((state) => state.bank)
  const { result: businessGroupStore } = useSelector((state) => state.businessGroups)
 
  const [company, setCompany] = useState(null);
  const [banks, setBanks] = useState([]);
  const [businessGroups, setBusinessGroups] = useState([]);

  const [profileProgress, setProfileProgress] = useState(15);

  useEffect(() => {
    dispatch(getCompanyManage())
    dispatch(getAllBank())
    dispatch(getAllBusinessGroup())
  }, [])

  useEffect(() => {
    setCompany(null)
    if(companyStore != null){
      setCompany({...companyStore});
      setProfileProgress(companyStore.progress)
    }
  }, [companyStore])

  useEffect(() => {
    if(bankStore != null){
      setBanks([...bankStore])
    }
  }, [bankStore])

  useEffect(() => {
    if(businessGroupStore != null){
      setBusinessGroups([...businessGroupStore])
    }
  }, [businessGroupStore])

  return(
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {company && (
          <Fragment>
            <div className="part-progressbar">
              <ProgressBarProfile progressValue={profileProgress} />
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <LeftPanel company={company}/>
              </Grid>
              <Grid item xs={12} sm={8}>
                <RightPanel
                  company={company}
                  banks={banks}
                  bankDetail={company.bankDetail}
                  companyAddress={company}
                  branches={company.branches}
                />
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Container>
    </StyledRoot>
  )
}

export default CompanyManagePage;