import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    styled,
    Typography
} from '@mui/material';

import { changeEmail } from '../../../../../../actions/auth';

const RootStyled = styled(Box)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
});

const StyledDialog = styled(Dialog)({
    "& .MuiDialogContent-root":{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        padding: 48,
        "& .Text":{
            fontSize: 24,
            fontWeight: 400,
            marginTop: 24
        }
    }
});

const BoxStatus = styled(Box)({
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    "& .icon":{
        fontSize: 112
    },
    "& .text":{
        marginTop: 24
    },
    "& .success":{
        color: "#4b924f"
    },
    "& .error":{
        color:"#e93d3d"
    }
});

const WaitingUpdateNewEmail = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [status, setStatus] = useState(null);

    let timeoutDisplay;

    const handleCloseDisplay = () => {
        clearInterval(timeoutDisplay);
        history.push('/');
    };

    useEffect(async () => {
        let email = new URLSearchParams(location.search).get("email");
        let token = new URLSearchParams(location.search).get("token");
        let formData = {
            newEmail: decodeURIComponent(email),
            token: token
        };
        console.log("formData : ",formData);
        const res = await dispatch(changeEmail(formData));
        if (res.status) {
            setStatus(res.status);
            timeoutDisplay = setTimeout(handleCloseDisplay, 5000);
        }
    },[]);

    return (
        <RootStyled className="page">
            <StyledDialog
                open={true}
            >
                <DialogContent>
                    {!status && 
                        <Fragment>
                            <CircularProgress size={80} />
                            <Typography className="Text">กำลังยืนยันการเปลี่ยนอีเมล</Typography>
                        </Fragment>
                    }
                    {status && status === 200 &&
                        <BoxStatus>
                            <i className="fa-regular fa-circle-check icon success"></i>
                            <Typography 
                                variant='h5' 
                                align="center"
                                className="text success"
                            >
                                ยืนยันการเปลี่ยนอีเมลสำเร็จ
                            </Typography>
                        </BoxStatus>
                    }
                    {status && status !== 200 &&
                        <BoxStatus>
                            <i className="fa-regular fa-circle-xmark icon error"></i>
                            <Typography 
                                variant='h5' 
                                align="center"
                                className="text error"
                            >
                                ยืนยันการเปลี่ยนอีเมลไม่สำเร็จ
                            </Typography>
                        </BoxStatus>
                    }
                </DialogContent>
            </StyledDialog>
        </RootStyled>
    );
};

export default WaitingUpdateNewEmail;