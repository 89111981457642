import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography, Link, Grid, Avatar } from "@mui/material";

import TranslateIcon from "@mui/icons-material/Translate";

import ButtonBlue from "../shared/general/ButtonBlue";
import TextFieldTheme from "../shared/general/TextFieldTheme";
import Logo from "../../../components/pages/assets/ANT job.png";

// import Logo from "../assets/logo.png";

import { isEmptyArray } from "formik";
import { Box } from "@mui/system";

import { login } from "../../../actions/auth";

const StyledRoot = styled("div")(({}) => ({
  width: "100%",
  backgroundColor: "#ffffff",
  paddingBottom: 200,
  paddingTop: "90px",
}));

const StyledCard = styled(Card)({
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  borderRadius: 20,
});

const StyledWrapSection = styled("div")(({}) => ({
  width: 350,
  margin: "auto",
  "& .media":{
    height: 140,
    // margin: 10,
    // padding: 20
  },
  "& .part-logo": {
    padding: 16,
    height: 200,
    width: "100%",
    boxSizing: "border-box",
    "& img": {
      objectFit: "contain",
    }
  }
}));

const StyledTextFieldTheme = styled(TextFieldTheme)(({}) => ({
  marginTop: 16,
}));

const StyledForgetPassword = styled(Link)(({}) => ({
  fontSize: 14,
}));

const StyledAgree = styled(FormControlLabel)(({}) => ({
  marginTop: 16,
  alignItems: "center",
  "& .MuiCheckbox-root": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& .MuiTypography-body1": {
    fontSize: 12,
  },
}));


const LoginPage = (props) => {

  const [errorCode, setErrorCode] = useState("");

  const dispatch = useDispatch();
  const [valueLogin, setValueLogin] = useState({
    email: "",
    password: "",
    rememberMe: false
  });

  useEffect(() => {
  
  }, []);


  const handleChangeText = (event) => {
    setValueLogin({ ...valueLogin, [event.target.name]: event.target.value });
  };

  const handleChangeCheckbox = (event) => {
    setValueLogin({ ...valueLogin, [event.target.name]: event.target.checked });
  };

  const handleLogin = async () => {
    setErrorCode("")

    if(valueLogin.email.length === 0){
      setErrorCode("กรุณากรอกอีเมล")
      return
    }

    if(valueLogin.password.length === 0){
      setErrorCode("กรุณากรอกรหัสผ่าน")
      return
    }

    const response = await dispatch(login(valueLogin.email, valueLogin.password, valueLogin.rememberMe))
    // console.log(response)

    if(response.status === 200){
      props.history.push("/");
      window.location.reload();
    } else if (response.status === 404){
      if(response.data.errorCode === "ERROR_INVALID_EMAIL"){
        setErrorCode("อีเมลไม่ถูกต้อง")
      } 
    } else if (response.status === 401){
      if (response.data.errorCode === "ERROR_INVALID_PASSWORD") {
        setErrorCode("รหัสผ่านไม่ถูกต้อง")
      }
    }

      // .then(() => {
      //   props.history.push("/");
      //   window.location.reload();
      // })
      // .catch((e) => {
      //   console.log(e)
      // });
    
  };

  return (
    <Fragment>
      <StyledRoot>
        <StyledWrapSection>
          <StyledCard>
            {/* <div className="part-logo">
              <img src={Logo} alt="logo" className="media"/>
            </div> */}
            <Avatar className="part-logo" src={Logo} variant="square">

            </Avatar>
            <Box style={{ padding:24 }}>
              <Typography variant="h4" gutterBottom>
                เข้าสู่ระบบ
              </Typography>
              {/* <Divider style={{ margin: "28px 0" }} /> */}
              <div>
                {/* <Typography gutterBottom>
              ยังไม่มีบัญชี?{" "}
              <Link component={NavLink} to="/register">
                Get started
              </Link>
            </Typography> */}
                <TextFieldTheme
                  label="อีเมล"
                  name="email"
                  variant="filled"
                  fullWidth
                  onChange={handleChangeText}
                />
                <StyledTextFieldTheme
                  label="รหัสผ่าน"
                  name="password"
                  variant="filled"
                  type="password"
                  fullWidth
                  onChange={handleChangeText}
                />

                {errorCode && <Typography color="error" fontSize={14} style={{ padding: 5 }}>{errorCode}</Typography>}

                <div style={{ marginTop: 16 }}>
                  <StyledForgetPassword color="text.secondary" underline="always">
                    ลืมรหัสผ่าน?
                  </StyledForgetPassword>
                </div>
                <div>
                  <StyledAgree
                    control={<Checkbox 
                      name="rememberMe"
                      checked={!!valueLogin.rememberMe}
                      onChange={handleChangeCheckbox}
                    />}
                    label={
                      <Typography color="text.secondary">จำฉันไว้</Typography>
                    }
                    labelPlacement="end"
                  />
                </div>

                <ButtonBlue
                  variant={"contained"}
                  style={{ marginTop: 16, width: "100%" }}
                  component={NavLink}
                  to="/login"
                  size="large"
                  onClick={handleLogin}
                >
                  เข้าสู่ระบบ
                </ButtonBlue>
              </div>
            </Box>
          </StyledCard>
        </StyledWrapSection>
      </StyledRoot>
    </Fragment>
  );
};

export default LoginPage;
