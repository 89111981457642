import { Box, Chip, Divider, Grid, IconButton, Modal, Paper, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import dayjs from "dayjs";

import { Delete, Edit } from "@mui/icons-material";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogConfirmDelete from "./dialogConfirmDelete";
import DrawerAbout from './drawerAbout';
import DrawerEducation from "./drawerEducation";
import DrawerExperience from "./drawerExperience";
import DrawerJobGroupSkill from "./drawerJobGroupSkill";

import { getManpowerById, deleteManpowerExperience, deleteManpowerEducation } from "../../../../../actions/manpower";
import Certificated from "./certificated";

const StyledRoot = styled("div")({
  "& .BoxBetween":{
    display:"flex",
    justifyContent:"space-between"
  }
})

const StyledContentCard = styled("div")({
  padding: 24,
  paddingTop: 16,
  "& .header-text": {
    marginBottom: 24,
    fontSize: 20,
  },
  "& .expertise-text": {
    marginBottom: 16,
    fontSize: 18,
  },
  "& .part-skill": {
    display: "flex",
    "& .MuiChip-root": {
      marginRight: 8,
    }
  }
});

const EditIconButton = styled(IconButton)(({theme}) => ({
  position:"absolute", 
  right:0, 
  top:0, 
  padding:8,
  color: theme.palette.primary.main,
  "&:hover":{
    backgroundColor:"transparent"
  }
}));

const DivButton = styled("div")({
  position:"absolute", 
  right:0,
  display:"flex",
  "& .Edit":{
    color: "#1976d2",
    marginRight:"10px"
  },
  "& .Delete":{
    color: "#d32f2f"
  },
  "& svg":{
    fontSize:"20px"
  }
});

const BoxFlexColumn = styled(Box)(({theme}) => ({
  display:"flex", 
  flexDirection:"column", 
  position:"relative"
}));

const BoxAndEditButton = (props) => {
  const { component: Component, children  } = props
  const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile); 
  const { result: UserProfile } = useSelector(state => state.userProfile);
  const [openDrawer, setOpenDrawer] = useState(false);

  const onClose = () => {
    setOpenDrawer(false);
  }

  return (
    <BoxFlexColumn>
      {UserProfile && ManpowerProfile && 
        UserProfile.idVendor === ManpowerProfile.idVendor && 
        <EditIconButton
          size="small"
          onClick={() => { setOpenDrawer(true) }}
        >
          <i className="fa-solid fa-pen" />
        </EditIconButton>
      }
      {children}
      {openDrawer && 
        <Component open={openDrawer} onClose={onClose} data={ManpowerProfile} idManpower={ManpowerProfile.idManpower}/>
      }
    </BoxFlexColumn>
  );
};

const EducationComponent = (props) => {
  const { setIsSuccess } = props;
  const dispatch = useDispatch();
  const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile);
  const { result: UserProfile } = useSelector(state => state.userProfile);
  const [openDialog, setOpenDialog] = useState({ isOpen: false, data: null, isEdit: false, idManpowerEducation: null });
  const [openConfirmDelete, setOpenConfirmDelete] = useState({ isOpen: false, idManpowerEducation: null });

  let timeoutDisplay;

  const handleCloseDisplay = () => {
    clearInterval(timeoutDisplay);
    setIsSuccess({
      status: false,
      isEducation: false,
      isAdd: false,
      isDelete: false 
    });
  };

  const handleCountDownTimeSet = () => {
    timeoutDisplay = setTimeout(handleCloseDisplay, 5000);
  };

  const handleDelete = (idManpowerEducation) => {
  
    dispatch(deleteManpowerEducation(ManpowerProfile.idManpower, idManpowerEducation)).then(res => {
      dispatch(getManpowerById(ManpowerProfile.idManpower));
    })
    
    setOpenConfirmDelete({ isOpen: false, idManpowerEducation: null })
  };

  return (
    <CardStyle>
      <StyledContentCard>
        <Grid className="header-text" container justifyContent="space-between" alignItems="center">
          <Typography variant="h5" fontSize={20}>ประวัติการศึกษา</Typography>
          {UserProfile && ManpowerProfile && 
            UserProfile.idVendor === ManpowerProfile.idVendor && 
            <ButtonBlue 
              variant="outlined" 
              startIcon={<i className="fa-regular fa-plus"></i>}
              onClick={() => setOpenDialog({ isOpen: true, data: null, isEdit: false, idManpowerEducation: null })}
            >เพิ่มประวัติการศึกษา</ButtonBlue>
          }
        </Grid>
        {ManpowerProfile.educations && ManpowerProfile.educations.length > 0 ?
          ManpowerProfile.educations.map((education, index) => (
            <React.Fragment key={education.idManpowerEducation}>
              {index !== 0 && <Divider style={{ margin:"0 0 10px", borderColor:"rgba(0, 0, 0, 0.08)", borderStyle: "dashed" }} />}
              <div style={{ width:"100%", display:"flex", flexDirection:"column", marginBottom:"10px", position:"relative" }}>
                <Typography style={{ width:"100%", fontWeight: 500, fontSize: 20 }}>
                  {/* {`(${education.startYear? dayjs().year(education.startYear).format("YYYY"): "ไม่ระบุ"} - ${education.endYear? dayjs().year(education.endYear).format("YYYY"): "ไม่ระบุ"}) ${education.degree}`} */}
                  {`(${education.startYear? dayjs().year(education.startYear).format("YYYY"): "ไม่ระบุ"} - ${education.endYear? dayjs().year(education.endYear).format("YYYY"): "ไม่ระบุ"})`} {education.degree} {education.faculty}
                </Typography>
                {/* {education.faculty && <Typography color="text.secondary">คณะ {education.faculty}</Typography>} */}
                {education.major && <Typography><span style={{color: "#919eab", fontSize: 14}}>สาขาวิชา</span> {education.major}</Typography>}
                {education.university && <Typography><span style={{color: "#919eab", fontSize: 14}}>สถานศึกษา</span> {education.university}</Typography>}
                {education.gpa && <Typography fontSize={14}>(GPA: {education.gpa})</Typography>}
                {/* <Typography color="text.secondary">
                  {`${education.university}`+`${education.major ? `, ${education.major}` : ""}`}
                </Typography> */}
                {UserProfile && ManpowerProfile &&
                  UserProfile.idVendor === ManpowerProfile.idVendor &&
                  <DivButton>
                    <IconButton
                      aria-label="edit"
                      className="Edit"
                      onClick={() => setOpenDialog({ isOpen: true, data: education, isEdit: true, idManpowerEducation: education.idManpowerEducation })}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      className="Delete"
                      onClick={() => setOpenConfirmDelete({ isOpen: true, idManpowerEducation: education.idManpowerEducation })}
                    >
                      <Delete />
                    </IconButton>
                  </DivButton>
                }
              </div>
            </React.Fragment>
          ))
          :
          <Typography 
            align="center" 
            style={{ width:"100%", color:"#919eab", margin:"15px 0" }}
          >ไม่มีข้อมูลประวัติการศึกษา</Typography>
        }
        {openDialog.isOpen && 
          <DrawerEducation 
            open={openDialog.isOpen}
            onClose={() => setOpenDialog({ isOpen: false, data: null, isEdit: false, idManpowerEducation: null })}
            isEdit={openDialog.isEdit}
            idManpower={ManpowerProfile.idManpower}
            idManpowerEducation={openDialog.idManpowerEducation}
            data={openDialog.data}
            setIsSuccess={setIsSuccess}
            handleCountDownTimeSet={handleCountDownTimeSet}
          />
        }
        {openConfirmDelete.isOpen && 
          <DialogConfirmDelete 
            open={openConfirmDelete.isOpen}
            onClose={() => setOpenConfirmDelete({ isOpen: false, idManpowerEducation: null })}
            isEducation={true}
            onSubmit={() => handleDelete(openConfirmDelete.idManpowerEducation)}
          />
        }
      </StyledContentCard>
    </CardStyle>
  );
};

const ExperienceComponent = (props) => {
  const { setIsSuccess } = props;
  const dispatch = useDispatch();
  const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile);
  const { result: UserProfile } = useSelector(state => state.userProfile);
  const [openDialog, setOpenDialog] = useState({ isOpen: false, data: null, isEdit: false, idManpowerExperience: null });
  const [openConfirmDelete, setOpenConfirmDelete] = useState({ isOpen: false, idManpowerExperience: null });
  const { result: AllJobGroups } = useSelector((state) => state.jobGroups);

  const displayDuration = (startYear, endYear) => {
    let result = dayjs().year(parseInt(startYear)).format("YYYY - ");
    result += dayjs().year(parseInt(endYear)).format("YYYY");
    return result;
  };

  let timeoutDisplay;

  const handleCloseDisplay = () => {
    clearInterval(timeoutDisplay);
    setIsSuccess({
      status: false,
      isEducation: false,
      isAdd: false,
      isDelete: false 
    });
  };

  const handleCountDownTimeSet = () => {
    timeoutDisplay = setTimeout(handleCloseDisplay, 5000);
  };

  const handleDelete = (idManpowerExperience) => {

    dispatch(deleteManpowerExperience(ManpowerProfile.idManpower, idManpowerExperience)).then(res => {
      dispatch(getManpowerById(ManpowerProfile.idManpower));
    })
    
    setOpenConfirmDelete({ isOpen: false, idManpowerExperience: null })

  };

  return (
    <CardStyle>
      <StyledContentCard>
        <Grid className="header-text" container justifyContent="space-between" alignItems="center">
          <Typography variant="h5" fontSize={20}>ประสบการณ์ทำงาน</Typography>
          {UserProfile && ManpowerProfile && 
            UserProfile.idVendor === ManpowerProfile.idVendor && 
            <ButtonBlue 
              variant="outlined" 
              startIcon={<i className="fa-regular fa-plus"></i>}
              onClick={() => setOpenDialog({ isOpen: true, data: null, isEdit: false, idManpowerExperience: null })}
            >เพิ่มประสบการณ์ทำงาน</ButtonBlue>
          }
        </Grid>
        {ManpowerProfile.experiences && ManpowerProfile.experiences.length > 0 ?
          ManpowerProfile.experiences.map((experience, index) => (
            <React.Fragment key={experience.idManpowerExperiences}>
              {index !== 0 && <Divider style={{ margin: "0 0 10px", borderColor: "rgba(0, 0, 0, 0.08)", borderStyle: "dashed" }} />}
              <div style={{ width:"100%", display:"flex", flexDirection:"column", marginBottom:"10px", position:"relative" }}>
                <Typography style={{ width:"100%", fontWeight: 500, fontSize: 20 }}>
                  {`(${experience.startYear? dayjs().year(experience.startYear).format("YYYY"): "ไม่ระบุ"} - ${experience.endYear? dayjs().year(experience.endYear).format("YYYY"): "ไม่ระบุ"})`} {experience.positionName}
                  {/* {`(${experience.startYear? dayjs().year(experience.startYear).format("YYYY"): "ไม่ระบุ"} - ${experience.endYear? dayjs().year(experience.endYear).format("YYYY"): "ไม่ระบุ"}) ${experience.positionName}`} */}
                </Typography>

                {experience.idJobGroup && <Typography><span style={{color: "#919eab", fontSize: 14}}>กลุ่มงาน</span> {AllJobGroups.find(j => j.idJobGroup === experience.idJobGroup).jobGroupName}</Typography>}
                {experience.companyName && <Typography><span style={{color: "#919eab", fontSize: 14}}>บริษัท</span> {experience.companyName}</Typography>}

                {UserProfile && ManpowerProfile &&
                  UserProfile.idVendor === ManpowerProfile.idVendor &&
                  <DivButton>
                    <IconButton
                      aria-label="edit"
                      className="Edit"
                      onClick={() => setOpenDialog({ isOpen: true, data: experience, isEdit: true, idManpowerExperience: experience.idManpowerExperiences })}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      className="Delete"
                      onClick={() => setOpenConfirmDelete({ isOpen: true, idManpowerExperience: experience.idManpowerExperiences })}
                    >
                      <Delete />
                    </IconButton>
                  </DivButton>
                }
              </div>
            </React.Fragment>
          ))
          :
          <Typography
            align="center"
            style={{ width: "100%", color: "#919eab", margin: "15px 0" }}
          >ไม่มีข้อมูลประสบการณ์ทำงาน</Typography>
        }
        {openDialog.isOpen && 
          <DrawerExperience 
            open={openDialog.isOpen}
            onClose={() => setOpenDialog({ isOpen: false, data: null, isEdit: false, idManpowerExperience: null })}
            isEdit={openDialog.isEdit}
            idManpower={ManpowerProfile.idManpower}
            idManpowerExperience={openDialog.idManpowerExperience}
            data={openDialog.data}
            setIsSuccess={setIsSuccess}
            handleCountDownTimeSet={handleCountDownTimeSet}
          />
        }
        {openConfirmDelete.isOpen && 
          <DialogConfirmDelete 
            open={openConfirmDelete.isOpen}
            onClose={() => setOpenConfirmDelete({ isOpen: false, idManpowerExperience: null })}
            isEducation={false}
            onSubmit={() => handleDelete(openConfirmDelete.idManpowerExperience)}
          />
        }
      </StyledContentCard>
    </CardStyle>
  );
};

const RightPanel = (props) => {
  const { setIsSuccess } = props;
  const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile);
  const { result: UserProfile } = useSelector(state => state.userProfile);

  return(
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardStyle>
            <StyledContentCard>
              <BoxAndEditButton component={DrawerAbout}>
                <Typography className="header-text" variant="h5">แนะนำตัว</Typography>
                <Typography>
                  {ManpowerProfile && ManpowerProfile.about || "-"}
                </Typography>
              </BoxAndEditButton>
            </StyledContentCard>
          </CardStyle>
        </Grid>

        <Grid item xs={12}>
          <CardStyle>
            <StyledContentCard>
              <BoxAndEditButton component={DrawerJobGroupSkill}>
                <Typography className="header-text" variant="h5">ความเชี่ยวชาญ และ ทักษะ</Typography>
                <Typography className="expertise-text">
                  {ManpowerProfile && ManpowerProfile.jobGroupName || "ไม่พบความเชี่ยวชาญ"}
                </Typography>
                <Typography>ทักษะ</Typography>
                <div className="part-skill">
                  {ManpowerProfile && ManpowerProfile.skills.length > 0 ? 
                    ManpowerProfile.skills.map((skill, index) => (
                      <Chip key={index} label={skill.skillName} />
                    ))
                    :
                    <Typography 
                      align="center" 
                      style={{ width:"100%", color:"#919eab", margin:"15px 0" }}
                    >ไม่พบทักษะ</Typography>
                  }
                </div>
              </BoxAndEditButton>              
            </StyledContentCard>
          </CardStyle>
        </Grid>

        <Grid item xs={12}>
          <EducationComponent 
            setIsSuccess={setIsSuccess}
          />
        </Grid>

        <Grid item xs={12}>
          <ExperienceComponent 
            setIsSuccess={setIsSuccess}
          />
        </Grid>

        <Grid item xs={12}>         
          <Certificated
            idManpower={ManpowerProfile.idManpower}
            data={ManpowerProfile.certificates}
            isMyManpower={
              UserProfile && ManpowerProfile && 
              (UserProfile.idVendor === ManpowerProfile.idVendor)
            }
          />         
        </Grid>

      </Grid>
    </StyledRoot>
  )
}

export default RightPanel;