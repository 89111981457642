import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
    Container,
    styled,
    Typography
} from "@mui/material";

import BiddingContracts from './biddingContracts';
import { getMyContractVendor } from "../../../../actions/bidding";

const StyledRoot = styled("div")({
    "& .header-text": {
        marginBottom: 24,
    },
});

const VendorMyContract = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMyContractVendor());
    },[]);

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Typography className="header-text" variant="h4">
                    สัญญาของฉัน
                </Typography>
                <BiddingContracts type="LimitTime" />
                <BiddingContracts type="Guarantee" />
            </Container>
        </StyledRoot>
    );
};

export default VendorMyContract;