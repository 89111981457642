import { httpClient } from "./httpClient";

const getMyVendors = () => {
    return httpClient.get(`admin/company/my_vendors`);
};

const updateVendorHidden = (formData) => {
    return httpClient.post(`admin/company/vendor_hidden`, formData);
};

const addMyVendor = (formData) => {
    return httpClient.post(`admin/company/my_vendors/add`, formData);
};

const addAdminCompany = (formData) => {
    return httpClient.post(`admin/company/manage_admin`, formData);
};

const verifyTokenAddAdminCompany = (query) => {
    return httpClient.get(`admin/company/manage_admin/verifyToken`, {params: query});
};

const createPasswordAdminCompany = (formData) => {
    return httpClient.post(`admin/company/manage_admin/createPassword`, formData);
};

const deleteAdminCompany = (idAdminAntJob) => {
    return httpClient.delete(`admin/company/manage_admin/${idAdminAntJob}`);
};

export default {
  getMyVendors,
  updateVendorHidden,
  addMyVendor,
  addAdminCompany,
  verifyTokenAddAdminCompany,
  createPasswordAdminCompany,
  deleteAdminCompany
};