import React, { Fragment, useState } from "react";
import { Checkbox, Container, FormControlLabel, FormHelperText, Grid, MenuItem, Paper, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import TextFieldTheme from "../shared/general/TextFieldTheme";

import jobGroup from "../assets/data/jobGroup";
import { Link } from "react-router-dom";
import ButtonBlue from "../shared/general/ButtonBlue";
import SignupSuccess from "./signupSuccess";

import { signupVendor } from "../../../actions/signup";

const StyledRoot = styled("div")({
  "& .fieldLabel": {
    fontSize: 16,
  },
  "& a": {
    color: "#1976d2",
    textDecoration: "none",
  },
  "& .part-expertise": {
    "& .MuiPaper-root": {
      height: 96,
      borderRadius: 16,
      position: "relative",
      padding: 16,
      boxSizing: "border-box",
      userSelect: "none",
      cursor: "pointer",
  //   "& .wrap-btn-select": {
  //     position: "absolute",
  //     right: 16,
  //     top: 8,
  //     "& .MuiButtonBase-root": {
  //       backgroundColor: "#0000000a",
  //       padding: 2,
  //       fontSize: 16,
  //     },
  //   },
      "& .wrap-name": {
        width: 160,
        "& .MuiTypography-root": {
          fontSize: 20,
        },
      },
      "& .wrap-icon": {
        position: "absolute",
        fontSize: 35,
        color: "#919eab",
        right: 16,
        bottom: 8,
      },
    },
    "& .selected": {
      color: "#007afe",
      border: "1px solid #007afe",
      "& .wrap-icon": {
        color: "#007afe",
      }
    }
  }
})

const VendorSingUp = () => {
  const dispatch = useDispatch();

  const [isSuccess, setIsSuccess] = useState(false);

  const { control, handleSubmit, getValues, setValue, formState: { errors }, setError} = useForm({
    defaultValues: {
      vendorName: "",
      vendorNameShort: "",
      mainContactName: "",
      mainContactPhone: "",
      mainContactEmail: "",
      // expertises: [],
      password: "",
      confirmPassword: "",
      acceptPrivacyPolicy: false,
    }
  });

  const onSubmit = async (formData) => {
    const cleansingForm = {
      vendorName: formData.vendorName,
      vendorNameShort: formData.vendorNameShort,
      mainContactName: formData.mainContactName,
      mainContactPhone: formData.mainContactPhone,
      mainContactEmail: formData.mainContactEmail,
      password: formData.password,
    }

    const response = await dispatch(signupVendor(cleansingForm));
    if (response.status === 200){
      setIsSuccess(true)
    } else {
      if (response.response.status === 400) {
        if(response.response.data.message === "email is already exists"){
          setError("mainContactEmail", { message: "อีเมลนี้มีผู้ใช้แล้ว" })
        }
        if(response.response.data.message === "vendorNameShort is already exists"){
          setError("vendorNameShort", { message: "ชื่อย่อคู่ธุรกิจนี้มีผู้ใช้แล้ว" })
        }
      }
    }
  };

  // const onClickExpertiseHandler = (idExpertise) => {
  //   let expertisesTemp = getValues("expertises");

  //   if(expertisesTemp.find(item => item === idExpertise)){
  //     setValue("expertises", expertisesTemp.filter(item => item !== idExpertise));
  //     return
  //   }
    
  //   setValue("expertises", [...expertisesTemp, idExpertise]);
  //   return

  // }

  return(
    <StyledRoot className="page">
      <Container maxWidth="md">
        {!isSuccess &&(
          <Fragment>
            <Typography variant="h4" marginBottom={3}>สมัครสมาชิกสำหรับ Vendor</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="vendorName"
                    rules={{
                      required: {value: true, message: "กรุณากรอกชื่อ Vendor"}
                    }}
                    render={({field})=>(
                      <Fragment>
                        <Typography className="fieldLabel" gutterBottom>ชื่อคู่ธุรกิจ</Typography>
                        <TextFieldTheme
                          {...field}
                          size="small"
                          fullWidth
                          helperText={errors && errors.vendorName && errors.vendorName.message}
                          error={errors && errors.vendorName ? true: false}
                          autoComplete="vendorName"
                        />
                      </Fragment>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller 
                    name="vendorNameShort"
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณากรอกชื่อย่อ Vendor"}
                    }}
                    render={({ field }) => (
                      <Fragment>
                        <Typography className="fieldLabel" gutterBottom>ชื่อย่อคู่ธุรกิจ</Typography>
                        <TextFieldTheme
                          {...field}
                          size="small"
                          fullWidth
                          helperText={errors && errors.vendorNameShort && errors.vendorNameShort.message}
                          error={errors && errors.vendorNameShort ? true: false}
                          autoComplete="vendorNameShort"
                        />
                      </Fragment>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name="mainContactName"
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณากรอกชื่อผู้ติดต่อ"}
                    }}
                    render={({field})=>(
                      <Fragment>
                        <Typography className="fieldLabel" gutterBottom>ชื่อผู้ติดต่อ</Typography>
                        <TextFieldTheme
                          {...field} 
                          fullWidth
                          helperText={errors && errors.mainContactName && errors.mainContactName.message}
                          error={errors && errors.mainContactName ? true: false}
                          autoComplete="mainContactName"
                        />     
                      </Fragment>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name="mainContactPhone"
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณากรอกเบอร์โทรอย่างน้อย 9 หลัก"},
                      validate: (value) => (!isNaN(value) && value.length >= 9) || "กรุณากรอกเบอร์โทรอย่างน้อย 9 หลัก"
                    }}
                    render={({field})=>(
                      <Fragment>
                        <Typography className="fieldLabel" gutterBottom>เบอร์โทร</Typography>
                        <TextFieldTheme
                        {...field}
                        fullWidth
                        helperText={errors && errors.mainContactPhone && errors.mainContactPhone.message}
                        error={errors && errors.mainContactPhone ? true: false}
                        autoComplete="mainContactPhone"
                      />     
                      </Fragment>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name="mainContactEmail"
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณากรอกอีเมล"},
                      pattern: {
                        // value: /^[A-Za-z0-9\_\.]+@[a-z]+\.[A-Za-z\.]{2,4}$/,
                        value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z.]{1,}[A-z]$/,
                        message: "กรุณากรอกอีเมลให้ถูกต้อง เช่น 'antjob@example.com'"
                      }
                    }}
                    render={({field})=>(
                      <Fragment>
                        <Typography className="fieldLabel" gutterBottom>อีเมล</Typography>
                        <TextFieldTheme
                        {...field}
                        fullWidth
                        helperText={errors && errors.mainContactEmail && errors.mainContactEmail.message}
                        error={errors && errors.mainContactEmail ? true: false}
                        autoComplete="mainContactEmail"
                      />
                      </Fragment>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณากรอกรหัสผ่าน"},
                      validate: (value) => (value.length >= 8) || "กรุณากรอกรหัสผ่าน อย่างน้อย 8 ตัว",
                      pattern: {
                        value: /^[A-Za-z0-9_]+$/,
                        message: "กรุณากรอกรหัสผ่าน เป็นตัวภาษาอังกฤษ ตัวเลข หรือเครื่องหมาย_เท่านั้น"
                      }
                    }}
                    render={({field})=>(
                      <Fragment>
                        <Typography className="fieldLabel" gutterBottom>รหัสผ่าน</Typography>
                        <TextFieldTheme
                          {...field}
                          fullWidth
                          type="password"
                          helperText={errors && errors.password && errors.password.message}
                          error={errors && errors.password ? true: false}
                        />
                      </Fragment>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณากรอกยืนยันรหัสผ่าน"},
                      validate: (value) => (value === getValues("password")) || "รหัสผ่านไม่ตรงกัน",
                    }}
                    render={({field})=>(
                      <Fragment>
                        <Typography className="fieldLabel" gutterBottom>ยืนยันรหัสผ่าน</Typography>
                        <TextFieldTheme
                          {...field}
                          fullWidth
                          type="password"
                          helperText={errors && errors.confirmPassword && errors.confirmPassword.message}
                          error={errors && errors.confirmPassword ? true: false}
                        />
                      </Fragment>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="acceptPrivacyPolicy"
                    control={control}
                    rules={{
                      required: {value: true, message: "กรุณายอมรับ เงื่อนไขการให้บริการ และ นโยบายความเป็นส่วนตัว ของ AntJob"},
                    }}
                    render={({field})=>(
                      <Fragment>
                        <FormControlLabel
                          label={<Typography>ยอมรับ <Link to="" target="_blank">เงื่อนไขการให้บริการ</Link> และ <Link to="" target="_blank">นโยบายความเป็นส่วนตัว</Link> ของ AntJob</Typography>}
                          control={
                            <Checkbox {...field} />
                          } 
                        />
                        <FormHelperText error={errors && errors.acceptPrivacyPolicy ? true: false}>
                          {errors && errors.acceptPrivacyPolicy && errors.acceptPrivacyPolicy.message}
                        </FormHelperText>
                      </Fragment>
                    )}
                  />
                </Grid>

                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                  <Typography>เป็นสมาชิกอยู่แล้ว ? <Link to="/login">เข้าสู่ระบบ</Link></Typography>
                  <ButtonBlue variant="contained" type="submit">สมัครสมาชิก</ButtonBlue>
                </Grid>

              </Grid>
            </form>
          </Fragment>
        )}
        {isSuccess && (
          <SignupSuccess />
        )}
      </Container>
    </StyledRoot>
  )
}

export default VendorSingUp;