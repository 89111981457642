import React from "react";
import { Avatar, CardContent, Chip, styled, Typography } from "@mui/material";
import CardStyle from "./Card";

import CcContent from "../../assets/vendor/kec.jpg"

import Utils from "../../../../utils"
import { Link } from "react-router-dom";

const StyledCardContent = styled(CardContent)({
  padding: 24,
  "& .card-header": {
    paddingBottom: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiChip-root": {
      color: "#637381",
      fontSize: 16,
      borderRadius: 8,
      "& i": {
        marginRight: 8,
      }
    }
  },
  "& .card-logo": {
    paddingBottom: 16,
    "& .MuiAvatar-root": {
      margin: "0 auto",
      width: 180,
      height: 180,
      backgroundColor: "white",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
    },
    "& i": {
      margin: "0 auto",
      width: 180,
      height: 180,
      fontSize: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }
  },
  "& .card-vendor-name": {
    // paddingBottom: 16,
    textAlign: "center",
    "& .MuiTypography-root": {
      fontSize: 22,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "& a": {
      textDecoration: "none",
      color: "inherit",
    }
  }
})

const CardCompany = (props) => {

  const { company } = props;

  return(
    <CardStyle>
      <StyledCardContent>
        <div className="card-header">
          <Chip label={<span><i className="fa-solid fa-square-list"></i>{`${Utils.numberWithCommas(company.CountJobRequest || 0)}`}</span>}/>
        </div>
        <div className="card-logo">
          <Avatar
            variant="rounded"
            src={company.companyImageURL}
            // src={company.companyImage? `${process.env.REACT_APP_API_URL}image/company/${company.companyImage}`: ""}
            component={Link}
            to={`company/${company.companyNameShort || ""}`}
          >
            <img src="assets/logo_default.png" />
          </Avatar>
        </div>
        <div className="card-vendor-name">
          <Link to={`company/${company.companyNameShort}`}>
            <Typography variant="h6">
                {company.companyName || company.companyNameShort || "ไม่ระบุ"}
            </Typography>
          </Link>
        </div>
      </StyledCardContent>
    </CardStyle>
  )
}

export default CardCompany;