import React from "react";
import { styled } from "@mui/material";
// import makeStyles from '@mui/styles/makeStyles';

// const useStyles = makeStyles((theme) => ({
//     content: {
//       display: "flex",
//       justifyContent: "center",
//     },
//   }));

const StyledRoot = styled("div")({
  display: "flex",
  justifyContent: "center",
})

const NotFoundPage = () => {
    // const classes = useStyles();
    return (
        // <div className={`page ${classes.content}`}>
        //     <img width={600} alt="not found" src={`${process.env.PUBLIC_URL}/assets/background/error404.jpg`} />
        //     TEST
        //     NOT Found
        // </div>
      <StyledRoot className="page">
        <img width={600} alt="not found" src={`/assets/background/error404.jpg`} />
      </StyledRoot>
    )
}

export default NotFoundPage;