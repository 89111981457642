import React, { useEffect, useState } from "react";
import { 
  Autocomplete, 
  Box,
  Container, 
  Grid, 
  Pagination, 
  styled, 
  Typography 
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import CardRequest from "../../shared/general/CardRequest";

import { getAllWorkingLocations } from "../../../../actions/address";
import { getJobs, getJobGroups } from "../../../../actions/job";

const StyledRoot = styled("div")({
  "& .part-filter": {
    marginBottom: 48,
    padding: "24px 0",
    "& .card-filter":{
      padding: "12px 24px",
      backgroundColor: "#f9fafb",
      borderRadius: 20,
    },
    "& .MuiAutocomplete-root": {
      "& input": {
        padding: 0,
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiInputAdornment-root": {
        "& i": {
          fontSize: 24,
          color: "#919EAB",
        }
      },
      "& .MuiButtonBase-root": {
        "& i": {
          fontSize: 14,
          color: "#919EAB",
        }
      }
    }
  },
  "& .part-pagination": {
    paddingTop: 24,
    display: "flex",
    justifyContent: "flex-end",
  }
});

const StyledTextField = styled(TextFieldTheme)({
  "& input":{
    padding:"9px !important"
  },
  "& fieldset":{
    border:"none"
  }
});

const BoxFilter = styled(Box)({
  display:"flex", 
  alignItems:"center",
  padding: "12px 24px",
  backgroundColor: "#f9fafb",
  borderRadius: 20,
  "& .MuiButtonBase-root":{
    "&:hover":{
      backgroundColor:"#f9fafb"
    }
  }
});

const CompanySearchPage = () => {

  const dispatch = useDispatch();
  const search = useLocation();

  const { control, getValues, setValue } = useForm({
    defaultValues: {
      province: null,
      jobGroup: null,
      positionName: "",
      companyName: ""
    }
  });

  const { result: AllWorkingLocations } = useSelector((state) => state.workingLocation);
  const { result: AllJobGroups } = useSelector(state => state.jobGroups);
  const { isFethcing: isFetchingAllJobs, result: AllJobs } = useSelector((state) => state.jobs);

  const [page, setPage] = useState(1);
  let formChangeTime;

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  }

  const onFormChange = () => {
    setPage(1)
    clearTimeout(formChangeTime)
    formChangeTime = setTimeout(() => {
      onSubmitHandler()
    }, 700)
  }

  const onSubmitHandler = () => {
    let formData = getValues();
    let query = {
      province: (formData.province && formData.province.workingLocationNameTH) || null,
      jobGroup: (formData.jobGroup && formData.jobGroup.idJobGroup) || null,
      positionName: formData.positionName || null,
      companyName: formData.companyName || null
    };
    dispatch(getJobs(query));
  }

  useEffect(() => {
    dispatch(getJobGroups());
    dispatch(getAllWorkingLocations()); 
    dispatch(getJobs());
  }, []);

  useEffect(() => {
    let province = new URLSearchParams(search.search).get("province");
    if(province !== null && AllWorkingLocations !== null){
      let val = AllWorkingLocations.find(location => location.workingLocationNameTH.includes(province));
      setValue("province",val);
      onSubmitHandler();
    }
  },[AllWorkingLocations]);

  useEffect(() => {
    let jobGroup = new URLSearchParams(search.search).get("jobGroup");
    if(jobGroup !== null && AllJobGroups !== null){
        let val = AllJobGroups.find(group => parseInt(group.idJobGroup) === parseInt(jobGroup));
        setValue("jobGroup",val);
        onSubmitHandler();
    }
  },[AllJobGroups]);

  useEffect(() => {
    let companyName = new URLSearchParams(search.search).get("companyName");
    if(companyName !== null){
      setValue("companyName",companyName);
      onSubmitHandler();
    }
  },[]);

  return(
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="part-filter">
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <BoxFilter>
                  <i className="fa-light fa-square-list" style={{ fontSize: "24px", color: "#919EAB" }}></i>
                  <Controller
                    control={control}
                    name="positionName"
                    render={({ field: { onChange, value } }) => (
                      <StyledTextField
                        value={value}
                        placeholder="ชื่อตำแหน่งงาน"
                        autoComplete="positionName"
                        onChange={(e) => {
                          onChange(e)
                          onFormChange();
                        }}
                      />
                    )}
                  />
                </BoxFilter>
              </Grid>
              <Grid item xs={6}>
                <BoxFilter>
                  <i className="fa-light fa-building-user" style={{ fontSize: "24px", color: "#919EAB" }}></i>
                  <Controller
                    control={control}
                    name="companyName"
                    render={({ field: { onChange, value }}) => (
                      <StyledTextField
                        value={value}
                        placeholder="บริษัท"
                        autoComplete="companyName"
                        onChange={(e) => {
                          onChange(e);
                          onFormChange();
                        }}
                      />
                    )}
                  />
                </BoxFilter>
              </Grid>
              
              <Grid item xs={6}>
                <BoxFilter>
                  <i className="fa-light fa-briefcase" style={{ fontSize: "24px", color: "#919EAB" }}></i>
                  <Controller
                    control={control}
                    name="jobGroup"
                    render={({ field: { onChange, value }}) => (
                      <Autocomplete
                        fullWidth
                        options={AllJobGroups || []}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => option.jobGroupName}
                        isOptionEqualToValue={(option, value) => option.jobGroupName === value.jobGroupName}
                        renderOption={(props, option) => (
                          <li {...props}>
                            {option.jobGroupName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            placeholder="ประเภทกลุ่มงาน"
                            InputProps={{
                              ...params.InputProps
                            }}
                          />
                        )}
                        onChange={(_, data) => {
                          onChange(data);
                          onSubmitHandler()
                        }}
                        value={value}
                      />
                    )}
                  />
                </BoxFilter>
              </Grid>
              
              <Grid item xs={6}>
                <BoxFilter>
                  <i className="fa-light fa-location-dot" style={{ fontSize: "24px", color: "#919EAB" }}></i>
                  <Controller
                    control={control}
                    name="province"
                    render={({ field: { onChange, value }}) => (
                      <Autocomplete
                        fullWidth
                        options={AllWorkingLocations || []}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) => option.workingLocationNameTH}
                        isOptionEqualToValue={(option, value) => option.workingLocationNameTH === value.workingLocationNameTH}
                        renderOption={(props, option) => (
                          <li {...props}>
                            {option.workingLocationNameTH}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            placeholder="จังหวัด"
                            InputProps={{
                              ...params.InputProps
                            }}
                          />
                        )}
                        onChange={(_, data) => {
                          onChange(data);
                          onSubmitHandler()
                        }}
                        value={value}
                      />
                    )}
                  />
                </BoxFilter>
              </Grid>
            </Grid>
          </form>
        </div>

        <div style={{ marginBottom:"20px" }}>
          {(!isFetchingAllJobs && AllJobs && AllJobs.length > 0) && 
            <Grid container columnSpacing={4} rowSpacing={4}>
              {AllJobs
                .slice((page-1) * 12, ((page-1) * 12) + 12)
                .map((job) => (
                  <Grid key={`job_${job.idJob}`} item xs={4}>
                    <CardRequest value={job} />
                  </Grid>
              ))}
            </Grid>
          }
          {(!isFetchingAllJobs && AllJobs && AllJobs.length <= 0) && 
            <Typography align="center" variant="h5">ไม่พบงานที่ต้องการ</Typography>
          }
          {(isFetchingAllJobs) && 
            <Typography align="center" variant="h5">กำลังโหลดข้อมูล</Typography>
          }
        </div>

        {AllJobs && AllJobs.length > 0 && 
          <div className="part-pagination">
            <Pagination
              count={Math.ceil((AllJobs ? AllJobs.length : 0) / 12) || 1}
              shape="rounded"
              onChange={onChangePage}
              page={page}
            />
          </div>
        }

      </Container>
    </StyledRoot>
  )
}

export default CompanySearchPage;