import React, { Fragment, useState } from "react";
import dayjs from 'dayjs';
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Chip, Divider, Grid, IconButton, styled, Tooltip, Typography } from "@mui/material";

import CardStyle from "../../../shared/general/Card";
import DrawerEducationsExperiencesLeft from "./drawerEducationsExperiencesLeft";
import DrawerName from "./drawerName";
import DrawerPersonalInfo from "./drawerPersonalInfo";
import { getManpowerById, updateManpowerStatus } from "../../../../../actions/manpower";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogUploadResume from "./dialogUploadResume";
import Resume from "./resume";
import utils, { stringToColor } from "../../../../../utils";

const StyledRoot = styled('div')({
  padding: 24,
  paddingTop: 16,
  "& .part-avatar": {
    marginBottom: 16,
    display: "flex",
    justifyContent: "center",
    "& .MuiAvatar-root": {
      width: 150,
      height: 150,
    },
  },
  "& .part-name": {
    "& .MuiTypography-h5": {
      textAlign: "center",
    },
    "& .vendor-name": {
      textAlign: "center",
      color: "#007afd",
    }
  },
  "& .MuiDivider-root": {
    margin: "8px 0",
    borderWidth: "0px 0px thin",
    borderColor: "#919eab52",
    borderStyle: "dashed",
  },
  "& .header-text": {
    marginBottom: 16,
    fontSize: 20,
  }
})

const StyledChipAlrJob = styled(Chip)({
  width:120,
  borderRadius: 4,
  height: "unset",
  padding: 8,
  backgroundColor:"#eb7d7d",
  color:"#b81a1a",
  fontSize: 14,
});

const StyledChipFree = styled(Chip)({
  width:120,
  borderRadius: 4,
  height: "unset",
  padding: 8,
  fontSize: 14,
  backgroundColor: "#76d576",
  color: "#0b5f10"
});

const StyledChipWaiting = styled(Chip)({
  width:120,
  borderRadius: 4,
  height: "unset",
  padding: 8,
  fontSize: 14,
  backgroundColor:"#fff0ce",
  color:"#ff9800"
});

const BoxTooltip = styled(Box)({
  display:"flex",
  padding: "5px 10px",
  "& .firstChip":{
    marginRight: 8
  }
});

const EditIconButton = styled(IconButton)(({theme}) => ({
  position:"absolute", 
  right:0, 
  top:0, 
  padding:8,
  color: theme.palette.primary.main,
  "&:hover":{
    backgroundColor:"transparent"
  }
}));

const BoxFlexColumn = styled(Box)(({theme}) => ({
  display:"flex", 
  flexDirection:"column", 
  position:"relative"
}));

const BoxAndEditButton = (props) => {
  const { component: Component, children  } = props
  const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile); 
  const { result: UserProfile } = useSelector(state => state.userProfile);
  const [openDrawer, setOpenDrawer] = useState(false);

  const onClose = () => {
    setOpenDrawer(false);
  }

  return (
    <BoxFlexColumn>
      {UserProfile && ManpowerProfile && 
        UserProfile.idVendor === ManpowerProfile.idVendor && 
        <EditIconButton
          size="small"
          onClick={() => { setOpenDrawer(true) }}
        >
          <i className="fa-solid fa-pen" />
        </EditIconButton>
      }
      {children}
      {openDrawer && 
        <Component open={openDrawer} onClose={onClose} data={ManpowerProfile} idManpower={ManpowerProfile.idManpower}/>
      }
    </BoxFlexColumn>
  );
};

const LeftPanel = () => {

  const { result: ManpowerProfile } = useSelector(state => state.manpowerProfile);
  const { result: UserProfile } = useSelector(state => state.userProfile);

  const [isOpenDialogResume, setIsOpenDialogResume] = useState(false);

  const dispatch = useDispatch();

  // const renderDegree = (degree) => {
  //   switch(Number(degree)){
  //     case 1: return "ประถมศึกษา";
  //     case 2: return "มัธยมศึกษา";
  //     case 3: return "ปวช./ปวส.";
  //     case 4: return "ปริญญาตรี";
  //     case 5: return "ปริญญาโท";
  //     case 6: return "ปริญญาเอก";
  //     default: return "-";
  //   }
  // }

  const renderAge = (totalMonth) => {
    const year = Math.floor(totalMonth / 12);
    const month = (totalMonth % 12)
    return `${year} ปี ${month} เดือน`
  }

  const onChangeStatusHandler = (idStatus) => {
    dispatch(updateManpowerStatus(ManpowerProfile.idManpower, { idStatus: idStatus })).then(res => {
      dispatch(getManpowerById(ManpowerProfile.idManpower));
    })
  }

  return(
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardStyle>
            <StyledRoot>
              <BoxAndEditButton
                component={DrawerName}
              >
                {ManpowerProfile && <div className="part-avatar">
                  {/* <Avatar src={`${process.env.REACT_APP_API_URL}image/profile/${(ManpowerProfile.idManpower % 40)+1}.jpg`}/> */}
                  <Avatar sx={{bgcolor: stringToColor(`${ManpowerProfile.name} ${ManpowerProfile.lastname}`), fontSize: 48}} children={`${ManpowerProfile.name[0]}${ManpowerProfile.lastname[0]}`}/>
                </div>}

                <div className="part-name">
                  {/* <Typography variant="h5">
                    {ManpowerProfile ? `${ManpowerProfile.name} ${ManpowerProfile.lastname}` : "-"}
                  </Typography> */}
                  <Typography variant="h5">{ManpowerProfile.title} {ManpowerProfile.name} {ManpowerProfile.lastname}</Typography>
                  <Typography className="vendor-name">{ManpowerProfile ? ManpowerProfile.vendorName : "-"}</Typography>
                </div>
              </BoxAndEditButton>
              <Divider />
              <BoxAndEditButton
                component={DrawerPersonalInfo}
              >
                <Typography variant="caption" color="text.secondary" fontSize={14}>เลขบัตรประชาชน</Typography>
                <Typography>{ManpowerProfile.personalId || "-"}</Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>อายุ</Typography>
                <Typography>{ManpowerProfile.dateOfBirth? renderAge(dayjs(new Date()).diff(ManpowerProfile.dateOfBirth, 'month')): "-"}</Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>เบอร์โทร</Typography>
                <Typography>{ManpowerProfile && ManpowerProfile.telephoneMobile || "-"}</Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>อีเมล</Typography>
                <Typography>{ManpowerProfile && ManpowerProfile.email || "-"}</Typography>
              </BoxAndEditButton>
              <Divider />
              <BoxAndEditButton
                component={DrawerEducationsExperiencesLeft}
              >
                <Typography variant="caption" color="text.secondary" fontSize={14}>ประสบการณ์ทำงาน</Typography>
                
                <Typography>
                  {(ManpowerProfile['OESY(Y)'] || ManpowerProfile['OESY(M)']) ?
                    <>
                      {ManpowerProfile['OESY(Y)'] && `${ManpowerProfile['OESY(Y)']} ปี`} {ManpowerProfile['OESY(M)'] && `${ManpowerProfile['OESY(M)']} เดือน`}
                    </> :
                    'ไม่มีประสบการณ์ทำงาน'}
                </Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>ระดับการศึกษา</Typography>
                <Typography>{ManpowerProfile['educationDegree'] || 'ไม่ระบุระดับการศึกษา'}</Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>เงินเดือนปัจจุบัน</Typography>
                <Typography>{(ManpowerProfile['currentSalary'] && `${utils.numberWithCommas(ManpowerProfile['currentSalary'])} บาท`) || '-'}</Typography>

                <Typography variant="caption" color="text.secondary" fontSize={14}>เงินเดือนที่คาดหวัง</Typography>
                <Typography>{(ManpowerProfile['expectedSalary'] && `${utils.numberWithCommas(ManpowerProfile['expectedSalary'])} บาท`) || '-'}</Typography>

              </BoxAndEditButton>
            </StyledRoot>
          </CardStyle>
        </Grid>

        <Grid item xs={12}>
          <CardStyle>
            <StyledRoot>
              <Typography className="header-text" variant="h5">สถานะ</Typography>

              {UserProfile && (UserProfile.idVendor === ManpowerProfile.idVendor) ?(
                <Fragment>
                  {ManpowerProfile.idStatus === 6 && (
                    <Tooltip
                      title={
                        <BoxTooltip>
                          <StyledChipAlrJob label="ไม่ว่าง" className="firstChip" onClick={() => {onChangeStatusHandler(7)}}/>
                        </BoxTooltip>
                      }
                      placement="top"
                    >
                      <StyledChipFree label="ว่าง"/>
                    </Tooltip>
                  )}

                  {ManpowerProfile.idStatus === 7 && (
                    <Tooltip
                      title={
                        <BoxTooltip>
                          <StyledChipFree label="ว่าง" className="firstChip" onClick={() => {onChangeStatusHandler(6)}}/>
                        </BoxTooltip>
                      }
                      placement="top"
                    >
                      <StyledChipAlrJob label="ไม่ว่าง"/>
                    </Tooltip>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {ManpowerProfile.idStatus === 6 && (
                    <StyledChipFree label="ว่าง"/>
                  )}
                  {ManpowerProfile.idStatus === 7 && (
                    <StyledChipAlrJob label="ไม่ว่าง"/>
                  )}
                </Fragment>
              )}

              {![6, 7].includes(ManpowerProfile.idStatus) && (
                <StyledChipWaiting label="รอดำเนินการ" />
              )}
            </StyledRoot>
          </CardStyle>
        </Grid>

        {/* <Grid item xs={12}>
          <CardStyle>
            <Box padding="24px">
              <ButtonBlue
                startIcon={<i className="fa-solid fa-up-to-line"></i>}
                variant="outlined"
                fullWidth
                padding="16px"
                onClick={()=>{setIsOpenDialogResume(true)}}
              >
                อัพโหลด เรซูเม่
              </ButtonBlue>
              
              <DialogUploadResume
                open={isOpenDialogResume}
                onClose={()=>{setIsOpenDialogResume(false)}}
              />
            </Box>
          </CardStyle>
        </Grid> */}

        <Grid item xs={12}>
          <Resume
            idManpower={ManpowerProfile.idManpower}
            data={ManpowerProfile.resume}
            isMyManpower={
              UserProfile && ManpowerProfile && 
              (UserProfile.idVendor === ManpowerProfile.idVendor)
            }
          />
        </Grid>

      </Grid>
    </Fragment>
  )
}

export default LeftPanel;