import React, { useState } from "react";
import { styled, Tab, Tabs, Typography } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import ProfilePanel from "./profilePanel";
import AddressPanel from "./addressPanel";
import BankPanel from "./bankPanel";
import ExpertisePanel from "./expertisePanel";
import AdminSetting from "./adminSetting";

const StyledRoot = styled("div")({
  padding: 24,
  paddingTop: 16,
  "& .MuiTabs-root": {
    marginBottom: 16,
  },
  "& i": {
    marginRight: 8,
  }
});

const RightPanel = (props) => {

  const { vendor, vendorAddress, bankDetail, branches } = props;

  const [tabValue, setTabValue] = useState(0);
  const [adminList, setAdminList] = useState(vendor.admin);

  const handleTabChangeHandler = (_, newValue) => {
    setTabValue(newValue);
  }

  return(
    <CardStyle>
      <StyledRoot>
        <Tabs value={tabValue} onChange={handleTabChangeHandler} variant="scrollable">
          <Tab label={<Typography><i className="fa-solid fa-building"></i>Vendor</Typography>}></Tab>
          <Tab label={<Typography><i className="fa-solid fa-map-location-dot"></i>ที่อยู่</Typography>}></Tab>
          <Tab label={<Typography><i className="fa-solid fa-building-columns"></i>ธนาคาร</Typography>}></Tab>
          <Tab label={<Typography><i className="fa-solid fa-trophy-star"></i>ความเชี่ยวชาญ</Typography>}></Tab>
          <Tab label={<Typography><i className="fa-solid fa-users-gear"></i>ผู้ดูแล</Typography>}></Tab>
        </Tabs>

        {tabValue === 0 && <ProfilePanel vendor={vendor}/>}
        {tabValue === 1 && <AddressPanel vendorAddress={vendorAddress} branches={branches} vendor={vendor}/>}
        {tabValue === 2 && <BankPanel bankDetail={bankDetail} vendor={vendor}/>}
        {tabValue === 3 && <ExpertisePanel />}
        {tabValue === 4 && <AdminSetting adminList={adminList} setAdminList={setAdminList}/>}
      </StyledRoot>
    </CardStyle>
  )
}

export default RightPanel;