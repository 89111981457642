import { httpClient } from "./httpClient";

const getAllJobRequestAllVendor = () => {
    return httpClient.get("jobs/all-vendor");
};

const getJobForm = () => {
    return httpClient.get("jobs/jobForm");
};

const getJobRequestByIdJobRequest = (idjobRequest) => {
    return httpClient.get("jobs/"+idjobRequest);
};

const getCompanyMyJob = () => {
    return httpClient.get(`companies/myjobs`);
};

const closeJob = (idJob) => {
    return httpClient.patch(`job/${idJob}/close_job`);
};

const addJob = (formData) => {
    return httpClient.post(`jobs`, formData);
};

const updateJob = (idJob, formData) => {
    return httpClient.patch(`jobs/${idJob}`, formData);
};

const getJobGroups = () => {
    return httpClient.get("job-groups");
};

const getJobEdit = (idJob) => {
    return httpClient.get(`jobs/${idJob}/edit`);
};

const getHistoryJobsCompany = () => {
    return httpClient.get(`company/jobs/history`);
};

const addJobAttachFile = (idJob, formData) => {
    return httpClient.post(`jobs/${idJob}/attachFile`, formData);
};

const deleteJobAttachFile = (idJob) => {
    return httpClient.delete(`jobs/${idJob}/attachFile`);
};

const getJobs = (query) => {
    return httpClient.get(`jobs`,{ params: query });
};

export default {
    getAllJobRequestAllVendor,
    getJobForm,
    getJobRequestByIdJobRequest,
    getCompanyMyJob,
    closeJob,
    addJob,
    updateJob,
    getJobGroups,
    getJobEdit,
    getHistoryJobsCompany,
    addJobAttachFile,
    deleteJobAttachFile,
    getJobs
};