import React, { Fragment, useState } from "react";
import { Box, Dialog, Grid, IconButton, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Dropzone from "react-dropzone";
import AddIcon from "../../assets/add.png"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { addContractCompany } from "../../../../actions/contract";


const StyledRoot = styled(Box)({
  padding: 16,
  // minWidth: 600,
  boxSizing: "border-box",
  "& .dropzone": {
    marginTop: 16,
    minHeight: 144,
    maxHeight: 144,
    height: "100%",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .inner-dropzone": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& img": {
        width: 80,
        height: 80,
      }
    }
  },
  "& .file-wrap": {
    textDecoration: "none",
    color: "inherit",
    position: "relative",
    cursor: "pointer",
    flexShrink: 0,
    padding: 8,
    width: "100%",
    height: 144,
    boxSizing: "border-box",
    border: "1px solid #D0D0D0",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    "& .icon-part": {
      flexGrow:1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& i": {
        fontSize: 56,
        color: "#D0D0D0"
      }
    },
    "& .filename": {
      flexShrink: 1,
      height: "48px",
      "& .MuiTypography-root": {
        fontSize: 14,
        display: "-webkit-box",
        overflow: "hidden",
        WebkitLineClamp: 2, 
        WebkitBoxOrient: "vertical",
        textAlign: "center",
        wordBreak: "break-all",
      }
    },
    "& .delete": {
      position: "absolute",
      top: 0,
      right: 0,
      padding: 8,
      "& i": {
        fontSize: 16,
      }
    }
  }
})

const DialogAddContract = (props) => {

  const {open, onClose, idVendor, setStatusDialogConfig} = props;

  const [isFetching, setIsFetching] = useState(false);

  const {control, handleSubmit, getValues, setError } = useForm({
    defaultValues: {
      startDate: null,
      endDate: null,
      contractFile: null,
    }
  });

  const dispatch = useDispatch();

  const onSubmit = async (submitData) => {

    setIsFetching(true);

    const formData = new FormData();

    formData.append("startDate", submitData.startDate)
    formData.append("endDate", submitData.endDate)
    formData.append("contractFile", submitData.contractFile)
    formData.append("idVendor", idVendor)

    await dispatch(addContractCompany(formData)).then(res => {
      if(res.status === 200){
        setIsFetching(false);
        onClose();
        setStatusDialogConfig({
          isOpen: true,
          status: "success",
          message: "เพิ่มสัญญาสำเร็จ",
        })
      }
    })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>

          <Typography variant="h6">อัพโหลดไฟล์สัญญา</Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="contractFile"
                rules={{
                  required: {
                    value: true,
                    message: "กรุณาอัพโหลดไฟล์สัญญา"
                  }
                }}
                render={({field, formState}) => (
                  <Fragment>
                    {!field.value && 
                    <Dropzone
                      onDrop={(acceptedFiles, rejectedFiles) => {
                        if(acceptedFiles.length > 0){
                          field.onChange(acceptedFiles[0])
                        }
                      }}
                      accept="application/pdf"
                      maxFiles={1}
                      multiple={false}
                      maxSize={10485760}
                    >
                      {({
                        getRootProps,
                        getInputProps,
                      }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                            <Box>
                              <input {...getInputProps()}/>
                              <Box className="inner-dropzone">
                                <img alt="add" src={AddIcon} />
                                <Typography paddingTop="8px" fontSize={14} color="text.third">อัพโหลดเรซูเม่ได้ที่นี่</Typography>
                              </Box>
                            </Box>
                        </div>
                        )
                      }
                    </Dropzone>}

                    {field.value && 
                      <Box className="file-wrap" component={"a"} href={URL.createObjectURL(field.value)} target="_blank">
                        <Box className="icon-part">
                          <i className="fa-solid fa-file"></i>
                        </Box>
                        <Box className="filename">
                          <Typography>{field.value.name}</Typography>
                        </Box>
                        <IconButton className="delete" onClick={(e)=>{e.preventDefault(); field.onChange(null)}}>
                          <i className="fa-solid fa-trash-can"></i>
                        </IconButton>
                      </Box>
                    }

                    {/* <FormHelperText error>*สามารถอัพโหลดได้เฉพาะไฟล์ .pdf เท่านั้น</FormHelperText> */}

                  </Fragment>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="startDate"
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณากรอกวันที่เริ่มสัญญา"
                    },
                    validate: {
                      endDateMoreThanstart: () => {
                        if(!getValues("startDate") || !getValues("endDate")){
                          return true;
                        } else if(dayjs(getValues("endDate")).isAfter(dayjs(getValues("startDate")))){
                          return true;
                        } else {
                          return "ไม่สามารถเลือกวันสิ้นสุดสัญญาก่อนวันเริ่มต้นสัญญาได้"
                        }
                      }
                    }
                  }}
                  render={({field, formState}) => (
                    <DesktopDatePicker
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="DD MMMM YYYY"
                      // disableFuture
                      disableMaskedInput
                      openTo="year"
                      onChange={(value)=>{
                        field.onChange(value)
                      }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          label="วันที่เริ่มสัญญา"
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "",
                            readOnly: true
                          }}
                          error={formState.errors && formState.errors[field.name]? true: false}
                          helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="endDate"
                  rules={{
                    required: {
                      value: true,
                      message: "กรุณากรอกวันที่สิ้นสุดสัญญา"
                    },
                    validate: {
                      endDateMoreThanstart: () => {
                        if(!getValues("startDate") || !getValues("endDate")){
                          return true;
                        } else if(dayjs(getValues("endDate")).isAfter(dayjs(getValues("startDate")))){
                          return true;
                        } else {
                          return "ไม่สามารถเลือกวันสิ้นสุดสัญญาก่อนวันเริ่มต้นสัญญาได้"
                        }
                      }
                    }
                  }}
                  render={({field, formState}) => (
                    <DesktopDatePicker
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="DD MMMM YYYY"
                      // disableFuture
                      disableMaskedInput
                      openTo="year"
                      onChange={(value)=>{
                        field.onChange(value)
                      }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          label="วันที่สิ้นสุดสัญญา"
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "",
                            readOnly: true
                          }}
                          error={formState.errors && formState.errors[field.name]? true: false}
                          helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message? formState.errors[field.name].message: null}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <ButtonBlue variant="contained" type="submit" disabled={isFetching}>เพิ่มสัญญา</ButtonBlue>
              </Box>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </Dialog>
  )
}

export default DialogAddContract