import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogContent, Grid, MenuItem, styled, Typography } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { useDispatch } from "react-redux";
import { getBiddingById, submitBid } from "../../../../actions/bidding";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import DropAttachFile from "../../shared/general/DropAttachFile";

const StyledRoot = styled("div")({
  "& .input-with-select": {
    "& .MuiInputBase-root": {
      paddingRight: 0,
    },
    "& .select-input": {
      width: 110,
      "& .MuiSelect-select": {
        color: "#919eab",
      },
      "& fieldset": {
        border: "none",
        borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      "& .MuiOutlinedInput-input": {
        textAlign: "right",
        paddingLeft: "4px!important",
      }
    }
  }
})

const NumberCustom = React.forwardRef((props, ref) => {
  return <NumberFormat {...props} getInputRef={ref}/>
})

const BiddingOfferCharge = (props) => {
  const { open, onClose, idBidding, values, chargeMax } = props

  const [isFetching, setIsFetching] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [deletingFiles, setDeletingFiles] = useState([]);

  const dispatch = useDispatch();

  const { control, handleSubmit, formState: { errors }, getValues, clearErrors, watch, setValue, setError } = useForm({
    defaultValues: {
      chargeSalaryOffer: (values && values.chargeSalaryOffer) || "",
      chargeOtOffer: (values && values.chargeOtOffer) || "",
      chargeHireOffer: (values && values.chargeHireOffer) || "",
      chargeCompensationOffer: (values && values.chargeCompensationOffer) || "",
      description: (values && values.description) || "",
      chargeHireOfferType: (values && values.chargeHireOfferType) || "1",
      attachFiles: [],
    }
  });

  const chargeHireOfferTypeWatch = watch("chargeHireOfferType");

  const onDeleteCurrentFiles = (newCurrentFiles, fileDeleteName) => {
    setCurrentFiles(newCurrentFiles);
    setDeletingFiles([...deletingFiles, fileDeleteName])
  }

  const onSubmit = async (formData) => {
    clearErrors("atLeastOne")
    if(!(formData.chargeSalaryOffer || formData.chargeOtOffer || formData.chargeHireOffer || formData.chargeCompensationOffer)){
      setError('atLeastOne', { type: 'custom', message: 'กรุณาเสนอค่าชาร์จอย่างน้อย 1 ค่าชาร์จ' });
      return
    }

    setIsFetching(true)

    const cleansingData = new FormData();

    cleansingData.append("chargeSalaryOffer", formData.chargeSalaryOffer)
    cleansingData.append("chargeOtOffer", formData.chargeOtOffer)
    cleansingData.append("chargeHireOffer", formData.chargeHireOffer)
    cleansingData.append("chargeCompensationOffer", formData.chargeCompensationOffer)
    cleansingData.append("description", formData.description)
    cleansingData.append("chargeHireOfferType", formData.chargeHireOfferType)
    cleansingData.append("bidType", 1)

    formData.attachFiles.map((f) => {
      cleansingData.append("attachFiles", f)
    })

    deletingFiles.map(filename => {
      cleansingData.append("deleteFiles", filename)
    })

    const response = await dispatch(submitBid(idBidding, cleansingData))
    setIsFetching(false)

    if(response.status === 200){
      dispatch(getBiddingById(idBidding));
      onClose();
    }
  }

  useEffect(() => {
    if(values.attachFile){
      setCurrentFiles([
        {
          name: values.attachFile,
          url: values.attachFileURL,
        }
      ])
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style:{
          borderRadius: 20,
        }
      }}
    >
      <StyledRoot>
        <DialogContent>
          <Typography variant="h6" pb="24px">เสนอค่าชาร์จที่ต้องการ</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="chargeSalaryOffer"
                  rules={{
                    // required: {value: true, message: "กรุณากรอกชาร์จค่าแรง"},
                    validate: {
                      lessThan: (value) => {
                        if(chargeMax.chargeSalaryMax === "" || chargeMax.chargeSalaryMax === null){
                          return true
                        } else {
                          return (value <= chargeMax.chargeSalaryMax) || `ค่าชาร์จต้องไม่เกิน ${chargeMax.chargeSalaryMax} %`
                        }
                      }
                    }
                  }}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      InputProps={{
                        inputComponent: NumberCustom,
                        endAdornment: <Typography color="text.secondary">%</Typography>
                      }}
                      inputProps={{
                        allowNegative: false,
                        value: field.value,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value)
                        },
                      }}
                      label="ชาร์จค่าแรง"
                      placeholder={`0 - ${chargeMax.chargeSalaryMax}`}
                      autoComplete="chargeSalaryOffer"
                      helperText={errors && errors.chargeSalaryOffer && errors.chargeSalaryOffer.message}
                      error={errors && errors.chargeSalaryOffer ? true: false}
                      onChange={()=>{clearErrors("atLeastOne")}}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="chargeOtOffer"
                  rules={{
                    // required: {value: true, message: "กรุณากรอกชาร์จค่าOT"},
                    validate: {
                      lessThan: (value) => {
                        if(chargeMax.chargeOtMax === "" || chargeMax.chargeOtMax === null){
                          return true
                        } else {
                          return (value <= chargeMax.chargeOtMax) || `ค่าชาร์จต้องไม่เกิน ${chargeMax.chargeOtMax} %`
                        }
                      }
                    }
                  }}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      InputProps={{
                        inputComponent: NumberCustom,
                        endAdornment: <Typography color="text.secondary">%</Typography>
                      }}
                      inputProps={{
                        allowNegative: false,
                        value: field.value,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value)
                        },
                      }}
                      label="ชาร์จค่าOT"
                      placeholder={`0 - ${chargeMax.chargeOtMax}`}
                      autoComplete="chargeOtOffer"
                      helperText={errors && errors.chargeOtOffer && errors.chargeOtOffer.message}
                      error={errors && errors.chargeOtOffer ? true: false}
                      onChange={()=>{clearErrors("atLeastOne")}}
                    />

                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="chargeHireOffer"
                  rules={{
                    // required: {value: true, message: "กรุณากรอกชาร์จค่าเหมาจ่าย"},
                    validate: {
                      lessThan: (value) => {
                        if(chargeMax.chargeHireMax === "" || chargeMax.chargeHireMax === null){
                          return true
                        }
                        else if (Number(chargeHireOfferTypeWatch) === 2){
                          return (value <= 200000) || `ค่าชาร์จต้องไม่เกิน 200,000 บาท`
                        } else {
                          return (value <= chargeMax.chargeHireMax) || `ค่าชาร์จต้องไม่เกิน ${chargeMax.chargeHireMax} %`
                        }
                      }
                    }
                  }}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      className="input-with-select"
                      InputProps={{
                        inputComponent: NumberCustom,
                        endAdornment:  (
                          <Controller
                            control={control}
                            name="chargeHireOfferType"
                            render={({field}) => (
                              <TextFieldTheme
                                {...field}
                                className="select-input"
                                select
                                onChange={(e)=>{
                                  setValue("chargeHireOffer", "")
                                  clearErrors("chargeHireOffer")
                                  field.onChange(e.target.value)
                                }}
                              >
                                <MenuItem value="1">%</MenuItem>
                                <MenuItem value="2">บาท</MenuItem>
                              </TextFieldTheme>
                            )}
                          />
                        )
                      }}
                      inputProps={{
                        allowNegative: false,
                        value: field.value,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value)
                        },
                      }}
                      label="ชาร์จค่าเหมาจ่าย"
                      placeholder={Number(chargeHireOfferTypeWatch) === 1? (chargeMax.chargeHireMax  && `0 - ${chargeMax.chargeHireMax}`): "0 - 200,000"}
                      autoComplete="chargeHireOffer"
                      helperText={errors && errors.chargeHireOffer && errors.chargeHireOffer.message}
                      error={errors && errors.chargeHireOffer ? true: false}
                      onChange={()=>{clearErrors("atLeastOne")}}
                    />

                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="chargeCompensationOffer"
                  rules={{
                    // required: {value: true, message: "กรุณากรอกชาร์จค่าชดเชย"},
                    validate: {
                      lessThan: (value) => {
                        if(chargeMax.chargeCompensationMax === "" || chargeMax.chargeCompensationMax === null){
                          return true
                        } else {
                          return (value <= chargeMax.chargeCompensationMax) || `ค่าชาร์จต้องไม่เกิน ${chargeMax.chargeCompensationMax} %`
                        }
                      }
                    }
                  }}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      InputProps={{
                        inputComponent: NumberCustom,
                        endAdornment: <Typography color="text.secondary">%</Typography>
                      }}
                      inputProps={{
                        allowNegative: false,
                        value: field.value,
                        thousandSeparator: ",",
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value)
                        },
                      }}
                      label="ชาร์จค่าชดเชย"
                      placeholder={chargeMax.chargeCompensationMax  && `0 - ${chargeMax.chargeCompensationMax}`}
                      autoComplete="chargeCompensationOffer"
                      helperText={errors && errors.chargeCompensationOffer && errors.chargeCompensationOffer.message}
                      error={errors && errors.chargeCompensationOffer ? true: false}
                      onChange={()=>{clearErrors("atLeastOne")}}
                    />

                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="description"
                  // rules={{
                  //   required: {value: true, message: "กรุณากรอกชาร์จค่าชดเชย"},
                  // }}
                  render={({field}) => (
                    <TextFieldTheme
                      {...field}
                      label="ข้อความถึงบริษัท"
                      multiline
                      rows={3}
                      helperText={errors && errors.description && errors.description.message}
                      error={errors && errors.description ? true: false}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Box style={{paddingBottom: 16}}>
                  <Typography>ไฟล์แนบ (.pdf สูงสุด 1 ไฟล์ ไม่เกิน 2mb)</Typography>
                </Box>
                <Controller
                  control={control}
                  name="attachFiles"
                  render={({field}) => (
                    <DropAttachFile
                      accept="application/pdf"
                      maxFiles={1}
                      maxSize={2}
                      files={field.value}
                      onChange={field.onChange}
                      currentFiles={currentFiles}
                      onDeleteCurrentFiles={onDeleteCurrentFiles}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography color="error">
                    {errors && errors.atLeastOne && errors.atLeastOne.message}
                  </Typography>
                  <ButtonBlue variant="contained" type="submit">ประมูล</ButtonBlue>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </StyledRoot>
    </Dialog>
  )
}

export default BiddingOfferCharge;