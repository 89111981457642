import React, { Fragment } from "react";
import { styled, Paper, Typography  } from "@mui/material";

import { Link } from "react-router-dom";

import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

import ButtonBlue from "../shared/general/ButtonBlue";
import ReactSlickDemo from "./slickTopCompany";

const StyledRoot = styled("div")({
  marginTop: 120,
  "& .company-head": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-card": {
    position: "relative",
  },
});

const TopCompany = (props) => {
  const { company, count, isFetching } = props;
  return (
    <Fragment>
      {company.length > 0 && !isFetching && (
        <StyledRoot>
          <div className="company-head">
            <Typography variant="h4">บริษัทชั้นนำ</Typography>
            <Typography variant="h6">{count || 0} บริษัท</Typography>
          </div>
          <div className="wrap-card">
            <ReactSlickDemo company={company} />
          </div>
          <div style={{ textAlign: "center" }}>
            <ButtonBlue 
              variant="outlined" 
              endIcon={<ArrowRightAltOutlinedIcon />}
              component={Link} 
              to={`company-search`}
            >บริษัททั้งหมด</ButtonBlue>
          </div>
        </StyledRoot>
      )}
    </Fragment>
  );
};

export default TopCompany;