import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, styled, Typography } from "@mui/material";

import request from "../../assets/data/request"
import CardRequestManage from "./requestManageCard";
import { getCompanyMyJob } from "../../../../actions/job";

const StyledRoot = styled("div")({});

const RequestPanel = (props) => {

  const { data } = props;

  const dispatch = useDispatch();
  // const { result: userProfile } = useSelector(state => state.userProfile);
  // const { isFetching: isFetching, result: AllJobs } = useSelector(state => state.jobs);
  // const [filteredJob, setFilteredJob] = useState([]);

  // useEffect(() => {
  //   if(userProfile && userProfile.idCompany){
  //     dispatch(getCompanyMyJob());
  //   }
  // },[userProfile]);

  // useEffect(() => {
  //   if(AllJobs !== null){
  //     setFilteredJob([...AllJobs])
  //   }
  // }, [AllJobs])

  return(
    <StyledRoot>
      <Grid container spacing={3}>
        {/* {!isFetching && AllJobs && AllJobs.length > 0 && 
          AllJobs.map((job) => (
          <Grid item xs={12} sm={6} md={4} key={job.idJob}>
            <CardRequestManage value={job} />
          </Grid>
        ))} */}

        {data.map((job) => (
          <Grid item xs={12} sm={6} md={4} key={job.idJob}>
            <CardRequestManage value={job} />
          </Grid>
        ))}

        {/* {isFetching && <Grid item xs={12}><Typography variant="h5" textAlign="center">กำลังโหลดข้อมูล...</Typography></Grid>} */}
        {data.length === 0 && <Grid item xs={12}><Typography variant="h5" textAlign="center">ไม่พบงาน</Typography></Grid>}
      </Grid>
    </StyledRoot>
  )
}

export default RequestPanel